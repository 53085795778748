import React, { useState } from 'react';
import Select from 'react-select';
import isEqual from 'fast-deep-equal';

import { CLIP_NAME, CAP_CLIP_NAME } from 'constants/common';

import {
  Button,
  AlertDialogIcon,
  Flex,
  MdIcon,
  Text,
  Textarea,
  useTheme,
} from '@workshop/ui';

import { hooks } from 'utils';
import { useWindowDimensions } from 'utils/hooks/useDimensions';

import { Orientation } from 'types/cms';
import { ModalVideo } from 'components/ModalVideo';

export interface VideoClipItem {
  id: number;
  image?: string;
  name: string;
  orientation?: Orientation;
  stepId?: number;
  summary?: string;
  script?: string;
  subtitles?: string;
  videoSrc: string;
  mediaType: 'video' | 'audio' | 'image' | 'text' | 'embed';
}

interface VideoClipsState {
  [key: number]: VideoClipItem;
}

interface VideoClipsListProps {
  availableSteps?: { id: string; index: number }[];
  uploadInProgress?: boolean;
  videoClips: VideoClipItem[];
  isDisabled?: boolean;
  isTextEditingDisabled?: boolean;
  onDelete: (clipId: number) => Promise<void>;
  onSave: (videoClips: VideoClipItem[]) => Promise<any>;
}

/**
 * Turn an array of VideoClipItem into an object indexing VideoClipItem by id
 */
const videoClipsToState = (clips: VideoClipItem[]) =>
  clips.reduce((acc, clip) => ({ ...acc, [clip.id]: clip }), {});

const VideoClipThumbnail: React.FC<{
  imageUrl?: string;
  mediaType: string;
}> = ({ imageUrl, mediaType }) => (
  <Flex
    alignItems="center"
    backgroundColor="background.tint3"
    backgroundImage={imageUrl && `url(${imageUrl})`}
    backgroundPosition="center"
    backgroundSize="cover"
    borderRadius="sm"
    boxSize="image.xs"
    justifyContent="center"
    mr={2}
    my={{ base: 2, sm: 0 }}
    boxShadow="md"
  >
    {!Boolean(imageUrl) && (
      <MdIcon
        name={
          mediaType === 'video'
            ? 'Movie'
            : mediaType === 'audio'
            ? 'Mic'
            : mediaType === 'text'
            ? 'Title'
            : mediaType === 'embed'
            ? 'Code'
            : 'PhotoCamera'
        }
        color="icon.muted"
        boxSize={8}
      />
    )}
  </Flex>
);

const VideoClipMeta: React.FC<{
  clipName: string;
  clipSummary?: string;
  onPlay: () => void;
  onClickEditSummary: () => void;
  onChangeSummary: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onDelete: () => Promise<void>;
  onSaveSummary: (summary?: string) => Promise<any>;
  isEditingSummary: boolean;
  isDisabled?: boolean;
  isTextEditingDisabled?: boolean;
  isLoading?: boolean;
}> = ({
  clipName,
  clipSummary,
  onPlay,
  onClickEditSummary,
  onChangeSummary,
  onDelete,
  onSaveSummary,
  isEditingSummary,
  isDisabled = false,
  isTextEditingDisabled = false,
  isLoading = false,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const theme = useTheme();
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < parseInt(theme.breakpoints.md, 10);

  return (
    <Flex
      alignItems={{ base: 'normal', md: 'center' }}
      bg="white"
      borderRadius="md"
      flex={1}
      p={2}
      boxShadow="md"
      flexDirection={{ base: 'column', md: 'row' }}
    >
      <Flex alignItems="center" flex={1} mb={{ base: 2, md: 0 }}>
        <Button
          secondary
          display="flex"
          size="sm"
          icon="OpenInFull"
          mr={2}
          onClick={onPlay}
          px={2}
        ></Button>
        <Text
          mr={3}
          overflow="hidden"
          // https://github.com/chakra-ui/chakra-ui/issues/662
          style={{ textOverflow: 'ellipsis' }}
          flex={1}
          fontSize="sm"
          color="text.muted"
          overflowWrap="anywhere"
          display="-webkit-box"
          sx={{
            '-webkit-box-orient': 'vertical',
            '-webkit-line-clamp': '3',
          }}
        >
          {clipName}
        </Text>
      </Flex>
      <Flex alignItems="center" justifyContent="flex-end">
        {isEditingSummary || clipSummary ? (
          <>
            <Textarea
              // value={clipSummary}
              defaultValue={clipSummary}
              flex={1}
              placeholder={`Write a summary for this ${CLIP_NAME}...`}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                onChangeSummary(e);
                setIsEditing(true);
              }}
              isDisabled={isDisabled && isTextEditingDisabled}
              mr="defaultMargin"
            />
            {(!isDisabled || !isTextEditingDisabled) && (
              <Button
                size="sm"
                isLoading={isSubmitting}
                isDisabled={isSubmitting || !isEditing}
                onClick={async () => {
                  setIsSubmitting(true);
                  await onSaveSummary(clipSummary);
                  setIsSubmitting(false);
                  setIsEditing(false);
                }}
              >
                Save
              </Button>
            )}
          </>
        ) : (
          <Button
            secondary
            display="flex"
            size="sm"
            icon="ModeEdit"
            mr={2}
            onClick={onClickEditSummary}
            isDisabled={isDisabled && isTextEditingDisabled}
            px={{ base: 2, md: 3 }}
          >
            {isMobile ? '' : 'Summary'}
          </Button>
        )}
        {!isDisabled && (
          <AlertDialogIcon
            name="RemoveCircle"
            alertHeader={`Delete This ${CAP_CLIP_NAME}`}
            alertBody={`Are you sure you would like to delete this ${CLIP_NAME}?`}
            submitBtnLabel="Delete"
            submitBtnColor="red"
            onSubmit={onDelete}
            onCancel={() => {}}
            color={isLoading ? 'neutral.300' : 'red.300'}
            cursor={isLoading ? 'default' : 'pointer'}
            ml={2}
            boxSize="18px"
            minW="18px"
            minH="18px"
          />
        )}
      </Flex>
    </Flex>
  );
};

const VideoClipsList: React.FC<VideoClipsListProps> = ({
  availableSteps = [],
  videoClips: videoClipsProps,
  uploadInProgress = false,
  isDisabled = false,
  isTextEditingDisabled = false,
  onDelete,
  onSave,
}) => {
  const [playingClipId, setPlayingClipId] = useState<number | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Formatted state copy of the `videoClips` prop data
  const [videoClips, setVideoClips] = useState<VideoClipsState>(
    videoClipsToState(videoClipsProps)
  );

  // Dictionary to allow us to keep track of which clip summaries should
  // display as editable within the list of clips
  const [editingSummary, setEditingSummary] = useState<{
    [key: number]: boolean;
  }>({});

  // If the `videoClips` prop data changes then reset our local `videoClips`
  // state to reflect the most up to date changes
  hooks.useDeepEqualEffect(() => {
    setVideoClips(videoClipsToState(videoClipsProps));
  }, [videoClipsProps]);

  // Determine whether a video clip should be displayed in our ModalVideo
  const playingClip = playingClipId ? videoClips[playingClipId] : null;

  // Turn the list of `availableSteps` into a list of Option's for the Select
  // widget. Order by index and adjust the index by '-1' to account for the
  // fact `availableSteps` will only ever include 'normal' steps and not 'intro'
  // steps.
  const stepOptions = availableSteps
    .sort((a, b) => a.index - b.index)
    .map((step) => ({
      label: (step.index - 1).toString(),
      value: step.id,
    }));

  // A simple dirty flag to determine whether the user should be able to
  // save the form
  const saveEnabled =
    !uploadInProgress &&
    availableSteps?.length &&
    !isEqual(videoClips, videoClipsToState(videoClipsProps));

  // Sort clips by filename, this ensures that clips maintain their
  // intended order here and also when they are assigned to steps on save
  const sortedClips = Object.values({ ...videoClips }).sort((a, b) => {
    try {
      // Sort alphanumeric strings "naturally" – e.g. a1, a5, a10, a11
      return a.name.localeCompare(b.name, 'en', { numeric: true });
    } catch (e) {
      // If not supported, fall back to sort alphabetically – e.g. a1, a10, a11, a5
      console.warn('Extended localeCompare() not supported in this browser.');
      return a.name.localeCompare(b.name);
    }
  });

  return (
    <Flex flexDir="column">
      <ModalVideo
        isOpen={Boolean(playingClip)}
        isDisabled={isDisabled && isTextEditingDisabled}
        video={playingClip?.videoSrc || ''}
        videoHq={playingClip?.videoSrc || ''}
        subtitles={playingClip?.subtitles || ''}
        summary={playingClip?.summary || ''}
        script={playingClip?.script || ''}
        onClose={() => setPlayingClipId(null)}
        onSaveSummary={async (summary) => {
          if (!playingClip) return;

          await onSave([
            {
              ...playingClip,
              summary,
            },
          ]);
        }}
        onSaveScript={async (script) => {
          if (!playingClip) return;

          await onSave([
            {
              ...playingClip,
              script,
            },
          ]);
        }}
        mediaType={playingClip?.mediaType}
        orientation={playingClip?.orientation}
        clipId={`${playingClip?.id}`}
      />
      <Flex
        bg="background.tint3"
        flexDir="column"
        borderRadius="md"
        px={3}
        pt={3}
        pb={1}
      >
        <Flex justifyContent="space-between" mb={2}>
          <Text color="text.muted" fontWeight="semibold">
            {`Unassigned ${CAP_CLIP_NAME}s`}
          </Text>
          <Text color="text.muted" fontWeight="semibold">
            Assign to Step
          </Text>
        </Flex>
        {sortedClips.map((clip) => (
          <Flex key={`video-list-item-${clip.id}`} alignItems="center" mb={2}>
            <Flex
              flexDirection={{ base: 'column', sm: 'row' }}
              alignItems={{ base: 'normal', sm: 'center' }}
              flex={1}
            >
              <VideoClipThumbnail
                key={`video-list-thumb-${clip.id}`}
                imageUrl={clip.image}
                mediaType={clip.mediaType}
              />
              <VideoClipMeta
                key={`video-list-meta-${clip.id}`}
                clipName={clip.name}
                clipSummary={clip.summary}
                onPlay={() => setPlayingClipId(clip.id)}
                onClickEditSummary={() =>
                  setEditingSummary((prev) => ({ ...prev, [clip.id]: true }))
                }
                onChangeSummary={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  setVideoClips({
                    ...videoClips,
                    [clip.id]: { ...clip, summary: e.currentTarget.value },
                  })
                }
                onSaveSummary={async (summary) => {
                  await onSave([
                    {
                      ...clip,
                      summary,
                    },
                  ]);
                }}
                onDelete={() =>
                  isSubmitting ? Promise.resolve() : onDelete(clip.id)
                }
                isEditingSummary={editingSummary[clip.id]}
                isDisabled={isDisabled}
                isTextEditingDisabled={isTextEditingDisabled}
                isLoading={isSubmitting}
              />
            </Flex>
            <Flex minW="100px" ml={4} alignItems="center">
              <Flex flexDir="column" flex={1}>
                <Select
                  placeholder=""
                  options={stepOptions}
                  value={
                    stepOptions.find(
                      ({ value }) => value === clip.stepId?.toString()
                    ) || null
                  }
                  isDisabled={isDisabled}
                  onChange={(option) => {
                    if (option) {
                      setVideoClips({
                        ...videoClips,
                        [clip.id]: { ...clip, stepId: parseInt(option.value) },
                      });
                    }
                  }}
                />
              </Flex>
            </Flex>
          </Flex>
        ))}
      </Flex>
      {(!isDisabled || !isTextEditingDisabled) && (
        <Flex mt={3} justifyContent="flex-end">
          <Button
            isDisabled={isSubmitting || !saveEnabled}
            mr={2}
            secondary
            onClick={() => {
              setEditingSummary({});
              setVideoClips(videoClipsToState(videoClipsProps));
            }}
          >
            Cancel
          </Button>
          <Button
            isLoading={isSubmitting}
            isDisabled={isSubmitting || !saveEnabled}
            onClick={async () => {
              setIsSubmitting(true);
              await onSave(sortedClips);
              setIsSubmitting(false);
              setEditingSummary({});
            }}
          >
            Save
          </Button>
        </Flex>
      )}
    </Flex>
  );
};

export default VideoClipsList;
