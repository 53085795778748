import { decamelizeKeys } from 'humps';
import {
  CMSStepListSchema,
  CMSStepSchema,
  CMSMCQSchema,
} from 'redux/schemas/cms';

import { callAPIWithTeam } from 'utils';
import API from 'constants/api';

import { StepAT } from 'redux/actionTypes/cms';

import {
  CreatePromptAction,
  FetchStepAction,
  PatchStepAction,
  StepCreateAction,
  StepListAction,
  IStepListItem,
  IStep,
  DeleteStepAction,
  CreateStepQuestions,
  FetchStepQuestionsAction,
  UpdateStepQuestions,
  IPrompt,
} from 'types/cms';

export const list = (sessionId: number) => {
  return callAPIWithTeam<StepListAction>({
    types: [
      StepAT.CMS_FETCH_STEP_LIST_REQUEST,
      StepAT.CMS_FETCH_STEP_LIST_SUCCESS,
      StepAT.CMS_FETCH_STEP_LIST_FAILURE,
    ],
    endpoint: API.cms.sessionSteps(sessionId),
    method: 'GET',
    schema: [CMSStepListSchema],
    path: 'results',
  });
};

export const create = (
  sessionId: number,
  data: Partial<IStepListItem>,
  noToast?: boolean
) => {
  return callAPIWithTeam<StepCreateAction>({
    types: [
      StepAT.CMS_CREATE_STEP_REQUEST,
      StepAT.CMS_CREATE_STEP_SUCCESS,
      StepAT.CMS_CREATE_STEP_FAILURE,
    ],
    endpoint: API.cms.sessionSteps(sessionId),
    method: 'POST',
    body: decamelizeKeys(data),
    schema: CMSStepListSchema,
    meta: {
      toast: { success: noToast ? false : 'Successfully created step.' },
    },
  });
};

export const retrieve = (id: number) => {
  return callAPIWithTeam<FetchStepAction>({
    types: [
      StepAT.CMS_FETCH_STEP_REQUEST,
      StepAT.CMS_FETCH_STEP_SUCCESS,
      StepAT.CMS_FETCH_STEP_FAILURE,
    ],
    endpoint: API.cms.step(id),
    method: 'GET',
    schema: CMSStepSchema,
  });
};

type PromptData = Omit<IPrompt, 'id'>;
type StepUpdate = Omit<IStep, 'prompt'> & {
  prompt?: PromptData | {};
};

export const update = (id: number, data: Partial<StepUpdate>) => {
  return callAPIWithTeam<PatchStepAction>({
    types: [
      StepAT.CMS_PATCH_STEP_REQUEST,
      StepAT.CMS_PATCH_STEP_SUCCESS,
      StepAT.CMS_PATCH_STEP_FAILURE,
    ],
    endpoint: API.cms.step(id),
    method: 'PATCH',
    body: data instanceof FormData ? data : decamelizeKeys(data),
    schema: CMSStepSchema,
  });
};

export const remove = (id: number) => {
  return callAPIWithTeam<DeleteStepAction>({
    types: [
      StepAT.CMS_DELETE_STEP_REQUEST,
      StepAT.CMS_DELETE_STEP_SUCCESS,
      StepAT.CMS_DELETE_STEP_FAILURE,
    ],
    endpoint: API.cms.step(id),
    method: 'DELETE',
    schema: CMSStepSchema,
    meta: {
      stepId: id,
      toast: { success: 'Successfully deleted step' },
    },
  });
};

interface MCQCreateData {
  session: number;
  step: number;
  data: {
    answerOrder?: 'index' | 'random';
    answers: { content: string; correct: boolean; id?: string | number }[];
    content: string;
    explanation?: string;
  };
}

interface MCQUpdateData extends MCQCreateData {
  question: number;
}

export const stepQuestionActions = {
  create: ({ session, step, data }: MCQCreateData) => {
    return callAPIWithTeam<CreateStepQuestions>({
      types: [
        StepAT.CMS_CREATE_STEP_MCQ_REQUEST,
        StepAT.CMS_CREATE_STEP_MCQ_SUCCESS,
        StepAT.CMS_CREATE_STEP_MCQ_FAILURE,
      ],
      endpoint: API.cms.stepMCQ(session, step),
      method: 'POST',
      body: decamelizeKeys(data),
    });
  },

  update: ({ session, step, question, data }: MCQUpdateData) => {
    return callAPIWithTeam<UpdateStepQuestions>({
      types: [
        StepAT.CMS_UPDATE_STEP_MCQ_REQUEST,
        StepAT.CMS_UPDATE_STEP_MCQ_SUCCESS,
        StepAT.CMS_UPDATE_STEP_MCQ_FAILURE,
      ],
      endpoint: API.cms.stepMCQ(session, step, question),
      method: 'PATCH',
      body: decamelizeKeys(data),
    });
  },

  list: (questionIds: number[]) => {
    return callAPIWithTeam<FetchStepQuestionsAction>({
      types: [
        StepAT.CMS_FETCH_STEP_MCQ_LIST_REQUEST,
        StepAT.CMS_FETCH_STEP_MCQ_LIST_SUCCESS,
        StepAT.CMS_FETCH_STEP_MCQ_LIST_FAILURE,
      ],
      endpoint: API.cms.questions(questionIds),
      method: 'GET',
      path: 'results',
      schema: [CMSMCQSchema],
    });
  },
};

export const stepPromptActions = {
  create: (data: PromptData) => {
    return callAPIWithTeam<CreatePromptAction>({
      types: [
        StepAT.CMS_CREATE_PROMPT_REQUEST,
        StepAT.CMS_CREATE_PROMPT_SUCCESS,
        StepAT.CMS_CREATE_PROMPT_FAILURE,
      ],
      endpoint: API.cms.prompt,
      method: 'POST',
      body: decamelizeKeys(data),
    });
  },
};
