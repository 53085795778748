import React, { useState } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';

import navRoutes from 'navigation/Routes';

import { useCurrentTeam } from 'redux/selectors';

import { ScreenWrapper } from 'screens/common/ScreenWrapper';
import {
  Button,
  Card,
  Text,
  Flex,
  Stack,
  VStack,
  LinkButton,
} from '@workshop/ui';

import AccountBasics from './AccountBasics';
import RedeemCoupon from './RedeemCoupon';
import SubscriptionManagement from './SubscriptionManagement';
import AccountManagement from './AccountManagement';
import PaymentSettings from './PaymentSettings';
import SelectTheme from './SelectTheme';
import Cookies from './Cookies';
import Accessibility from './Accessibility';

import { AppleResponse } from 'components/SocialAuth/AppleButton';
import { FacebookResponse } from 'components/SocialAuth/FacebookButton';
import { SectionTitle } from 'components/Common';

import { GlobalState } from 'types';
import { ProviderTypes, PROVIDERS } from 'types/common';
import { profileActions, authActions } from 'redux/actions/common';

import { hooks, analytics, getFlagEmoji } from 'utils';

import { PLATFORM } from 'constants/env';
import { COUNTRY_OPTIONS } from 'constants/settings';
import { PLATFORM_DISPLAY_NAME, PLATFORM_EMAIL } from 'constants/common';

const BR = '%0D%0A'; // Break Line in email link

type PropsFromRedux = ConnectedProps<typeof connector>;

interface SettingsProps extends PropsFromRedux {}

const SettingsScreen: React.FC<SettingsProps> = ({
  userDetails,
  userCoupons,
  userSubscriptions,
  socialProviders,
  socialConnections,
  paymentSettings,
  userProfile,
}) => {
  const { email, name, unverifiedEmail } = userDetails;
  const { country, dateOfBirth } = userProfile;
  const dispatch = useDispatch();
  const history = useHistory();

  const currentTeam = useCurrentTeam();

  const [socialConnectionLoadingState, setSocialConnectionLoadingState] =
    useState<Record<ProviderTypes, boolean>>({
      facebook: false,
      apple: false,
    });

  const {
    userCoupons: userCouponsLoading,
    userSubscriptions: userSubscriptionsLoading,
    socialProviders: socialProvidersLoading,
    socialConnections: socialConnectionsLoading,
    paymentSettings: paymentSettingsLoading,
  } = hooks.useLoadingDataState(
    {
      userCoupons: { actions: [() => profileActions.coupons.fetch()] },
      userSubscriptions: {
        actions: [() => profileActions.subscriptions.fetch()],
      },
      socialProviders: {
        actions: [() => authActions.social.fetchProviders()],
      },
      socialConnections: {
        actions: [() => authActions.social.fetchConnections()],
      },
      paymentSettings: {
        actions: [() => profileActions.payment.fetch()],
      },
    },
    []
  );

  const handleSubmitAccountBasics = async (values: {
    email: string;
    name: string;
    country: string;
    dateOfBirth: string;
  }) => {
    const data: {
      name: string;
      profile: { country: string; dateOfBirth: string };
      email?: string;
    } = {
      name: values.name,
      profile: { country: values.country, dateOfBirth: values.dateOfBirth },
    };

    if (
      email.toLowerCase() !== values.email.toLowerCase() &&
      unverifiedEmail?.toLowerCase() !== values.email.toLowerCase()
    ) {
      // Only process the email if changed to prevent email verification errors
      data['email'] = values.email;
    }

    return await dispatch(profileActions.updateUserProfile(data));
  };

  const handleResendVerificationEmail = async () => {
    if (unverifiedEmail)
      return await dispatch(
        profileActions.emailVerification.resend(unverifiedEmail)
      );
  };

  const handleCancelEmailChange = async () => {
    if (unverifiedEmail)
      return await dispatch(profileActions.emailVerification.cancel());
  };

  const handleResetPassword = async () =>
    await dispatch(authActions.password.reset({ data: { email } }));

  const handleRedeemCoupon = async (coupon: { code: string }) =>
    await dispatch(profileActions.coupons.redeem(coupon));

  const handleDeleteAccount = async (deleteType: 'DELETE' | 'DEACTIVATE') => {
    // The delete account API handles both deletion and deactivation depending on
    // the "type" parameter passed through
    return await dispatch(profileActions.account.delete(deleteType)).then(
      (res) => {
        if (!res.error) {
          // On successful deletion or deactivation of User account we log them out
          dispatch(authActions.tokenDeleteRequest());
          analytics.reset();
        }
      }
    );
  };

  const handleConnectSocialAccount = async (
    params:
      | {
          provider: 'facebook';
          response: FacebookResponse;
        }
      | {
          provider: 'apple';
          response: AppleResponse;
        }
  ) => {
    let data: {
      accessToken?: string;
      idToken?: string;
    } = {};

    if (!PROVIDERS.includes(params.provider)) return;

    if (params.provider === 'facebook') {
      if ('status' in params.response) {
        // TODO: Handle error
        return;
      }
      const { accessToken } = params.response;
      data.accessToken = accessToken;
    }

    if (params.provider === 'apple') {
      if ('error' in params.response) {
        // TODO: Handle error - should be handled by onError
        return;
      }

      // TODO: TODO: Don't know how to handle
      if ('code' in params.response) return;

      const { authorization } = params.response;
      const { code, id_token } = authorization;
      data = {
        accessToken: code,
        idToken: id_token,
      };
    }

    setSocialConnectionLoadingState((prev) => ({
      ...prev,
      [params.provider]: true,
    }));

    await dispatch(authActions.socialConnect(data, params.provider));

    setSocialConnectionLoadingState((prev) => ({
      ...prev,
      [params.provider]: false,
    }));
  };

  const handleRemoveSocialConnection = async (id: string, provider: string) => {
    setSocialConnectionLoadingState((prev) => ({ ...prev, [provider]: true }));
    await dispatch(authActions.social.removeConnection(id, provider));
    setSocialConnectionLoadingState((prev) => ({ ...prev, [provider]: false }));
  };

  const handleUpdateCardDetails = async (id: string) =>
    await dispatch(profileActions.payment.update(id));

  const isLoading =
    userCouponsLoading ||
    userSubscriptionsLoading ||
    socialProvidersLoading ||
    socialConnectionsLoading ||
    paymentSettingsLoading;

  const currentYear = new Date().getFullYear();

  return (
    <>
      <ScreenWrapper>
        <Flex zIndex={4} mt={-5} mb={8} mx={{ base: 'defaultMargin', md: 0 }}>
          <LinkButton
            to={navRoutes.common.home.path()}
            icon="ArrowBack"
            variant="outline"
            size="sm"
          >
            Back Home
          </LinkButton>
        </Flex>
        <Stack spacing="defaultMargin" mb={16}>
          <Flex flex={1} flexDir="column">
            <VStack alignItems="stretch" spacing={5}>
              <Flex flexDir="column">
                <SectionTitle title="Theme" />
                <SelectTheme />
              </Flex>
              <Flex flexDir="column">
                <SectionTitle title="Account Basics" />
                <AccountBasics
                  onSubmit={handleSubmitAccountBasics}
                  onCancelEmailVerification={handleCancelEmailChange}
                  onResendVerificationEmail={handleResendVerificationEmail}
                  countryOptions={Object.keys(COUNTRY_OPTIONS).reduce(
                    (acc, key) => ({
                      ...acc,
                      [key]:
                        key === '-'
                          ? '-'
                          : `${getFlagEmoji(key.slice(0, 2))} ${
                              COUNTRY_OPTIONS[key]
                            }`,
                    }),
                    {}
                  )}
                  email={email}
                  unverifiedEmail={unverifiedEmail}
                  name={name}
                  country={country}
                  dateOfBirth={dateOfBirth}
                  isLoading={isLoading}
                />
              </Flex>
              <Flex flexDir="column">
                <SectionTitle title="Redeem Coupon" />
                <RedeemCoupon
                  onSubmit={handleRedeemCoupon}
                  userCoupons={userCoupons}
                  isLoading={isLoading}
                />
              </Flex>
              <Flex flexDir="column">
                <SectionTitle title="Course Payment Settings" />
                <PaymentSettings
                  isLoading={isLoading}
                  onSubmit={handleUpdateCardDetails}
                  paymentSettings={paymentSettings}
                />
              </Flex>
              {Boolean(PLATFORM === 'steppit' && currentTeam) && (
                <Flex flexDir="column">
                  <SectionTitle title="Channel Settings" />
                  <Card padding={3}>
                    <Flex
                      w="100%"
                      alignItems={{ base: 'flex-start', md: 'center' }}
                      flexDirection={{ base: 'column', md: 'row' }}
                    >
                      <Text
                        pr={{ base: 0, md: 2 }}
                        pb={{ base: 2, md: 0 }}
                        flex={1}
                      >
                        {`You can manage your channel's settings from its Admin tab`}
                        .
                      </Text>
                      <Button
                        alignSelf="flex-end"
                        size="sm"
                        onClick={() =>
                          history.push({
                            pathname: navRoutes.cms.myOrganisation.path(),
                          })
                        }
                      >
                        Open Channel Admin
                      </Button>
                    </Flex>
                  </Card>
                </Flex>
              )}
              {PLATFORM === 'workshop' && (
                <Flex flexDir="column">
                  <SectionTitle title="Subscription Management" />
                  <SubscriptionManagement
                    activeSubscriptions={userSubscriptions}
                    isLoading={isLoading}
                  />
                </Flex>
              )}
              <Flex flexDir="column">
                <SectionTitle title="Account Management" />
                <AccountManagement
                  isLoading={isLoading}
                  requestDataLink={`mailto:support${PLATFORM_EMAIL}?subject=Request of user data&body=Hi there,${BR}${BR}I would like to request a copy of my ${PLATFORM_DISPLAY_NAME} user data.${BR}${BR}My email address is: ${
                    email || ''
                  }.${BR}${BR}Thanks,${BR}${name || ''}`}
                  socialProviders={socialProviders}
                  socialConnections={socialConnections}
                  socialConnectionLoadingState={socialConnectionLoadingState}
                  handleDeleteAccount={handleDeleteAccount}
                  handleResetPassword={handleResetPassword}
                  handleConnect={handleConnectSocialAccount}
                  removeSocialConnection={handleRemoveSocialConnection}
                />
              </Flex>
              <Flex flexDir="column">
                <SectionTitle title="Accessibility" />
                <Accessibility />
              </Flex>
              <Flex flexDir="column">
                <SectionTitle title="Cookies" />
                <Cookies />
              </Flex>
            </VStack>
          </Flex>
        </Stack>
      </ScreenWrapper>
      <Stack
        direction="row"
        position="absolute"
        bottom={16}
        right={0}
        display={{ base: 'none', sm: 'flex' }}
      >
        <Link to={navRoutes.global.terms.path()}>
          <Flex
            alignItems="center"
            paddingX={2}
            cursor="pointer"
            _hover={{ bg: 'background.tint3' }}
            borderRadius="full"
            mr={2}
          >
            <Text fontSize="sm" color="text.muted">
              Terms
            </Text>
          </Flex>
        </Link>
        <Link to={navRoutes.global.privacy.path()}>
          <Flex
            alignItems="center"
            paddingX={2}
            cursor="pointer"
            _hover={{ bg: 'background.tint3' }}
            borderRadius="full"
            mr={2}
          >
            <Text fontSize="sm" color="text.muted">
              Privacy
            </Text>
          </Flex>
        </Link>
        <Link to={navRoutes.global.cookies.path()}>
          <Flex
            alignItems="center"
            paddingX={2}
            cursor="pointer"
            _hover={{ bg: 'background.tint3' }}
            borderRadius="full"
            mr={2}
          >
            <Text fontSize="sm" color="text.muted">
              Cookies
            </Text>
          </Flex>
        </Link>
      </Stack>
    </>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  userDetails: state.user.userDetails,
  userCoupons: state.user.userCoupons,
  userSubscriptions: state.user.userSubscriptions,
  socialProviders: state.auth.socialProviders,
  socialConnections: state.auth.socialConnections,
  paymentSettings: state.user.userPaymentSettings,
  userProfile: state.user.userProfile,
});

const connector = connect(mapStateToProps);

export default connector(SettingsScreen);
