import { useDispatch } from 'react-redux';
import { S3Client } from '@aws-sdk/client-s3';
import { Upload } from '@aws-sdk/lib-storage';

import { getCookie } from 'utils';
import API from 'constants/api';
import { STATUS } from 'constants/background';
import { BUCKET, CLOUDFRONT_DOMAIN } from 'constants/env';

import { backgroundActions } from 'redux/actions/common';

import { ChunkUploadStatus } from 'types/common';

import useChunkUpload from './useChunkUpload';

interface Progress {
  uploaded: number; // Percentage 0–100
  total: number; // File size in bytes
  status: ChunkUploadStatus;
}

type OnStart = () => void;
type OnCreated = (id: string, progress: Progress) => void;
type OnProgress = (id: string, progress: Progress) => void;
type OnComplete = (id: string) => void;
type OnCancel = (id: string) => void;

/**
 * Fetches short-lived STS credentials from the backend.
 * This method should return an object containing the keys:
 * { accessKeyId, secretAccessKey, sessionToken }
 */
async function fetchStsCredentials() {
  const resp = await fetch(API.cms.getUploadCreds, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'X-CSRFToken': getCookie('csrftoken'),
      'Content-Type': 'application/json',
    },
  });
  if (!resp.ok) {
    throw new Error('Failed to fetch STS credentials');
  }
  return resp.json();
}

/**
 * Internal class that manages a direct-to-S3 upload using AWS SDK v3
 * while integrating with existing Redux actions to track progress.
 */
class ChunkUpload {
  chunkSize: number;
  uploadId: string | null;
  filename: string | null;
  progress: Progress;
  onStart: OnStart;
  onCreated: OnCreated;
  onProgress: OnProgress;
  onComplete: OnComplete;
  onCancel: OnCancel;
  isDisabled: boolean;
  private currentUpload: Upload | null = null;

  constructor({
    chunkSize,
    onStart,
    onCreated,
    onProgress,
    onComplete,
    onCancel,
    isDisabled,
  }: {
    chunkSize?: number;
    onStart: OnStart;
    onCreated: OnCreated;
    onProgress: OnProgress;
    onComplete: OnComplete;
    onCancel: OnCancel;
    isDisabled: boolean;
  }) {
    this.chunkSize = chunkSize || 5542880; // Default to 5MB parts
    this.uploadId = null;
    this.filename = null;
    this.progress = {
      uploaded: 0,
      total: 0,
      status: STATUS.created,
    };

    // Disable callbacks if the user lacks permissions
    this.onStart = isDisabled ? () => {} : onStart;
    this.onCreated = isDisabled ? () => {} : onCreated;
    this.onProgress = isDisabled ? () => {} : onProgress;
    this.onComplete = isDisabled ? () => {} : onComplete;
    this.onCancel = isDisabled ? () => {} : onCancel;

    this.isDisabled = isDisabled;
  }

  /**
   * Internal helper for updating the progress object
   * and dispatching via onProgress.
   */
  private _onProgress = (progress: Partial<Progress>) => {
    this.progress = {
      ...this.progress,
      ...progress,
    };
    if (this.uploadId) {
      this.onProgress(this.uploadId, this.progress);
    } else if (this.filename) {
      this.onProgress(`temp-${this.filename}`, this.progress);
    }
  };

  /**
   * Public method triggered by the consuming code. Starts the upload process.
   */
  public startUpload = async <T = any>(file: File): Promise<T | void> => {
    if (this.isDisabled) {
      // If disabled, skip all upload logic
      console.warn('User does not have required permissions to upload.');
      return Promise.resolve();
    }
    this.onStart();
    return this.upload(file);
  };

  /**
   * Handles the core S3 upload using AWS SDK v3's @aws-sdk/lib-storage.
   * Automatically chunks large files and reports progress via Redux.
   */
  private upload = async (file: File): Promise<any> => {
    try {
      this.filename = file.name;
      // Create a temporary "upload ID" for Redux tracking
      this.uploadId = `s3-${Date.now()}-${file.name}`;
      this.onCreated(this.uploadId, this.progress);

      this._onProgress({
        uploaded: 0,
        total: file.size,
        status: STATUS.inProgress,
      });

      // Fetch temporary credentials
      const { accessKeyId, secretAccessKey, sessionToken } =
        await fetchStsCredentials();

      // Configure the S3 client
      const s3Client = new S3Client({
        region: 'eu-west-1',
        credentials: {
          accessKeyId,
          secretAccessKey,
          sessionToken,
        },
      });

      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');

      // Perform a managed multipart upload
      this.currentUpload = new Upload({
        client: s3Client,
        params: {
          Bucket: BUCKET,
          Key: `media/direct_uploads/${year}/${month}/${day}/${this.uploadId}`,
          Body: file,
        },
        queueSize: 10,
        partSize: this.chunkSize,
        leavePartsOnError: false,
      });

      this.currentUpload.on('httpUploadProgress', (evt) => {
        if (evt.total) {
          const percent = Math.round((evt.loaded / evt.total) * 100);
          this._onProgress({ uploaded: percent });
        }
      });

      const result = await this.currentUpload.done();
      return this.completeUpload(result, file.name);
    } catch (error) {
      console.error(error);
      this._onProgress({ uploaded: 0, status: STATUS.error });
      return;
    }
  };

  /**
   * Completes the upload by dispatching final progress
   * and returning the S3 file location.
   */
  private completeUpload = (uploadResult: any, filename: string) => {
    this._onProgress({
      uploaded: 100,
      status: STATUS.completed,
    });

    if (this.uploadId) {
      this.onComplete(this.uploadId);
    }
    const uploadUrl = `https://${CLOUDFRONT_DOMAIN}/${uploadResult.Key}`;
    const result = {
      payload: {
        file: uploadUrl,
        filename,
      },
    };
    return result;
  };

  /**
   * Cancels the current upload if in progress and dispatches the cancellation.
   */
  public cancelUpload = () => {
    this._onProgress({ status: STATUS.cancelled });
    if (this.currentUpload) {
      this.currentUpload.abort();
    }
    if (this.uploadId) {
      this.onCancel(this.uploadId);
    } else if (this.filename) {
      this.onCancel(`temp-${this.filename}`);
    }
  };
}

/**
 * Hook for creating a ChunkUpload instance with callbacks that
 * dispatch backgroundActions to Redux. This ensures the UI can
 * track status, progress, and completion without changes to
 * the rest of the application code.
 */
const useDirectUpload = (isDisabled = false) => {
  const dispatch = useDispatch();
  // (For local dev)
  // return useChunkUpload(isDisabled);
  const _onStart =
    (filename: string, filesize: number, metadata?: Object) => () =>
      dispatch(
        backgroundActions.startChunkUpload({
          filename,
          filesize,
          metadata,
        })
      );

  const _onCreated =
    (filename: string, filesize: number, metadata?: Object) =>
    (id: string, progress: Progress) =>
      dispatch(
        backgroundActions.createChunkUpload({
          id,
          progress: progress.uploaded,
          status: progress.status,
          filename,
          filesize,
          metadata,
        })
      );

  const _onProgress =
    (filename: string, filesize: number) => (id: string, progress: Progress) =>
      dispatch(
        backgroundActions.updateChunkUpload({
          id,
          progress: progress.uploaded,
          status: progress.status,
          filename,
          filesize,
        })
      );

  const onComplete = (id: string) =>
    dispatch(backgroundActions.completeDirectUpload(id));

  const onCancel = (id: string) =>
    dispatch(backgroundActions.cancelChunkUpload(id));

  /**
   * Factory method for creating a new ChunkUpload instance.
   * The returned object has methods for:
   *   - startUpload(file)
   *   - cancelUpload()
   */
  const createChunkUpload = (
    filename: string,
    filesize: number,
    metadata?: Object,
    chunkSize?: number
  ) => {
    const onStart = _onStart(filename, filesize, metadata);
    const onCreated = _onCreated(filename, filesize, metadata);
    const onProgress = _onProgress(filename, filesize);

    return new ChunkUpload({
      onStart,
      onCreated,
      onProgress,
      onComplete,
      onCancel,
      chunkSize,
      isDisabled,
    });
  };

  return createChunkUpload;
};

export default useDirectUpload;
