import React, { useState } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Range } from 'react-date-range';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { saveAs } from 'file-saver';

import isEmail from 'validator/lib/isEmail';

import { hooks, courseUtils, analytics } from 'utils';

import navRoutes from 'navigation/Routes';

import { useCurrentTeam, useCurrentTeamProfile } from 'redux/selectors';
import { useHasPermission } from 'redux/selectors/organisation';
import { useWindowDimensions } from 'utils/hooks/useDimensions';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AlertDialogButton,
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  MdIcon,
  Skeleton,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  Text,
  chakra,
  useTheme,
  useDisclosure,
} from '@workshop/ui';

import {
  AnimatedButton,
  DateRangeInput,
  LabelCheckbox,
  LabelDatePicker,
  LabelInput,
  LabelSelect,
  LabelWrapper,
  ConfirmModal,
} from 'components/Common';
import {
  EditModal,
  Props as EditModalProps,
} from 'components/Common/EditModal';
import { IconTooltip } from 'components/IconTooltip';
import { EnrolStudentsModal } from 'components/CohortAllocation';
import { Loading } from 'components/Loading';
import { HeaderTag } from 'components/AppHeader';
import { ClassTypeItem } from 'components/CohortAllocation';

import { discourseActions } from 'redux/actions/common';
import {
  cohortActions,
  enrolmentActions,
  courseActions,
  assessmentActions,
} from 'redux/actions/cms';

import { GlobalState } from 'types';
import {
  AccessType,
  Cohort,
  CohortStatus,
  SocialType,
  TeamMember,
  CertificateLog,
  PERMISSION_SLUGS,
} from 'types/common';
import { License } from 'types/cms';

import { PRIVATE_CLASS_SIZE_LIMIT } from 'constants/courses';
import { PLATFORM } from 'constants/env';
import {
  ENROLMENT_BLACKLIST,
  ADVANCED_COHORT_OPTIONS_WHITELIST,
} from 'constants/organisation';

import './EditCohortModal.css';

import { JournalEntryModal } from 'screens/cms/ManageCohorts/src/JournalEntryTable';
import {
  JournalUnitSummary,
  CohortMember,
} from 'screens/cms/ManageCohorts/src';

export interface EditCohortFormData {
  accessType: AccessType;
  isPrivate: boolean;
  isTest: boolean;
  isAnonymous: boolean;
  onSale: boolean;
  label: string;
  socialType: SocialType;
  spacesTotal: number;
  privateChatDisabled: boolean;
  mentors: {
    mentorId: string;
    isAssessor: boolean;
    isSubstitute: boolean;
    subDetails?: {
      subStart: Date | undefined;
      subEnd: Date | undefined;
    };
  }[];
  status: CohortStatus;
}

const labelStyleProps = { whiteSpace: 'nowrap' } as const;

type CohortStudentItemProps = {
  completedUnits?: number;
  isLoading?: boolean;
  expiryDate?: string;
  showExpiryDate?: boolean;
  otherCourses?: (string | undefined)[];
  totalUnits?: number;
  courseProgressId?: number;
  currentCohort: Cohort;
  username?: string;
  name?: string;
  userId?: number;
  certificate?: CertificateLog;
  enrolmentId?: number;
  mutedName?: boolean;
};

const SendCertificateDialog: React.FC<{
  isAnonymous: boolean;
  onSend: (email?: string) => Promise<void>;
}> = ({ isAnonymous, onSend }) => {
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  if (emailSent) {
    return (
      <Flex
        // @ts-ignore - Complex union type that TypeScript has trouble with
        backgroundColor="background.success"
        color="text.success"
        padding={4}
        borderRadius="md"
        alignItems="center"
        justifyContent="center"
        mt={2}
      >
        <MdIcon name="MarkEmailRead" />
        <Text ml={2} fontWeight="semibold">
          Certificate Sent
        </Text>
      </Flex>
    );
  }
  return (
    <>
      {isAnonymous ? (
        <>
          <Text mb={4}>
            Please enter an email address below to send this certificate to your
            student.
          </Text>
          <LabelInput
            id="email"
            name="email"
            label="Email"
            labelPosition="top"
            defaultValue={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
          />
          <Flex justifyContent="flex-end">
            <Button
              icon="Send"
              isDisabled={!isEmail(email)}
              onClick={async () => {
                const res: any = await onSend(email);
                if (!res?.error) {
                  setEmailSent(true);
                }
              }}
            >
              Send Certificate
            </Button>
          </Flex>
        </>
      ) : (
        <>
          <Text mb={4}>
            Please confirm that you'd like to email a certificate to this
            student.
          </Text>
          <Flex justifyContent="flex-end">
            <Button
              icon="Send"
              onClick={async () => {
                const res: any = await onSend();
                if (!res?.error) {
                  setEmailSent(true);
                }
              }}
            >
              Yes, Send
            </Button>
          </Flex>
        </>
      )}
    </>
  );
};

const CohortStudentItem: React.FC<CohortStudentItemProps> = ({
  completedUnits,
  isLoading,
  expiryDate,
  showExpiryDate,
  otherCourses,
  totalUnits,
  courseProgressId,
  currentCohort,
  username,
  name,
  userId,
  certificate,
  enrolmentId,
  mutedName,
}) => {
  const [isSaving, setIsSaving] = useState(false);

  const currentExpDate = expiryDate ? new Date(expiryDate) : undefined;
  const [newExpDate, setNewExpDate] = useState(currentExpDate);
  const [newCohort, setNewCohort] = useState('');
  const dispatch = useDispatch();

  const currentTeamProfile = useCurrentTeamProfile();
  const currentTeamOrg = currentTeamProfile?.id;

  const canManageStudents = useHasPermission(
    PERMISSION_SLUGS.can_manage_students
  );

  const discourseMember = useSelector((state: GlobalState) =>
    Object.values(state.discourse.members).find((m) => m.username === username)
  );
  const enrolment = useSelector((state: GlobalState) =>
    Object.values(state.cms.enrolment.enrolment).find(
      (e) =>
        (enrolmentId && e.id === enrolmentId) ||
        (e.currentCohort === currentCohort.id && e.user === userId)
    )
  );

  const [studentName, setStudentName] = useState(enrolment?.studentName || '');

  // Class transfer options – cohorts must be for the same course,
  // be of the same type and have spaces available
  const classOptions = useSelector((state: GlobalState) =>
    Object.values(state.cms.cohort)
      .filter(
        (c) =>
          c.id !== currentCohort.id &&
          c.organisation === currentTeamOrg &&
          c.course === currentCohort.course &&
          c.isTest === currentCohort.isTest &&
          c.isPrivate === currentCohort.isPrivate &&
          c.isAnonymous === currentCohort.isAnonymous &&
          c.accessType === currentCohort.accessType &&
          c.classType === currentCohort.classType &&
          c.organisation === currentCohort.organisation &&
          c.socialType &&
          currentCohort.socialType &&
          (c.spacesAvailable > 0 || !courseUtils.hasClassSizeLimit(c)) &&
          c.status === CohortStatus.live
      )
      .reduce(
        (acc, c) => ({
          ...acc,
          [c.id]: `${moment(c.startDate).format('Do MMMM YYYY')}${
            c.label ? ' - ' : ''
          }${c.label}${c.status === CohortStatus.draft ? ' (Draft)' : ''}`,
        }),
        {} as { [key: string]: string }
      )
  );

  const displayName = discourseMember?.name || name;

  const expiryDateChanged =
    newExpDate && !moment(newExpDate).isSame(moment(currentExpDate));

  const studentNameChanged = Boolean(
    enrolment && studentName && studentName !== enrolment.studentName
  );

  const canSave =
    showExpiryDate || Boolean(enrolment && userId) || studentNameChanged;

  let saveDisabled = isSaving || !courseProgressId;

  if (!isSaving && courseProgressId) {
    saveDisabled = !expiryDateChanged && !newCohort;
  }

  if (!isSaving && studentNameChanged) {
    saveDisabled = false;
  }

  const onSave = async () => {
    setIsSaving(true);
    if (studentNameChanged && enrolment) {
      await dispatch(
        enrolmentActions.updateEnrolment(enrolment.id, {
          studentName,
        })
      );
    }
    if (!courseProgressId) {
      setIsSaving(false);
      return;
    }

    if (newExpDate && expiryDateChanged) {
      await dispatch(
        cohortActions.updateCourseProgress(courseProgressId, {
          expiryDate: moment(newExpDate?.toISOString()).format('YYYY-MM-DD'),
        })
      );
    }
    if (newCohort && enrolment) {
      await dispatch(
        enrolmentActions.updateEnrolment(enrolment.id, {
          currentCohort: parseInt(newCohort),
        })
      );
      await Promise.all([
        dispatch(cohortActions.updateCohort(currentCohort.id, {})),
        dispatch(cohortActions.updateCohort(parseInt(newCohort), {})),
      ]);
    }
    setIsSaving(false);
    analytics.track('Class Edited');
  };

  return (
    <AccordionItem borderTop="default">
      {({ isExpanded }) => (
        <Flex
          flexDir="column"
          className={isExpanded ? 'student-expanded-panel' : ''}
        >
          <AccordionButton display="flex" px={0} py={3}>
            <Flex flex={1} pr={2}>
              <Text
                fontSize="sm"
                textAlign="left"
                color={mutedName ? 'text.muted' : 'text.default'}
              >
                {displayName}
              </Text>
            </Flex>
            <Flex flex={1} pr={2}>
              {Boolean(completedUnits && totalUnits) && (
                <Text
                  fontSize="sm"
                  textAlign="left"
                >{`${completedUnits}/${totalUnits} units complete`}</Text>
              )}
            </Flex>
            <Flex flex={1} pr={2}>
              {showExpiryDate && (
                <Text fontSize="sm" textAlign="left">
                  {currentExpDate
                    ? moment(currentExpDate).format('Do MMMM YYYY')
                    : 'Never'}
                </Text>
              )}
            </Flex>
            <Flex width={6} pr={2}>
              {certificate?.generatedCertificate && (
                <MdIcon name="AssignmentTurnedIn" color="icon.success" />
              )}
            </Flex>
            <Flex width={5} justifyContent="flex-end">
              <AccordionIcon color="text.muted" />
            </Flex>
          </AccordionButton>
          <AccordionPanel px={0} pb={4} overflow="visible">
            <Skeleton isLoaded={!isLoading} flexDir="column">
              <Divider mb="4" />
              {showExpiryDate && (
                <Box flex={1}>
                  <LabelDatePicker
                    id="startDate"
                    label="Expiry Date"
                    onChange={(date) => {
                      if (!date) return;
                      setNewExpDate(date);
                    }}
                    date={newExpDate || null}
                  />
                </Box>
              )}
              {Boolean(enrolment && userId) && (
                <Box flex={1}>
                  <LabelSelect
                    id="classTransfer"
                    name="classTransfer"
                    label="Class Transfer"
                    defaultValue={undefined}
                    options={{ '': '-', ...classOptions }}
                    onChange={(e) => setNewCohort(e.target.value)}
                  />
                </Box>
              )}
              {Boolean(otherCourses?.length) && (
                <Flex flexDir="column" mt="4">
                  <Text
                    mb="2"
                    color="text.muted"
                  >{`${displayName}'s Other Courses :`}</Text>
                  {otherCourses?.map((courseTitle) => (
                    <Text
                      key={`otherCourse-${courseTitle}`}
                      my="0.5"
                      fontWeight="bold"
                    >
                      {courseTitle}
                    </Text>
                  ))}
                </Flex>
              )}

              {/* Student names only applicable when cohort is anonymous
              (i.e. no student accounts are associated with each enrolment) */}
              {/* Cannot be edited after a certificate has been generated */}
              {Boolean(
                currentCohort.isAnonymous &&
                  enrolment &&
                  !certificate?.generatedCertificate
              ) && (
                <LabelInput
                  id="studentName"
                  name="studentName"
                  label="Name on Certificate"
                  defaultValue={studentName}
                  onChange={(e) => setStudentName(e.target.value)}
                />
              )}

              <Flex flexDir="column" mt="4">
                {certificate?.generatedCertificate ? (
                  <LabelWrapper label="Certificate">
                    <Flex>
                      <Button
                        variant="ghost"
                        icon="FindInPage"
                        mr={2}
                        onClick={() =>
                          window.open(
                            certificate.generatedCertificate,
                            '_blank'
                          )
                        }
                      >
                        Preview
                      </Button>
                      <Button
                        variant="ghost"
                        icon="Download"
                        mr={2}
                        onClick={() => {
                          const filename = `Certificate_${certificate.certificateUid}`;
                          saveAs(certificate.generatedCertificate, filename);
                        }}
                      >
                        Download
                      </Button>

                      <AlertDialogButton
                        variant="ghost"
                        secondary={false}
                        icon="Send"
                        alertHeader="Send Certificate"
                        alertBody={
                          <SendCertificateDialog
                            isAnonymous={currentCohort.isAnonymous}
                            onSend={async (email) => {
                              setIsSaving(true);
                              await dispatch(
                                cohortActions.sendCertificateLog(
                                  certificate.id,
                                  {
                                    ...(email ? { email } : {}),
                                  }
                                )
                              );
                              setIsSaving(false);
                            }}
                          />
                        }
                        allowSubmit={false}
                        onCancel={() => null}
                        cancelBtnLabel="Close"
                      >
                        Send
                      </AlertDialogButton>
                    </Flex>
                  </LabelWrapper>
                ) : canManageStudents ? (
                  <>
                    <LabelWrapper
                      label="Certificate"
                      helpText={
                        userId ? (
                          'Learners will automatically earn their certificates by competing all required elements of the course. This button lets you override this automatic system and manually award this learner their certificate now.'
                        ) : (
                          <Text>
                            {
                              'Use this button to generate a certificate for this learner once they have completed the course. '
                            }
                            {Boolean(
                              currentCohort.isAnonymous &&
                                !enrolment?.studentName
                            ) && (
                              <chakra.span color="text.warning">
                                A name is required above to generate a
                                certificate.
                              </chakra.span>
                            )}
                          </Text>
                        )
                      }
                    >
                      <Flex>
                        <AlertDialogButton
                          variant="outline"
                          colorScheme="green"
                          secondary={false}
                          icon="AssignmentTurnedIn"
                          alertHeader="Are you sure?"
                          alertBody={
                            currentCohort.isAnonymous
                              ? "Once generated, this certificate cannot be edited. Please make sure the student's name is written correctly before generating."
                              : "After generating a certificate, this learner will see their course as 'complete' and won't be required to complete any more sessions."
                          }
                          allowSubmit
                          submitBtnLabel="Yes, Generate Certificate"
                          submitBtnColor="green"
                          onCancel={() => null}
                          mr={2}
                          // Only allow certificate to be generated if the student's name is defined
                          isDisabled={
                            currentCohort.isAnonymous && !enrolment?.studentName
                          }
                          onSubmit={async () => {
                            setIsSaving(true);
                            await dispatch(
                              cohortActions.createCertificateLog({
                                ...(userId ? { student: userId } : {}),
                                ...(enrolment
                                  ? { enrolment: enrolment.id }
                                  : {}),
                                course: currentCohort.course,
                              })
                            );
                            // This is currently required as certificate response data
                            // cannot be added to the cohort when enrolment data isn't available
                            // TODO: Remove this when all students have an associated 'enrolment'
                            if (!enrolment) {
                              await dispatch(
                                courseActions.fetchMyCohorts({
                                  fetchNextPage: true,
                                })
                              );
                            }
                            setIsSaving(false);
                          }}
                        >
                          Generate Certificate
                        </AlertDialogButton>
                      </Flex>
                    </LabelWrapper>
                  </>
                ) : null}
              </Flex>

              {canSave && (
                <Flex
                  flexDirection="row"
                  alignContent="center"
                  justifyContent="flex-end"
                >
                  <AnimatedButton
                    isDisabled={saveDisabled}
                    isLoading={isSaving}
                    onClick={onSave}
                  >
                    Save
                  </AnimatedButton>
                </Flex>
              )}
            </Skeleton>
          </AccordionPanel>
        </Flex>
      )}
    </AccordionItem>
  );
};

interface EditCohortModalProps extends Omit<EditModalProps, 'title'> {
  availableMentors: TeamMember[];
  cohort: Cohort;
  isLicensed?: boolean;
  validLicenses?: License[];
}

const EditCohortModal: React.FC<EditCohortModalProps> = (props) => {
  const {
    availableMentors,
    cohort,
    isLicensed,
    validLicenses,
    isOpen,
    modalSize,
    onClose,
  } = props;

  const { enrolments: enrolmentsLoading } = hooks.useLoadingDataState(
    {
      enrolments: {
        actions: [
          () =>
            enrolmentActions.listEnrolments({
              fetchNextPage: true,
              queryParams: `cohort=${cohort.id}`,
            }),
        ],
      },
    },
    []
  );

  const [isUpdating, setIsUpdating] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showEnrolStudents, setShowEnrolStudents] = useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [fetchedParticipantData, setFetchedParticipantData] = useState<
    string[]
  >([]);
  const [tabIdx, setTabIdx] = useState<number | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [participantDataLoaded, setParticipantDataLoaded] = useState(false);

  const dispatch = useDispatch();
  const currentTeam = useCurrentTeam();

  const theme = useTheme();
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < parseInt(theme.breakpoints.md, 10);

  const courseProgress = useSelector((state: GlobalState) =>
    Object.values(state.cms.courseProgress)
  );

  const courses = useSelector((state: GlobalState) =>
    Object.values(state.cms.course.courseList)
  );

  const cohortEnrolments = useSelector((state: GlobalState) =>
    Object.values(state.cms.enrolment.enrolment)
  ).filter((e) => e.currentCohort === cohort.id);

  const mentorOptions = availableMentors.reduce(
    (acc, curr) => ({ ...acc, [curr.user.id]: curr.user.name }),
    {} as { [key: string]: string }
  );

  const currentCohortType = courseUtils.getCohortType(cohort);

  const {
    handleSubmit,
    control,
    errors,
    register,
    formState,
    watch,
    reset,
    setValue,
    getValues,
  } = useForm<EditCohortFormData>({
    mode: 'onChange',
    defaultValues: {
      accessType: cohort.accessType,
      isPrivate: cohort.isPrivate,
      isTest: cohort.isTest,
      isAnonymous: cohort.isAnonymous,
      onSale: cohort.onSale,
      privateChatDisabled: cohort.privateChatDisabled,
      label: cohort.label,
      socialType: cohort.socialType,
      spacesTotal: cohort.spacesTotal,
      mentors:
        cohort.mentors && Array.isArray(cohort.mentors)
          ? cohort.mentors
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((m) => ({
                mentorId: m.id.toString(),
                isAssessor: m.assessor,
                isSubstitute: m.isSubstitute,
                subDetails:
                  m.expiryDate && m.subStartDate
                    ? {
                        subEnd: new Date(m.expiryDate),
                        subStart: new Date(m.subStartDate),
                      }
                    : undefined,
              }))
          : [],
      status: cohort.status,
    },
  });

  const {
    fields: mentors,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'mentors',
  });

  const {
    isOpen: isJournalModalOpen,
    onOpen: onJournalModalOpen,
    onClose: onJournalModalClose,
  } = useDisclosure();
  const [journalUnitsForModal, setJournalUnitsForModal] = useState<
    JournalUnitSummary[]
  >([]);
  const [journalMemberDataForModal, setJournalMemberDataForModal] = useState<
    CohortMember[]
  >([]);

  // --- Selectors for required data ---
  const journalEntries = useSelector((state: GlobalState) =>
    Object.values(state.cms.journal.journalEntries)
  );
  const unitSummaries = useSelector((state: GlobalState) =>
    Object.values(state.cms.unit.unitSummary)
  );
  const sessionSummary = useSelector(
    (state: GlobalState) => state.cms.session.sessionSummary
  );
  const discourseMembers = useSelector(
    (state: GlobalState) => state.discourse.members
  );
  // --- End Selectors ---

  // Simplified function to prepare data *and* open the modal
  const prepareAndOpenJournalData = () => {
    // 1. Check if the initial fetch sequence triggered by tab change has completed.
    if (!participantDataLoaded) {
      console.warn(
        'Participant data fetch sequence not yet complete. Cannot open journal modal.'
      );
      // Optionally add user feedback here (e.g., using a different notification system if toast was removed)
      alert('Learner data is still loading, please wait.'); // Simple alert for feedback
      return; // Stop execution if data isn't loaded
    }

    // 2. Basic check for essential data needed for preparation.
    // If these are empty, the modal will likely be empty, but let's allow opening it
    // and rely on console warnings for debugging missing data issues.
    if (!unitSummaries || !sessionSummary || !discourseMembers) {
      console.warn(
        'Core data (units, sessions, or members) appears missing from store.'
      );
      // Proceed with preparation, but expect potential issues.
    }

    // --- Prepare Data Logic ---
    const cohortJournalEntries = journalEntries.filter(
      (entry) => entry.cohort === cohort.id
    );

    const formattedJournalUnits = unitSummaries
      .filter(
        (unit) =>
          unit.course === cohort.course && unit.unitType !== 'assessment'
      )
      .map((unit) => ({
        ...unit,
        modules: unit.modules
          .map((moduleId) => sessionSummary[moduleId])
          .filter((module) => module && module.moduleType === 'normal'),
      }));

    if (
      formattedJournalUnits.length === 0 &&
      unitSummaries.some((u) => u.course === cohort.course)
    ) {
      console.warn(
        'Units found for course, but none were formatted for journal modal (check unitType/moduleType filters).'
      );
    }

    const members: CohortMember[] = cohort.participants.map(
      (participantUsername, idx) => {
        const discourseMember = Object.values(discourseMembers).find(
          (member) => member.username === participantUsername
        );
        if (!discourseMember) {
          console.warn(
            `Discourse member data not found for participant: ${participantUsername}`
          );
        }
        const memberEntries = cohortJournalEntries.filter(
          (entry) => entry.username === participantUsername
        );

        return {
          id: discourseMember?.id || idx,
          name: discourseMember?.name || participantUsername,
          username: participantUsername,
          journalEntries: memberEntries,
          avatar: discourseMember?.avatarTemplate,
          isLoading: !discourseMember,
        };
      }
    );
    // --- End Prepare Data Logic ---

    // Update state specifically for the modal *before* opening
    setJournalUnitsForModal(formattedJournalUnits);
    setJournalMemberDataForModal(members);

    // *** Explicitly call the function to open the modal ***
    console.log('Attempting to open JournalEntryModal...'); // Debug log
    onJournalModalOpen(); // This should now be called if participantDataLoaded is true
  };

  /* ------------------------ HANDLERS ------------------------ */

  const handleOnClose = () => {
    reset();
    onClose();
  };

  const handleOnSave =
    (showConfirmModal: boolean) =>
    async (e?: React.FormEvent<HTMLFormElement>) => {
      if (showConfirmModal) {
        setShowStatusModal(true);
        return;
      }

      setIsUpdating(true);
      if (e && e.stopPropagation) {
        // prevent any outer forms from receiving the event too
        e.stopPropagation();
      }

      const submit = await handleSubmit(async ({ mentors, ...data }) => {
        await dispatch(
          cohortActions.updateCohort(cohort.id, {
            ...data,
            mentors:
              mentors && Array.isArray(mentors)
                ? mentors.map((m) => ({
                    assessor: m.isAssessor,
                    isSubstitute: m.isSubstitute,
                    mentor: parseInt(m.mentorId),
                    subStartDate:
                      m.subDetails?.subStart &&
                      moment(m.subDetails?.subStart?.toISOString()).format(
                        'YYYY-MM-DD'
                      ),
                    expiryDate:
                      m.subDetails?.subEnd &&
                      moment(m.subDetails?.subEnd?.toISOString()).format(
                        'YYYY-MM-DD'
                      ),
                  }))
                : [],
          })
        );
      });

      await submit(e);
      analytics.track('Class Edited');

      setIsUpdating(false);
    };

  const onExpandAccordion = async (idx: number | number[]) => {
    const participantUsername = cohort.participants[idx as number];

    if (fetchedParticipantData.includes(participantUsername)) {
      return;
    }

    setTimeout(async () => {
      await dispatch(cohortActions.listUserCourseProgress(participantUsername));
      setFetchedParticipantData((prev) => [...prev, participantUsername]);
    }, 500);
  };

  const mentorValues = watch('mentors');
  const nonSubMentors = mentorValues.filter((m) => !m.isSubstitute);
  const spacesTotal = watch('spacesTotal');

  const hasClassSizeLimit = courseUtils.hasClassSizeLimit(cohort);

  const isValid =
    mentorValues.find((val) => val.mentorId) &&
    (hasClassSizeLimit
      ? spacesTotal &&
        spacesTotal > 0 &&
        spacesTotal >= cohort.numParticipants &&
        spacesTotal <= PRIVATE_CLASS_SIZE_LIMIT
      : true);

  const values = getValues();

  // Check if the user is about to make the status live
  //  --> if it's the case, show them the confirmation modal
  const showConfirmModal =
    cohort.status === CohortStatus.draft && values.status === CohortStatus.live;

  const modalProps = {
    isOpen,
    modalSize,
    onCancel: handleOnClose,
    onClose: handleOnClose,
    onSave: handleOnSave(showConfirmModal),
    title: 'Manage Class',
    subTitle: (
      <>
        {`${cohort.courseDetails.title}`}
        {isLicensed ? (
          <>
            {`\xa0\xa0\xa0`}
            <HeaderTag
              as={chakra.span}
              display="inline"
              title={`From ${cohort.courseDetails.organisation.name}`}
              bg="background.info"
              color="text.info"
              letterSpacing="normal"
            />
          </>
        ) : (
          ''
        )}
        {`\xa0\xa0\xa0|\xa0\xa0\xa0${moment(cohort.startDate).format(
          'Do MMMM YYYY'
        )}${cohort.label ? ' - ' : ''}${cohort.label}`}
        {cohort.status === CohortStatus.draft ? (
          <>
            {`\xa0\xa0\xa0`}
            <HeaderTag
              as={chakra.span}
              display="inline"
              title="Draft"
              bg="background.tint2"
              color="text.muted"
              letterSpacing="normal"
            />
          </>
        ) : (
          ''
        )}
      </>
    ),
    isUpdating,
    saveDisabled:
      cohort.status === CohortStatus.draft
        ? !formState.dirty
        : !(formState.dirty && isValid) || isUpdating,
    showFooter: tabIdx !== 1,
    saveLabel: cohort.status === CohortStatus.draft ? 'Save Draft' : 'Save',
  };

  let totalLicensedSpacesAvailable = 0;
  validLicenses?.forEach((l) => {
    totalLicensedSpacesAvailable += l.totalEnrolments - l.enrolments.length;
  });

  const accessDuration =
    cohort.accessDuration || cohort.courseDetails.accessDuration;

  const numTempParticipants =
    cohort.numParticipants - cohort.participants.length;

  let participantsPanel = (
    <Flex mb={4}>
      {numTempParticipants > 0 ? null : (
        <Text color="text.muted">This class doesn't have any learners yet</Text>
      )}
    </Flex>
  );

  if (cohort.isAnonymous && cohortEnrolments.length > 0) {
    participantsPanel = (
      <Flex flexDir="column">
        <Flex flexDir="row" mb={4}>
          <Flex flex={1} pr={2}>
            <Text color="text.muted" textAlign="left">
              Student
            </Text>
          </Flex>
          <Flex flex={1} pr={2}></Flex>
          <Flex flex={1}></Flex>
          <Flex width={6} />
          <Flex width={5} />
        </Flex>
        <Accordion allowToggle flex="1" onChange={onExpandAccordion}>
          {cohortEnrolments.map((e, index) => (
            <CohortStudentItem
              key={`enrolment-${e.id}`}
              currentCohort={cohort}
              name={e.studentName || `Student #${index + 1}`}
              mutedName={!e.studentName}
              certificate={cohort.certificateLogs?.find(
                (cl) => cl.id === e.certificateLog
              )}
              enrolmentId={e.id}
            />
          ))}
        </Accordion>
      </Flex>
    );
  }

  if (!cohort.isAnonymous && cohort.participants.length) {
    participantsPanel =
      !participantDataLoaded || isLoading ? (
        <Flex justifyContent="center" alignItems="center" my="4">
          <Loading />
        </Flex>
      ) : (
        <Flex flexDir="column">
          <Flex justifyContent="flex-end" mb={4}>
            <Button
              icon="TableChart"
              size="sm"
              onClick={prepareAndOpenJournalData}
              isDisabled={!participantDataLoaded}
              variant="outline"
            >
              See Table of Uploads
            </Button>
          </Flex>
          <Flex flexDir="row" mb={4}>
            <Flex flex={1} pr={2}>
              <Text color="text.muted" textAlign="left">
                Student
              </Text>
            </Flex>
            <Flex flex={1} pr={2}>
              <Text color="text.muted" textAlign="left">
                Course Progress
              </Text>
            </Flex>
            <Flex flex={1}>
              <Text color="text.muted" textAlign="left">
                Access Expires
              </Text>
            </Flex>
            <Flex width={6} />
            <Flex width={5} />
          </Flex>
          <Accordion allowToggle flex="1" onChange={onExpandAccordion}>
            {cohort.participants.map((username) => {
              const userCourseProgress = courseProgress.find(
                (cp) =>
                  cp.participantUsername === username &&
                  cp.course.toString() === cohort.courseDetails.id.toString()
              );

              const totalUnits = userCourseProgress?.unitProgress.length || 0;

              const completedUnits =
                userCourseProgress?.unitProgress.filter(
                  (up) => up.status === 'complete'
                ).length || 0;

              const expiryDate = userCourseProgress?.expiryDate;

              const otherCourseCourses = courseProgress
                .filter(
                  (cp) =>
                    cp.participantUsername === username &&
                    cp.course.toString() !== cohort.courseDetails.id.toString()
                )
                .map(
                  (cp) =>
                    courses.find(
                      (c) => c.id.toString() === cp.course.toString()
                    )?.title
                );

              const certificateLog = cohort.certificateLogs?.find(
                (cl) => cl.student === username
              );

              return (
                <CohortStudentItem
                  key={`cohortStudent-${username}`}
                  completedUnits={completedUnits}
                  currentCohort={cohort}
                  courseProgressId={userCourseProgress?.id}
                  expiryDate={expiryDate || ''}
                  showExpiryDate
                  isLoading={
                    !fetchedParticipantData.includes(username) ||
                    enrolmentsLoading
                  }
                  otherCourses={otherCourseCourses}
                  totalUnits={totalUnits}
                  username={username}
                  name={userCourseProgress?.participantName}
                  userId={userCourseProgress?.user}
                  certificate={certificateLog}
                />
              );
            })}
          </Accordion>
        </Flex>
      );
  }

  const isOnSale = cohort.onSale && moment(cohort.startDate).isAfter();
  const hasStarted = moment(cohort.startDate).isBefore();
  const hasExpired = moment(cohort.endDate).isBefore();

  return (
    <EditModal {...modalProps}>
      <ConfirmModal
        body="This means opening up your class for enrollments. Once a learner enrols, the class cannot be moved back to 'Draft' status or be deleted."
        btnColor="green"
        btnLabel="Yes, Publish"
        title="Are You Sure You Want to Publish?"
        isOpen={showStatusModal}
        isLoading={isUpdating}
        onClose={() => setShowStatusModal(false)}
        onClick={handleOnSave(false)}
      />
      <ConfirmModal
        body="Are you sure you want to delete this class? This is not reversible."
        btnLabel="Yes, Delete"
        title="Confirm Class Deletion"
        isOpen={showDeleteModal}
        isLoading={isDeleting}
        onClose={() => setShowDeleteModal(false)}
        onClick={async () => {
          setIsDeleting(true);
          await dispatch(cohortActions.deleteCohort(cohort.id));
          analytics.track('Class Deleted');
          setIsDeleting(false);
          handleOnClose();
        }}
      />
      <EnrolStudentsModal
        title="Enroll Learners"
        isOpen={showEnrolStudents}
        onClose={() => setShowEnrolStudents(false)}
        onCancel={() => setShowEnrolStudents(false)}
        onSave={handleOnSave(false)}
        modalSize="lg"
        course={cohort.courseDetails.id}
        cohort={cohort.id}
        cohortIsTest={cohort.isTest}
        cohortIsAnonymous={cohort.isAnonymous}
        cohortSocialType={cohort.socialType}
        courseIsLicensed={isLicensed}
        licenses={validLicenses}
        cohortSpacesAvailable={cohort.spacesAvailable}
        licensedSpacesAvailable={totalLicensedSpacesAvailable}
        licensorName={cohort.courseDetails.organisation.name}
      />
      <JournalEntryModal
        isOpen={isJournalModalOpen}
        onClose={onJournalModalClose}
        members={journalMemberDataForModal}
        journalUnits={journalUnitsForModal}
      />
      <Flex
        flexDir={{ base: 'column', md: 'row' }}
        alignItems={{ base: 'normal', md: 'center' }}
      >
        <Box display="none">
          <LabelSelect
            isDisabled
            id="status"
            name="status"
            label="Status"
            error={!!errors.status}
            registerInputRef={register()}
            defaultValue={undefined}
            options={CohortStatus}
            labelStyleProps={labelStyleProps}
            tooltip="cohort_status"
          />
        </Box>
        <Flex flex={1} />
      </Flex>
      {currentCohortType ? (
        <Box mb={4}>
          <ClassTypeItem classType={currentCohortType} isExpandable />
        </Box>
      ) : null}
      <Flex flex={1} mb={4} alignItems="center" flexWrap="wrap">
        {hasExpired ? (
          <>
            <HeaderTag
              title="Class Expired"
              bg="background.error"
              color="text.error"
              mr={6}
            />
            <Flex flex={1} />
          </>
        ) : hasStarted ? (
          <>
            <HeaderTag
              title="Started"
              bg="background.primary"
              color="text.primary"
              mr={6}
            />
            <Flex flex={1} />
          </>
        ) : isOnSale && PLATFORM === 'steppit' ? (
          <Flex
            flex={1}
            flexDirection={{ base: 'column', md: 'row' }}
            alignItems={{ base: 'flex-start', md: 'center' }}
            w="100%"
          >
            <HeaderTag
              title="On Sale"
              bg="background.success"
              color="text.success"
              mr={6}
            />
            <Text
              flex={1}
              fontSize="sm"
              color="text.muted"
              mt={{ base: 2, md: 0 }}
              w="100%"
            >
              {'Available at '}
              <chakra.span color="text.primary" fontWeight="semibold">
                <Link
                  to={navRoutes.global.courseLanding.path(cohort.course)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {`${window.location.protocol}//${
                    window.location.host
                  }${navRoutes.global.courseLanding.path(cohort.course)}`}
                  <MdIcon
                    fontSize="2xs"
                    name="OpenInNew"
                    display="inline"
                    ml={1.5}
                  />
                </Link>
              </chakra.span>
            </Text>
          </Flex>
        ) : (
          <Flex flex={1} />
        )}

        <Flex color="icon.muted" alignItems="center">
          <MdIcon name="People" />
          {cohort.isAnonymous ? (
            <Text ml={1} fontWeight="bold">
              {cohort.numEnrolments}
            </Text>
          ) : (
            <>
              {' '}
              <Text ml={1} fontWeight="bold">
                {cohort.numParticipants}
              </Text>
              {hasClassSizeLimit ? <Text>/{cohort.spacesTotal}</Text> : null}
            </>
          )}
        </Flex>

        {/* Enrolling new students is allowed if:
        - The current organisation isn't on our pay-per-enrolment blacklist
          AND
        - The cohort is not on sale
          AND
        - The cohort doesn't have a size limit and is not a test OR the cohort has spaces available
          AND
        - The course is not licensed OR the course is licensed and valid licenses are available
        */}
        {currentTeam &&
        !ENROLMENT_BLACKLIST.includes(currentTeam) &&
        !cohort.onSale &&
        ((!hasClassSizeLimit && !cohort.isTest) ||
          cohort.spacesAvailable > 0) &&
        (!isLicensed ||
          (isLicensed && validLicenses && validLicenses.length > 0)) ? (
          <Box>
            {!(validLicenses && validLicenses.length > 0) ? (
              // TODO: Remove after Beta
              <Text color="text.muted" fontSize="sm" ml={6}>
                No Enrollments Available to Redeem
              </Text>
            ) : (
              <Button
                onClick={() => setShowEnrolStudents(true)}
                icon="PersonAdd"
                ml={6}
              >
                {PLATFORM === 'workshop'
                  ? 'Enroll Students'
                  : 'Enroll Learners'}
              </Button>
            )}
          </Box>
        ) : null}
      </Flex>
      <Tabs
        variant="enclosed"
        onChange={async (idx) => {
          setTabIdx(idx);
          if (idx === 1 && !participantDataLoaded && !isLoading) {
            setIsLoading(true);
            try {
              // Fetch core data first
              await Promise.all([
                dispatch(cohortActions.listCohortCourseProgress(cohort.id)),
                dispatch(courseActions.retrieve(cohort.courseDetails.id)),
                cohort.discourseGroupName
                  ? dispatch(
                      discourseActions.getGroupMembers(
                        cohort.discourseGroupName
                      )
                    )
                  : Promise.resolve(),
              ]);
              // Fetch secondary data after core data fetches resolve
              await dispatch(assessmentActions.fetchForCohort(cohort.id));
              setParticipantDataLoaded(true);
            } catch (error) {
              console.error('Failed to load participant data:', error);
              // Maybe set an error state here instead of just logging
            } finally {
              setIsLoading(false);
            }
          }
        }}
      >
        {cohort.status === CohortStatus.live && (
          <TabList mb="1em">
            <Tab fontWeight="semibold">
              <Flex alignItems="center">
                <MdIcon name="Settings" mr={2} />
                <Text>Details</Text>
              </Flex>
            </Tab>
            <Tab fontWeight="semibold">
              <Flex alignItems="center">
                <MdIcon name="ManageAccounts" mr={2} />
                <Text>{PLATFORM === 'workshop' ? 'Students' : 'Learners'}</Text>
              </Flex>
            </Tab>
          </TabList>
        )}
        {cohort.status === CohortStatus.draft && (
          <Text mb={6}>
            Here's a summary of your class. If you're happy with your choices,
            then you can hit 'Publish Class' to start enrolling learners.
            Otherwise feel free to delete this class and start a new one.
          </Text>
        )}
        <TabPanels>
          <TabPanel p={0}>
            {/* Main section */}
            <Flex flexDir="column" flex={1}>
              <LabelInput
                id="label"
                name="label"
                label="Label"
                registerInputRef={register()}
                labelStyleProps={labelStyleProps}
                labelPosition={isMobile ? 'top' : 'inline'}
              />
              {hasClassSizeLimit ? (
                <LabelInput
                  id="spacesTotal"
                  name="spacesTotal"
                  label="Class Size Limit"
                  placeholder={`Enter a number between ${Math.max(
                    1,
                    cohort.numParticipants
                  )} and ${PRIVATE_CLASS_SIZE_LIMIT}`}
                  inputType="number"
                  error={
                    spacesTotal <= 0 ||
                    spacesTotal < cohort.numParticipants ||
                    spacesTotal > PRIVATE_CLASS_SIZE_LIMIT
                  }
                  errorMessage={`Please enter a number between ${Math.max(
                    1,
                    cohort.numParticipants
                  )} and ${PRIVATE_CLASS_SIZE_LIMIT}`}
                  labelStyleProps={labelStyleProps}
                  registerInputRef={register()}
                  labelPosition={isMobile ? 'top' : 'inline'}
                />
              ) : null}
            </Flex>
            {/* Advanced settings (only for select orgs) */}
            {currentTeam &&
            ADVANCED_COHORT_OPTIONS_WHITELIST.includes(currentTeam) ? (
              <Accordion allowToggle flex="1">
                <AccordionItem borderTop="default">
                  <AccordionButton display="flex" py={3} px={0}>
                    <Flex flex={1}>
                      <Text textAlign="left">Advanced Settings</Text>
                    </Flex>
                    <AccordionIcon color="text.muted" />
                  </AccordionButton>
                  <AccordionPanel py={2} px={0}>
                    <Flex flexDir={{ base: 'column', md: 'row' }}>
                      <Flex flex={1}>
                        <LabelCheckbox
                          isDisabled={false}
                          id="isTest"
                          name="isTest"
                          label="Test class"
                          registerInputRef={register()}
                          defaultValue={undefined}
                          fontWeight="normal"
                          tooltip="test_cohort"
                        />
                      </Flex>
                      <Flex flex={1}>
                        <LabelCheckbox
                          isDisabled={false}
                          id="isPrivate"
                          name="isPrivate"
                          label="Private class"
                          registerInputRef={register()}
                          defaultValue={undefined}
                          fontWeight="normal"
                          tooltip="private_cohort"
                          labelStyleProps={labelStyleProps}
                        />
                      </Flex>
                      <Flex flex={1}>
                        <LabelCheckbox
                          isDisabled={false}
                          id="onSale"
                          name="onSale"
                          label="On sale"
                          registerInputRef={register()}
                          defaultValue={undefined}
                          fontWeight="normal"
                          // tooltip="on_sale_cohort"
                        />
                      </Flex>
                    </Flex>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            ) : null}

            {/* Mentors section */}
            <Flex flexDir="column">
              <Flex flexDir="row" mt={6} mb={2}>
                <Flex w={{ base: '55%', md: '65%' }}>
                  <Text color="text.muted">Class Mentor(s)</Text>
                </Flex>
                <Flex
                  w={{ base: '40%', md: '30%' }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Text color="text.muted" mr={2}>
                    Substitute
                  </Text>
                  <IconTooltip tooltip="sub_teacher" />
                </Flex>
                <Flex w="5%" />
              </Flex>
              {mentors && Array.isArray(mentors)
                ? mentors.map((item, idx) => {
                    const mentor = mentorValues[idx] || item;

                    const isSub = mentor.isSubstitute;
                    const subStart = mentor.subDetails?.subStart;
                    const subEnd = mentor.subDetails?.subEnd;

                    const isOnlyPermMentor = !isSub && nonSubMentors.length < 2;

                    const isLastMentor = idx + 1 === mentors.length;

                    return (
                      <Flex flexDir="column" key={`mentor-${item.id}`}>
                        <Divider mb="4" />
                        <Flex flexDir="row" alignItems="center">
                          <Flex w={{ base: '55%', md: '65%' }}>
                            <LabelSelect
                              name={`mentors[${idx}].mentorId` as const}
                              registerInputRef={register()}
                              defaultValue={item.mentorId}
                              options={{ '': '-', ...mentorOptions }}
                            />
                          </Flex>
                          <Flex
                            w={{ base: '40%', md: '30%' }}
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            mb="defaultMargin"
                          >
                            <Checkbox
                              name={`mentors[${idx}].isSubstitute` as const}
                              ref={register()}
                              defaultChecked={item.isSubstitute}
                              defaultValue={item.isSubstitute}
                              isDisabled={isOnlyPermMentor}
                            />
                            {isSub && (
                              <Flex
                                backgroundColor="background.tint3"
                                padding={1}
                                borderRadius="sm"
                                mt={2}
                                mx={2}
                              >
                                {/* @ts-ignore - Known issue with react-hook-form Controller type definitions */}
                                <Controller
                                  name={`mentors[${idx}].subDetails` as const}
                                  defaultValue={item.subDetails}
                                  control={control}
                                  onChange={(range: { range1: Range }[]) => ({
                                    subStart: range[0].range1.startDate,
                                    subEnd: range[0].range1.endDate,
                                  })}
                                  as={
                                    <DateRangeInput
                                      dateRange={
                                        subStart && subEnd
                                          ? {
                                              startDate: subStart,
                                              endDate: subEnd,
                                            }
                                          : undefined
                                      }
                                      size="xs"
                                    />
                                  }
                                />
                              </Flex>
                            )}
                          </Flex>
                          <Flex w="5%">
                            {!isOnlyPermMentor && (
                              <MdIcon
                                color="red.400"
                                cursor="pointer"
                                name="Cancel"
                                onClick={() => remove(idx)}
                              />
                            )}
                          </Flex>
                        </Flex>
                        {isLastMentor && <Divider mb="4" />}
                      </Flex>
                    );
                  })
                : null}
            </Flex>
            <Flex flex={1} justifyContent="flex-end">
              <Button
                icon="Add"
                variant="outline"
                size="sm"
                onClick={() =>
                  append({
                    mentorId: '',
                    isAssessor: true,
                    isSubstitute: false,
                  })
                }
              >
                Add Mentor
              </Button>
            </Flex>
            {cohort.status === CohortStatus.draft ? (
              <Flex
                flex={1}
                mt="4"
                alignItems={{ base: 'initial', md: 'row' }}
                flexDirection={{ base: 'column', md: 'row' }}
              >
                <Box>
                  <Button
                    colorScheme="red"
                    variant="outline"
                    onClick={() => {
                      setShowDeleteModal(true);
                    }}
                  >
                    <Text fontWeight="bold">Delete Class</Text>
                  </Button>
                </Box>
                <Flex flex={1} />
                <Flex
                  flexDirection={{ base: 'column', sm: 'row' }}
                  alignItems={{ base: 'flex-end', sm: 'center' }}
                  mt={{ base: 6, md: 0 }}
                >
                  <Text
                    fontSize="sm"
                    color="text.muted"
                    mr={{ base: 0, sm: 4 }}
                    flex={1}
                    textAlign="right"
                    mb={{ base: 2, sm: 0 }}
                  >
                    Publish your class to start enrolling learners
                  </Text>
                  <Button
                    colorScheme="green"
                    onClick={() => {
                      setValue('status', CohortStatus.live);
                      setShowStatusModal(true);
                    }}
                    disabled={!isValid || isUpdating}
                  >
                    <Text fontWeight="bold">Publish Class</Text>
                  </Button>
                </Flex>
              </Flex>
            ) : null}
          </TabPanel>
          <TabPanel p={0}>
            <Flex flexDir="row" mb={4}>
              {accessDuration && !cohort.isAnonymous ? (
                <Flex flexDir="column" mr={6}>
                  <Flex flexDir="row">
                    <Text mr={2} color="text.muted">
                      Default Access Period:
                    </Text>
                    <Text fontWeight="bold" color="text.muted">
                      {`${accessDuration} week${
                        accessDuration === 1 ? '' : 's'
                      }`}
                    </Text>
                  </Flex>
                  <Text fontSize="xs" color="text.muted">
                    {`This access period is set on your ${
                      cohort.accessDuration ? 'class' : 'course'
                    } and determines the
                    default expiry date for learners. Individual learner access
                    can be extended beyond this date below.`}
                  </Text>
                </Flex>
              ) : null}
              {cohort.isAnonymous ? (
                <Flex flexDir="column" mr={6}>
                  <Text fontSize="sm" color="text.muted">
                    Live rooms require no learner login, as content is
                    accessible via room links. The number of spaces available on
                    the class equates to the number of learners eligible to
                    receive a certificate of completion.
                  </Text>
                </Flex>
              ) : null}
            </Flex>
            <Divider mb="6" />
            {participantsPanel}
            {numTempParticipants > 0 && (
              <Flex justifyContent="flex-start">
                <HeaderTag
                  title={`${numTempParticipants}${
                    cohort.participants.length > 0 ? ' more' : ''
                  } student${
                    numTempParticipants === 1 ? ' has' : 's have'
                  } not yet activated their account${
                    numTempParticipants === 1 ? '' : 's'
                  }`}
                  bg="background.warning"
                  color="text.warning"
                  mt={4}
                  mb={3}
                />
              </Flex>
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </EditModal>
  );
};

export default EditCohortModal;
