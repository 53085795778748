import { decamelizeKeys } from 'humps';
import {
  CMSSessionDetailSchema,
  CMSSessionListSchema,
  CMSSessionListAllSchema,
} from 'redux/schemas/cms';

import { callAPIWithTeam } from 'utils';
import API from 'constants/api';

import { SessionAT } from 'redux/actionTypes/cms';

import {
  FetchSessionAction,
  PatchSessionAction,
  DeleteSessionAction,
  SessionCreateAction,
  SessionListAction,
  SessionListAllAction,
  DuplicateSessionAction,
  UnsyncSessionAction,
  ISessionListItem,
  ISession,
} from 'types/cms';

export const listAll =
  ({
    fetchNextPage,
    url,
  }: {
    fetchNextPage?: boolean;
    url?: string | undefined;
  }) =>
  // @ts-ignore
  async (dispatch: Dispatch) =>
    dispatch(
      callAPIWithTeam<SessionListAllAction>({
        types: [
          SessionAT.CMS_FETCH_ALL_SESSIONS_REQUEST,
          SessionAT.CMS_FETCH_ALL_SESSIONS_SUCCESS,
          SessionAT.CMS_FETCH_ALL_SESSIONS_FAILURE,
        ],
        endpoint: url || API.cms.mentorSessions,
        method: 'GET',
        schema: [CMSSessionListAllSchema],
        path: 'results',
        meta: {
          toast: {
            error: false,
          },
        },
        fetchNextPage: fetchNextPage
          ? // @ts-ignore
            async (url) => {
              return await dispatch(listAll({ fetchNextPage: true, url }));
            }
          : null,
      })
    );

export const list = (courseId: number, unitId: number) => {
  return callAPIWithTeam<SessionListAction>({
    types: [
      SessionAT.CMS_FETCH_SESSION_LIST_REQUEST,
      SessionAT.CMS_FETCH_SESSION_LIST_SUCCESS,
      SessionAT.CMS_FETCH_SESSION_LIST_FAILURE,
    ],
    endpoint: API.cms.sessions(courseId, unitId),
    method: 'GET',
    schema: [CMSSessionListSchema],
    path: 'results',
    meta: {
      toast: {
        error: 'Could not retrieve sessions',
      },
    },
  });
};

export const create = (
  courseId: number,
  unitId: number,
  data: Partial<ISessionListItem>
) => {
  return callAPIWithTeam<SessionCreateAction>({
    types: [
      SessionAT.CMS_CREATE_SESSION_REQUEST,
      SessionAT.CMS_CREATE_SESSION_SUCCESS,
      SessionAT.CMS_CREATE_SESSION_FAILURE,
    ],
    endpoint: API.cms.sessions(courseId, unitId),
    method: 'POST',
    body: decamelizeKeys(data),
    schema: CMSSessionListSchema,
    meta: {
      toast: {
        success: 'Successfully created session.',
        error: 'Could not create session',
      },
    },
  });
};

export const retrieve = (id: number) => {
  return callAPIWithTeam<FetchSessionAction>({
    types: [
      SessionAT.CMS_FETCH_SESSION_REQUEST,
      SessionAT.CMS_FETCH_SESSION_SUCCESS,
      SessionAT.CMS_FETCH_SESSION_FAILURE,
    ],
    endpoint: API.cms.session(id),
    method: 'GET',
    schema: CMSSessionDetailSchema,
    meta: {
      toast: {
        error: false,
      },
    },
  });
};

export const update = (id: number, data: Partial<ISession> | FormData) => {
  return callAPIWithTeam<PatchSessionAction>({
    types: [
      SessionAT.CMS_PATCH_SESSION_REQUEST,
      SessionAT.CMS_PATCH_SESSION_SUCCESS,
      SessionAT.CMS_PATCH_SESSION_FAILURE,
    ],
    endpoint: API.cms.session(id),
    method: 'PATCH',
    body: data instanceof FormData ? data : decamelizeKeys(data),
    schema: CMSSessionDetailSchema,
    meta: {
      toast: {
        error: { source: 'api' },
      },
    },
  });
};

export const remove = (id: number) => {
  return callAPIWithTeam<DeleteSessionAction>({
    types: [
      SessionAT.CMS_DELETE_SESSION_REQUEST,
      SessionAT.CMS_DELETE_SESSION_SUCCESS,
      SessionAT.CMS_DELETE_SESSION_FAILURE,
    ],
    endpoint: API.cms.session(id),
    method: 'DELETE',
    meta: {
      sessionId: id.toString(),
      toast: { success: 'Successfully deleted session' },
    },
  });
};

export const duplicate = (id: number, unitId: number) => {
  return callAPIWithTeam<DuplicateSessionAction>({
    types: [
      SessionAT.CMS_DUPLICATE_SESSION_REQUEST,
      SessionAT.CMS_DUPLICATE_SESSION_SUCCESS,
      SessionAT.CMS_DUPLICATE_SESSION_FAILURE,
    ],
    endpoint: API.cms.duplicateSession,
    body: {
      module: id,
      unit: unitId,
    },
    method: 'POST',
  });
};

export const unsync = (id: number) => {
  return callAPIWithTeam<UnsyncSessionAction>({
    types: [
      SessionAT.CMS_UNSYNC_SESSION_REQUEST,
      SessionAT.CMS_UNSYNC_SESSION_SUCCESS,
      SessionAT.CMS_UNSYNC_SESSION_FAILURE,
    ],
    endpoint: API.cms.unsyncSession,
    body: {
      module: id,
    },
    method: 'POST',
  });
};
