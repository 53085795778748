import React, { useState, useRef, useEffect } from 'react';
import { useSpring } from 'react-spring';
import { useForm } from 'react-hook-form';

import { Flex, Text, Button, Spinner } from '@workshop/ui';

import {
  AnimatedButton,
  LabelTextArea,
  LabelInput,
  AiButtonProps,
} from 'components/Common';

export interface IAddItem {
  inputText: string;
}

interface AddItemProps {
  onSave: (formData: IAddItem) => any;
  label: string;
  onClick?: (...args: any) => void;
  helpText?: string;
  isUpdating?: boolean;
  isLoading?: boolean;
  flexDir?: 'row' | 'column';
  size?: 'sm' | 'md';
  icon?: string;
  button?: boolean;
  multiline?: boolean;
  variant?: 'solid' | 'outline' | 'dotted' | 'ghost';
  showInput?: boolean;
  value?: string;
  hideCard?: boolean;
  fontWeight?: 'normal' | 'semibold' | 'bold';
  topLabel?: string;
  colorScheme?: string;
  fontSize?: 'sm' | 'md';
  placeholder?: string;
  aiButton?: AiButtonProps;
  inlineElement?: React.ReactElement;
}

const AddItem: React.FC<AddItemProps> = ({
  onSave,
  label,
  onClick,
  helpText,
  isUpdating = false,
  isLoading = false,
  flexDir = 'row',
  size = 'md',
  icon = 'AddCircle',
  button = false,
  multiline = false,
  variant = 'ghost',
  showInput = false,
  value = '',
  hideCard = false,
  fontWeight = 'normal',
  topLabel = '',
  colorScheme = 'blue',
  fontSize = 'sm',
  placeholder = '',
  aiButton,
  inlineElement = null,
}) => {
  const { register, handleSubmit, errors, clearError, formState, reset } =
    useForm<IAddItem>();
  const textInputEl = useRef<HTMLInputElement | HTMLTextAreaElement>();
  const [isSaving, setIsSaving] = useState(false);
  const [isInputVisible, setIsInputVisible] = useState(showInput);

  useEffect(() => {
    // When the text input is shown, autofocus it
    if (isInputVisible && !showInput) {
      textInputEl.current?.focus();
    }
  }, [isInputVisible]);

  useEffect(() => {
    if (!onClick) {
      // If `isSaving` is true and `isUpdating` transitions
      // from true -> false, then we need to hide the input
      // as an update has occurred.
      if (isSaving && !isUpdating) {
        if (!showInput) setIsInputVisible(false);
        setIsSaving(false);
      }
    }
  }, [isSaving, isUpdating, onClick]);

  useEffect(() => {
    if (value && showInput) {
      reset({ inputText: value });
    }
  }, [value]);

  const buttonsDisabled = Boolean(!formState.dirty || errors.inputText);

  const btnStyle = useSpring({
    opacity: buttonsDisabled || isUpdating ? 0.5 : 1,
  });

  const onAddItem = () => {
    onClick ? onClick() : setIsInputVisible(true);
  };

  const onSubmit = handleSubmit((formData) => {
    setIsSaving(true);
    onSave(formData);
  });

  const onCancel = () => {
    clearError();
    reset({ inputText: value });
    if (!showInput) setIsInputVisible(false);
  };

  return (
    <Flex
      flex={1}
      flexDirection="column"
      {...(hideCard
        ? {}
        : button
        ? !(isInputVisible || isUpdating)
          ? {}
          : {
              color: 'text.muted',
              backgroundColor: 'background.default',
              borderRadius: 'md',
              boxShadow: 'md',
              padding: 2,
              margin: -2,
            }
        : {
            padding: 'defaultPadding',
          })}
    >
      <Flex flex={1} alignItems="center">
        {isInputVisible ? (
          <Flex flex={1} flexDirection="column">
            {Boolean(showInput && topLabel) && (
              <Text fontSize="sm" color="text.muted" mb={1}>
                {topLabel}
              </Text>
            )}
            <Flex flex={1} flexDir={flexDir}>
              <Flex
                flex={2}
                alignItems="center"
                mb={
                  flexDir === 'column'
                    ? showInput && buttonsDisabled
                      ? 0
                      : 3
                    : 0
                }
              >
                {multiline ? (
                  <LabelTextArea
                    label=""
                    id="inputText"
                    name="inputText"
                    registerInputRef={(e: HTMLTextAreaElement | null) => {
                      if (e) {
                        register(e);
                        textInputEl.current = e;
                      }
                    }}
                    color="text.default"
                    placeholder={placeholder || label}
                    fontSize={size}
                    fontWeight={fontWeight}
                    defaultValue={value}
                    backgroundColor="background.default"
                    noMargin
                    aiButton={aiButton}
                    autoFocus
                  />
                ) : (
                  <LabelInput
                    label=""
                    id="inputText"
                    name="inputText"
                    registerInputRef={(e: HTMLInputElement | null) => {
                      if (e) {
                        register(e);
                        textInputEl.current = e;
                      }
                    }}
                    color="text.default"
                    placeholder={placeholder || label}
                    fontSize={size}
                    fontWeight={fontWeight}
                    defaultValue={value}
                    backgroundColor="background.default"
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        onSubmit();
                      }
                    }}
                    noMargin
                    aiButton={aiButton}
                    autoFocus
                  />
                )}
              </Flex>
              {showInput && buttonsDisabled ? null : (
                <Flex justifyContent="flex-end" alignItems="center" ml={2}>
                  <AnimatedButton
                    secondary
                    onClick={onCancel}
                    isDisabled={isUpdating}
                    fontSize={size}
                    icon="Close"
                    w={10}
                  />
                  <AnimatedButton
                    isLoading={isUpdating}
                    isDisabled={buttonsDisabled || isUpdating}
                    // @ts-ignore
                    style={btnStyle}
                    onClick={onSubmit}
                    fontSize={size}
                  >
                    Save
                  </AnimatedButton>
                </Flex>
              )}
            </Flex>
          </Flex>
        ) : isUpdating ? (
          <Spinner color="common.primary" />
        ) : (
          <>
            {inlineElement}
            <Button
              {...(variant === 'dotted'
                ? { variant: 'outline', borderStyle: 'dashed' }
                : { variant })}
              fontSize={fontSize}
              icon={icon}
              flex={1}
              onClick={onAddItem}
              isLoading={isLoading}
              colorScheme={colorScheme}
            >
              {label}
            </Button>
          </>
        )}
      </Flex>
      {helpText && (
        <Flex>
          <Text fontSize="xs" color="text.muted">
            {helpText}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export default AddItem;
