import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useForm, FormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { Post, Topic } from 'discourse-js';
import moment from 'moment';
import { FaYoutube } from 'react-icons/fa';

import { GlobalState } from 'types';

import {
  Box,
  Button,
  Flex,
  Input,
  MdIcon,
  Stack,
  Text,
  Textarea,
  useTheme,
} from '@workshop/ui';

import { discourseActions } from 'redux/actions/common';
import { journalActions } from 'redux/actions/learner';
import { useUser } from 'redux/selectors';

import { PLATFORM } from 'constants/env';

import {
  ImageUpload,
  LabelTextArea,
  LabelInput,
  RenderHtml,
} from 'components/Common';
import { SteppitMiniIcon } from 'components/Brand';

const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

interface NewPostForm {
  image: File;
  title: string;
  description: string;
}

type SelectOption =
  | {
      value: string;
      label: string;
    }
  | null
  | undefined;

export interface NewPostCardProps {
  discourseCategoryId?: number;
  title?: string;
  onSubmitSuccess?: (post: Post | null) => void;
  onCancel?: () => void;
  buttonsPosition?: 'top' | 'bottom';
  buttonsAlign?: 'right' | 'center';
  submitButtonLabel?: string;
  fullWidth?: boolean;
  moduleProgressId?: number | null;
  tags?: string[] | null;
  isTitleRequired?: boolean;
  isTextOnly?: boolean;
  isAssessment?: boolean;
  moduleOptions?: {
    value: string;
    label: string;
    options: {
      value: string;
      label: string;
    }[];
  }[];
}

const youtubeSlugFromUrl = (url: string) => {
  const regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[7].length === 11 ? match[7] : false;
};

const steppitSlugFromUrl = (url: string) => {
  const regExp = /steppit\.com\/(?:embed\/)?s\/([a-zA-Z0-9_-]+)\/?(?:\?|$)/;
  const match = url.match(regExp);
  return match && match[1] ? match[1] : false;
};

const NewPostCard: React.FC<NewPostCardProps> = ({
  onSubmitSuccess,
  onCancel,
  discourseCategoryId,
  title,
  buttonsPosition = 'top',
  buttonsAlign = 'right',
  submitButtonLabel = 'Post',
  fullWidth = false,
  moduleProgressId = null,
  tags = null,
  isTitleRequired = true,
  isTextOnly = false,
  isAssessment = false,
  moduleOptions,
}) => {
  const dispatch = useDispatch();
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isEmbedding, setIsEmbedding] = useState<boolean>(false);
  const [isCustomEmbedding, setIsCustomEmbedding] = useState<string>('');
  const [isPreviewingEmbed, setIsPreviewingEmbed] = useState<boolean>(false);
  const [embedInput, setEmbedInput] = useState<string>('');
  const [selectedModule, setSelectedModule] = useState<SelectOption>(null);
  const formMethods = useForm<NewPostForm>({});
  const user = useUser();
  const theme = useTheme();

  let embedCode = embedInput;
  if (isCustomEmbedding === 'steppit' || isCustomEmbedding === 'youtube') {
    const embedSlug =
      isCustomEmbedding === 'steppit'
        ? steppitSlugFromUrl(embedInput)
        : isCustomEmbedding === 'youtube'
        ? youtubeSlugFromUrl(embedInput)
        : '';
    const embedUrl =
      isCustomEmbedding === 'steppit'
        ? `https://www.steppit.com/embed/s/${embedSlug}`
        : isCustomEmbedding === 'youtube'
        ? `https://www.youtube.com/embed/${embedSlug}`
        : '';
    embedCode = embedSlug
      ? `<iframe width="720" height="405" src="${embedUrl}"></iframe>`
      : '';
  } else {
    const [, embedUrl] =
      /<iframe[^>]*\s+src=["'](.*?)["']/.exec(embedCode) ?? [];
    embedCode = embedUrl
      ? `<iframe width="720" height="405" src="${embedUrl}"></iframe>`
      : '';
  }

  const moduleProgress = useSelector((state: GlobalState) =>
    Object.values(state.learner.courseProgress.modules).find((mp) =>
      selectedModule
        ? mp.module === selectedModule.value
        : mp.id === moduleProgressId
    )
  );
  const module = useSelector((state: GlobalState) =>
    Object.values(state.learner.courses.modules).find((m) =>
      selectedModule
        ? m.slug === selectedModule.value
        : m.slug === moduleProgress?.module
    )
  );
  const unit = useSelector((state: GlobalState) =>
    Object.values(state.learner.courses.units).find(
      (u) => u.slug === module?.unit
    )
  );

  const currentModuleProgressId = moduleProgressId || moduleProgress?.id;
  const currentTags = tags
    ? tags
    : module && unit
    ? [`module-${module.index - 1}`, `unit-${unit.index - 1}`]
    : null;

  // Poll image response from discourse to sync up with journal
  const pollTopicUntilImageExists = async (
    id: number
  ): Promise<Topic | undefined> => {
    const topic = await dispatch(discourseActions.getTopic(id));
    if (topic) {
      // @ts-ignore
      const { imageUrl } = topic;
      if (imageUrl) {
        // @ts-ignore
        return topic;
      }
      await timeout(200);
      return pollTopicUntilImageExists(id);
    }
  };

  const {
    register,
    handleSubmit,
    errors,
    formState: { dirty, isValid },
    setValue,
    unregister,
    triggerValidation,
  } = formMethods;

  useEffect(() => {
    register('image', { required: false });

    return () => {
      unregister('image');
    };
  }, [register, unregister]);

  const onSubmit = handleSubmit(async (data) => {
    setIsSubmitting(true);

    let post = null;
    const title = module
      ? `${user.name || ''}: ${module.title} (${moment().unix()})`
      : data.title
      ? data.title
      : '';

    let embedStr = '';
    if (embedCode) {
      const [, embedSrc] =
        /<iframe[^>]*\s+src=["'](.*?)["']/.exec(embedCode) ?? [];
      if (embedSrc) {
        embedStr = `[iframe src="${embedSrc}"]`;
      }
    }

    if (!isAssessment) {
      post = await dispatch(
        discourseActions.createTopic({
          raw: embedStr
            ? `${embedStr}\n\n${data.description}`
            : data.description,
          title,
          imageFile: data.image,
          category: discourseCategoryId || undefined,
          ...(currentTags ? { tags: currentTags } : {}),
        })
      );
    }

    // Sync up the image from Discourse with with the journal
    let migratedImageUrl: string | null = null;
    let topic: Topic | undefined | void;
    if (post) {
      if (data.image) {
        // If the image is nested in the cooked post response, then we can grab it from there
        // @ts-ignore
        if (post.cooked) {
          // @ts-ignore
          const match = post.cooked.match(/<img\s+[^>]*src="([^"]+)"/);
          if (match && match.length > 0) {
            migratedImageUrl = match[1] as string;
          }
        }
        if (migratedImageUrl) {
          // @ts-ignore
          topic = await dispatch(discourseActions.getTopic(post.topicId));
        } else {
          // Otherwise we poll the topic to get the image response from Discourse
          // @ts-ignore
          topic = await pollTopicUntilImageExists(post.topicId);
          if (topic) {
            const { imageUrl } = topic;
            migratedImageUrl = imageUrl;
          }
        }
      } else {
        // @ts-ignore
        topic = await dispatch(discourseActions.getTopic(post.topicId));
      }
    }

    if ((post || isAssessment) && currentModuleProgressId) {
      await dispatch(
        journalActions.createModuleProgressJournalEntry({
          description: embedStr
            ? `${embedStr}\n\n${data.description}`
            : data.description,
          moduleProgress: currentModuleProgressId,
          ratingStrength: 3,
          ...(title ? { title } : {}),
          // @ts-ignore
          ...(post ? { discourseTopicId: post.topicId } : {}),
          ...(isAssessment ? { isAssessment, imageFile: data.image } : {}),
          ...(migratedImageUrl && topic ? { migratedImageUrl, topic } : {}),
        })
      );
    }

    // @ts-ignore
    (post || isAssessment) && onSubmitSuccess && onSubmitSuccess(post || null);

    setIsSubmitting(false);
  });

  const onImageDrop = async (name: string, acceptedFiles: File[]) => {
    setValue(name, acceptedFiles[0]);
    await setImagePreview(URL.createObjectURL(acceptedFiles[0]));
    triggerValidation();
  };

  const embedOptions = [
    {
      slug: 'steppit',
      label: 'Steppit session',
      icon: <SteppitMiniIcon color="#19A96D" />,
      color: 'green',
    },
    {
      slug: 'youtube',
      label: 'Youtube video',
      icon: <FaYoutube />,
      color: 'red',
    },
    {
      slug: 'custom',
      label: 'Custom embed',
      icon: <MdIcon name="Code" />,
      color: 'neutral',
    },
  ];

  return (
    <Box flex={1}>
      <FormContext {...formMethods}>
        <Flex
          flex={1}
          flexDirection={{
            base: 'column',
            md: fullWidth || isCustomEmbedding ? 'column' : 'row',
          }}
        >
          {!isTextOnly && (
            <Flex
              flexDirection="column"
              mb={{ base: 2, md: fullWidth || isCustomEmbedding ? 2 : 0 }}
            >
              {isCustomEmbedding ? (
                <Flex
                  height="100%"
                  bg="background.tint1"
                  borderWidth={1}
                  borderColor="border.default"
                  p={3}
                  borderRadius="md"
                  flexDirection="column"
                >
                  {isPreviewingEmbed ? (
                    <Box
                      w="100%"
                      minH="350px"
                      position="relative"
                      mb={3}
                      bg="background.tint2"
                    >
                      <Box w="100%" h={0} pb="56.25%">
                        <Box position="absolute" w="100%" h="100%">
                          <RenderHtml iframeOnly html={embedCode || ''} />
                        </Box>
                      </Box>
                    </Box>
                  ) : isCustomEmbedding === 'custom' ? (
                    <LabelTextArea
                      id="embed"
                      label="Embed code"
                      labelStyleProps={{ fontSize: 'sm' }}
                      labelPosition="top"
                      boxSizing="border-box"
                      flex={1}
                      name="embed"
                      placeholder="Paste your <iframe> embed code here..."
                      defaultValue={embedInput}
                      helpText={`Embed any <iframe> content in your post here. For example, this could be a Zoom call, a Google Form, a SlideShare presentation or any other type of embedded widget. You can usually find the embed code via buttons like "Share" or "Embed".`}
                      onChange={(e) => setEmbedInput(e.target.value)}
                      backgroundColor="background.default"
                      fontSize="sm"
                      fontFamily="monospace"
                    />
                  ) : (
                    <LabelInput
                      label={
                        isCustomEmbedding === 'steppit'
                          ? 'Steppit session link'
                          : isCustomEmbedding === 'youtube'
                          ? 'YouTube video link'
                          : ''
                      }
                      labelStyleProps={{ fontSize: 'sm' }}
                      labelPosition="top"
                      boxSizing="border-box"
                      flex={1}
                      name="embed"
                      placeholder={
                        isCustomEmbedding === 'steppit'
                          ? 'Paste a link to a public Steppit session here...'
                          : isCustomEmbedding === 'youtube'
                          ? 'Paste a link to a YouTube video here...'
                          : ''
                      }
                      defaultValue={embedInput}
                      helpText={
                        isCustomEmbedding === 'steppit'
                          ? 'Make sure your session is accessible via a public link, e.g. https://www.steppit.com/s/ABC'
                          : isCustomEmbedding === 'youtube'
                          ? 'E.g. https://www.youtube.com/watch?v=ABC'
                          : ''
                      }
                      onChange={(e) => setEmbedInput(e.target.value)}
                      backgroundColor="background.default"
                      fontSize="sm"
                    />
                  )}

                  <Stack direction="row" justifyContent="flex-end">
                    {!isPreviewingEmbed && (
                      <Button
                        size="sm"
                        variant="outline"
                        icon="ArrowBack"
                        w={8}
                        onClick={() => setIsCustomEmbedding(false)}
                      />
                    )}
                    <Button
                      size="sm"
                      variant="outline"
                      icon={isPreviewingEmbed ? 'Close' : 'Preview'}
                      onClick={() => setIsPreviewingEmbed(!isPreviewingEmbed)}
                    >
                      {isPreviewingEmbed ? 'Close Preview' : 'Preview'}
                    </Button>
                  </Stack>
                </Flex>
              ) : isEmbedding ? (
                <Flex
                  position="relative"
                  height="100%"
                  width={{ base: '100%', md: fullWidth ? '100%' : '200px' }}
                  bg="background.tint2"
                  borderWidth={1}
                  borderColor="border.default"
                  p={4}
                  borderRadius="md"
                >
                  <Button
                    position="absolute"
                    top={1}
                    left={1}
                    icon="ArrowBack"
                    size="sm"
                    variant="ghost"
                    onClick={() => setIsEmbedding(false)}
                    w={8}
                  />
                  <Stack
                    justifyContent="center"
                    alignItems="center"
                    fontWeight="semibold"
                    color="text.muted"
                    fontSize="sm"
                    direction="column"
                    minHeight="150px"
                    height="100%"
                    width="100%"
                  >
                    <Text textAlign="center">Embed options:</Text>
                    {embedOptions.map((o) => (
                      <Flex
                        bg={`${o.color}.50`}
                        color={`${o.color}.600`}
                        alignItems="center"
                        borderRadius="full"
                        cursor="pointer"
                        _hover={{
                          bg: `${o.color}.100`,
                        }}
                        px={3}
                        py={1}
                        borderColor={`${o.color}.600`}
                        borderWidth={0.5}
                        width="160px"
                        onClick={() => setIsCustomEmbedding(o.slug)}
                      >
                        {o.icon}
                        <Text ml={2}>{o.label}</Text>
                      </Flex>
                    ))}
                  </Stack>
                </Flex>
              ) : (
                <Flex
                  width={{ base: '100%', md: fullWidth ? '100%' : '200px' }}
                  height="100%"
                  flexDirection="column"
                >
                  <Flex flex={1}>
                    <ImageUpload
                      id="new_post_image"
                      backgroundColor="background.tint2"
                      height="120px"
                      name="image"
                      width={{ base: '100%', md: fullWidth ? '100%' : '200px' }}
                      image={imagePreview || ''}
                      onDrop={onImageDrop}
                      styleProps={{
                        backgroundPosition: 'center',
                        backgroundSize: 'contain',
                      }}
                      placeholder="Add image..."
                      showBlur
                    />
                  </Flex>
                  {!imagePreview && PLATFORM === 'steppit' && (
                    <Stack direction="row" mt={2} spacing={2}>
                      <Flex
                        flex={1}
                        bg="background.tint2"
                        borderWidth={1}
                        borderColor="border.default"
                        p={2}
                        justifyContent="center"
                        alignItems="center"
                        fontWeight="semibold"
                        color="text.muted"
                        borderRadius="md"
                        fontSize="sm"
                        cursor="pointer"
                        _hover={{
                          bg: 'background.primary',
                        }}
                        onClick={() => setIsEmbedding(true)}
                      >
                        <MdIcon name="Code" fontSize="lg" />
                        <Text ml={1.5}>Add embed...</Text>
                      </Flex>
                    </Stack>
                  )}
                </Flex>
              )}
            </Flex>
          )}
          <Flex
            flexDir="column"
            flex={1}
            paddingLeft={{
              base: 0,
              md: fullWidth || isCustomEmbedding ? 0 : 2,
            }}
          >
            <Flex
              mb={title || buttonsPosition === 'top' ? 2 : 0}
              alignItems="center"
            >
              {title ? (
                <>
                  <Flex
                    alignItems="center"
                    borderRadius="xl"
                    boxSize="image.2xs"
                    background="background.tint2"
                    mr={2}
                    justifyContent="center"
                  >
                    <MdIcon boxSize="icon" name="People" color="icon.default" />
                  </Flex>
                  <Text fontWeight="semibold" mr={4} marginY={0}>
                    {title}
                  </Text>
                </>
              ) : null}
              <Box flex={1} />
              {buttonsPosition === 'top' && (
                <>
                  {onCancel ? (
                    <Button secondary onClick={onCancel} mr={2}>
                      Cancel
                    </Button>
                  ) : null}
                  <Button
                    isDisabled={isSubmitting || !dirty || !isEmpty(errors)}
                    isLoading={isSubmitting}
                    onClick={onSubmit}
                  >
                    Post
                  </Button>
                </>
              )}
            </Flex>
            {moduleOptions && (
              <Box mb={2}>
                <Select
                  options={moduleOptions}
                  value={selectedModule}
                  onChange={(module) => setSelectedModule(module)}
                  placeholder="Tag session..."
                  styles={{
                    control: (base: React.CSSProperties) => ({
                      ...base,
                      cursor: 'pointer',
                      backgroundColor: theme.colors.background.default,
                      borderColor: theme.colors.border.muted,
                      borderRadius: theme.radii.md,
                    }),
                    placeholder: (base: React.CSSProperties) => ({
                      color: theme.colors.text.muted,
                    }),
                    menu: (base: React.CSSProperties) => ({
                      ...base,
                      backgroundColor: theme.colors.background.default,
                    }),
                    option: (base: React.CSSProperties) => ({
                      ...base,
                      cursor: 'pointer',
                    }),
                  }}
                />
              </Box>
            )}
            {Boolean(isTitleRequired && !module) && (
              <Input
                boxSizing="border-box"
                mb={2}
                name="title"
                placeholder="Add a title..."
                fontWeight="semibold"
                ref={register({
                  required: true,
                  validate: (val) => Boolean(val?.length),
                })}
                onChange={() => !isValid && triggerValidation()}
                backgroundColor="background.default"
                px={2}
              />
            )}
            <Textarea
              boxSizing="border-box"
              flex={1}
              name="description"
              placeholder="Write a description..."
              ref={register({
                required: true,
                validate: (val) => Boolean(val?.length),
              })}
              resize="none"
              onChange={() => !isValid && triggerValidation()}
              backgroundColor="background.default"
              px={2}
            />
          </Flex>
        </Flex>
        {buttonsPosition === 'bottom' && (
          <Flex
            mt={4}
            justifyContent={buttonsAlign === 'center' ? 'center' : 'flex-end'}
          >
            {onCancel ? (
              <Button
                secondary
                onClick={onCancel}
                mr={2}
                size={buttonsAlign === 'center' ? 'md' : 'sm'}
              >
                Cancel
              </Button>
            ) : null}
            <Button
              isDisabled={isSubmitting || !dirty || !isEmpty(errors)}
              isLoading={isSubmitting}
              onClick={onSubmit}
              size={buttonsAlign === 'center' ? 'md' : 'sm'}
              minWidth={buttonsAlign === 'center' ? '200px' : 0}
              icon={isAssessment ? 'AssignmentTurnedIn' : 'PostAdd'}
            >
              {submitButtonLabel}
            </Button>
          </Flex>
        )}
      </FormContext>
    </Box>
  );
};

export default NewPostCard;
