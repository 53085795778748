import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Flex,
  Text,
  MdIcon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Spinner,
} from '@workshop/ui';

import { useUploadList } from 'redux/selectors/background';
import { backgroundActions } from 'redux/actions/common';

import { STATUS } from 'constants/background';
import { ChunkUploadStatus } from 'types/common';

interface Props {
  id: string;
  upload: {
    progress: number;
    filename: string;
    filesize: number;
    status: ChunkUploadStatus;
  };
  displayText?: boolean;
  displayBytes?: boolean;
  light?: boolean;
  centered?: boolean;
}

export const UploadStatus: React.FC<Props> = ({
  id,
  upload,
  displayText = true,
  displayBytes,
  light = false,
  centered = false,
}) => {
  const dispatch = useDispatch();

  const cancelUpload = (id: string) => {
    dispatch(backgroundActions.cancelChunkUpload(id));
  };

  const { filename, progress, status, filesize: rawFilesize } = upload;

  const fileSize = rawFilesize / 1000000;
  const uploadBytes = `${(fileSize * (progress / 100)).toFixed(
    2
  )}MB / ${fileSize.toFixed(2)}MB`;

  const isCancelled = status === STATUS.cancelled;
  const isError = status === STATUS.error;

  if (isError) {
    return (
      <Flex
        flex={1}
        mt={centered ? 2 : 0}
        mb={centered ? 0 : 2}
        flexDirection={centered ? 'column' : 'row'}
        alignItems="center"
        textAlign={centered ? 'center' : 'left'}
      >
        <MdIcon
          color={light ? 'neutral.200' : 'text.muted'}
          name="Error"
          boxSize={centered ? 6 : 4}
          mb={centered ? 2 : 0}
          mr={centered ? 0 : 2}
        />
        <Text
          mb={centered ? 6 : 0}
          color={light ? 'neutral.200' : 'text.muted'}
          whiteSpace="break-spaces"
          fontSize="sm"
        >
          {'Upload failed 😣\nPlease refresh the page and try again.'}
        </Text>
        {centered ? (
          <Button
            icon="Refresh"
            colorScheme="whiteAlpha"
            onClick={() => window.location.reload()}
            size="sm"
          >
            Refresh Page
          </Button>
        ) : null}
      </Flex>
    );
  }

  return (
    <Flex
      flex={1}
      mt={centered ? 2 : 0}
      mb={centered ? 0 : 2}
      alignItems="stretch"
      flexDir="column"
      opacity={isCancelled ? 0.5 : 1}
      pointerEvents={isCancelled ? 'none' : 'auto'}
    >
      <Flex flexDirection={centered ? 'column' : 'row'} alignItems="center">
        <Flex flex={centered ? 1 : 0} flexDirection="column">
          {/* <Progress value={progress} my={2} borderRadius="md" /> */}
          <CircularProgress
            value={progress}
            size={centered ? 12 : 6}
            thickness="16px"
            color="common.progress"
            trackColor="background.tint2"
            capIsRound
            sx={{
              '& > div:first-child': {
                transitionProperty: 'width',
              },
            }}
            my={2}
          >
            {(status === STATUS.inProgress || status === STATUS.started) &&
              progress < 100 && (
                <CircularProgressLabel>
                  <Spinner speed="0.75s" color="common.progress" size="xs" />
                </CircularProgressLabel>
              )}
          </CircularProgress>
        </Flex>
        {(displayText || displayBytes) && (
          <Flex
            flexDirection="column"
            ml={centered ? 0 : 4}
            mt={centered ? 2 : 0}
            flex={centered ? 0 : 1}
          >
            {displayText && (
              <Text
                fontSize="xs"
                color={light ? 'white' : 'text.muted'}
                noOfLines={1}
              >
                {filename}
              </Text>
            )}
            {displayText ||
              (displayBytes && (
                <Text
                  fontSize="xs"
                  color={light ? 'white' : 'text.muted'}
                  noOfLines={1}
                >
                  {uploadBytes}
                </Text>
              ))}
          </Flex>
        )}
        <Flex
          alignItems="center"
          justifyContent="flex-start"
          ml={centered ? 0 : 4}
          mt={centered ? 4 : 0}
        >
          {upload.progress === 100 ? (
            <MdIcon name="CheckCircle" color="common.progress" boxSize="icon" />
          ) : (
            <Button
              variant="outline"
              colorScheme="whiteAlpha"
              icon="Cancel"
              size="xs"
              onClick={() => cancelUpload(id)}
              {...(!centered
                ? {
                    w: 6,
                  }
                : {})}
            >
              {centered ? 'Cancel' : null}
            </Button>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

const UploadStatusModal = () => {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const clearUploads = () => dispatch(backgroundActions.clearChunkUploads());
  const uploadList = useUploadList();
  const hasActiveUpload = !!Object.values(uploadList).find(
    (u) =>
      (u.status === STATUS.inProgress || u.status === STATUS.started) &&
      u.progress < 100
  );
  const uploadsComplete =
    Object.values(uploadList).length > 0 &&
    !Object.values(uploadList).find(
      (u) => u.status !== STATUS.completed || u.progress < 100
    );
  return (
    <>
      <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
        <ModalOverlay />
        <ModalContent borderRadius="md">
          <ModalHeader>Uploads</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {Object.keys(uploadList).length === 0 && (
              <Flex justifyContent="center" alignItems="center">
                <Text color="text.muted">You have no active uploads</Text>
              </Flex>
            )}
            <Flex flexDir="column">
              {Object.keys(uploadList).map((key) => {
                const upload = uploadList[key];
                return (
                  <Flex key={key}>
                    <UploadStatus id={key} upload={upload} />
                  </Flex>
                );
              })}
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button onClick={() => setShowModal(false)} size="sm" secondary>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Button
        fontSize="sm"
        ml="defaultMargin"
        boxShadow={{ base: 'none', md: 'lg' }}
        backgroundColor={{
          base: 'background.tint2',
          md: 'background.default',
        }}
        color="text.default"
        _hover={{
          backgroundColor: {
            base: 'background.tint1',
            md: 'background.tint1',
          },
        }}
        secondary
        leftIcon={
          hasActiveUpload ? (
            <Flex mr={{ base: -2, md: 0 }}>
              <Spinner speed="0.75s" color="common.progress" size="sm" />
            </Flex>
          ) : (
            <MdIcon
              name={uploadsComplete ? 'Done' : 'ArrowUpward'}
              color={uploadsComplete ? 'common.progress' : 'inherit'}
              mr={{ base: -2, md: 0 }}
            />
          )
        }
        onClick={() => setShowModal(true)}
        style={{}}
        borderRadius="full"
        w={{ base: 10, md: 'auto' }}
      >
        <Text display={{ base: 'none', md: 'inline' }}>Uploads</Text>
      </Button>
    </>
  );
};

export default UploadStatusModal;
