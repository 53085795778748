import React, { useEffect, useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import moment from 'moment';

import navRoutes from 'navigation/Routes';

import {
  COURSE_PUBLISH_STATUS,
  ACCESS_TYPES,
  COHORT_TYPES,
  SOCIAL_TYPES,
  PRIVATE_CLASS_SIZE_LIMIT,
} from 'constants/courses';
import { PLATFORM_DISPLAY_NAME } from 'constants/common';
import { PRO_ORGS, WORKSHOP_ORGS } from 'constants/organisation';
import { ICONS } from 'constants/ui';
import { PLATFORM } from 'constants/env';

import {
  Divider,
  Flex,
  MdIcon,
  Text,
  Button,
  Stack,
  Box,
  chakra,
  useToast,
} from '@workshop/ui';

import {
  LabelInput,
  LabelSelect,
  LabelDatePicker,
  StepsModal,
  ButtonGrid,
  ProCta,
} from 'components/Common';
import { Props as EditModalProps } from 'components/Common/EditModal';
import { ClassTypeItem } from 'components/CohortAllocation';
import { IconTooltip } from 'components/IconTooltip';

import { courseUtils, analytics } from 'utils';
import { useWindowDimensions } from 'utils/hooks/useDimensions';

import { cohortActions } from 'redux/actions/cms';
import { useCurrentTeamProfile } from 'redux/selectors';
import { getLicensedCourses } from 'redux/selectors/course';
import { useHasPermission } from 'redux/selectors/organisation';

import { GlobalState } from 'types';
import {
  TeamMember,
  Organisation,
  AccessType,
  SocialType,
  CohortStatus,
  PERMISSION_SLUGS,
} from 'types/common';

export interface CreateCohortFormData {
  accessType: AccessType;
  course: number;
  isPrivate: boolean;
  isTest: boolean;
  isAnonymous: boolean;
  onSale: boolean;
  label: string;
  socialType: SocialType;
  spacesTotal: number;
  startDate: Date | null;
  mentors: {
    mentorId: string;
    isAssessor: boolean;
    isSubstitute: boolean;
    subDetails?: {
      subStart: Date | undefined;
      subEnd: Date | undefined;
    };
  }[];
}

const labelStyleProps = { whiteSpace: 'nowrap' } as const;

interface CreateCohortModalProps extends EditModalProps {
  availableMentors: TeamMember[];
  onCreateCohort: (id: number) => void;
}

const CreateCohortModal: React.FC<CreateCohortModalProps> = (props) => {
  const {
    availableMentors,
    isOpen,
    modalSize,
    title,
    onClose,
    onCreateCohort,
  } = props;

  const [isUpdating, setIsUpdating] = useState(false);
  const defaultClassType = COHORT_TYPES[0];
  const [selectedClassType, setSelectedClassType] = useState(defaultClassType);
  const [triggerNextStep, setTriggerNextStep] = useState<boolean | undefined>(
    undefined
  );
  const [triggerOpenProPopup, setTriggerOpenProPopup] = useState<
    boolean | undefined
  >(undefined);

  const dispatch = useDispatch();
  const history = useHistory();

  const currentTeam = useCurrentTeamProfile() as Organisation;

  const isEditBillingDisabled = !useHasPermission(
    PERMISSION_SLUGS.can_edit_billing_details
  );
  const toast = useToast();

  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < 689;

  const courseList = useSelector(
    (state: GlobalState) => state.cms.course.courseList
  );

  const liveCourses = Object.values(courseList).filter(
    (c) =>
      c.organisation === currentTeam.id &&
      c.status === COURSE_PUBLISH_STATUS.published &&
      c.courseType !== 'session'
  );

  const courseOptions = liveCourses.reduce(
    (acc, curr) => ({ ...acc, [curr.id.toString()]: curr.title }),
    {} as { [key: string]: string }
  );

  const licensedCourses = useSelector((state: GlobalState) =>
    getLicensedCourses(
      state.learner.courses.courses.detail,
      state.cms.enrolment.license,
      currentTeam.id
    )
  );

  const licensedCourseOptions = licensedCourses
    ? Object.values(licensedCourses).reduce(
        (acc, curr) => ({
          ...acc,
          [curr.id.toString()]: `${curr.title} (from ${curr.organisation.name})`,
        }),
        {} as { [key: string]: string }
      )
    : {};

  const mentorOptions = availableMentors.reduce(
    (acc, curr) => ({ ...acc, [curr.user.id]: curr.user.name }),
    {} as { [key: string]: string }
  );
  const mentorOptionsKeys = Object.keys(mentorOptions);

  const allOptions = {
    ...licensedCourseOptions,
    ...(PLATFORM === 'steppit' || WORKSHOP_ORGS.includes(currentTeam.id)
      ? courseOptions
      : {}),
  };

  const firstCourseOption =
    Object.entries(allOptions).length > 0
      ? parseInt(
          Object.entries(allOptions).sort(([aKey, aValue], [bKey, bValue]) =>
            aValue.localeCompare(bValue)
          )[0][0]
        )
      : undefined;

  const { handleSubmit, control, errors, register, setValue, watch, reset } =
    useForm<CreateCohortFormData>({
      mode: 'onChange',
      defaultValues: {
        course: firstCourseOption,
        label: '',
        spacesTotal: undefined,
        startDate: undefined,
        socialType: defaultClassType.socialType,
        accessType: defaultClassType.accessType,
        isAnonymous: defaultClassType.isAnonymous,
        isPrivate: true,
        onSale: false,
        // If only 1 mentor is available, add them by default
        mentors:
          mentorOptionsKeys.length === 1
            ? [
                {
                  mentorId: mentorOptionsKeys[0],
                  isAssessor: true,
                  isSubstitute: false,
                },
              ]
            : [{ mentorId: '', isAssessor: true, isSubstitute: false }],
      },
    });

  const {
    fields: mentors,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'mentors',
  });

  const handleOnClose = () => {
    reset();
    onClose();
  };

  const handleOnSave = async (e?: React.FormEvent<HTMLFormElement>) => {
    setIsUpdating(true);
    if (e && e.stopPropagation) {
      // prevent any outer forms from receiving the event too
      e.stopPropagation();
    }

    const submit = await handleSubmit(async ({ mentors, ...data }) => {
      const spacesTotal = courseUtils.hasClassSizeLimit({
        socialType: data.socialType,
        isAnonymous: data.isAnonymous,
      })
        ? data.spacesTotal
        : 0;
      const res = await dispatch(
        cohortActions.createCohort({
          ...data,
          status: CohortStatus.live,
          organisation: currentTeam.id,
          course: data.course.toString(),
          startDate: moment(startDate?.toISOString()).format('YYYY-MM-DD'),
          onSale: data.onSale,
          isPrivate: data.isPrivate,
          isTest: false,
          privateChatDisabled: true,
          spacesTotal,
          accessType: data.accessType,
          socialType: data.socialType,
          mentors: mentors
            .filter((m) => m.mentorId)
            .map((m) => ({
              assessor: true,
              isSubstitute: m.isSubstitute,
              mentor: parseInt(m.mentorId),
              subStartDate:
                m.isSubstitute && m.subDetails?.subStart
                  ? moment(m.subDetails?.subStart?.toISOString()).format(
                      'YYYY-MM-DD'
                    )
                  : undefined,
              expiryDate:
                m.isSubstitute && m.subDetails?.subEnd
                  ? moment(m.subDetails?.subEnd?.toISOString()).format(
                      'YYYY-MM-DD'
                    )
                  : undefined,
            })),
        })
      );
      analytics.track('Class Created', {
        type: selectedClassType.slug,
        enrolment_type: data.isPrivate ? 'private' : 'on_sale',
        size: spacesTotal,
        ...(startDate ? { start_date: startDate.toISOString() } : {}),
      });
      if (res?.payload?.hasOwnProperty('id')) {
        // @ts-ignore
        onCreateCohort(res.payload.id);
      }
    });

    await submit(e);

    setIsUpdating(false);

    handleOnClose();
  };

  useEffect(() => {
    register('course', { required: true });
    register('startDate', { required: true });
    register('label');
    register('spacesTotal');
    register('socialType');
    register('accessType');
    register('isAnonymous');
    register('isPrivate');
    register('onSale');
  }, [register]);

  useEffect(() => {
    setValue('socialType', selectedClassType.socialType);
    setValue('accessType', selectedClassType.accessType);
    setValue('isAnonymous', selectedClassType.isAnonymous);
  }, [selectedClassType.id]);

  const courseValue = watch('course');
  const startDate = watch('startDate');
  const mentorValues = watch('mentors');
  const nonSubMentors = mentorValues.filter((m) => !m.isSubstitute);
  const accessTypeValue = watch('accessType');
  const socialTypeValue = watch('socialType');
  const isAnonymousValue = watch('isAnonymous');
  const spacesTotalValue = watch('spacesTotal');
  const onSaleValue = watch('onSale');

  const isCourseLicensed = Boolean(
    licensedCourses &&
      Object.values(licensedCourses).find((c) => c.id === courseValue)
  );
  const course =
    isCourseLicensed && licensedCourses
      ? Object.values(licensedCourses).find((c) => c.id === courseValue)
      : courseList[courseValue];

  useEffect(() => {
    if (isCourseLicensed && PLATFORM === 'workshop') {
      const roomType = COHORT_TYPES.find((c) => c.slug === 'rooms');
      if (roomType) {
        setSelectedClassType(roomType);
      }
    }
  }, [isCourseLicensed]);

  const isValid = mentorValues.find((val) => val.mentorId);
  const hasClassSizeLimit = courseUtils.hasClassSizeLimit({
    socialType: socialTypeValue,
    isAnonymous: isAnonymousValue,
  });

  const modalProps = {
    isOpen,
    modalSize,
    onCancel: handleOnClose,
    onClose: handleOnClose,
    heading: title,
    loading: isUpdating,
    submitting: isUpdating,
  };

  const isPro = Boolean(
    currentTeam && (currentTeam.isPro || PRO_ORGS.includes(currentTeam.id))
  );

  const hasConnectedStripe = Boolean(
    currentTeam &&
      currentTeam.stripeAccountId &&
      currentTeam.stripeAccountActive
  );

  const allSteps = [
    {
      slug: 'class',
      label: 'New Class',
      icon: <MdIcon name={ICONS.course} />,
      nextButtonText: 'Next',
      content: (
        <Box pt={6} pb={{ base: 6, md: 0 }}>
          <Text mb={4} color="text.muted">
            Each class you run will give a new group of learners the chance to
            collaborate, chat with you and make their way through your course
            step by step.
          </Text>
          <Text mb={4} fontSize="lg" fontWeight="semibold">
            Which course are you creating this class for?
          </Text>
          <LabelSelect
            isDisabled={false}
            label="Course"
            onChange={(e) => setValue('course', parseInt(e.target.value))}
            error={!!errors.course}
            defaultValue={courseValue}
            options={allOptions}
            labelStyleProps={labelStyleProps}
            labelPosition={isMobile ? 'top' : 'inline'}
          />
        </Box>
      ),
    },
    {
      slug: 'enrolments',
      label: 'Enrollments',
      icon: <MdIcon name="Workspaces" />,
      nextButtonText: 'Next',
      nextButtonDisabled: true,
      content: (
        <Box pt={6} pb={{ base: 6, md: 0 }}>
          <Text mb={4} fontSize="lg" fontWeight="semibold">
            Do you want to put this class on sale or enroll learners privately?
          </Text>
          <ButtonGrid
            mb={8}
            minHeight={180}
            items={[
              {
                slug: 'forSale',
                name:
                  PLATFORM === 'steppit'
                    ? 'Open a Class for Sale'
                    : 'Open a Class for Sale',
                description: isCourseLicensed
                  ? ''
                  : PLATFORM === 'steppit'
                  ? 'Sell places for your class from your Steppit channel.'
                  : 'Sell class places for your course',
                icon: 'Sell',
                onClick: () => {
                  if (!isCourseLicensed) {
                    if (hasConnectedStripe) {
                      if (course?.price) {
                        setValue('onSale', true);
                        setValue('isPrivate', false);
                        setTriggerNextStep(!triggerNextStep);
                      } else {
                        history.push({
                          pathname: navRoutes.cms.editCourse.path(course?.id),
                          search: 'tab=details&focus=price',
                        });
                      }
                    } else if (!isEditBillingDisabled) {
                      history.push({
                        pathname: navRoutes.cms.myOrganisation.path(),
                        search: 'tab=payments&p=start',
                      });
                    } else {
                      toast({
                        title: 'Action from Channel Owner Required',
                        description: `To start selling on ${PLATFORM_DISPLAY_NAME}, your channel owner needs to connect your channel to Stripe.`,
                        status: 'warning',
                        duration: 5000,
                        isClosable: true,
                      });
                    }
                    setTriggerOpenProPopup(undefined);
                  }
                },
                disabled: isCourseLicensed,
                footerElement: (
                  <Flex alignItems="center" justifyContent="center" mt={3}>
                    {isCourseLicensed ? (
                      <Text
                        textAlign="center"
                        color="text.muted"
                        fontSize="xs"
                        whiteSpace="break-spaces"
                      >
                        <chakra.b>Not Available</chakra.b>
                        {`${
                          course && course.organisation.hasOwnProperty('name')
                            ? // @ts-ignore
                              `\n(Course by ${course.organisation.name})`
                            : ''
                        }`}
                      </Text>
                    ) : hasConnectedStripe ? (
                      <>
                        {course?.price ? (
                          <>
                            <Text
                              textAlign="center"
                              color="text.muted"
                              fontSize="xs"
                              mr={1}
                            >
                              {'Course price: '}
                              <chakra.b>{`£${Math.floor(
                                parseInt(course.price)
                              )}`}</chakra.b>
                            </Text>
                            <IconTooltip tooltip="create_cohort_price" />
                          </>
                        ) : (
                          <Text
                            textAlign="center"
                            color="text.error"
                            fontSize="xs"
                            mr={1}
                          >
                            Set a price on your course to start selling places
                          </Text>
                        )}
                      </>
                    ) : (
                      <Text
                        textAlign="center"
                        color="text.primary"
                        fontSize="xs"
                        mr={1}
                      >
                        Connect to Stripe to start selling places
                      </Text>
                    )}
                  </Flex>
                ),
              },
              {
                slug: 'private',
                name:
                  PLATFORM === 'steppit'
                    ? 'Start a Private Class'
                    : 'Start a Private Class',
                description:
                  PLATFORM === 'steppit'
                    ? 'Use enrollments to add people to your class via invites or integrations.'
                    : 'Privately enroll people onto your course.',
                icon: 'PersonAdd',
                onClick: () => {
                  if (isPro || isCourseLicensed) {
                    setValue('onSale', false);
                    setValue('isPrivate', true);
                    setTriggerNextStep(!triggerNextStep);
                  } else {
                    setTriggerOpenProPopup(!triggerOpenProPopup);
                  }
                },
                footerElement:
                  isPro || isCourseLicensed ? undefined : (
                    <Box mt={4}>
                      <ProCta hideCta triggerOpenPopup={triggerOpenProPopup} />
                    </Box>
                  ),
              },
            ]}
          />
        </Box>
      ),
    },
    {
      slug: 'type',
      label: 'Type',
      icon: <MdIcon name="Style" />,
      nextButtonText: 'Next',
      content: (
        <Box pt={6} pb={{ base: 6, md: 0 }}>
          <Text mb={4} color="text.muted">
            You can shape your class based on how you'd like your learners to
            follow the course and what level of interaction you want to provide.
          </Text>
          <Text mb={4} fontSize="lg" fontWeight="semibold">
            Which type works best for this class?
          </Text>
          <Stack direction="column" spacing="defaultMargin">
            {/* Don't show community options if this course is licenced */}
            {/* Don't show 'live rooms' option (isAnonymous == true) if this course is not licenced */}
            {COHORT_TYPES.filter((t) =>
              isCourseLicensed
                ? PLATFORM === 'workshop'
                  ? t.isAnonymous
                  : t.socialType !== SOCIAL_TYPES.community
                : !t.isAnonymous
            ).map((classType) => (
              <ClassTypeItem
                key={`classType-${classType.id}`}
                classType={classType}
                selectedClassType={selectedClassType}
                setSelectedClassType={setSelectedClassType}
                showDivider={classType.isAnonymous}
                isSelectable
              />
            ))}
          </Stack>
        </Box>
      ),
    },
    {
      slug: 'details',
      label: 'Details',
      icon: <MdIcon name="Event" />,
      nextButtonText: 'Next',
      nextButtonDisabled: hasClassSizeLimit
        ? !startDate ||
          !spacesTotalValue ||
          spacesTotalValue <= 0 ||
          spacesTotalValue > PRIVATE_CLASS_SIZE_LIMIT
        : !startDate,
      content: (
        <Box pt={6} pb={{ base: 6, md: 0 }}>
          <Text mb={4} color="text.muted">
            {`Now you can set a few details, such as when you want the class to start. Learners will receive instructions from ${PLATFORM_DISPLAY_NAME} to let them know how and when they can access their course.`}
          </Text>
          <Text mb={4} fontSize="lg" fontWeight="semibold">
            When will class start?
          </Text>
          {/* Main section */}
          <Flex flexDir="column">
            <Flex flexDir="column" flex={1}>
              <LabelDatePicker
                id="startDate"
                label={
                  accessTypeValue === ACCESS_TYPES.scheduled
                    ? 'Start Date'
                    : 'Launch Date'
                }
                onChange={(date) => {
                  setValue('startDate', date);
                }}
                date={startDate}
                labelStyleProps={{ whiteSpace: 'nowrap' }}
                labelPosition={isMobile ? 'top' : 'inline'}
                helpText={`${
                  accessTypeValue === ACCESS_TYPES.scheduled
                    ? 'Pick the date when learners can start the first unit of your course. Subsequent units will then unlock weekly.'
                    : 'Pick the date when learners can first access your course. All units will be available at launch.'
                }${
                  onSaleValue
                    ? ' After this date, class places will no longer be available to buy, so make sure to leave enough time for your class to fill up before it starts.'
                    : ''
                }`}
                emptyValue=""
                minDate={new Date()}
              />
            </Flex>
            <Flex px={2} />
            <Flex flexDir="column" flex={1}>
              <LabelInput
                label="Label"
                onChange={(e) => setValue('label', e.target.value)}
                helpText="(Optional) Add a short label to name your class or to help learners differentiate between classes starting on the same date."
                labelStyleProps={labelStyleProps}
                labelPosition={isMobile ? 'top' : 'inline'}
              />
              {hasClassSizeLimit ? (
                <LabelInput
                  label="Class Size Limit"
                  onChange={(e) =>
                    setValue('spacesTotal', parseInt(e.target.value))
                  }
                  placeholder={`Enter a number between 1 and ${PRIVATE_CLASS_SIZE_LIMIT}`}
                  helpText="The maximum number of learners that will be able to join this class. We recommend setting a limit of 10 for the best group-learning experience."
                  inputType="number"
                  error={
                    spacesTotalValue <= 0 ||
                    spacesTotalValue > PRIVATE_CLASS_SIZE_LIMIT
                  }
                  errorMessage={`Please enter a number between 1 and ${PRIVATE_CLASS_SIZE_LIMIT}`}
                  labelStyleProps={labelStyleProps}
                  labelPosition={isMobile ? 'top' : 'inline'}
                />
              ) : null}
            </Flex>
          </Flex>
        </Box>
      ),
    },
    {
      slug: 'mentors',
      label: 'Mentors',
      icon: <MdIcon name="School" />,
      nextButtonText: 'Create Class',
      nextButtonDisabled: !isValid || isUpdating,
      content: (
        <Box pt={6} pb={{ base: 6, md: 0 }}>
          <Text mb={4} color="text.muted">
            Finally you can pick who in your team will be teaching this class.
            As your course grows in popularity, it can be useful to bring in
            some extra help!
          </Text>
          <Text mb={4} fontSize="lg" fontWeight="semibold">
            Who will be teaching this class?
          </Text>
          {/* Mentors section */}
          <Flex flexDir="column">
            <Flex flexDir="row" mb={2}>
              <Flex w="95%">
                <Text color="text.muted">Class Mentor(s)</Text>
              </Flex>
              <Flex w="5%" />
            </Flex>
            {mentors.map((item, idx) => {
              const mentor = mentorValues[idx] || item;
              const isSub = mentor.isSubstitute;
              const isOnlyPermMentor = !isSub && nonSubMentors.length < 2;
              const isLastMentor = idx + 1 === mentors.length;
              return (
                <Flex flexDir="column" key={item.id}>
                  <Divider mb="4" />
                  <Flex flexDir="row" alignItems="center">
                    <Flex w="95%">
                      <LabelSelect
                        name={`mentors[${idx}].mentorId` as const}
                        registerInputRef={register()}
                        defaultValue={item.mentorId}
                        options={{ '': '-', ...mentorOptions }}
                      />
                    </Flex>
                    <Flex w="5%" justifyContent="flex-end">
                      {!isOnlyPermMentor && (
                        <MdIcon
                          color="red.400"
                          cursor="pointer"
                          name="RemoveCircle"
                          onClick={() => remove(idx)}
                          mb="defaultMargin"
                        />
                      )}
                    </Flex>
                  </Flex>
                  {isLastMentor && <Divider mb="4" />}
                </Flex>
              );
            })}
          </Flex>
          <Flex flex={1} justifyContent="flex-end">
            <Button
              icon="Add"
              variant="outline"
              size="sm"
              onClick={() =>
                append({
                  mentorId: '',
                  isAssessor: true,
                  isSubstitute: false,
                })
              }
            >
              Add Mentor
            </Button>
          </Flex>
        </Box>
      ),
    },
  ];

  const steps =
    PLATFORM === 'workshop'
      ? isCourseLicensed
        ? allSteps.filter((s) => s.slug !== 'enrolments' && s.slug !== 'type')
        : allSteps.filter((s) => s.slug !== 'enrolments')
      : allSteps;

  return (
    <StepsModal
      {...modalProps}
      hideStepLabels={!isMobile}
      onCompleteStep={async (stepIndex: number) => {
        if (stepIndex === steps.length - 1) {
          handleOnSave();
        }
      }}
      triggerNextStep={triggerNextStep}
      modalSize="3xl"
      steps={steps}
    />
  );
};

export default CreateCohortModal;
