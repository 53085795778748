import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalProps,
  Flex,
  Text,
} from '@workshop/ui';
import { useSpring, Transition, animated } from 'react-spring';
import { AnimatedButton } from 'components/Common/index';

interface EditCardProps {
  error?: boolean;
  isUpdating?: boolean;
  onCancel?: (e?: React.FormEvent<HTMLFormElement>) => void;
  onSave: (e?: React.FormEvent<HTMLFormElement>) => void;
  saveDisabled?: boolean;
}

interface EditModalProps {
  /**
   * This enables/prevents the modal closing on clicking the overlay. If using React Hook Form
   * this must be disabled to prevent handleSubmit closing the modal automatically
   */
  closeOnOverlayClick?: boolean;
  isOpen: boolean;
  modalSize?: ModalProps['size'];
  onClose: () => void;
  saveLabel?: string;
  saveIcon?: string;
  saveColorScheme?: string;
  cancelDisabled?: boolean;
  cancelLabel?: string;
  showFooter?: boolean;
  showHeader?: boolean;
  subTitle?: string | React.ReactElement;
  title: string;
  showCloseButton?: boolean;
  secondaryButtonLabel?: string;
  onSecondaryButtonClick?: () => void;
  secondaryButtonIcon?: string;
}

export interface Props extends EditModalProps, EditCardProps {}

export const EditModal: React.FC<Props> = ({
  children,
  closeOnOverlayClick = true,
  isOpen,
  isUpdating = false,
  modalSize,
  onCancel,
  onClose,
  onSave,
  saveDisabled = false,
  saveLabel = 'Save',
  cancelDisabled,
  cancelLabel = 'Cancel',
  showFooter = true,
  showHeader = true,
  subTitle,
  title,
  showCloseButton = true,
  saveIcon,
  saveColorScheme,
  secondaryButtonLabel,
  onSecondaryButtonClick,
  secondaryButtonIcon,
}) => {
  const btnStyle = useSpring({
    opacity: saveDisabled ? 0.5 : 1,
  });
  const AnimatedModalContent = animated(ModalContent);
  const AnimatedModalOverlay = animated(ModalOverlay);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={closeOnOverlayClick}
      motionPreset="none"
      size={modalSize}
    >
      <Transition
        items={isOpen}
        from={{ opacity: 0, backdropFilter: 'blur(0px)' }}
        enter={{ opacity: 1, backdropFilter: 'blur(10px)' }}
        leave={{ opacity: 0, backdropFilter: 'blur(0px)' }}
      >
        {(styles, item) => (
          <AnimatedModalOverlay backdropFilter="blur(10px)" style={styles} />
        )}
      </Transition>
      <Transition
        items={isOpen}
        from={{ scale: 0.98 }}
        enter={{ scale: 1 }}
        leave={{ scale: 0.98 }}
        config={{ tension: 200, friction: 8 }}
      >
        {(styles, item) => (
          <AnimatedModalContent marginX={2} style={styles}>
            {showHeader && (
              <ModalHeader
                fontWeight="bold"
                letterSpacing="title"
                display={{ base: 'block', md: 'flex' }}
                alignItems="center"
                px={{ base: 4, md: 6 }}
              >
                <Text>{title}</Text>
                {subTitle && (
                  <Text
                    fontSize="md"
                    fontWeight="semibold"
                    color="text.muted"
                    ml={{ base: 0, md: 4 }}
                    mt={{ base: 2, md: 0 }}
                    flex={1}
                  >
                    {subTitle}
                  </Text>
                )}
              </ModalHeader>
            )}

            {showCloseButton && <ModalCloseButton />}

            <ModalBody px={{ base: 4, md: 6 }}>{children}</ModalBody>
            {showFooter && (
              <ModalFooter px={{ base: 4, md: 6 }} pb={{ base: 4, md: 5 }}>
                <Flex
                  flexDirection="row"
                  alignContent="center"
                  justifyContent="flex-end"
                >
                  {onCancel && (
                    <AnimatedButton
                      isDisabled={
                        cancelDisabled !== undefined
                          ? cancelDisabled
                          : saveDisabled
                      }
                      onClick={onCancel}
                      secondary
                    >
                      {cancelLabel}
                    </AnimatedButton>
                  )}
                  {!!secondaryButtonLabel && (
                    <AnimatedButton
                      isDisabled={saveDisabled}
                      isLoading={isUpdating}
                      // @ts-ignore
                      style={btnStyle}
                      // @ts-ignore
                      onClick={onSecondaryButtonClick}
                      icon={secondaryButtonIcon || ''}
                      variant="outline"
                    >
                      {secondaryButtonLabel}
                    </AnimatedButton>
                  )}
                  {!!saveLabel && (
                    <AnimatedButton
                      isDisabled={saveDisabled}
                      isLoading={isUpdating}
                      // @ts-ignore
                      style={btnStyle}
                      onClick={onSave}
                      icon={saveIcon || ''}
                      {...(saveColorScheme
                        ? {
                            colorScheme: saveColorScheme,
                          }
                        : {})}
                    >
                      {saveLabel}
                    </AnimatedButton>
                  )}
                </Flex>
              </ModalFooter>
            )}
          </AnimatedModalContent>
        )}
      </Transition>
    </Modal>
  );
};
