import React, { useState, useEffect } from 'react';
import { FaGem } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import {
  Flex,
  Text,
  Image,
  Button,
  useColorModeValue,
  chakra,
  ButtonProps,
} from '@workshop/ui';

import { analytics } from 'utils';
import { GlobalState } from 'types';

import { PLATFORM } from 'constants/env';
import { PLATFORM_EMAIL } from 'constants/common';
import {
  PRO_TEAM_MEMBERS_LIMIT,
  PRO_MONTHLY_ENROLMENTS_LIMIT,
  PRO_AI_CREDITS_LIMIT,
} from 'constants/organisation';

import navRoutes from 'navigation/Routes';

import { useUser, useCurrentTeamProfile, useHasRole } from 'redux/selectors';

import { EditModal } from 'components/Common/EditModal';
import { Pro } from 'components/Brand';
import { PricingTable } from 'components/Common';

// TODO: Move Stripe actions to redux folder
import {
  getCheckoutSession,
  getConfig,
} from 'screens/common/Checkout/src/CheckoutSteps/api';

import iAi from 'assets/images/illustrations/i-ai.png';

const discountLabel = '';

interface ProCtaProps {
  label?: string;
  hideProIcon?: boolean;
  hideCta?: boolean;
  triggerOpenPopup?: boolean;
  ctaProps?: ButtonProps;
  ctaText?: string;
}

const ProCta: React.FC<ProCtaProps> = ({
  label = 'Available with',
  hideProIcon = false,
  hideCta = false,
  triggerOpenPopup,
  ctaProps,
  ctaText = 'Upgrade to Unlock',
}: ProCtaProps) => {
  const discountIsValid = false;

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<{
    error: boolean;
    message: string;
  } | null>(null);
  const [stripePromise, setStripePromise] = useState(null);
  const [joinedList, setJoinedList] = useState(false);
  // const [addingPromoCode, setAddingPromoCode] = useState(discountIsValid);
  // const [promoCode, setPromoCode] = useState(
  //   discountIsValid ? discountCode : ''
  // );
  const [addingPromoCode, setAddingPromoCode] = useState(false);
  const [promoCode, setPromoCode] = useState('');
  const [impactId, setImpactId] = useState(
    // @ts-ignore
    window.impactClickId || ''
  );

  const labelColor = useColorModeValue('orange.500', 'orange.200');

  const dispatch = useDispatch();

  const user = useUser();
  const teamProfile = useCurrentTeamProfile();
  const isOwner = useHasRole('owner');

  const location = useSelector(
    (state: GlobalState) => state.background.location
  );

  const initData = async () => {
    setIsLoading(true);
    // @ts-ignore
    const configResponse: $Exact<{ error: any }> | StripeConfig =
      await getConfig();
    setIsLoading(false);

    if (configResponse.error) {
      setError({
        error: true,
        message: `An error occurred. Please try refreshing the page, or if the problem persists, contact us on support${PLATFORM_EMAIL}`,
      });
      return;
    }

    const stripeJs = document.createElement('script');
    stripeJs.src = 'https://js.stripe.com/v3/';
    stripeJs.async = true;
    stripeJs.onload = () => {
      const sp = loadStripe(configResponse?.stripePublishableKey);
      // @ts-ignore
      setStripePromise(sp);
    };
    if (document.body) document.body.appendChild(stripeJs);
  };

  useEffect(() => {
    initData();
  }, []);

  // useEffect(() => {
  //   if (discountIsValid) {
  //     setPromoCode(discountCode);
  //   } else {
  //     setPromoCode('');
  //   }
  // }, [discountIsValid]);

  useEffect(() => {
    if (isOpen) {
      analytics.track('Pro Subscription Popup Opened');
      // Impact affiliate setup
      // @ts-ignore
      if (window.ire && !impactId) {
        // @ts-ignore
        window.ire(
          'generateClickId',
          // @ts-ignore
          (clickId) => {
            // @ts-ignore
            setImpactId(clickId);
            // @ts-ignore
            window.impactClickId = clickId;
          }
        );
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if (triggerOpenPopup !== undefined) {
      setIsOpen(true);
    }
  }, [triggerOpenPopup]);

  const discountBgColor = useColorModeValue('neutral.900', 'neutral.100');
  const discountColor = useColorModeValue('#fff', '#000');

  if (PLATFORM !== 'steppit') {
    return null;
  }
  const onClose = () => setIsOpen(false);

  {
    /* TODO: Pro channel required to:
      - Invite up to 3 team members to your channel (basic channels have a limit of 1 team member)
          - **+£5pm** for each additional seat
      - Create private classes for up to 10 students per month
          - **+£2** for each additional enrolment
      - Create courses of any length (basic channels have a limit of 3 units)

      Coming soon:
      - Launch live rooms for your sessions
      - Create and sell bulk enrollment agreements
      - Add assessments to your courses
      - Add interactive elements to your sessions (e.g. quiz questions & prompts)
      - Access performance & insights dashboards for your courses
    */
  }
  const proLabel = 'text.primary';
  const PRO_FEATURES = [
    {
      slug: 'ai-assistant',
      description: (
        <>
          {`Get a `}
          <chakra.span fontWeight="bold" color={proLabel}>
            tailored AI assistant
          </chakra.span>
          {` to help you build and sell your courses`}
        </>
      ),
      extra: '',
    },
    {
      slug: 'unlimited-units',
      description: (
        <>
          {'Add '}
          <chakra.span fontWeight="bold" color={proLabel}>
            unlimited units
          </chakra.span>
          {' to your courses'}
        </>
      ),
      extra: '',
    },
    // TODO: Update interactive elements to work with Pro
    // {
    //   slug: 'interactive-elements',
    //   description: (
    //     <>
    //       {'Make your courses interactive with '}
    //       <chakra.span fontWeight="bold" color={proLabel}>
    //         quizzes
    //       </chakra.span>
    //       {', '}
    //       <chakra.span fontWeight="bold" color={proLabel}>
    //         prompts
    //       </chakra.span>
    //       {' and '}
    //       <chakra.span fontWeight="bold" color={proLabel}>
    //         assessments
    //       </chakra.span>
    //     </>
    //   ),
    //   extra: '',
    // },
    {
      slug: 'private-classes',
      description: (
        <>
          {'Create '}
          <chakra.span fontWeight="bold" color={proLabel}>
            private classes
          </chakra.span>
          {` for up to ${PRO_MONTHLY_ENROLMENTS_LIMIT} students per month`}
        </>
      ),
      extra: '',
      // extra: 'Enrol more students for £3 per additional enrolment',
    },
    {
      slug: 'team-members',
      description: (
        <>
          {`Expand your channel with up to ${PRO_TEAM_MEMBERS_LIMIT} `}
          <chakra.span fontWeight="bold" color={proLabel}>
            team members
          </chakra.span>
        </>
      ),
      extra: '',
      // extra: 'Add more members for £5pm per additional member',
    },
    {
      slug: 'ai-credits',
      description: (
        <>
          {`Receive ${PRO_AI_CREDITS_LIMIT} `}
          <chakra.span fontWeight="bold" color={proLabel}>
            AI credits
          </chakra.span>
          {` per month to spend on your assistant`}
        </>
      ),
      extra: '',
    },
    // TODO: Update live rooms to work with Pro
    // {
    //   slug: 'live-rooms',
    //   description: (
    //     <>
    //       {'Launch '}
    //       <chakra.span fontWeight="bold" color={proLabel}>
    //         live rooms
    //       </chakra.span>
    //       {' to present sessions in real time'}
    //     </>
    //   ),
    //   extra: '',
    // },
  ];
  const proTrialAvailable = teamProfile?.proTrialRedeemed === false;
  return (
    <Flex flexDirection="column" alignItems="center">
      {label ? (
        <Flex alignItems="center" pb={2}>
          <Text
            fontSize="sm"
            textAlign="center"
            color={labelColor}
            pr={hideProIcon ? 0 : 1.5}
          >
            {label}
          </Text>
          {!hideProIcon && <Pro size="xs" tier={2} />}
        </Flex>
      ) : null}
      {!hideCta && (
        <>
          <Button
            leftIcon={<FaGem />}
            colorScheme="orange"
            onClick={() => setIsOpen(true)}
            {...ctaProps}
          >
            {ctaText}
          </Button>
          {proTrialAvailable && !ctaProps && (
            <Text color="text.muted" fontSize="sm" mt={2}>
              Try 7 days of Pro for <chakra.b>free</chakra.b>
            </Text>
          )}
        </>
      )}
      <EditModal
        title=""
        isOpen={isOpen}
        onSave={() => null}
        onClose={onClose}
        modalSize="4xl"
        showFooter={false}
        showHeader={false}
      >
        <Flex flexDirection="column">
          {/* <Flex
            flex={1}
            justify="center"
            align="center"
            px={6}
            pb={2}
            mb={-4}
            mt={-2}
            maxW="300px"
            mx="auto"
          >
            <Image
              alt="Steppit Course"
              fit="contain"
              align="center"
              w="90%"
              h="90%"
              htmlHeight="100"
              htmlWidth="100"
              src={iAi}
            />
          </Flex> */}
          <Flex
            py={6}
            mb={6}
            borderBottomWidth={1}
            justifyContent="center"
            alignItems="center"
          >
            <Text
              fontSize={{ base: '3xl', md: '4xl' }}
              textAlign="center"
              pr={3}
              fontWeight="extrabold"
            >
              {proTrialAvailable ? 'Try' : 'Upgrade to'}
            </Text>
            <Pro size="md" tier={2} />
            {proTrialAvailable && (
              <Text
                fontSize={{ base: '3xl', md: '4xl' }}
                textAlign="center"
                pl={3}
                fontWeight="extrabold"
              >
                for Free
              </Text>
            )}
          </Flex>
          {/* {PRO_FEATURES.map((feature) => (
            <Flex
              key={`pro-feature-${feature.slug}`}
              flexDirection="column"
              alignItems="center"
              textAlign="center"
              pb={6}
            >
              <Text maxWidth={270}>{feature.description}</Text>
              {feature.extra ? (
                <Text color="text.muted" fontSize="xs" pt={1}>
                  {feature.extra}
                </Text>
              ) : null}
            </Flex>
          ))} */}
          <Text textAlign="center" color="text.muted">
            Upgrade your channel to Steppit Pro to expand your courses, get your
            own personally-tailored AI assistant, create private
            enrollment-based classes and start inviting team members to help
            work on your channel.
          </Text>
          <Flex flexDirection="column" pt={6}>
            <Text fontSize="xl" textAlign="center" pb={6} fontWeight="bold">
              Select Your Plan:
            </Text>
            <PricingTable
              proTrialAvailable={proTrialAvailable}
              onClickCta={async (
                planType: 'monthly' | 'yearly',
                tier: 0 | 1 | 2 | 3
              ) => {
                // Open checkout
                setIsLoading(true);
                const successPath = `${window.location.protocol}//${
                  window.location.host
                }${navRoutes.global.proSuccess.path()}`;
                const cancelPath = `${window.location.protocol}//${window.location.host}`;
                analytics.track('Pro Subscription Checkout Started');
                analytics.logConversion(
                  dispatch,
                  'startedProCheckout',
                  {},
                  `steppit-pro-${tier}-${planType}`
                );
                let referral = null;
                // @ts-ignore
                if (window.rewardful && window.Rewardful?.referral) {
                  // @ts-ignore
                  referral = window.Rewardful.referral;
                }
                if (teamProfile) {
                  const sessionResponse = await getCheckoutSession(
                    {
                      type: 'pro_membership',
                      id: `steppit-pro-${tier}-${planType}`,
                      email: encodeURIComponent(user.email),
                      successUrl: encodeURIComponent(successPath),
                      cancelUrl: encodeURIComponent(cancelPath),
                      organisation: teamProfile.id,
                      ...(promoCode ? { couponCode: promoCode } : {}),
                      ...(referral ? { referral } : {}),
                      ...(impactId ? { impactId } : {}),
                    },
                    dispatch
                  );

                  // if (sessionResponse.error) {
                  //   setError({
                  //     hasError: true,
                  //     message: `An error occurred. Please try refreshing the page, or if the problem persists, contact us on support${PLATFORM_EMAIL}`,
                  //   });
                  //   return;
                  // }
                  const stripe = await stripePromise;
                  if (stripe) {
                    // @ts-ignore
                    await stripe.redirectToCheckout({
                      sessionId: sessionResponse.checkoutSessionId,
                    });
                  }
                }
                setIsLoading(false);
              }}
            />
          </Flex>
          {/* <Flex color={labelColor} justifyContent="center" pb={4}>
            <FaGem />
          </Flex> */}
          {/* <Box
            color={labelColor}
            textAlign="center"
            pb={6}
            mb={6}
            borderBottomWidth={1}
          >
            {!discountIsValid ? (
              <>
                {proTrialAvailable ? (
                  <Text>
                    <chakra.b>Free for 7 days</chakra.b>, then
                    {` ${PRO_PRICE(location?.currency)} per month`}
                  </Text>
                ) : (
                  <Text>
                    <chakra.b>Start now</chakra.b> for
                    {` ${PRO_PRICE(location?.currency)} per month`}
                  </Text>
                )}
                {discountLabel ? (
                  <Text
                    fontSize="sm"
                    bg={discountBgColor}
                    color={discountColor}
                    p={2}
                    borderRadius="sm"
                    mt={6}
                  >
                    {discountLabel}
                  </Text>
                ) : null}
              </>
            ) : (
              <>
                <Text
                  fontSize="sm"
                  bg="background.error"
                  color="text.error"
                  p={2}
                  borderRadius="sm"
                  mb={4}
                >
                  {discountLabel}
                </Text>
                <Text color="text.error">
                  <chakra.span textDecoration="line-through">{`${PRO_PRICE(
                    location?.currency
                  )}`}</chakra.span>
                  <chakra.b>{` ${PRO_95_DISCOUNT_PRICE(
                    location?.currency
                  )} for your first month`}</chakra.b>
                </Text>
                <Text color="text.muted" fontSize="sm">
                  {`then ${PRO_PRICE(location?.currency)} per month`}
                </Text>
              </>
            )}
          </Box> */}
          <Flex flexDirection="column" justifyContent="center">
            {joinedList ? (
              <Text color="text.success" pb={5}>
                Thanks for your interest, we'll be in touch! 👍
              </Text>
            ) : teamProfile?.id && isOwner ? (
              <>
                {/* <Button
                  mb={2.5}
                  size="lg"
                  icon="ArrowForward"
                  iconPosition="right"
                  isLoading={isLoading}
                  onClick={async () => {
                    // OLD: Join waiting list
                    // setIsLoading(true);
                    // const res = await dispatch(
                    //   mailActions.joinList(PRO_LIST_HASH, { email: user.email })
                    // );
                    // setIsLoading(false);
                    // // @ts-ignore
                    // if (res.payload?.success) {
                    //   setJoinedList(true);
                    // }

                    // Open checkout
                    setIsLoading(true);
                    const successPath = `${window.location.protocol}//${
                      window.location.host
                    }${navRoutes.global.proSuccess.path()}`;
                    const cancelPath = `${window.location.protocol}//${window.location.host}`;
                    analytics.track('Pro Subscription Checkout Started');
                    analytics.logConversion(dispatch, 'startedProCheckout');
                    let referral = null;
                    // @ts-ignore
                    if (window.rewardful && window.Rewardful?.referral) {
                      // @ts-ignore
                      referral = window.Rewardful.referral;
                    }
                    const sessionResponse = await getCheckoutSession(
                      {
                        type: 'pro_membership',
                        id: 'steppit-pro-1-monthly',
                        email: encodeURIComponent(user.email),
                        successUrl: encodeURIComponent(successPath),
                        cancelUrl: encodeURIComponent(cancelPath),
                        organisation: teamProfile.id,
                        ...(promoCode ? { couponCode: promoCode } : {}),
                        ...(referral ? { referral } : {}),
                        ...(impactId ? { impactId } : {}),
                      },
                      dispatch
                    );

                    // if (sessionResponse.error) {
                    //   setError({
                    //     hasError: true,
                    //     message: `An error occurred. Please try refreshing the page, or if the problem persists, contact us on support${PLATFORM_EMAIL}`,
                    //   });
                    //   return;
                    // }
                    const stripe = await stripePromise;
                    if (stripe) {
                      // @ts-ignore
                      await stripe.redirectToCheckout({
                        sessionId: sessionResponse.checkoutSessionId,
                      });
                    }
                    setIsLoading(false);
                  }}
                >
                  {proTrialAvailable ? 'Try Pro for Free' : 'Upgrade to Pro'}
                </Button> */}
                {/* {addingPromoCode ? (
                  <Input
                    mb={2}
                    placeholder="Enter promo code"
                    textAlign="center"
                    onChange={(e) => setPromoCode(e.target.value)}
                    defaultValue={discountIsValid ? discountCode : ''}
                  />
                ) : (
                  <Button
                    size="sm"
                    icon="Add"
                    variant="ghost"
                    isLoading={isLoading}
                    mb={2}
                    onClick={() => setAddingPromoCode(true)}
                  >
                    Add Promo Code
                  </Button>
                )} */}
              </>
            ) : teamProfile?.id && !isOwner ? (
              <Text pb={5} color="text.muted" fontSize="sm" textAlign="center">
                To unlock these features, ask your channel owner to upgrade to a
                Pro Channel
              </Text>
            ) : (
              <Text pb={5} color="text.muted" fontSize="sm" textAlign="center">
                Open your channel to upgrade to Pro
              </Text>
            )}
          </Flex>
        </Flex>
      </EditModal>
    </Flex>
  );
};

export default ProCta;
