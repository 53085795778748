import React, { useEffect, useState, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';

import { PLATFORM } from 'constants/env';
import { CLIP_NAME, CAP_CLIP_NAME } from 'constants/common';
import { STATUS } from 'constants/background';

import {
  Button,
  AlertDialogButton,
  Flex,
  Text,
  MdIcon,
  useDisclosure,
  Spinner,
  Skeleton,
} from '@workshop/ui';

import { useUploadList } from 'redux/selectors/background';
import { useHasRole } from 'redux/selectors';

import { IVideoClip } from 'types/cms';

import { UploadStatus } from 'components/AppHeader/UploadStatusButton';
import { ModalVideo } from 'components/ModalVideo';
import { LabelTextArea } from 'components/Common';

import { tourIds } from 'screens/cms/SessionEdit';

interface Props {
  id: string;
  index: number;
  isDisabled?: boolean;
  isTextEditingDisabled?: boolean;
  isLoading?: boolean;
  image?: string;
  summary?: string;
  script?: string;
  subtitles?: string;
  video?: string;
  filename?: string | null;
  isThumbnailUpdating?: boolean;
  error?: string;
  videoExists?: boolean;
  canDeleteClip?: boolean;
  mediaType?: 'video' | 'audio' | 'image' | 'text' | 'embed';
  orientation?: 'portrait' | 'landscape';
  dragHandleProps?: DraggableProvidedDragHandleProps;
  onReplace?: () => void;
  onRemove: () => void;
  onSaveSummary: (summary: string) => Promise<any>;
  onSaveScript: (script: string) => Promise<any>;
  onSaveClip: (data: Partial<IVideoClip>) => Promise<void>;
  onUpload: (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string,
    mediaType?: 'video' | 'audio'
  ) => Promise<void>;
  qrBlob: string;
}

export const VideoClipItem: React.FC<Props> = ({
  id,
  image,
  index,
  isDisabled = false,
  isTextEditingDisabled = false,
  isLoading = false,
  summary,
  script,
  subtitles,
  video,
  filename,
  isThumbnailUpdating,
  error,
  canDeleteClip = true,
  mediaType,
  orientation,
  dragHandleProps = {},
  onReplace,
  onRemove,
  onSaveSummary,
  onSaveScript,
  onSaveClip,
  onUpload,
  qrBlob,
}) => {
  const [hover, setHover] = useState(false);
  const fileInput = useRef<HTMLInputElement>();
  const hasAdminRole = useHasRole('admin');

  // A local loading state which can be set/used for any API interactions
  // which are triggered from directly within the video clip item. For
  // example, copywriter's are able to save individual clip summaries
  // which use this local loading state to control the visibility &
  // interactivity of the video clip save button.
  const [isLoadingLocally, setIsLoadingLocally] = useState(false);

  // Generate the summary ID used throughout the component
  const summaryId = `summary_${id}`;
  const scriptId = `script_${id}`;

  // Use react hook form context to access the parent form which wraps
  // the entire step which contains all the video clips related to that
  // step
  const {
    register,
    reset,
    handleSubmit,
    formState: { dirtyFields },
  } = useFormContext<{ [key: string]: string | FileList }>();

  // If this clip's summary changes, reset the form state to
  // reflect this.
  useEffect(() => {
    reset({ [summaryId]: summary });
  }, [summary, reset, summaryId]);

  useEffect(() => {
    reset({ [scriptId]: script });
  }, [script, reset, scriptId]);

  // Disclosure for handling the display of the video preview modal
  const { onOpen, isOpen, onClose } = useDisclosure();

  // If there is an in progress or completed video upload related to this
  // video clip, then extract it from the state so that we're able to display
  // upload progress metadata.
  const uploadList = useUploadList();
  const clipUpload = Object.values(uploadList).find((upload) => {
    const { metadata } = upload;
    return metadata && 'id' in metadata && id === metadata.id;
  });

  // If an upload exists for this clip and the status isn't `completed`
  // then we have an in progress upload
  const uploadInProgress = clipUpload && clipUpload.status !== STATUS.completed;

  // If `isDisabled` is true yet `isTextEditingDisabled` then this indicates
  // that the user can only edit text fields within the video clip. In this
  // scenario we need to calculate a local dirty flag since clips can be saved
  // on an individual basis, rather than at the step level which is the defaul
  // behaviour when a user has full edit permissions.
  const isDirty = Boolean(
    dirtyFields.has(summaryId) || dirtyFields.has(scriptId)
  );
  // Following on from above, the `handleSubmit` from our form is only used in
  // the scenario where a user can only edit the text fields within the video
  // clip. The data received will be for the entire step-level form, so we must
  // extract the relevant summary before submitting the data.
  const onSubmit = handleSubmit(async (data) => {
    const clipSummary = data[summaryId];
    const clipScript = data[scriptId];
    // The data which can be submitted through this `handleSubmit` handler should
    // only ever be a string, as the text summary is the only field which can be
    // edited. However, here we explicitly check this to satisfy TS.
    if (typeof clipSummary === 'string' || typeof clipScript === 'string') {
      setIsLoadingLocally(true);
      if (typeof clipSummary === 'string') {
        await onSaveSummary(clipSummary);
      }
      if (typeof clipScript === 'string') {
        await onSaveScript(clipScript);
      }
      setIsLoadingLocally(false);
    }
  });

  const showAiSummaryButton = Boolean(
    (mediaType === 'video' || mediaType === 'audio') &&
      video &&
      !isDisabled &&
      !isTextEditingDisabled &&
      (PLATFORM === 'steppit' || (PLATFORM === 'workshop' && hasAdminRole))
  );

  return (
    <Flex
      backgroundColor="background.default"
      padding={2}
      flex={1}
      borderRadius="md"
      mb={2}
      boxShadow="md"
      flexDirection="column"
      {...(index === 1
        ? {
            'data-tour': tourIds.sessionOutlineBit,
          }
        : {})}
    >
      <Flex>
        <Flex
          marginRight={2}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onTouchStart={() => setHover(true)}
          onTouchEnd={() => setHover(false)}
        >
          {uploadInProgress || isThumbnailUpdating ? (
            <Flex
              backgroundColor="background.tint3"
              height="image.md"
              width="image.md"
              borderRadius="md"
              alignItems="center"
              justifyContent="center"
            >
              <Spinner color="icon.muted" />
            </Flex>
          ) : (
            <Flex
              backgroundImage={image && `url(${image})`}
              backgroundSize="cover"
              backgroundPosition="center"
              backgroundColor="background.tint3"
              height="image.md"
              width="image.md"
              borderRadius="md"
              onClick={onOpen}
              justifyContent="center"
              alignItems="center"
            >
              {!image && (
                <MdIcon
                  name={
                    mediaType === 'video'
                      ? 'Movie'
                      : mediaType === 'audio'
                      ? 'Mic'
                      : mediaType === 'text'
                      ? 'Title'
                      : mediaType === 'embed'
                      ? 'Code'
                      : 'PhotoCamera'
                  }
                  color="icon.muted"
                  boxSize={8}
                />
              )}
            </Flex>
          )}
          <ModalVideo
            isOpen={isOpen}
            onClose={onClose}
            onSaveSummary={onSaveSummary}
            onSaveScript={onSaveScript}
            summary={summary}
            script={script}
            subtitles={subtitles}
            video={video}
            videoHq={video}
            isDisabled={isDisabled && isTextEditingDisabled}
            isEditable={true}
            onUpload={onUpload}
            onSaveClip={onSaveClip}
            mediaType={mediaType}
            orientation={orientation}
            clipId={id}
            qrBlob={qrBlob}
          />
          <Flex
            position="absolute"
            {...(index === 1
              ? {
                  'data-tour': tourIds.sessionOutlineBitDrag,
                }
              : {})}
            {...dragHandleProps}
          >
            {hover ? (
              <Flex
                position="relative"
                top={1}
                left={1}
                width="icon"
                height="icon"
                justifyContent="center"
                alignItems="center"
                backgroundColor="background.primary"
                borderRadius="sm"
              >
                <MdIcon
                  name="Reorder"
                  boxSize={5}
                  color="icon.primary"
                  fontSize="inherit"
                  opacity={0.5}
                />
              </Flex>
            ) : (
              index && (
                <Flex
                  backgroundColor="background.primary"
                  position="relative"
                  left={1}
                  top={1}
                  width="icon"
                  height="icon"
                  borderRadius="sm"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Text fontSize="xs" color="text.primary">
                    {index}
                  </Text>
                </Flex>
              )
            )}
          </Flex>
        </Flex>
        <Flex flex={3} flexDirection="column">
          <Skeleton isLoaded={!isLoading} loadingStyle={{ width: '100%' }}>
            <Flex flex={1} position="relative">
              <LabelTextArea
                label=""
                registerInputRef={register({
                  required: {
                    value: false,
                    message: 'This is required',
                  },
                })}
                id={summaryId}
                name={summaryId}
                defaultValue={summary}
                placeholder={`Summarize this ${CLIP_NAME} (you can auto-generate this after recording)...`}
                borderColor="border.muted"
                backgroundColor="background.default"
                maxLength={1000}
                isDisabled={isDisabled && isTextEditingDisabled}
                tooltip="clip_summary"
                noMargin
                paddingTop={5}
                // aiButton={
                //   showAiSummaryButton
                //     ? {
                //         toolSlug: 'clipSummaries',
                //         label: `Summarize ${capitalize(mediaType)} Clip`,
                //         tooltip: `Let your assistant summarize this ${mediaType} clip into 1 or 2 simple sentences for you`,
                //         isLoading: false,
                //         loadingText: '',
                //         // isOutline: !!course?.title,
                //         isDisabled: isLoading,
                //       }
                //     : undefined
                // }
              />
              <Text
                color="text.muted"
                position="absolute"
                bg="background.default"
                pt={1.5}
                px={1}
                top="1px"
                left={3}
                borderBottomRightRadius="sm"
                fontSize="2xs"
                zIndex={1}
                pointerEvents="none"
                letterSpacing={0.5}
              >
                SUMMARY
              </Text>
            </Flex>
          </Skeleton>
          {error && (
            <Text color="intent.error" marginY={1} fontSize="xs">
              {error}
            </Text>
          )}
        </Flex>
      </Flex>
      {PLATFORM === 'steppit' && (
        <Skeleton isLoaded={!isLoading} loadingStyle={{ width: '100%' }} mt={2}>
          <Flex flex={1} position="relative">
            <LabelTextArea
              registerInputRef={register()}
              id={scriptId}
              name={scriptId}
              defaultValue={script}
              placeholder={`Write your private script for this ${CLIP_NAME}...`}
              borderColor="border.muted"
              backgroundColor="background.tint1"
              maxLength={1000}
              isDisabled={isDisabled && isTextEditingDisabled}
              noMargin
              tooltip="clip_script"
              color="text.muted"
              fontSize="sm"
              autoResize
              focusedMinH="md"
              paddingTop={5}
              // aiButton={
              //   PLATFORM === 'steppit' && !isTextEditingDisabled && !isDisabled
              //     ? {
              //         toolSlug: 'scripts',
              //         label: 'Generate Script',
              //         tooltip: `Let your assistant help you write a private script for this ${CLIP_NAME}`,
              //         isLoading: false,
              //         loadingText: '',
              //         // isOutline: !!course?.title,
              //         isDisabled: isLoading,
              //         // position: 'left',
              //       }
              //     : undefined
              // }
            />
            <Text
              color="text.muted"
              position="absolute"
              bg="background.tint1"
              pt={1.5}
              px={1}
              top="1px"
              left={3}
              borderBottomRightRadius="sm"
              fontSize="2xs"
              zIndex={1}
              pointerEvents="none"
              letterSpacing={0.5}
            >
              SCRIPT
            </Text>
          </Flex>
        </Skeleton>
      )}
      <Flex
        flexDirection={{ base: 'column', lg: 'row' }}
        mt={2}
        flex={1}
        alignItems={{ base: 'flex-end', lg: 'center' }}
      >
        <Text
          pr={{ base: 0, lg: 3 }}
          pb={{ base: 2, lg: 0 }}
          overflow="hidden"
          // https://github.com/chakra-ui/chakra-ui/issues/662
          style={{ textOverflow: 'ellipsis' }}
          flex={1}
          color="text.muted"
          fontSize="sm"
          textAlign={{ base: 'right', lg: 'left' }}
        >
          {filename}
        </Text>

        <Flex>
          {canDeleteClip && !isDisabled && (
            <AlertDialogButton
              variant="ghost"
              colorScheme="red"
              alertHeader={`Delete This ${CAP_CLIP_NAME}`}
              alertBody={`Are you sure you would like to delete this ${CLIP_NAME}?`}
              submitBtnLabel="Delete"
              submitBtnColor="red"
              onSubmit={onRemove}
              onCancel={() => {}}
              type="button"
              isDisabled={uploadInProgress}
              size="sm"
              mr="defaultMargin"
              icon="RemoveCircleOutline"
            >
              {`Delete ${CAP_CLIP_NAME}`}
            </AlertDialogButton>
          )}
          <Flex flex={1} />
          <Button
            secondary
            onClick={onOpen}
            mr="defaultMargin"
            icon="Edit"
            size="sm"
          >
            Edit
          </Button>
          {/* {!isDisabled && (
                <FormLabel
                  htmlFor={`videoClip_${id}`}
                  cursor="pointer"
                  pb={0}
                  mb={0}
                >
                  <Input
                    ref={(e: HTMLInputElement | null) => {
                      if (e) {
                        register(e);
                        fileInput.current = e;
                      }
                    }}
                    id={`videoClip_${id}`}
                    type="file"
                    accept="video/mp4,video/x-m4v,video/*"
                    style={{ display: 'none' }}
                    onChange={onUpload}
                    name={`videoClip_${id}`}
                    isDisabled={uploadInProgress}
                  />
                  <Button
                    secondary
                    onClick={() => fileInput.current?.click()}
                    icon="Cached"
                    size="sm"
                    isLoading={uploadInProgress}
                    isDisabled={uploadInProgress}
                  >
                    Replace
                  </Button>
                </FormLabel>
              )} */}
          {(!isDisabled || !isTextEditingDisabled) && (
            <Button
              type="submit"
              onClick={onSubmit}
              size="sm"
              isDisabled={!isDirty || isLoadingLocally}
              isLoading={isLoadingLocally}
            >
              Save
            </Button>
          )}
        </Flex>
        {clipUpload && (
          <Flex direction="column" maxW="400px" ml={4} mt={2}>
            <UploadStatus id={id} upload={clipUpload} displayText />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
