const neutral = {
  10: '#FAFDFF',
  50: '#F6F9FC',
  100: '#F0F1F4',
  200: '#D6D8E1',
  300: '#A1A7BA',
  400: '#878EA6',
  500: '#68728D',
  600: '#575D75',
  700: '#414658',
  800: '#2B2F3B',
  900: '#16171D',
};

// Shifted 3 stops lighter to allow for light color variants in theme
const neutralLight = {
  50: '#FFFFFF',
  100: '#FFFFFF',
  200: '#FFFFFF',
  300: '#F8F9FB',
  400: '#F5F6F7',
  500: '#F1F3F9',
  600: '#E1E5EE',
  700: '#D1D5DE',
  800: '#BABFCE',
  900: '#8A8E99',
};

// Shifted 3 stops darker to allow for dark color variants in theme
const neutralDark = {
  50: '#E1E5EE',
  100: '#D1D5DE',
  200: '#BABFCE',
  300: '#8A8E99',
  400: '#686A73',
  500: '#42464B',
  600: '#232527',
  700: '#232527',
  800: '#232527',
  900: '#232527',
};

const neutralAlpha = {
  50: 'rgba(0, 0, 0, 0.04)',
  100: 'rgba(0, 0, 0, 0.06)',
  200: 'rgba(0, 0, 0, 0.09)',
  300: 'rgba(0, 0, 0, 0.14)',
  400: 'rgba(0, 0, 0, 0.3)',
  500: 'rgba(0, 0, 0, 0.47)',
  600: 'rgba(0, 0, 0, 0.7)',
  700: 'rgba(0, 0, 0, 0.81)',
  800: 'rgba(0, 0, 0, 0.81)',
  900: 'rgba(0, 0, 0, 0.81)',
};

const neutralLightAlpha = {
  50: 'rgba(225, 229, 238, 0.04)',
  100: 'rgba(225, 229, 238, 0.06)',
  200: 'rgba(225, 229, 238, 0.09)',
  300: 'rgba(225, 229, 238, 0.14)',
  400: 'rgba(225, 229, 238, 0.3)',
  500: 'rgba(225, 229, 238, 0.47)',
  600: 'rgba(225, 229, 238, 0.7)',
  700: 'rgba(225, 229, 238, 0.81)',
  800: 'rgba(225, 229, 238, 0.81)',
  900: 'rgba(225, 229, 238, 0.81)',
};

const blue = {
  // 50: '#efefff',
  // 100: '#d0d2e5',
  // 200: '#b2b4ce',
  // 300: '#9495b9',
  // 400: '#7577a3',
  // 500: '#595b86',
  // 600: '#494b6e',
  // 700: '#32344f',
  // 800: '#1e1f32',
  // 900: '#090917',

  50: '#E7EFFD',
  100: '#BCD2FB',
  200: '#91B6F8',
  300: '#6699F5',
  400: '#3A7CF2',
  500: '#0F5FF0',
  550: '#0C4CC0',
  600: '#0C4CC0',
  700: '#093990',
  800: '#062660',
  900: '#031330',
};

const blueAlpha = {
  // 50: 'rgba(89, 91, 134, 0.04)',
  // 100: 'rgba(89, 91, 134, 0.06)',
  // 200: 'rgba(89, 91, 134, 0.09)',
  // 300: 'rgba(89, 91, 134, 0.14)',
  // 400: 'rgba(89, 91, 134, 0.3)',
  // 500: 'rgba(89, 91, 134, 0.47)',
  // 600: 'rgba(89, 91, 134, 0.7)',
  // 700: 'rgba(89, 91, 134, 0.81)',
  // 800: 'rgba(89, 91, 134, 0.81)',
  // 900: 'rgba(89, 91, 134, 0.81)',

  50: 'rgba(15, 95, 240, 0.04)',
  100: 'rgba(15, 95, 240, 0.06)',
  200: 'rgba(15, 95, 240, 0.09)',
  300: 'rgba(15, 95, 240, 0.14)',
  400: 'rgba(15, 95, 240, 0.3)',
  500: 'rgba(15, 95, 240, 0.47)',
  600: 'rgba(15, 95, 240, 0.7)',
  700: 'rgba(15, 95, 240, 0.81)',
  800: 'rgba(15, 95, 240, 0.81)',
  900: 'rgba(15, 95, 240, 0.81)',
};

const red = {
  // 50: '#ffe6e8',
  // 100: '#f8bbc0',
  // 200: '#ee9098',
  // 300: '#E76B74',
  // 400: '#CF6067',
  // 500: '#c4222d',
  // 600: '#9a1922',
  // 700: '#6f1118',
  // 800: '#44080d',
  // 900: '#1d0002',

  50: '#FDE8EC',
  100: '#F8BECA',
  200: '#F495A8',
  300: '#F06B86',
  400: '#EB4263',
  500: '#E71841',
  550: '#B91334',
  600: '#B91334',
  700: '#8B0E27',
  800: '#5C0A1A',
  900: '#2E050D',
};

const orange = {
  50: '#fff0df',
  100: '#F7C59F',
  200: '#f5b889',
  300: '#f09b5b',
  400: '#ec802e',
  500: '#d36716',
  550: '#B95B13',
  600: '#a44f10',
  700: '#76380a',
  800: '#482103',
  900: '#1d0900',
};

const yellow = {
  50: '#FEF8E7',
  100: '#FCEBBB',
  200: '#FADE8F',
  300: '#F7D163',
  400: '#F5C338',
  500: '#F3B60C',
  600: '#C39209',
  700: '#926D07',
  800: '#614905',
  900: '#312402',
};

const green = {
  // 50: '#e7f9ee',
  // 100: '#cae4d8',
  // 200: '#add1c0',
  // 300: '#85BAA1',
  // 400: '#6eac8e',
  // 500: '#73A18B',
  // 600: '#40725b',
  // 700: '#2d5141',
  // 800: '#183226',
  // 900: '#00130a',

  50: '#E9FCF4',
  100: '#C1F5E0',
  200: '#99EFCB',
  300: '#72E9B7',
  400: '#4AE3A3',
  500: '#22DD8F',
  550: '#1FCB83',
  600: '#19A96D',
  700: '#148556',
  800: '#0E5839',
  900: '#072C1D',
};

const pink = {
  50: '#ffe7f1',
  100: '#f4bed1',
  200: '#EDA4BD',
  300: '#e16b93',
  400: '#d74274',
  500: '#be295b',
  600: '#941f46',
  700: '#6a1532',
  800: '#420b1e',
  900: '#1b010b',
};

const purple = {
  50: '#EFEBFA',
  100: '#D3C6F0',
  200: '#B7A2E7',
  300: '#9B7DDD',
  400: '#7F59D4',
  500: '#6334CB',
  550: '#4F2AA2',
  600: '#4F2AA2',
  700: '#3B1F7A',
  800: '#281551',
  900: '#140A29',
};

const brown = {
  // 50: '#ffeced',
  // 100: '#e6cfd2',
  // 200: '#cfb2b6',
  // 300: '#b89499',
  // 400: '#A07178',
  // 500: '#875F65',
  // 600: '#6c484d',
  // 700: '#4e3237',
  // 800: '#321d20',
  // 900: '#19050a',

  50: '#F4F0F1',
  100: '#E1D5D7',
  200: '#CEBABD',
  300: '#BBA0A3',
  400: '#A88589',
  500: '#956A6F',
  600: '#775559',
  700: '#594043',
  800: '#3C2A2C',
  900: '#1E1516',
};

export default {
  neutral,
  neutralLight,
  neutralDark,
  neutralAlpha,
  neutralLightAlpha,
  blue,
  blueAlpha,
  red,
  orange,
  yellow,
  green,
  pink,
  purple,
  brown,
};
