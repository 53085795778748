import React, { useState, useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  FcFaq,
  FcIdea,
  FcCalendar,
  FcDiploma1,
  FcMoneyTransfer,
  FcSportsMode,
  FcMindMap,
  FcGallery,
  FcInspection,
  FcLike,
  FcReadingEbook,
  FcCollaboration,
  FcAreaChart,
} from 'react-icons/fc';
import { IoFootsteps, IoVideocam } from 'react-icons/io5';
import {
  FaRegHandshake,
  FaRunning,
  FaChalkboardTeacher,
  FaRegSmileBeam,
} from 'react-icons/fa';
import { useHistory, useLocation } from 'react-router';
import { useLottie } from 'lottie-react';
import moment from 'moment';
import { useInView } from 'react-intersection-observer';

import { GlobalState } from 'types';

import {
  PLATFORM,
  discordUrl,
  aiFeatureUrl,
  solutionUrls,
  pricingUrl,
  contactUrl,
  meetingUrl,
  trialUrl,
} from 'constants/env';
import {
  PRO_TEAM_MEMBERS_LIMIT,
  PRO_MONTHLY_ENROLMENTS_LIMIT,
  BASIC_UNIT_LIMIT,
  PRO_PRICE,
  PRO_95_DISCOUNT_PRICE,
  PRO_AI_CREDITS_LIMIT,
} from 'constants/organisation';
import { NAV_HEIGHT } from 'containers/AppHeader';
import { ICONS } from 'constants/ui';

import { analytics, getParamFromUrl } from 'utils';
import { useWindowDimensions } from 'utils/hooks/useDimensions';

import navRoutes from 'navigation/Routes';

import {
  LinkBox,
  LinkOverlay,
  Flex,
  Text,
  Box,
  Stack,
  StackDivider,
  LinkButton,
  MdIcon,
  Container,
  Heading,
  Image,
  Icon,
  createIcon,
  IconProps,
  SimpleGrid,
  Grid,
  GridItem,
  chakra,
  useColorModeValue,
  Divider,
  Card,
  Button,
  BoxProps,
  CircularProgress,
  CircularProgressLabel,
  useTheme,
} from '@workshop/ui';

import Brand, {
  ProGem,
  WorkshopIcon,
  SteppitWavelengthHorizLogo,
  BrandLg,
  SteppitMiniIcon,
  SteppitProLogo,
  SteppitSelectLogo,
} from 'components/Brand';
import { Footer } from 'components/Footer';
import { SessionModal, PricingTable, RenderHtml } from 'components/Common';
import { AppHeader } from 'containers/AppHeader';
import { ScreenWrapper } from 'screens/common/ScreenWrapper';
import { IconTooltip } from 'components/IconTooltip';

import { PaymentsWelcomeModal } from 'screens/cms/MyOrganisation/src';

import iSteps from 'assets/images/illustrations/i-steps.png';
import iGroups from 'assets/images/illustrations/i-groups.png';
import iClasses from 'assets/images/illustrations/i-classes.png';
import iSales from 'assets/images/illustrations/i-sales.png';
import iCourses from 'assets/images/illustrations/i-courses.png';
import iFilming from 'assets/images/illustrations/i-filming.png';
import iAi from 'assets/images/illustrations/i-ai.png';
import avatar1 from 'assets/images/illustrations/avatar1.png';
import avatar2 from 'assets/images/illustrations/avatar2.png';
import avatar3 from 'assets/images/illustrations/avatar3.png';
import avatar4 from 'assets/images/illustrations/avatar4.png';

import iTraining from 'assets/images/illustrations/i-training.png';
import iCreators from 'assets/images/illustrations/i-creators.png';
import iCoaches from 'assets/images/illustrations/i-coaches.png';
import iEducators from 'assets/images/illustrations/i-educators.png';
import iBrands from 'assets/images/illustrations/i-brands.png';
import iProviders from 'assets/images/illustrations/i-providers.png';
import iSpecialists from 'assets/images/illustrations/i-specialists.png';

import rAotd from 'assets/images/illustrations/r-aotd.png';
import rLs from 'assets/images/illustrations/r-ls.png';
import rNawl from 'assets/images/illustrations/r-nawl.png';
import rEducate from 'assets/images/illustrations/r-educate.png';
import rCs from 'assets/images/illustrations/r-cs.png';

// @ts-ignore
import pChannel from 'assets/images/examples/channel-home.jpg'; // @ts-ignore
import pChannelMob from 'assets/images/examples/channel-home-mob.jpg';

// @ts-ignore
import vHomeMp4Light from 'assets/videos/steppit-home-l.mp4'; // @ts-ignore
import vHomeMp4Dark from 'assets/videos/steppit-home-d.mp4'; // @ts-ignore
import vHomeWebmLight from 'assets/videos/steppit-home-l.webm'; // @ts-ignore
import vHomeWebmDark from 'assets/videos/steppit-home-d.webm'; // @ts-ignore
import vHomeMp4LightXs from 'assets/videos/steppit-home-l-xs.mp4'; // @ts-ignore
import vHomeMp4DarkXs from 'assets/videos/steppit-home-d-xs.mp4'; // @ts-ignore
import vHomeWebmLightXs from 'assets/videos/steppit-home-l-xs.webm'; // @ts-ignore
import vHomeWebmDarkXs from 'assets/videos/steppit-home-d-xs.webm'; // @ts-ignore
import pHomeLight from 'assets/videos/steppit-home-l.jpg'; // @ts-ignore
import pHomeDark from 'assets/videos/steppit-home-d.jpg';

// @ts-ignore
import vStepsMp4Light from 'assets/videos/steps-creators.mp4'; // @ts-ignore
import vStepsMp4Dark from 'assets/videos/steps-creators-d.mp4'; // @ts-ignore
import vStepsWebmLight from 'assets/videos/steps-creators.webm'; // @ts-ignore
import vStepsWebmDark from 'assets/videos/steps-creators-d.webm'; // @ts-ignore
import pStepsLight from 'assets/videos/steps-creators.jpg'; // @ts-ignore
import pStepsDark from 'assets/videos/steps-creators-d.jpg';

// @ts-ignore
import vAiMp4Light from 'assets/videos/ai-course-demo.mp4'; // @ts-ignore
import vAiMp4Dark from 'assets/videos/ai-course-demo-d.mp4'; // @ts-ignore
import vAiWebmLight from 'assets/videos/ai-course-demo.webm'; // @ts-ignore
import vAiWebmDark from 'assets/videos/ai-course-demo-d.webm'; // @ts-ignore
import pAiLight from 'assets/videos/ai-course-demo.jpg'; // @ts-ignore
import pAiDark from 'assets/videos/ai-course-demo-d.jpg';

// @ts-ignore
import vClassesMp4Light from 'assets/videos/class-demo.mp4'; // @ts-ignore
import vClassesMp4Dark from 'assets/videos/class-demo-d.mp4'; // @ts-ignore
import vClassesWebmLight from 'assets/videos/class-demo.webm'; // @ts-ignore
import vClassesWebmDark from 'assets/videos/class-demo-d.webm'; // @ts-ignore
import pClassesLight from 'assets/videos/class-demo.jpg'; // @ts-ignore
import pClassesDark from 'assets/videos/class-demo-d.jpg';

import wootricLogo from 'assets/images/brands/wootric-logo.png';

// @ts-ignore
import cocktailSessionImage from 'assets/images/examples/cocktail.jpg';
// @ts-ignore
import cakeSessionImage from 'assets/images/examples/cake.jpg';
// @ts-ignore
import kitchenSessionImage from 'assets/images/examples/kitchen.jpg';
// @ts-ignore
import oilSessionImage from 'assets/images/examples/oil.jpg';
// @ts-ignore
import makeupSessionImage from 'assets/images/examples/makeup.jpg';

// @ts-ignore
import sortedReviewImage from 'assets/images/reviews/sorted.jpg';
// @ts-ignore
import sortedReviewBgImage from 'assets/images/reviews/sortedBg.jpg';
// @ts-ignore
import jamCodingReviewImage from 'assets/images/reviews/kjJamCoding.jpg';
// @ts-ignore
import jamCodingReviewBgImage from 'assets/images/reviews/jamCodingBg2.jpg';
// @ts-ignore
import joshImage from 'assets/images/reviews/josh.jpg';

// @ts-ignore
import rPhillis from 'assets/images/reviews/review-phillis.jpg'; // @ts-ignore
import rGeoff from 'assets/images/reviews/review-geoff.jpg'; // @ts-ignore
import rVanita from 'assets/images/reviews/review-vanita.jpg'; // @ts-ignore

// @ts-ignore
import barAotd from 'assets/images/reviews/bar-aotd.png';
// @ts-ignore
import barNewApps from 'assets/images/reviews/bar-newApps.png';

import assistantAnimation from 'assets/lottie/assistant.json';

const discount =
  'Try Pro free for 7 days and get your own personal AI assistant, tailored to you.';
const discountLabel = 'Try Our Pro AI For Free ✨';
// const discount =
//   '';
// const discountLabel = '';

const SOLUTIONS = [
  {
    image: iEducators,
    title: 'For Teachers, Tutors & Professors',
    description: 'Inspire your students',
    link: solutionUrls.teachers,
  },
  {
    image: iProviders,
    title: 'For Independent Learning Providers',
    description: 'Scale your offering',
    link: solutionUrls.providers,
  },
  {
    image: iSpecialists,
    title: 'For Education Specialists',
    description: 'Share your expertise',
    link: solutionUrls.specialists,
  },
  {
    image: iCoaches,
    title: 'For Coaches',
    description: 'Help your clients',
    link: solutionUrls.coaches,
  },

  // {
  //   image: iCreators,
  //   title: 'For Content Creators',
  //   description: 'Monetize your audience',
  //   link: solutionUrls.creators,
  //   small: true,
  // },
  // {
  //   image: iTraining,
  //   title: 'For Internal Training',
  //   description: 'Train your team',
  //   link: solutionUrls.training,
  //   small: true,
  // },
  // {
  //   image: iBrands,
  //   title: 'For Brands',
  //   description: 'Engage your customers',
  //   link: solutionUrls.brands,
  //   small: true,
  // },
];

const backgrounds = [
  `url("data:image/svg+xml, %3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'560\' height=\'185\' viewBox=\'0 0 560 185\' fill=\'none\'%3E%3Cellipse cx=\'102.633\' cy=\'61.0737\' rx=\'102.633\' ry=\'61.0737\' fill=\'%23ED64A6\' /%3E%3Cellipse cx=\'399.573\' cy=\'123.926\' rx=\'102.633\' ry=\'61.0737\' fill=\'%23F56565\' /%3E%3Cellipse cx=\'366.192\' cy=\'73.2292\' rx=\'193.808\' ry=\'73.2292\' fill=\'%2338B2AC\' /%3E%3Cellipse cx=\'222.705\' cy=\'110.585\' rx=\'193.808\' ry=\'73.2292\' fill=\'%23ED8936\' /%3E%3C/svg%3E")`,
  `url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='560' height='185' viewBox='0 0 560 185' fill='none'%3E%3Cellipse cx='457.367' cy='123.926' rx='102.633' ry='61.0737' transform='rotate(-180 457.367 123.926)' fill='%23ED8936'/%3E%3Cellipse cx='160.427' cy='61.0737' rx='102.633' ry='61.0737' transform='rotate(-180 160.427 61.0737)' fill='%2348BB78'/%3E%3Cellipse cx='193.808' cy='111.771' rx='193.808' ry='73.2292' transform='rotate(-180 193.808 111.771)' fill='%230BC5EA'/%3E%3Cellipse cx='337.295' cy='74.415' rx='193.808' ry='73.2292' transform='rotate(-180 337.295 74.415)' fill='%23ED64A6'/%3E%3C/svg%3E")`,
  `url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='560' height='185' viewBox='0 0 560 185' fill='none'%3E%3Cellipse cx='102.633' cy='61.0737' rx='102.633' ry='61.0737' fill='%23ED8936'/%3E%3Cellipse cx='399.573' cy='123.926' rx='102.633' ry='61.0737' fill='%2348BB78'/%3E%3Cellipse cx='366.192' cy='73.2292' rx='193.808' ry='73.2292' fill='%230BC5EA'/%3E%3Cellipse cx='222.705' cy='110.585' rx='193.808' ry='73.2292' fill='%23ED64A6'/%3E%3C/svg%3E")`,
  `url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='560' height='185' viewBox='0 0 560 185' fill='none'%3E%3Cellipse cx='457.367' cy='123.926' rx='102.633' ry='61.0737' transform='rotate(-180 457.367 123.926)' fill='%23ECC94B'/%3E%3Cellipse cx='160.427' cy='61.0737' rx='102.633' ry='61.0737' transform='rotate(-180 160.427 61.0737)' fill='%239F7AEA'/%3E%3Cellipse cx='193.808' cy='111.771' rx='193.808' ry='73.2292' transform='rotate(-180 193.808 111.771)' fill='%234299E1'/%3E%3Cellipse cx='337.295' cy='74.415' rx='193.808' ry='73.2292' transform='rotate(-180 337.295 74.415)' fill='%2348BB78'/%3E%3C/svg%3E")`,
];

const POPUP_SESSIONS = [
  {
    icon: 'Draw',
    label: 'Oil Painting',
    courseSlug: 'oil-painting',
    moduleId: 166,
    image: oilSessionImage,
    alt: 'Oil Painting',
    link: '',
    creator: 'Anne & Nneka',
  },
  {
    icon: 'Cake',
    label: 'Cake Decorating',
    courseSlug: 'cKau4Bls',
    moduleId: 1994,
    image: cakeSessionImage,
    alt: 'Cupcake',
    link: '',
    creator: 'Benjamina',
  },
  {
    icon: 'Kitchen',
    label: 'Kitchen Fitting',
    courseSlug: 'H7RgwHym',
    moduleId: 1989,
    image: kitchenSessionImage,
    alt: 'Kitchen',
    link: '',
    creator: 'Anthony',
  },
  // {
  //   icon: 'LocalBar',
  //   label: 'Making Quick Cocktails',
  //   courseSlug: 'TrO1qWsI',
  //   moduleId: 2016,
  //   image: cocktailSessionImage,
  //   alt: 'Cocktails',
  //   link: '',
  //   creator: 'Ben',
  // },
  // {
  //   icon: 'Mood',
  //   label: 'Flawless Face Makeup',
  //   courseSlug: 'fashion-tv-media-makeup',
  //   moduleId: 149,
  //   image: makeupSessionImage,
  //   alt: 'Cocktails',
  //   link: '',
  //   creator: 'Davinia',
  // },
];

interface LandingScreenProps extends RouteComponentProps {}

const Hero = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const { ref: inViewRef, inView: videoIsVisible } = useInView({
    fallbackInView: true,
  });
  const [playVideo, setPlayVideo] = useState(false);
  useEffect(() => {
    if (videoIsVisible && !playVideo) {
      setPlayVideo(true);
      if (videoRef.current) {
        if (videoRef.current.readyState < 3) {
          videoRef.current.load();
        } else {
          videoRef.current.play();
        }
      }
    }
    if (!videoIsVisible && playVideo) {
      setPlayVideo(false);
      if (videoRef.current) {
        videoRef.current.pause();
      }
    }
  }, [videoIsVisible]);
  const discountIsValid = true;

  const dispatch = useDispatch();
  const theme = useTheme();
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < parseInt(theme.breakpoints.md, 10);

  const vHomeMp4 = useColorModeValue(
    isMobile ? vHomeMp4LightXs : vHomeMp4Light,
    isMobile ? vHomeMp4DarkXs : vHomeMp4Dark
  );
  const vHomeWebm = useColorModeValue(
    isMobile ? vHomeWebmLightXs : vHomeWebmLight,
    isMobile ? vHomeWebmDarkXs : vHomeWebmDark
  );
  const pHome = useColorModeValue(pHomeLight, pHomeDark);
  useEffect(() => {
    if (videoRef.current) {
      // @ts-ignore
      videoRef.current.load();
    }
  }, [vHomeMp4]);
  return (
    <Container maxW="7xl">
      <Stack
        align="center"
        spacing={{ base: 0, md: 0 }}
        pt={{ base: 0, md: 16 }}
        pb={{ base: 0, md: 16 }}
        mt={{ base: 24, md: 12 }}
        direction={{ base: 'column-reverse', md: 'row' }}
      >
        <Stack
          flex={1.5}
          spacing={{ base: 4, md: 6 }}
          alignItems={{ base: 'center', md: 'normal' }}
          textAlign={{ base: 'center', md: 'left' }}
          zIndex={1}
          pt={{ base: 2, md: 0 }}
          pb={{ base: 8, md: 0 }}
          maxWidth={{ base: '500px', md: '540px', '2xl': '700px' }}
          pr={{ base: 0, md: 4 }}
        >
          <Flex flexDirection={{ base: 'column-reverse', md: 'column' }}>
            <Heading
              as="h1"
              lineHeight={1.2}
              fontWeight="extrabold"
              fontSize={{
                base: '3xl',
                sm: '4xl',
                md: '5xl',
                lg: '5xl',
                '2xl': '6xl',
              }}
            >
              Create, teach & inspire{' '}
              <chakra.span color="common.primary">stress-free.</chakra.span>
            </Heading>
          </Flex>
          <Text
            color="text.muted"
            fontSize={{ base: 'md', sm: 'lg', md: 'xl' }}
            pb={{ base: 0, md: 6 }}
          >
            Steppit makes it easy to deliver unparalleled online learning
            experiences at scale.
            {/* Steppit makes it easy to teach at scale, with social and video-based
            experiences that learners love. */}
          </Text>
          <SimpleGrid
            columns={{ base: 1, md: 2 }}
            spacing={3}
            display={{ base: 'none', md: 'grid' }}
            alignSelf="flex-start"
          >
            <LinkButton
              size="lg"
              px={6}
              icon="Event"
              href={meetingUrl}
              variant="outline"
              onClick={() => analytics.logConversion(dispatch, 'openedContact')}
            >
              Book an Intro
            </LinkButton>
            <LinkButton
              size="lg"
              px={6}
              icon="ArrowForward"
              iconPosition="right"
              href={trialUrl}
              onClick={() => analytics.logConversion(dispatch, 'openedContact')}
            >
              Get a Free Trial
            </LinkButton>
          </SimpleGrid>
        </Stack>
        <Flex
          flex={1}
          justify="center"
          align="center"
          position="relative"
          w="full"
        >
          <Box
            mt={{ base: -6 }}
            mb={{ base: -2, sm: -3 }}
            mx={{ base: 0, sm: -6 }}
            maxW={{ base: '350px', md: 'none' }}
          >
            <Box position="absolute" ref={inViewRef} />
            <video
              // @ts-ignore
              ref={videoRef}
              autoPlay
              loop
              muted
              playsInline
              poster={pHome}
              style={{
                // @ts-ignore
                '-webkit-mask-image': '-webkit-radial-gradient(white, black)',
                // @ts-ignore
                '-webkit-backface-visibility': 'hidden',
                // @ts-ignore
                '-moz-backface-visibility': 'hidden',
              }}
            >
              <source src={vHomeMp4} type="video/mp4" />
              <source src={vHomeWebm} type="video/webm" />
            </video>
          </Box>
        </Flex>
      </Stack>
    </Container>
  );
};

const FounderMessage = () => {
  const label = '';
  const body = '';
  // const label = moment().isBefore(moment('2024-04-01'))
  //   ? 'Update for March 2024'
  //   : 'Update for April 2024';

  // const body = moment().isBefore(moment('2024-04-01')) ? (
  //   <>
  //     What a month! With hundreds of new creators discovering Steppit every
  //     week, we're so excited to unveil 2 new updates for you all. #1 – starting
  //     today, anyone on our Pro Growth or Scale plans can now{' '}
  //     <chakra.span fontWeight="bold">embed sessions anywhere</chakra.span>, and
  //     #2 – our mobile app is{' '}
  //     <chakra.span fontWeight="bold">now available on Android</chakra.span> 🥳.
  //   </>
  // ) : (
  //   <>
  //     Our Spring update is here! With hundreds of new creators discovering
  //     Steppit every week, we're so excited to unveil 2 new updates for you all.
  //     #1 – starting today, anyone on our Pro Growth or Scale plans can now{' '}
  //     <chakra.span fontWeight="bold">embed sessions anywhere</chakra.span>, and
  //     #2 – our mobile app is{' '}
  //     <chakra.span fontWeight="bold">now available on Android</chakra.span> 🥳.
  //   </>
  // );
  return (
    <Container maxW="7xl" pb={10} my={20}>
      <Box position="relative" pl={{ base: '40px', md: '40px' }}>
        <Flex
          position="absolute"
          bottom={{ base: '-40px', md: '-40px' }}
          left={0}
          zIndex={2}
        >
          <Image
            height="60px"
            width="60px"
            htmlHeight="60"
            htmlWidth="60"
            borderRadius="full"
            bg="background.primary"
            src={joshImage}
            alt="Steppit Founder"
          />
        </Flex>
        <Box
          position="relative"
          _before={{
            content: '""',
            position: 'absolute',
            zIndex: 0,
            height: 'full',
            width: 'full',
            filter: 'blur(40px)',
            transform: 'rotate(180deg) scale(0.98)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            top: 0,
            left: 0,
            backgroundImage: backgrounds[3],
          }}
        >
          <Card
            padding={{ base: 6, md: 12 }}
            borderRadius="lg"
            overflow="visible"
            boxShadow="lg"
            backgroundColor="background.default"
            mt={4}
            position="relative"
          >
            <Flex flexDirection="column" flex={1} mb={1}>
              <Heading
                as="h2"
                fontSize={{ base: 'lg', md: 'xl' }}
                flex={1}
                fontWeight="bold"
              >
                What's New on Steppit?
              </Heading>
              <Text color="text.muted" fontSize="xs" mb={2}>
                {label}
              </Text>
              <Text fontSize={{ base: 'md', md: 'md', '2xl': 'lg' }} flex={1}>
                {body}
              </Text>
            </Flex>
          </Card>
        </Box>
        <Flex
          fontSize="sm"
          color="text.muted"
          position="absolute"
          right={0}
          left="70px"
          pt={2}
        >
          <Text py={1}>Josh T, Founder</Text>
          <Flex flex={1} />
          <LinkButton
            variant="ghost"
            size="sm"
            icon="ArrowForward"
            iconPosition="right"
            href={discordUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            Join Us On Discord
          </LinkButton>
        </Flex>
      </Box>
    </Container>
  );
};

interface FeatureProps {
  title: string;
  text: string;
  icon: React.ReactElement;
}

const Feature = ({ title, text, icon }: FeatureProps) => {
  return (
    <Stack alignItems="center" textAlign="center">
      <Flex
        w={16}
        h={16}
        align="center"
        justify="center"
        color="white"
        rounded="full"
        bg={useColorModeValue('background.tint3', 'background.default')}
        mb={1}
      >
        {icon}
      </Flex>
      <Text fontWeight="bold" fontSize={{ base: 'md', md: 'lg' }}>
        {title}
      </Text>
      <Text color="text.muted" fontSize={{ base: 'md', sm: 'sm', md: 'md' }}>
        {text}
      </Text>
    </Stack>
  );
};

const ProSection1 = () => {
  const [assistantJson, setAssistantJson] = useState(null);
  const [questionOpacity, setQuestionOpacity] = useState(1);
  const [currentQuestion, setCurrentQuestion] = useState(QUESTIONS[0]);
  const [currentQuestionIdx, setCurrentQuestionIdx] = useState(0);
  const question = QUESTIONS[currentQuestionIdx];

  useEffect(() => {
    if (question !== currentQuestion) {
      setQuestionOpacity(0);
      const timeout = setTimeout(() => {
        setCurrentQuestion(question);
        setQuestionOpacity(1);
      }, 500);
      return () => clearTimeout(timeout);
    }
  }, [question]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const nextQuestionIdx = (currentQuestionIdx + 1) % QUESTIONS.length;
      setCurrentQuestionIdx(nextQuestionIdx);
    }, 5000);
    return () => clearTimeout(timeout);
  }, [currentQuestionIdx]);

  const theme = useTheme();
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < parseInt(theme.breakpoints.md, 10);

  useEffect(() => {
    // @ts-ignore
    setAssistantJson(assistantAnimation);
  }, []);
  const { View: lottieAssistant } = useLottie({
    animationData: assistantJson,
    style: {
      height: isMobile ? 120 : 200,
      width: isMobile ? 120 : 200,
    },
  });
  return (
    <Flex
      flexDirection={{ base: 'column', md: 'row' }}
      textAlign={{ base: 'center', md: 'left' }}
      bg="background.primary"
      color="text.primary"
      borderRadius="lg"
      alignItems="center"
      pt={{ base: 0, md: 6 }}
      pb={{ base: 6, md: 6 }}
      pl={{ base: 4, md: 0 }}
      pr={{ base: 4, md: 6 }}
      mx={{ base: 4, md: 0 }}
      alignSelf="center"
      mt={20}
      mb={20}
    >
      <Flex position="relative" alignItems="center">
        <Box
          transform={{ base: 'translateY(-1.5rem)', md: 'translateX(-2rem)' }}
        >
          <Flex p="0.5rem" borderRadius="full" bg="background.default">
            {lottieAssistant}
          </Flex>
        </Box>
      </Flex>
      <Flex
        flexDirection="column"
        alignItems={{ base: 'center', md: 'flex-start' }}
        flex={1}
        maxWidth={{ base: '700px', md: 'none' }}
        mx="auto"
        py={{ base: 0, md: 2 }}
      >
        <Text
          lineHeight={1.2}
          mb={2}
          fontSize={{ base: 'xl', sm: '2xl' }}
          fontWeight="extrabold"
          opacity={questionOpacity}
          transition="opacity 0.5s"
        >
          {`"${currentQuestion}"`}
        </Text>
        <Heading
          as="h2"
          lineHeight={1.2}
          fontWeight="semibold"
          fontSize={{ base: 'lg', sm: 'xl' }}
          mb={4}
        >
          Find out in 5 minutes with our AI course creator
        </Heading>
        <Text mb={5} fontSize={{ base: 'md', md: 'lg' }}>
          Think about your skills and answer a few questions, then let our free
          AI course planner give you an idea of what's possible. For a more
          in-depth plan designed by your personalised AI assistant, start a free
          trial of Pro.
        </Text>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          alignItems="center"
          spacing={3}
        >
          <LinkButton
            to={`${navRoutes.global.planner.path()}`}
            icon="ArrowForward"
            iconPosition="right"
          >
            Launch Our Free AI Planner
          </LinkButton>
          <LinkButton
            variant="outline"
            icon="ArrowForward"
            iconPosition="right"
            to={`${navRoutes.global.planner.path()}?p=pro`}
          >
            Try Pro
          </LinkButton>
        </Stack>
      </Flex>
    </Flex>
  );
};

const ProSection2 = () => {
  const learnMoreColor = useColorModeValue('blue', 'green');
  return (
    <Flex
      flexDirection={{ base: 'column', md: 'row' }}
      textAlign={{ base: 'center', md: 'left' }}
      bg="background.success"
      color="text.success"
      borderRadius="lg"
      alignItems="center"
      p={{ base: 4, md: 6 }}
      mx={{ base: 4, md: 0 }}
      alignSelf="center"
      mt={20}
      mb={20}
    >
      <Flex
        position="relative"
        width={{
          base: '220px',
          sm: '250px',
          md: '250px',
          lg: '340px',
          xl: '370px',
        }}
        height={{
          base: '100px',
          sm: '120px',
          md: '200px',
          lg: '220px',
          xl: '240px',
        }}
        alignItems="center"
        mt={{ base: 8, md: 0 }}
      >
        <Box
          width={{
            base: '220px',
            sm: '250px',
            md: '250px',
            lg: '350px',
            xl: '400px',
          }}
          position="absolute"
          bottom={{
            base: 'auto',
            md: 'auto',
            lg: '-100px',
            xl: '-120px',
          }}
          left={{ base: 'auto', md: '-40px', lg: '-60px', xl: '-85px' }}
          top={{ base: '-120px', md: 'auto' }}
        >
          <Image alt="AI course creator illustration" src={iAi} />
        </Box>
      </Flex>
      <Flex
        flexDirection="column"
        alignItems={{ base: 'center', md: 'flex-start' }}
        flex={1}
        maxWidth={{ base: '700px', md: 'none' }}
        mx="auto"
      >
        <Heading
          as="h2"
          lineHeight={1.2}
          fontWeight="extrabold"
          fontSize={{ base: 'xl', sm: '2xl' }}
          mb={2}
        >
          Unlock AI tailored to you, with Pro
        </Heading>
        <Text mb={5} fontSize={{ base: 'md', md: 'lg' }}>
          Upgrade to Pro to coach your own AI assistant and discover how easy it
          can be to plan and produce courses that match your unique approach.
        </Text>
        <Stack direction={{ base: 'column', md: 'row' }} alignItems="center">
          <LinkButton
            to={`${navRoutes.global.planner.path()}?p=pro`}
            icon="ArrowForward"
            iconPosition="right"
            // colorScheme="red"
          >
            Try Pro for Free
          </LinkButton>
          <LinkButton
            variant="ghost"
            icon="OpenInNew"
            iconPosition="right"
            colorScheme={learnMoreColor}
            href={aiFeatureUrl}
          >
            Learn More
          </LinkButton>
        </Stack>
      </Flex>
    </Flex>
  );
};

const Features = () => {
  return (
    <Container maxW="7xl" py={{ base: 10, md: 20 }}>
      <Box px={4}>
        <Heading
          as="h2"
          fontWeight="extrabold"
          fontSize={{ base: 'xl', sm: '2xl', md: '3xl', lg: '4xl' }}
          lineHeight={1.2}
          textAlign="center"
          mb={10}
        >
          Additional features
        </Heading>
        <SimpleGrid
          columns={{ base: 1, sm: 2, md: 3 }}
          spacing={{ base: 6, md: 10 }}
        >
          <Feature
            icon={<Icon as={FcFaq} w={10} h={10} />}
            title="Interactive feedback"
            text={
              'Share feedback and mentor your class in private posting feeds and messages.'
            }
          />
          <Feature
            icon={<Icon as={FcGallery} w={10} h={10} />}
            title="Brand your channel"
            text={
              'Personalize your channel with your own color scheme, banner and profile picture.'
            }
          />
          <Feature
            icon={<Icon as={FcCalendar} w={10} h={10} />}
            title="Drip-feed content"
            text={
              'Guide your classes through a few sessions each week or let them access everything at once.'
            }
          />
          <Feature
            icon={<Icon as={FcDiploma1} w={10} h={10} />}
            title="Award certificates"
            text={
              'Require uploads on specific sessions and reward certificates on completion of your courses.'
            }
          />
          <Feature
            icon={<Icon as={FcMoneyTransfer} w={10} h={10} />}
            title="Easy payments"
            text={
              'Just connect your channel to Stripe to start selling class spaces to your audience.'
            }
          />
          {/* <Feature
            icon={<Icon as={FcSportsMode} w={10} h={10} />}
            title="Build Faster with Pro"
            text={
              'Upgrade to Pro to auto-generate summaries on your videos and plan extended courses with AI.'
            }
          /> */}
          <Feature
            icon={<Icon as={FcMindMap} w={10} h={10} />}
            title="Integrate with 5,000+ apps"
            text={
              'Automate your notifications, enrollments and workflows with our built-in Zapier integration.'
            }
          />
        </SimpleGrid>
      </Box>
    </Container>
  );
};

const QUESTIONS = [
  'How quickly can I plan a course?',
  'Who might want me to teach them?',
  'How could I structure my course?',
  'Could my course generate revenue?',
];

const Special = () => {
  const [assistantJson, setAssistantJson] = useState(null);
  const [questionOpacity, setQuestionOpacity] = useState(1);
  const [currentQuestion, setCurrentQuestion] = useState(QUESTIONS[0]);
  const [currentQuestionIdx, setCurrentQuestionIdx] = useState(0);
  const question = QUESTIONS[currentQuestionIdx];

  useEffect(() => {
    if (question !== currentQuestion) {
      setQuestionOpacity(0);
      const timeout = setTimeout(() => {
        setCurrentQuestion(question);
        setQuestionOpacity(1);
      }, 500);
      return () => clearTimeout(timeout);
    }
  }, [question]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const nextQuestionIdx = (currentQuestionIdx + 1) % QUESTIONS.length;
      setCurrentQuestionIdx(nextQuestionIdx);
    }, 3000);
    return () => clearTimeout(timeout);
  }, [currentQuestionIdx]);

  useEffect(() => {
    // @ts-ignore
    setAssistantJson(assistantAnimation);
  }, []);
  const { View: lottieAssistant } = useLottie({
    animationData: assistantJson,
    style: {
      height: 120,
      width: 120,
    },
  });
  const phBg = useColorModeValue('light', 'dark');
  const aiBg = useColorModeValue('#fff', '#221D21');
  const aiBorder = useColorModeValue(1, 0);
  return (
    <Container maxW="7xl" my={8}>
      <Stack
        spacing={{ base: 6, md: 8 }}
        textAlign="center"
        alignItems="center"
        bg="background.primary"
        px={{ base: 5, sm: 8, md: 16 }}
        py={{ base: 10, sm: 10, md: 16 }}
        borderRadius="xl"
        color="text.primary"
        my={6}
      >
        <Flex p={4} borderRadius="full" bg="background.default">
          {lottieAssistant}
        </Flex>
        <Flex flexDirection="column" alignItems="center">
          <Text
            lineHeight={1.2}
            mb={4}
            fontSize={{ base: 'lg', sm: 'xl', md: '2xl', lg: '3xl' }}
            opacity={questionOpacity}
            transition="opacity 0.5s"
          >
            {`"${currentQuestion}"`}
          </Text>
          <Heading
            as="h2"
            lineHeight={1.2}
            fontWeight="extrabold"
            textAlign="center"
            fontSize={{ base: 'xl', sm: '2xl', md: '3xl', lg: '3xl' }}
          >
            Find out in 5 minutes with our AI course creator
          </Heading>
        </Flex>
        <Box>
          <Text fontSize={{ base: 'md', sm: 'lg', md: 'xl' }} pb={4}>
            Think about what you could offer with your skills, then let our free
            AI course planner guide you through a few questions to help you plan
            a course and give you an idea of what's possible.
          </Text>
          <Text
            fontSize={{ base: 'md', sm: 'lg', md: 'xl' }}
            fontWeight="semibold"
            pb={{ base: 6, md: 2 }}
          >
            For a bespoke plan designed specifically around you, start a free
            trial of Pro to unlock your personalised AI assistant.
          </Text>
        </Box>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          alignItems={{ base: 'center', md: 'normal' }}
          spacing={{ base: 4, md: 3 }}
        >
          <Flex flexDirection="column" alignItems="center">
            <LinkButton
              rounded="full"
              px={6}
              size="md"
              icon="ArrowForward"
              iconPosition="right"
              to={navRoutes.global.planner.path()}
              display={{ base: 'flex', sm: 'none' }}
            >
              Try Our Free AI Planner
            </LinkButton>
            <LinkButton
              rounded="full"
              px={6}
              size="lg"
              icon="ArrowForward"
              iconPosition="right"
              to={navRoutes.global.planner.path()}
              display={{ base: 'none', sm: 'flex' }}
            >
              Try Our Free AI Planner
            </LinkButton>
          </Flex>
          <Flex flexDirection="column" alignItems="center">
            <LinkButton
              size="md"
              px={6}
              icon="ArrowForward"
              iconPosition="right"
              variant="outline"
              to={`${navRoutes.global.planner.path()}?p=pro`}
              display={{ base: 'flex', sm: 'none' }}
            >
              Plan with Advanced AI
            </LinkButton>
            <LinkButton
              size="lg"
              px={6}
              icon="ArrowForward"
              iconPosition="right"
              variant="outline"
              to={`${navRoutes.global.planner.path()}?p=pro`}
              display={{ base: 'none', sm: 'flex' }}
            >
              Plan with Advanced AI
            </LinkButton>
            <Flex alignItems="center" fontSize="sm" mt={2}>
              <MdIcon name="AutoAwesome" mr={1.5} />
              <Text>Try Pro free for 7 days</Text>
            </Flex>
          </Flex>
        </Stack>
        <Flex
          mt={16}
          flexWrap="wrap"
          alignItems="center"
          justifyContent="center"
        >
          <Flex m={3} mb={0}>
            <chakra.a
              href="https://www.producthunt.com/posts/steppit?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-steppit"
              // href="https://www.producthunt.com/posts/steppit-personal-ai-assistant?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-steppit&#0045;personal&#0045;ai&#0045;assistant"
              target="_blank"
            >
              <Image
                src={`https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=393691&theme=${phBg}`}
                // src={`https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=414196&theme=${phBg}`}
                alt="Featured on Product Hunt"
                style={{
                  width: '200px',
                  height: '54px',
                }}
                htmlWidth="250"
                htmlHeight="54"
              />
            </chakra.a>
          </Flex>
          <Flex m={3} mb={0}>
            <chakra.a
              href="https://theresanaiforthat.com/ai/steppit/?ref=embed"
              target="_blank"
            >
              <Flex
                borderWidth={aiBorder}
                borderColor="#7B8793"
                borderRadius="10px"
                my="4px"
                bg={aiBg}
                style={{
                  width: '216px',
                  height: '44px',
                }}
              >
                <Image
                  htmlWidth="216"
                  htmlHeight="44"
                  style={{
                    width: '216px',
                    height: '44px',
                  }}
                  src="https://media.theresanaiforthat.com/featured1.png"
                  alt="Featured on There's An AI For That"
                />
              </Flex>
            </chakra.a>
          </Flex>
        </Flex>
      </Stack>
    </Container>
  );
};

interface PointProps {
  text: string;
  iconBg: string;
  icon?: React.ReactElement;
}

const Point = ({ text, icon, iconBg }: PointProps) => {
  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      align="center"
      justify={{ base: 'center', md: 'normal' }}
    >
      <Flex
        w={8}
        h={8}
        align="center"
        justify="center"
        rounded="full"
        bg={iconBg}
      >
        {icon}
      </Flex>
      <Text fontWeight="semibold" flex={1}>
        {text}
      </Text>
    </Stack>
  );
};

const Detail1 = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const { ref: inViewRef, inView: videoIsVisible } = useInView({
    fallbackInView: true,
  });
  const [playVideo, setPlayVideo] = useState(false);
  useEffect(() => {
    if (videoIsVisible && !playVideo) {
      setPlayVideo(true);
      if (videoRef.current) {
        if (videoRef.current.readyState < 3) {
          videoRef.current.load();
        } else {
          videoRef.current.play();
        }
      }
    }
    if (!videoIsVisible && playVideo) {
      setPlayVideo(false);
      if (videoRef.current) {
        videoRef.current.pause();
      }
    }
  }, [videoIsVisible]);
  const vAiMp4 = useColorModeValue(vAiMp4Light, vAiMp4Dark);
  const vAiWebm = useColorModeValue(vAiWebmLight, vAiWebmDark);
  const pAi = useColorModeValue(pAiLight, pAiDark);
  const highlightColor = useColorModeValue('green.550', 'green.200');
  useEffect(() => {
    if (videoRef.current) {
      // @ts-ignore
      videoRef.current.load();
    }
  }, [vAiMp4]);
  return (
    <Container maxW="7xl" py={{ base: 10, md: 20 }}>
      <SimpleGrid
        columns={{ base: 1, md: 2 }}
        spacing={{ base: 10, md: 20 }}
        alignItems="center"
        display={{ base: 'flex', md: 'grid' }}
        flexDirection={{ base: 'column', md: 'initial' }}
      >
        <Flex
          flex={1}
          justifyContent="center"
          alignItems="center"
          position="relative"
        >
          <Box
            mt={{ base: -6 }}
            mb={{ base: -2, sm: -3 }}
            mx={{ base: 0, sm: -6 }}
            maxW={{ base: '350px', md: 'none' }}
          >
            <Box position="absolute" ref={inViewRef} />
            <video
              // @ts-ignore
              ref={videoRef}
              autoPlay
              loop
              muted
              playsInline
              poster={pAi}
              style={{
                // @ts-ignore
                '-webkit-mask-image': '-webkit-radial-gradient(white, black)',
                // @ts-ignore
                '-webkit-backface-visibility': 'hidden',
                // @ts-ignore
                '-moz-backface-visibility': 'hidden',
              }}
            >
              <source src={vAiMp4} type="video/mp4" />
              <source src={vAiWebm} type="video/webm" />
            </video>
          </Box>
        </Flex>
        <Stack spacing={4} textAlign={{ base: 'center', md: 'left' }}>
          <Text
            fontSize={{ base: 'md', sm: 'lg', md: 'xl' }}
            px={{ base: 4, md: 0 }}
            color={highlightColor}
            fontWeight="bold"
          >
            Plan Courses Fast
          </Text>
          <Heading
            as="h2"
            lineHeight={1.2}
            fontWeight="extrabold"
            fontSize={{ base: 'xl', sm: '2xl', md: '3xl', lg: '4xl' }}
          >
            Transform ideas into courses with{' '}
            <chakra.span color={highlightColor}>personal AI</chakra.span>
          </Heading>
          <Text
            fontSize={{ base: 'md', sm: 'lg', md: 'xl' }}
            px={{ base: 4, md: 0 }}
            color="text.muted"
          >
            Making online courses has never been simpler with our easy
            step-by-step course builder, and uniquely intuitive and personalised
            AI.
          </Text>
          <Stack
            spacing={4}
            pt={4}
            divider={
              <StackDivider
                borderColor={useColorModeValue('gray.100', 'gray.700')}
              />
            }
          >
            <Point
              icon={<MdIcon name="AutoAwesome" color="blue.500" w={5} h={5} />}
              iconBg={useColorModeValue('blue.100', 'blue.900')}
              text="Train AI to reflect your unique voice"
            />

            <Point
              icon={
                <MdIcon name="LowPriority" color="neutral.500" w={5} h={5} />
              }
              iconBg={useColorModeValue('neutral.100', 'neutral.900')}
              text="Easy drag-and-drop course creation"
            />
            <Point
              icon={<MdIcon name="Description" color="green.500" w={5} h={5} />}
              iconBg={useColorModeValue('green.100', 'green.900')}
              text="Upload PDFs and links to your resources"
            />
          </Stack>
        </Stack>
      </SimpleGrid>
    </Container>
  );
};

const Detail3 = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const { ref: inViewRef, inView: videoIsVisible } = useInView({
    fallbackInView: true,
  });
  const [playVideo, setPlayVideo] = useState(false);
  useEffect(() => {
    if (videoIsVisible && !playVideo) {
      setPlayVideo(true);
      if (videoRef.current) {
        if (videoRef.current.readyState < 3) {
          videoRef.current.load();
        } else {
          videoRef.current.play();
        }
      }
    }
    if (!videoIsVisible && playVideo) {
      setPlayVideo(false);
      if (videoRef.current) {
        videoRef.current.pause();
      }
    }
  }, [videoIsVisible]);
  const vClassesMp4 = useColorModeValue(vClassesMp4Light, vClassesMp4Dark);
  const vClassesWebm = useColorModeValue(vClassesWebmLight, vClassesWebmDark);
  const pClasses = useColorModeValue(pClassesLight, pClassesDark);
  const highlightColor = useColorModeValue('purple.500', 'purple.200');
  useEffect(() => {
    if (videoRef.current) {
      // @ts-ignore
      videoRef.current.load();
    }
  }, [vClassesMp4]);
  return (
    <Container maxW="7xl" py={{ base: 10, md: 20 }}>
      <SimpleGrid
        columns={{ base: 1, md: 2 }}
        spacing={{ base: 10, md: 20 }}
        alignItems="center"
        display={{ base: 'flex', md: 'grid' }}
        flexDirection={{ base: 'column', md: 'initial' }}
      >
        <Flex
          flex={1}
          justifyContent="center"
          alignItems="center"
          position="relative"
        >
          <Box
            mt={{ base: -6 }}
            mb={{ base: -2, sm: -3 }}
            mx={{ base: 0, sm: -6 }}
            maxW={{ base: '350px', md: 'none' }}
          >
            <Box position="absolute" ref={inViewRef} />
            <video
              // @ts-ignore
              ref={videoRef}
              autoPlay
              loop
              muted
              playsInline
              poster={pClasses}
              style={{
                // @ts-ignore
                '-webkit-mask-image': '-webkit-radial-gradient(white, black)',
                // @ts-ignore
                '-webkit-backface-visibility': 'hidden',
                // @ts-ignore
                '-moz-backface-visibility': 'hidden',
              }}
            >
              <source src={vClassesMp4} type="video/mp4" />
              <source src={vClassesWebm} type="video/webm" />
            </video>
          </Box>
        </Flex>
        <Stack spacing={4} textAlign={{ base: 'center', md: 'left' }}>
          <Text
            fontSize={{ base: 'md', sm: 'lg', md: 'xl' }}
            px={{ base: 4, md: 0 }}
            color={highlightColor}
            fontWeight="bold"
          >
            Teach Classes Your Way
          </Text>
          <Heading
            as="h2"
            lineHeight={1.2}
            fontWeight="extrabold"
            fontSize={{ base: 'xl', sm: '2xl', md: '3xl', lg: '4xl' }}
          >
            Give learners the best experience with{' '}
            <chakra.span color={highlightColor}>hands-on classes</chakra.span>
          </Heading>
          <Text
            fontSize={{ base: 'md', sm: 'lg', md: 'xl' }}
            px={{ base: 4, md: 0 }}
            color="text.muted"
          >
            Create a collaborative space where learners can post updates in
            private feeds, join discussions, and get feedback or encouragement
            directly from their mentor.
          </Text>
          <Stack
            spacing={4}
            pt={4}
            divider={
              <StackDivider
                borderColor={useColorModeValue('gray.100', 'gray.700')}
              />
            }
          >
            <Point
              icon={
                <Icon as={FaChalkboardTeacher} color="purple.500" w={5} h={5} />
              }
              iconBg={useColorModeValue('purple.100', 'purple.900')}
              text="Recreate the classroom experience online"
            />
            <Point
              icon={<MdIcon name="Groups" color="neutral.500" w={5} h={5} />}
              iconBg={useColorModeValue('neutral.100', 'neutral.900')}
              text="Teach small groups or an entire community"
            />
            <Point
              icon={<Icon as={FaRegHandshake} color="blue.500" w={5} h={5} />}
              iconBg={useColorModeValue('blue.100', 'blue.900')}
              text="Provide an environment for social learning"
            />
          </Stack>
        </Stack>
      </SimpleGrid>
    </Container>
  );
};

interface TestimonialCardProps {
  name: string;
  location: string;
  role: string;
  handle: string;
  title: string;
  content: string;
  personAvatar: string;
  channelAvatar: string;
  index: number;
  colSpan: number;
  showStars?: boolean;
}

const TestimonialCard = (props: TestimonialCardProps) => {
  const {
    name,
    location,
    title,
    content,
    personAvatar,
    channelAvatar,
    handle,
    showStars,
  } = props;
  return (
    <GridItem w="100%" h="100%" alignItems="center">
      <Flex justifyContent="center" w="100%" h="100%" mx="auto">
        <Flex
          justifyContent="center"
          position="relative"
          h="100%"
          _before={{
            content: '""',
            position: 'absolute',
            zIndex: 0,
            height: 'full',
            width: 'full',
            filter: 'blur(40px)',
            transform: 'scale(0.98)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            top: 0,
            left: 0,
            backgroundColor: 'background.primary',
          }}
        >
          <Flex
            boxShadow="lg"
            direction="column"
            rounded="xl"
            py={{ base: 6, md: 6 }}
            px={{ base: 4, xl: 6 }}
            // justifyContent="center"
            position="relative"
            bg={useColorModeValue('white', 'gray.800')}
            zIndex={1}
            h="100%"
            _after={{
              content: '""',
              position: 'absolute',
              height: '21px',
              width: '29px',
              left: '35px',
              top: '-10px',
              backgroundSize: 'cover',
              backgroundImage: `url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='29' height='21' viewBox='0 0 29 21' fill='none'%3E%3Cpath d='M6.91391 21C4.56659 21 2.81678 20.2152 1.66446 18.6455C0.55482 17.0758 0 15.2515 0 13.1727C0 11.2636 0.405445 9.43939 1.21634 7.7C2.0699 5.91818 3.15821 4.3697 4.48124 3.05454C5.84695 1.69697 7.31935 0.678787 8.89845 0L13.3157 3.24545C11.5659 3.96667 9.98676 4.94242 8.57837 6.17273C7.21266 7.36061 6.25239 8.63333 5.69757 9.99091L6.01766 10.1818C6.27373 10.0121 6.55114 9.88485 6.84989 9.8C7.19132 9.71515 7.63944 9.67273 8.19426 9.67273C9.34658 9.67273 10.4776 10.097 11.5872 10.9455C12.7395 11.7939 13.3157 13.1091 13.3157 14.8909C13.3157 16.8848 12.6542 18.4121 11.3311 19.4727C10.0508 20.4909 8.57837 21 6.91391 21ZM22.5982 21C20.2509 21 18.5011 20.2152 17.3488 18.6455C16.2391 17.0758 15.6843 15.2515 15.6843 13.1727C15.6843 11.2636 16.0898 9.43939 16.9007 7.7C17.7542 5.91818 18.8425 4.3697 20.1656 3.05454C21.5313 1.69697 23.0037 0.678787 24.5828 0L29 3.24545C27.2502 3.96667 25.6711 4.94242 24.2627 6.17273C22.897 7.36061 21.9367 8.63333 21.3819 9.99091L21.702 10.1818C21.9581 10.0121 22.2355 9.88485 22.5342 9.8C22.8756 9.71515 23.3238 9.67273 23.8786 9.67273C25.0309 9.67273 26.1619 10.097 27.2715 10.9455C28.4238 11.7939 29 13.1091 29 14.8909C29 16.8848 28.3385 18.4121 27.0155 19.4727C25.7351 20.4909 24.2627 21 22.5982 21Z' fill='%239F7AEA'/%3E%3C/svg%3E")`,
            }}
            textAlign="center"
          >
            <Flex direction="column" alignItems="center">
              <Box position="relative" height="80px" width="80px" mb={4}>
                <Image
                  src={personAvatar}
                  height="80px"
                  width="80px"
                  htmlHeight="80"
                  htmlWidth="80"
                  alignSelf="center"
                  alt={name}
                  borderRadius="full"
                />
                {channelAvatar ? (
                  <Box position="absolute" bottom="-10px" right="-10px">
                    <Image
                      src={channelAvatar}
                      height="40px"
                      width="40px"
                      htmlHeight="40"
                      htmlWidth="40"
                      alignSelf="center"
                      alt={handle}
                      borderRadius="full"
                    />
                  </Box>
                ) : null}
              </Box>
              <Flex direction="column" flex={1}>
                {showStars && (
                  <Flex
                    alignSelf="center"
                    color="common.progress"
                    fontSize="xl"
                    mb={3}
                  >
                    <MdIcon name="StarRate" />
                    <MdIcon name="StarRate" />
                    <MdIcon name="StarRate" />
                    <MdIcon name="StarRate" />
                    <MdIcon name="StarRate" />
                  </Flex>
                )}
                <Flex direction="column" justifyContent="space-between">
                  <Text pb={2} fontWeight="bold">
                    {title}
                  </Text>
                  <Text pb={4} color="text.muted" whiteSpace="break-spaces">
                    {content}
                  </Text>
                  <Text fontWeight="bold" fontSize="md">
                    {name}
                  </Text>
                  <Text color="text.muted" fontSize="sm">
                    {`from ${location}`}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </GridItem>
  );
};

const Detail2 = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const { ref: inViewRef, inView: videoIsVisible } = useInView({
    fallbackInView: true,
  });
  const [playVideo, setPlayVideo] = useState(false);
  useEffect(() => {
    if (videoIsVisible && !playVideo) {
      setPlayVideo(true);
      if (videoRef.current) {
        if (videoRef.current.readyState < 3) {
          videoRef.current.load();
        } else {
          videoRef.current.play();
        }
      }
    }
    if (!videoIsVisible && playVideo) {
      setPlayVideo(false);
      if (videoRef.current) {
        videoRef.current.pause();
      }
    }
  }, [videoIsVisible]);
  const vStepsMp4 = useColorModeValue(vStepsMp4Light, vStepsMp4Dark);
  const vStepsWebm = useColorModeValue(vStepsWebmLight, vStepsWebmDark);
  const pSteps = useColorModeValue(pStepsLight, pStepsDark);
  const highlightColor = useColorModeValue('orange.500', 'orange.200');
  useEffect(() => {
    if (videoRef.current) {
      // @ts-ignore
      videoRef.current.load();
    }
  }, [vStepsMp4]);
  return (
    <Container maxW="7xl" py={{ base: 10, md: 20 }}>
      <SimpleGrid
        columns={{ base: 1, md: 2 }}
        spacing={{ base: 10, md: 20 }}
        alignItems="center"
        display={{ base: 'flex', md: 'grid' }}
        flexDirection={{ base: 'column-reverse', md: 'initial' }}
      >
        <Stack spacing={4} textAlign={{ base: 'center', md: 'left' }}>
          <Text
            fontSize={{ base: 'md', sm: 'lg', md: 'xl' }}
            px={{ base: 4, md: 0 }}
            color={highlightColor}
            fontWeight="bold"
          >
            Produce Content Stress-free
          </Text>
          <Heading
            as="h2"
            lineHeight={1.2}
            fontWeight="extrabold"
            fontSize={{ base: 'xl', sm: '2xl', md: '3xl', lg: '4xl' }}
          >
            Hit record and teach straight from the app,{' '}
            <chakra.span color={highlightColor}>step by step</chakra.span>
          </Heading>
          <Text
            fontSize={{ base: 'md', sm: 'lg', md: 'xl' }}
            px={{ base: 4, md: 0 }}
            color="text.muted"
          >
            Go beyond lectures and guide your learners through step-by-step
            courses made 100% in the Steppit app.
          </Text>
          <Stack
            spacing={4}
            pt={4}
            divider={
              <StackDivider
                borderColor={useColorModeValue('gray.100', 'gray.700')}
              />
            }
          >
            <Point
              icon={<Icon as={IoVideocam} color="red.500" w={5} h={5} />}
              iconBg={useColorModeValue('red.100', 'red.900')}
              text="Film vertical or horizontal videos or just record audio"
            />
            <Point
              icon={<MdIcon name="Subtitles" color="neutral.500" w={5} h={5} />}
              iconBg={useColorModeValue('neutral.100', 'neutral.900')}
              text="Generate scripts and read from the in-app teleprompter"
            />
            <Point
              icon={<Icon as={FaRunning} color="orange.500" w={5} h={5} />}
              iconBg={useColorModeValue('orange.100', 'orange.900')}
              text="Perfect for active learning and microlearning"
            />
          </Stack>
        </Stack>
        <Flex position="relative" justifyContent="center" alignItems="center">
          <Box
            mt={{ base: -6 }}
            mb={{ base: -2, sm: -3 }}
            mx={{ base: 0, sm: -6 }}
            maxW={{ base: '350px', md: 'none' }}
          >
            <Box position="absolute" ref={inViewRef} />
            <video
              // @ts-ignore
              ref={videoRef}
              autoPlay
              loop
              muted
              playsInline
              poster={pSteps}
              style={{
                // @ts-ignore
                '-webkit-mask-image': '-webkit-radial-gradient(white, black)',
                // @ts-ignore
                '-webkit-backface-visibility': 'hidden',
                // @ts-ignore
                '-moz-backface-visibility': 'hidden',
              }}
            >
              <source src={vStepsMp4} type="video/mp4" />
              <source src={vStepsWebm} type="video/webm" />
            </video>
          </Box>
        </Flex>
      </SimpleGrid>
    </Container>
  );
};

const FlowItem = ({
  index,
  colorScheme,
  description,
  title,
}: {
  index: number;
  colorScheme: string;
  description: string;
  title: string;
}) => {
  return (
    <Flex
      my={4}
      padding={16}
      borderRadius="lg"
      backgroundColor={`background.${colorScheme}`}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
    >
      <Icon as={FcIdea} w={20} h={20} mb={4} />
      <Text
        fontSize="3xl"
        fontWeight="bold"
        color={`text.${colorScheme}`}
        mb={4}
      >{`${title}`}</Text>
      <Text color={`text.${colorScheme}`}>{`${description}`}</Text>
    </Flex>
  );
};

const steps = [
  {
    label: 'Create your content',
    icon: () => (
      <Flex
        w="36px"
        h="36px"
        backgroundColor="background.primary"
        borderRadius="full"
        alignItems="center"
        justifyContent="center"
      >
        <MdIcon name={ICONS.course} color="text.primary" />
      </Flex>
    ),
    colorScheme: 'primary',
    title: 'Create your content',
    description:
      'Train your AI and lay out a plan for a course or standalone session, then use your phone or webcam to record a series of steps, with no edit required.',
    image: iCourses,
    imageAlt: 'Simple Course Builder',
  },
  {
    label: 'Launch it your way',
    icon: () => (
      <Flex
        w="36px"
        h="36px"
        backgroundColor="background.success"
        borderRadius="full"
        alignItems="center"
        justifyContent="center"
      >
        <MdIcon name="Sell" color="text.success" />
      </Flex>
    ),
    colorScheme: 'success',
    title: 'Launch it your way',
    description:
      'Set up classes to perfectly suit your needs, then either privately enroll your learners or sell places on your course. Alternatively, you can share direct links to your content or embed standalone sessions on your own website without creating a class.',
    image: iSales,
    imageAlt: 'Integrated Sales Tools',
  },
  {
    label: 'Run your classes',
    icon: () => (
      <Flex
        w="36px"
        h="36px"
        backgroundColor="background.error"
        borderRadius="full"
        alignItems="center"
        justifyContent="center"
      >
        <MdIcon name="GroupWork" color="text.error" />
      </Flex>
    ),
    colorScheme: 'error',
    title: 'Run your classes',
    description:
      "If you decide to run classes, for each one you'll have access to a private feed of posts and a group chat, where you can keep track of progress and offer your support and guidance. You can then award every learner that completes your course with a branded digital certificate.",
    image: iClasses,
    imageAlt: 'Private Classes',
  },
];

const Flow = () => {
  const bg = useColorModeValue('background.tint3', 'background.tint1');
  return (
    <Container maxW="7xl" py={{ base: 10, md: 20 }}>
      <Heading
        as="h2"
        lineHeight={1.2}
        fontWeight="extrabold"
        fontSize={{ base: 'xl', sm: '2xl', md: '3xl', lg: '4xl' }}
        mt={2}
        mb={6}
        zIndex={1}
        textAlign="center"
      >
        How it works
      </Heading>
      {steps.map((step, index) => (
        <Flex
          key={`flow-item-${index}`}
          position="relative"
          px={{ base: 0, sm: 6, md: 0 }}
        >
          <Flex alignSelf="center" flexDirection="column" alignItems="center">
            <Flex
              alignItems="center"
              justifyContent="center"
              borderRadius="full"
              w={10}
              h={10}
              bg="background.success"
              borderWidth={2}
              borderColor="common.progress"
              zIndex={1}
            >
              <Text fontWeight="semibold" color="text.success">
                {index + 1}
              </Text>
            </Flex>
            <Box
              position="absolute"
              top={index === 0 ? '50%' : 0}
              bottom={index === steps.length - 1 ? '50%' : 0}
              w={0.5}
              bg="common.progress"
            />
          </Flex>
          <Flex
            flex={1}
            flexDirection={{ base: 'column-reverse', md: 'row' }}
            bg={{
              base: bg,
              md: 'none',
            }}
            borderRadius="lg"
            ml={{ base: 4, md: 0 }}
            my={{ base: 4, md: 0 }}
            p={{ base: 4, md: 0 }}
            position="relative"
          >
            <Box
              borderTopWidth="12px"
              borderBottomWidth="12px"
              borderRightWidth="12px"
              borderTopColor="transparent"
              borderBottomColor="transparent"
              borderRightColor="background.tint3"
              display={{ base: 'block', md: 'none' }}
              position="absolute"
              left="-12px"
              top="calc(50% - 12px)"
            />
            <Box
              alignSelf="center"
              flex={1}
              pl={{ base: 0, md: 6 }}
              py={4}
              textAlign={{ base: 'center', md: 'left' }}
            >
              <Text
                fontSize={{ base: 'lg', md: 'xl' }}
                fontWeight="bold"
                lineHeight={1}
              >{`Step ${index + 1}`}</Text>
              <Text
                fontWeight="bold"
                fontSize={{ base: 'xl', sm: '2xl', md: '2xl', lg: '3xl' }}
                pb={2}
              >
                {step.title}
              </Text>
              <Text color="text.muted" fontSize={{ base: 'md', md: 'lg' }}>
                {step.description}
              </Text>
            </Box>
            <Flex
              flex={1}
              alignItems="center"
              justifyContent="center"
              pl={{ base: 0, md: 4 }}
              py={6}
              position="relative"
            >
              <Box
                position="absolute"
                w="250px"
                h="180px"
                bg="background.default"
                borderRadius="xl"
                display={{ base: 'none', md: 'block' }}
              />
              <Image
                alt={step.imageAlt}
                src={step.image}
                objectFit="contain"
                maxHeight="200px"
                position="relative"
                htmlHeight="200"
                htmlWidth="310"
              />
            </Flex>
          </Flex>
        </Flex>
      ))}
    </Container>
  );
};

const learnerTestimonials = [
  {
    name: 'Melanie H',
    location: 'Mannheim, Germany',
    role: '',
    handle: '',
    content:
      'I’ve always learnt such a huge amount from each of the courses. They are easy to follow and well presented and the personal feedback you receive from both your mentor and fellow classmates is invaluable.',
    personAvatar: avatar1,
    channelAvatar: '',
    colSpan: 1,
  },
  {
    name: 'Birdy R',
    location: 'London, UK',
    role: '',
    handle: '',
    content:
      'The videos are clear and easy to follow. If you miss something you can rewind or follow the writing instructions. Each step is manageable size and straightforward, it’s really excellent. I highly recommend it.',
    personAvatar: avatar2,
    channelAvatar: '',
    colSpan: 1,
  },
  {
    name: 'Angela S',
    location: 'Kuala Lumpur, Malaysia',
    role: '',
    handle: '',
    content:
      'I learnt a lot of new skills. The courses are structured in such a way that you can fit them around your life. The tutors always answered questions promptly and gave good advice to help me progress.',
    personAvatar: avatar4,
    channelAvatar: '',
    colSpan: 1,
  },
];

const creatorTestimonials = [
  {
    name: 'Phillis S',
    location: 'Nevada, USA',
    role: '',
    handle: '',
    title: 'LOVING this platform!',
    content: `I have worked with numerous LMS systems and coaching platforms, so I know what it takes to set up online courses. I really didn't have any particular expectations for Steppit but I have been looking for a system that is less cumbersome, more intuitive, and quicker to launch my clients' coaching pages and courses. Steppit has integrated everything needed to plan, produce, and launch a course effortlessly.\n\nMy client has also fallen in love with Steppit, and cannot stop thanking me for using it to build her course. Honestly, I just love how much it simplifies my work.`,
    personAvatar: rPhillis,
    channelAvatar: '',
    colSpan: 1,
  },
  {
    name: 'Geoff G',
    location: 'England, UK',
    role: '',
    handle: '',
    title: 'First Class',
    content: `Absolutely blown away by the professionalism inside this product. So many offers are all sales page promises with awful user interfaces, but Steppit has broken the mould here.\n\nI love the micro-learning concept, the step approach, the creation options, the training content and the roadmap. I am totally convinced that this product will change everything for me.`,
    personAvatar: rGeoff,
    channelAvatar: '',
    colSpan: 1,
  },
  {
    name: 'Vanita A',
    location: 'Maryland, USA',
    role: '',
    handle: '',
    title: 'Clean, organized, rich with training and user-friendly',
    content: `I plan to use Steppit to create mini-courses to onboard real estate clients and answer FAQs, as a value add. I also want to automate my business processes and Steppit will be instrumental in educating my clients.\n\nWhat really impressed me was that yesterday I had a meeting with a member of the Steppit team and reviewed everything, including giving my opinion of future enhancements. This speaks to the level of customer service in place.`,
    personAvatar: rVanita,
    channelAvatar: '',
    colSpan: 1,
  },
];

const Testimonials = () => {
  const wsLogoColor = useColorModeValue('#16171D', '#FFFFFF');
  return (
    <Container maxW="7xl" pb={20} pt={{ base: 10, md: 20 }}>
      <Flex flexDirection="column" position="relative">
        <Flex
          pt={{ base: 2, md: 0 }}
          alignItems={{ base: 'center', lg: 'normal' }}
          flexDirection={{ base: 'column', lg: 'row' }}
        >
          <Flex
            flex={1}
            flexDirection="column"
            alignItems={{ base: 'center', lg: 'normal' }}
            textAlign={{ base: 'center', lg: 'left' }}
          >
            <Heading
              as="h2"
              lineHeight={1.2}
              fontWeight="extrabold"
              fontSize={{ base: 'xl', sm: '2xl', md: '3xl', lg: '4xl' }}
              mb={4}
            >
              Trusted by educators worldwide
            </Heading>
            <Stack
              direction={{ base: 'column', sm: 'row' }}
              mb={{ base: 4 }}
              alignSelf={{ base: 'center', lg: 'flex-start' }}
              alignItems="center"
              spacing={{ base: 2, sm: 1, md: 2 }}
            >
              <Flex
                bg="background.success"
                borderRadius="full"
                py={{ base: 1 }}
                px={{ base: 2 }}
                alignItems="center"
              >
                <MdIcon name="School" color="text.success" />
                <Text color="text.success" fontSize={{ base: 'sm' }} pl={1.5}>
                  3,000+ Courses Created
                </Text>
              </Flex>
              <Flex
                bg="background.primary"
                borderRadius="full"
                py={{ base: 1 }}
                px={{ base: 2 }}
                alignItems="center"
              >
                <MdIcon name="Store" color="text.primary" />
                <Text color="text.primary" fontSize={{ base: 'sm' }} pl={1.5}>
                  2,000+ Channels
                </Text>
              </Flex>
            </Stack>
            <Text
              color="text.muted"
              fontSize={{ base: 'md', sm: 'lg', md: 'xl' }}
            >
              Steppit's unique format has been enjoyed by thousands of learners
              and educators around the world. Here’s what some of them have to
              say…
            </Text>
          </Flex>
          <Flex
            mt={{ base: 8, lg: 0 }}
            alignItems="center"
            flexDirection={{ base: 'column', sm: 'row' }}
          >
            <Flex
              ml={{ base: 0, lg: 10 }}
              flexDirection="column"
              alignItems="center"
              textAlign="center"
            >
              <CircularProgress
                value={97}
                size={20}
                mb={1}
                thickness="8px"
                color="common.progress"
                trackColor="background.tint2"
                sx={{
                  '& > div:first-child': {
                    transitionProperty: 'width',
                  },
                }}
                aria-label="Learner Satisfaction"
              >
                <CircularProgressLabel>
                  <Text
                    color="common.progress"
                    fontWeight="extrabold"
                    fontSize="2xl"
                  >
                    97%
                  </Text>
                </CircularProgressLabel>
              </CircularProgress>
              <Text color="text.success" fontWeight="bold">
                Learner satisfaction
              </Text>
              <Text color="text.success" fontSize="sm">
                From 60k+ learner ratings
              </Text>
            </Flex>

            <Flex
              ml={{ base: 0, sm: 6, md: 10 }}
              mt={{ base: 6, sm: 0 }}
              flexDirection="column"
              alignItems="center"
              textAlign="center"
            >
              <CircularProgress
                value={92}
                size={20}
                mb={1}
                thickness="8px"
                color="text.muted"
                trackColor="background.tint2"
                sx={{
                  '& > div:first-child': {
                    transitionProperty: 'width',
                  },
                }}
                aria-label="Customer Support Rating"
              >
                <CircularProgressLabel>
                  <Text
                    color="text.muted"
                    fontWeight="extrabold"
                    fontSize="2xl"
                  >
                    92%
                  </Text>
                </CircularProgressLabel>
              </CircularProgress>
              <Flex alignItems="center">
                <Image
                  alt="Wootric Logo"
                  src={wootricLogo}
                  objectFit="contain"
                  width="104px"
                  height="24px"
                  htmlWidth="104"
                  htmlHeight="24"
                  position="relative"
                />
              </Flex>
              <Text color="text.muted" fontSize="sm">
                Customer support rating
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Box mt={16}>
          <Grid
            templateColumns={{
              base: 'repeat(1, 1fr)',
              md: 'repeat(2, 1fr)',
              lg: 'repeat(3, 1fr)',
            }}
            gap={3}
            mx="auto"
            w="full"
          >
            {creatorTestimonials.map((cardInfo, index) => (
              <Flex
                key={`review-card-${index}`}
                display={{ base: index > 1 ? 'none' : 'flex', lg: 'flex' }}
                height="100%"
              >
                <TestimonialCard {...cardInfo} index={index} showStars />
              </Flex>
            ))}
          </Grid>
        </Box>
      </Flex>
    </Container>
  );
};

const SortedTestimonialFeature = () => {
  return (
    <Container maxW="7xl" pb={20} pt={10}>
      <Box position="relative" pl={{ base: '40px', md: '40px' }}>
        <Flex
          position="absolute"
          bottom={{ base: '-40px', md: '-40px' }}
          left={0}
          zIndex={2}
        >
          <Image
            height="60px"
            width="60px"
            htmlHeight="60"
            htmlWidth="60"
            borderRadius="full"
            bg="background.primary"
            src={sortedReviewImage}
            alt="Sorted Food logo"
          />
        </Flex>
        <Card
          borderRadius="lg"
          boxShadow="none"
          backgroundColor="neutral.900"
          backgroundImage={{ base: 'none', md: sortedReviewBgImage }}
          backgroundSize="cover"
          backgroundPosition="top"
          padding={0}
          mt={4}
          _after={{
            content: '""',
            position: 'absolute',
            height: '42px',
            width: '58px',
            left: '70px',
            top: '-20px',
            backgroundSize: 'cover',
            backgroundImage: `url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='29' height='21' viewBox='0 0 29 21' fill='none'%3E%3Cpath d='M6.91391 21C4.56659 21 2.81678 20.2152 1.66446 18.6455C0.55482 17.0758 0 15.2515 0 13.1727C0 11.2636 0.405445 9.43939 1.21634 7.7C2.0699 5.91818 3.15821 4.3697 4.48124 3.05454C5.84695 1.69697 7.31935 0.678787 8.89845 0L13.3157 3.24545C11.5659 3.96667 9.98676 4.94242 8.57837 6.17273C7.21266 7.36061 6.25239 8.63333 5.69757 9.99091L6.01766 10.1818C6.27373 10.0121 6.55114 9.88485 6.84989 9.8C7.19132 9.71515 7.63944 9.67273 8.19426 9.67273C9.34658 9.67273 10.4776 10.097 11.5872 10.9455C12.7395 11.7939 13.3157 13.1091 13.3157 14.8909C13.3157 16.8848 12.6542 18.4121 11.3311 19.4727C10.0508 20.4909 8.57837 21 6.91391 21ZM22.5982 21C20.2509 21 18.5011 20.2152 17.3488 18.6455C16.2391 17.0758 15.6843 15.2515 15.6843 13.1727C15.6843 11.2636 16.0898 9.43939 16.9007 7.7C17.7542 5.91818 18.8425 4.3697 20.1656 3.05454C21.5313 1.69697 23.0037 0.678787 24.5828 0L29 3.24545C27.2502 3.96667 25.6711 4.94242 24.2627 6.17273C22.897 7.36061 21.9367 8.63333 21.3819 9.99091L21.702 10.1818C21.9581 10.0121 22.2355 9.88485 22.5342 9.8C22.8756 9.71515 23.3238 9.67273 23.8786 9.67273C25.0309 9.67273 26.1619 10.097 27.2715 10.9455C28.4238 11.7939 29 13.1091 29 14.8909C29 16.8848 28.3385 18.4121 27.0155 19.4727C25.7351 20.4909 24.2627 21 22.5982 21Z' fill='%2372E9B7'/%3E%3C/svg%3E")`,
          }}
        >
          <Flex flex={1} position="relative">
            <Flex
              position="absolute"
              bg="#000"
              opacity="0.5"
              top={0}
              right={0}
              bottom={0}
              left={0}
              display={{ base: 'none', md: 'flex', lg: 'none' }}
            />
            <Flex flexDirection="column" flex={1} position="relative">
              <Image
                src={sortedReviewBgImage}
                w="100%"
                h="200px"
                htmlHeight="200"
                htmlWidth="524"
                objectFit="cover"
                objectPosition="right"
                display={{ base: 'block', md: 'none' }}
                alt="Sorted Food team"
              />
              <Text
                color="#fff"
                fontSize={{ base: 'md', sm: 'lg', xl: 'xl', '2xl': '2xl' }}
                flex={1}
                padding={{ base: 6, md: 8, lg: 12 }}
              >
                Steppit lets us offer a whole new type of digital experience for
                our community. Learning by doing has never been easier, and the
                sense of togetherness it creates is amazing 👌 It’s been a
                game-changer for us, and we’re excited to keep using it!
              </Text>
            </Flex>
            <Flex flex={{ base: 0, md: 0.5, lg: 1 }} />
          </Flex>
        </Card>
        <Flex
          fontSize="sm"
          color="text.muted"
          position="absolute"
          right={0}
          left="70px"
          pt={2}
        >
          <Text py={1}>
            Barry Taylor, Co-Founder{' '}
            <chakra.a
              fontWeight="semibold"
              color="common.primary"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.steppit.com/resources/blog/sorted-food-from-youtube-foodies-to-online-teachers/"
            >
              @sortedfood
            </chakra.a>
          </Text>
        </Flex>
      </Box>
    </Container>
  );
};

const JamCodingTestimonialFeature = () => {
  return (
    <Container maxW="7xl" pb={20} pt={10}>
      <Box position="relative" pl={{ base: '40px', md: '40px' }}>
        <Flex
          position="absolute"
          bottom={{ base: '-40px', md: '-40px' }}
          left={0}
          zIndex={2}
        >
          <Image
            height="60px"
            width="60px"
            htmlHeight="60"
            htmlWidth="60"
            borderRadius="full"
            bg="background.primary"
            src={jamCodingReviewImage}
            alt="Jam Coding logo"
          />
        </Flex>
        <Card
          borderRadius="lg"
          boxShadow="none"
          backgroundColor="#4e344f"
          backgroundImage={{ base: 'none', md: jamCodingReviewBgImage }}
          backgroundSize="cover"
          backgroundPosition="top"
          padding={0}
          mt={4}
          _after={{
            content: '""',
            position: 'absolute',
            height: '42px',
            width: '58px',
            left: '70px',
            top: '-20px',
            backgroundSize: 'cover',
            backgroundImage: `url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='29' height='21' viewBox='0 0 29 21' fill='none'%3E%3Cpath d='M6.91391 21C4.56659 21 2.81678 20.2152 1.66446 18.6455C0.55482 17.0758 0 15.2515 0 13.1727C0 11.2636 0.405445 9.43939 1.21634 7.7C2.0699 5.91818 3.15821 4.3697 4.48124 3.05454C5.84695 1.69697 7.31935 0.678787 8.89845 0L13.3157 3.24545C11.5659 3.96667 9.98676 4.94242 8.57837 6.17273C7.21266 7.36061 6.25239 8.63333 5.69757 9.99091L6.01766 10.1818C6.27373 10.0121 6.55114 9.88485 6.84989 9.8C7.19132 9.71515 7.63944 9.67273 8.19426 9.67273C9.34658 9.67273 10.4776 10.097 11.5872 10.9455C12.7395 11.7939 13.3157 13.1091 13.3157 14.8909C13.3157 16.8848 12.6542 18.4121 11.3311 19.4727C10.0508 20.4909 8.57837 21 6.91391 21ZM22.5982 21C20.2509 21 18.5011 20.2152 17.3488 18.6455C16.2391 17.0758 15.6843 15.2515 15.6843 13.1727C15.6843 11.2636 16.0898 9.43939 16.9007 7.7C17.7542 5.91818 18.8425 4.3697 20.1656 3.05454C21.5313 1.69697 23.0037 0.678787 24.5828 0L29 3.24545C27.2502 3.96667 25.6711 4.94242 24.2627 6.17273C22.897 7.36061 21.9367 8.63333 21.3819 9.99091L21.702 10.1818C21.9581 10.0121 22.2355 9.88485 22.5342 9.8C22.8756 9.71515 23.3238 9.67273 23.8786 9.67273C25.0309 9.67273 26.1619 10.097 27.2715 10.9455C28.4238 11.7939 29 13.1091 29 14.8909C29 16.8848 28.3385 18.4121 27.0155 19.4727C25.7351 20.4909 24.2627 21 22.5982 21Z' fill='%2372E9B7'/%3E%3C/svg%3E")`,
          }}
        >
          <Flex flex={1} position="relative">
            <Flex
              position="absolute"
              bg="#4e344f"
              opacity="0.6"
              top={0}
              right={0}
              bottom={0}
              left={0}
              display={{ base: 'none', md: 'flex', lg: 'none' }}
            />
            <Flex flexDirection="column" flex={1} position="relative">
              <Image
                src={jamCodingReviewBgImage}
                w="100%"
                h="200px"
                htmlHeight="200"
                htmlWidth="524"
                objectFit="cover"
                objectPosition="right"
                display={{ base: 'block', md: 'none' }}
                alt="Jam Coding"
              />
              <Text
                color="#fff"
                fontSize={{ base: 'md', sm: 'lg', xl: 'xl', '2xl': '2xl' }}
                flex={1}
                padding={{ base: 6, md: 8, lg: 12 }}
              >
                I've been searching a long time for a solution like Steppit,
                it's the answer I needed! It's perfect for delivering our
                primary age computing workshops online with its step by step
                format, and we're now easily able to roll out online clubs wider
                across the UK and to the homeschool community 🙌
              </Text>
            </Flex>
            <Flex flex={{ base: 0, md: 0.5, lg: 1 }} />
          </Flex>
        </Card>
        <Flex
          fontSize="sm"
          color="text.muted"
          position="absolute"
          right={0}
          left="70px"
          pt={2}
        >
          <Text py={1}>
            Katie-jo Gracie, Regional Director{' '}
            <chakra.a
              fontWeight="semibold"
              color="common.primary"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.steppit.com/@jamcodinghomeedition"
            >
              @jamcodinghomeedition
            </chakra.a>
          </Text>
        </Flex>
      </Box>
    </Container>
  );
};

const Carousel = () => {
  const arrowStyles: BoxProps = {
    cursor: 'pointer',
    position: 'absolute',
    top: { base: 'auto', md: '50%' },
    bottom: { base: '0', md: 'auto' },
    w: 'auto',
    mt: { base: 'auto', md: '-28px' },
    mb: { base: '-12px', md: 'auto' },
    p: '16px',
    color: 'text.primary',
    fontSize: '24px',
    transition: '0.3s ease',
    borderRadius: 'full',
    userSelect: 'none',
    _hover: {
      opacity: 0.8,
      bg: 'background.primary',
    },
  };
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sessionPopupSlug, setSessionPopupSlug] = useState('');

  const history = useHistory();
  const location = useLocation();

  const slidesCount = POPUP_SESSIONS.length;

  const prevSlide = () => {
    setCurrentSlide((s) => (s === 0 ? slidesCount - 1 : s - 1));
  };

  const nextSlide = () => {
    setCurrentSlide((s) => (s === slidesCount - 1 ? 0 : s + 1));
  };

  const setSlide = (slide: number) => {
    setCurrentSlide(slide);
  };

  const carouselStyle = {
    transition: 'all .5s',
    ml: `-${currentSlide * 100}%`,
  };
  return (
    <Flex m={-3} pb={6} overflow="hidden">
      <Flex w="full" pos="relative">
        <Flex w="full" {...carouselStyle}>
          {POPUP_SESSIONS.map((slide, sid) => (
            <Flex
              key={`slide-${sid}`}
              boxSize="full"
              flex="none"
              py={20}
              opacity={currentSlide === sid ? 1 : 0}
              transition="opacity 0.25s ease"
            >
              <Flex
                boxSize="full"
                maxWidth={{ base: '300px', md: '550px' }}
                minHeight={{ base: '400px', md: 'auto' }}
                mx="auto"
                justifyContent="center"
                position="relative"
                _before={{
                  content: '""',
                  position: 'absolute',
                  zIndex: 0,
                  height: 'full',
                  width: 'full',
                  filter: 'blur(40px)',
                  transform: 'scale(0.98)',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  top: 0,
                  left: 0,
                  backgroundImage: backgrounds[sid % 4],
                }}
              >
                <Card
                  padding={4}
                  paddingTop={{ base: 6, md: 4 }}
                  paddingBottom={{ base: 6, md: 4 }}
                  h="100%"
                  w="90%"
                  mx="auto"
                  position={{ base: 'absolute', md: 'relative' }}
                  borderRadius="xl"
                  boxShadow="lg"
                  alignItems="center"
                  zIndex={1}
                >
                  <Stack
                    width="100%"
                    direction={{ base: 'column', md: 'row' }}
                    alignItems="center"
                    gap={4}
                  >
                    <Image
                      src={slide.image}
                      alt={slide.alt}
                      backgroundSize="cover"
                      borderRadius={{ base: 'full', md: 'lg' }}
                      width={{ base: '175px', sm: '200px' }}
                      height={{ base: '175px', sm: '200px', md: '300px' }}
                      htmlWidth="200"
                      htmlHeight="300"
                      objectFit="cover"
                    />
                    <Flex
                      flexDirection="column"
                      textAlign={{ base: 'center', md: 'left' }}
                      alignItems={{ base: 'center', md: 'flex-start' }}
                    >
                      <Text
                        fontSize="2xl"
                        fontWeight="bold"
                        lineHeight={1.2}
                        mb={0.5}
                      >
                        {slide.label}
                      </Text>
                      <Text
                        mb={4}
                        fontSize="lg"
                      >{`with ${slide.creator}`}</Text>
                      <Button
                        onClick={() => setSessionPopupSlug(slide.courseSlug)}
                        variant="outline"
                      >
                        Watch Session
                      </Button>
                    </Flex>
                  </Stack>
                </Card>
              </Flex>
            </Flex>
          ))}
        </Flex>
        <Box {...arrowStyles} left="0" onClick={prevSlide} zIndex={1}>
          <MdIcon name="ArrowBack" />
        </Box>
        <Box {...arrowStyles} right="0" onClick={nextSlide} zIndex={1}>
          <MdIcon name="ArrowForward" />
        </Box>
        <Stack
          direction="row"
          justify="center"
          pos="absolute"
          bottom="8px"
          w="full"
        >
          {Array.from({
            length: slidesCount,
          }).map((_, slide) => (
            <Box
              key={`dots-${slide}`}
              cursor="pointer"
              height={3}
              width={currentSlide === slide ? 16 : 3}
              m="0 2px"
              bg={currentSlide < slide ? 'background.tint2' : 'common.progress'}
              borderRadius="full"
              display="inline-block"
              transition="all 0.3s ease"
              _hover={{
                bg: 'text.success',
              }}
              onClick={() => setSlide(slide)}
            ></Box>
          ))}
        </Stack>
      </Flex>
      <SessionModal
        isOpen={!!sessionPopupSlug}
        onClose={() => {
          setSessionPopupSlug('');
          history.replace(location.pathname);
        }}
        courseSlug={sessionPopupSlug}
        title={
          POPUP_SESSIONS.find((s) => s.courseSlug === sessionPopupSlug)
            ?.label || ''
        }
        moduleId={
          POPUP_SESSIONS.find((s) => s.courseSlug === sessionPopupSlug)
            ?.moduleId || undefined
        }
        link={
          POPUP_SESSIONS.find((s) => s.courseSlug === sessionPopupSlug)?.link ||
          undefined
        }
      />
    </Flex>
  );
};

const Examples = () => {
  return (
    <Container maxW="3xl">
      <Flex
        textAlign="center"
        pt={{ base: 10, md: 16 }}
        justifyContent="center"
        direction="column"
        width="full"
        pb={16}
      >
        <Heading
          as="h2"
          lineHeight={1.2}
          fontWeight="extrabold"
          fontSize={{ base: 'xl', sm: '2xl', md: '3xl', lg: '4xl' }}
          mb={6}
          zIndex={1}
        >
          See Steppit in action
        </Heading>
        <Text
          fontSize={{ base: 'md', sm: 'lg', md: 'xl' }}
          px={{ base: 4, md: 0 }}
          mb={6}
          color="text.muted"
        >
          By building your courses around step-by-step sessions, the
          possibilities for engaging content are endless. Here's a taste of the
          kinds of sessions creators are making on Steppit...
        </Text>
        <Carousel />
      </Flex>
    </Container>
  );
};

const PricingFeature = ({
  infoOnClick,
  tooltip,
  children,
}: {
  infoOnClick?: () => void;
  tooltip?: string;
  children: React.ReactElement | string;
}) => {
  return (
    <Flex alignItems="center" mt={2}>
      <Flex shrink={0}>
        <MdIcon name="TaskAlt" color="common.progress" />
      </Flex>
      <Box ml={4}>
        <chakra.span>{children}</chakra.span>
      </Box>
      {tooltip ? (
        // @ts-ignore
        <IconTooltip tooltip={tooltip} color="text.muted" />
      ) : infoOnClick ? (
        <Box
          ml={1}
          mb={2}
          onClick={infoOnClick}
          cursor="pointer"
          _hover={{ opacity: 0.8 }}
        >
          <MdIcon name="HelpOutline" boxSize={3.5} color="text.muted" />
        </Box>
      ) : null}
    </Flex>
  );
};

const Pricing = () => {
  const [showPricing, setShowPricing] = useState(false);
  const proColor = useColorModeValue('orange.550', 'orange.200');
  const learnMoreColor = useColorModeValue('blue', 'green');
  const geoLocation = useSelector(
    (state: GlobalState) => state.background.location
  );
  const location = useLocation();
  const popupParam = getParamFromUrl(location, 'p');
  const discountIsValid = true;

  const discountBgColor = useColorModeValue('neutral.900', 'neutral.100');
  const discountColor = useColorModeValue('#fff', '#000');

  useEffect(() => {
    // @ts-ignore
    if (popupParam === 'pricing' && window?.location) {
      // @ts-ignore
      window.location.replace(pricingUrl);
    }
  }, [popupParam]);

  const options = [
    {
      bgColor: 'orange.100',
      mainColor: 'orange.600',
      logoBg: '#fff',
      logo: (
        <SteppitProLogo
          color="#d36716"
          // color="#ec7f2e"
        />
      ),
      title: 'Steppit Pro',
      description:
        'empowers you with simple, easy tools to create step-by-step courses tailored to your students.',
      primaryCtaLabel: 'Get a Free Trial',
      primaryCtaLink: trialUrl,
      primaryHoverColor: 'orange.700',
      secondaryCtaLabel: 'Learn More',
      secondaryCtaLink: pricingUrl,
      secondaryHoverColor: 'orange.50',
      secondaryClickColor: 'orange.200',
    },
    {
      bgColor: 'purple.100',
      mainColor: 'purple.600',
      logoBg: 'purple.800',
      logo: (
        <SteppitSelectLogo
          color="#6334CB"
          // color="#7347d1"
        />
      ),
      title: 'Steppit Select',
      description:
        'is where we work with you deliver an end-to-end solution with expert guidance and support.',
      primaryCtaLabel: 'Book an Intro',
      primaryCtaLink: meetingUrl,
      primaryHoverColor: 'purple.700',
      secondaryCtaLabel: 'Learn More',
      secondaryCtaLink: pricingUrl,
      secondaryHoverColor: 'purple.50',
      secondaryClickColor: 'purple.200',
    },
  ];

  return (
    <Container maxW="4xl">
      <Stack
        as={Box}
        textAlign="center"
        spacing={{ base: 8, md: 10 }}
        py={{ base: 16, md: 24 }}
        maxWidth={{ base: 500, md: 'none' }}
        mx="auto"
      >
        <Heading
          as="h2"
          fontWeight="extrabold"
          fontSize={{ base: 'xl', sm: '2xl', md: '3xl', lg: '4xl' }}
          lineHeight={1.2}
        >
          Plans tailored to your needs
        </Heading>

        <Text
          pb={4}
          color="text.muted"
          fontSize={{ base: 'md', sm: 'lg', md: 'xl' }}
        >
          Whether you want to build everything yourself with a Pro channel, or
          get extensive support from our team with a Select package, Steppit is
          sure to work for your needs.
        </Text>

        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={6}
          px={{ base: 4, sm: 6, md: 0 }}
          alignItems={{ base: 'center', md: 'inherit' }}
          textAlign="left"
        >
          {options.map((o) => (
            <Flex
              borderRadius="lg"
              py={8}
              bg={o.bgColor}
              color={o.mainColor}
              maxWidth="500px"
              boxShadow="xl"
              flex={1}
              flexDirection="column"
            >
              <Flex
                // bg={o.logoBg}
                // borderLeftRadius="full"
                // py={o.logoPy}
                // px={o.logoPy}
                h={24}
                // mb={6}
                // ml={{ base: 6, md: 8 }}
                alignItems="center"
                // justifyContent="center"
                mb={4}
                px={{ base: 6, md: 8 }}
              >
                {o.logo}
              </Flex>
              <Flex px={{ base: 6, md: 8 }} flexDirection="column" flex={1}>
                {/* <Heading
                  as="h2"
                  lineHeight={1.2}
                  fontWeight="extrabold"
                  fontSize={{
                    base: '2xl',
                  }}
                  mb={3}
                  maxWidth="350px"
                >
                  {o.title}
                </Heading> */}
                <Text
                  mb={8}
                  flex={1}
                  fontSize={{ base: 'lg', md: 'xl' }}
                  fontWeight="semibold"
                >
                  <chakra.span fontWeight="extrabold">{o.title}</chakra.span>
                  {` ${o.description}`}
                </Text>
                <Stack
                  direction={{
                    base: 'column',
                    sm: 'row',
                    md: 'column',
                    lg: 'row',
                  }}
                  alignItems="flex-start"
                >
                  <LinkButton
                    href={o.primaryCtaLink}
                    bg={o.mainColor}
                    color="#fff"
                    _hover={{
                      bg: o.primaryHoverColor,
                    }}
                    _active={{
                      bg: o.mainColor,
                    }}
                  >
                    {o.primaryCtaLabel}
                  </LinkButton>
                  <LinkButton
                    variant="outline"
                    href={o.secondaryCtaLink}
                    color={o.mainColor}
                    borderColor={o.mainColor}
                    _hover={{
                      bg: o.secondaryHoverColor,
                    }}
                    _active={{
                      bg: o.secondaryClickColor,
                    }}
                  >
                    {o.secondaryCtaLabel}
                  </LinkButton>
                </Stack>
              </Flex>
            </Flex>
          ))}
        </Stack>

        {/* {discountIsValid && (discountLabel || discount) ? (
          <Box>
            {discountLabel ? (
              <Text
                fontWeight="bold"
                fontSize="sm"
                textTransform="uppercase"
                color="text.success"
                textAlign="center"
                mb={2}
                mt={-2}
                // mt={{ base: 8, md: 12 }}
              >
                {discountLabel}
              </Text>
            ) : null}
            {discount ? (
              <Flex
                bg="background.success"
                // bg={discountBgColor}
                py={3}
                px={4}
                borderRadius={{ base: 'lg', lg: 'full' }}
                textAlign={{ base: 'center' }}
                alignItems="center"
                justifyContent="center"
                color="text.success"
                // color={discountColor}
                flexDirection={{ base: 'column', lg: 'row' }}
              >
                <Text fontWeight="bold">{discount}</Text>
              </Flex>
            ) : null}
          </Box>
        ) : null}
        <Box textAlign="left">
          <PricingTable simple proTrialAvailable={true} />
        </Box> */}
      </Stack>
    </Container>
  );
};

const CallToAction = () => {
  const dispatch = useDispatch();
  return (
    <Container maxW="4xl">
      <Stack
        as={Box}
        textAlign="center"
        spacing={{ base: 6, md: 8 }}
        py={{ base: 16, md: 36 }}
        maxWidth={{ base: 500, md: 'none' }}
        mx="auto"
      >
        <Heading
          fontWeight="extrabold"
          fontSize={{ base: '2xl', sm: '4xl', md: '5xl' }}
          lineHeight={1.2}
        >
          Take{' '}
          <Text as="span" color="common.primary">
            your first step
          </Text>{' '}
          today
        </Heading>
        <Text
          color="text.muted"
          fontSize={{ base: 'md', sm: 'lg', md: 'xl' }}
          maxWidth={{ base: '', md: '650px' }}
          alignSelf="center"
          whiteSpace="break-spaces"
          pb={6}
        >
          {`Transform your teaching, one step at a time.\nGet started for free.`}
        </Text>
        <SimpleGrid
          columns={{ base: 1 }}
          spacing={3}
          align="center"
          alignSelf="center"
          position="relative"
        >
          <LinkButton
            rounded="full"
            px={6}
            size="lg"
            icon="ArrowForward"
            iconPosition="right"
            href={trialUrl}
            onClick={() => analytics.logConversion(dispatch, 'openedContact')}
          >
            Get a Free Trial
          </LinkButton>
          {/* <LinkButton
            rounded="full"
            px={6}
            size="lg"
            icon="ArrowForward"
            iconPosition="right"
            variant="outline"
            to={navRoutes.global.planner.path()}
          >
            Plan Your First Course
          </LinkButton> */}
          <LinkButton
            size="lg"
            px={6}
            icon="Event"
            href={meetingUrl}
            variant="outline"
            onClick={() => analytics.logConversion(dispatch, 'openedContact')}
          >
            Book an Intro
          </LinkButton>
        </SimpleGrid>
      </Stack>
    </Container>
  );
};

const Solutions = () => {
  return (
    <Container maxW="7xl" pt={10} mb={16}>
      <Heading
        as="h2"
        lineHeight={1.2}
        fontWeight="extrabold"
        fontSize={{ base: 'xl', sm: '2xl', md: '3xl', lg: '4xl' }}
        mt={10}
        mb={6}
        zIndex={1}
        textAlign="center"
      >
        Solutions built around you
      </Heading>
      <Grid
        templateColumns={{
          base: 'repeat(1, 1fr)',
          sm: 'repeat(6, 1fr)',
          md: 'repeat(12, 1fr)',
          // xl: 'repeat(12, 1fr)',
        }}
        gap={{ base: 4 }}
        maxW={{ base: '500px', md: '800px', xl: 'none' }}
        mx="auto"
      >
        {SOLUTIONS.map((s, idx) => (
          <GridItem
            key={`solution-${s.title}`}
            textAlign="center"
            colSpan={{
              base: 1,
              sm: idx < 4 ? 3 : 2,
              md: idx < 4 ? 3 : 4,
            }}
            ml={{
              base: 0,
              // sm: idx === 4 ? 'calc(50% + 0.5rem)' : 0,
              // md: idx === 3 || idx === 4 ? 'calc(50% + 0.5rem)' : 0,
              md: idx === 6 ? '-33%' : 0,
              lg: idx === 6 ? '-50%' : 0,
            }}
            mr={{
              base: 0,
              // sm: idx === 4 ? 'calc(-50% - 0.5rem)' : 0,
              // md: idx === 3 || idx === 4 ? 'calc(-50% - 0.5rem)' : 0,
              // xl: 0,
              md: idx === 4 ? '-33%' : 0,
              lg: idx === 4 ? '-50%' : 0,
            }}
            _hover={{ transform: 'scale(1.05)' }}
            transition="transform 0.3s"
          >
            <LinkBox>
              <LinkOverlay href={s.link} aria-label={`Steppit ${s.title}`}>
                <Image
                  height={{
                    base: s.small ? '120px' : '180px',
                    md: s.small ? '120px' : '200px',
                    // xl: s.small ? '120px' : '160px',
                  }}
                  width={{
                    base: s.small ? '120px' : '180px',
                    md: s.small ? '120px' : '200px',
                    // xl: s.small ? '120px' : '160px',
                  }}
                  htmlHeight="200"
                  htmlWidth="200"
                  src={s.image}
                  alt={`Illustration ${s.title}`}
                  mx="auto"
                  objectFit="contain"
                />
                <Text
                  fontWeight="bold"
                  fontSize={{ base: 'md', sm: s.small ? 'sm' : 'md', md: 'md' }}
                >
                  {s.title}
                </Text>
                <Text color="text.muted" fontSize="sm" mb={4}>
                  {s.description}
                </Text>
                {/* <LinkButton variant="outline" href={s.link} size="sm">
              Learn More
            </LinkButton> */}
              </LinkOverlay>
            </LinkBox>
          </GridItem>
        ))}
      </Grid>
    </Container>
  );
};

const Creds = () => (
  <Container maxW="7xl">
    {/* TODO: Replace with grid */}
    {/* <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      spacing={12}
      flexWrap="wrap"
      // opacity={0.9}
    > */}
    <SimpleGrid
      columns={{ base: 1, sm: 3, md: 4, lg: 5 }}
      spacing={{ base: 6, md: 6 }}
      maxWidth={{ base: '500px', md: '950px' }}
      mx="auto"
      alignItems="center"
      justifyContent="center"
      my={{ base: 8, md: 10 }}
    >
      <Image
        objectFit="contain"
        width="100%"
        height="60px"
        // width="60px"
        htmlHeight="60"
        // htmlWidth="60"
        src={rAotd}
        alt="Apple App Store: App of the Day"
        display={{ base: 'none', md: 'block' }}
        _dark={{
          filter: 'brightness(2) saturate(0.4)',
        }}
      />
      <Image
        objectFit="contain"
        width="100%"
        height="45px"
        // width="60px"
        htmlHeight="45"
        // htmlWidth="60"
        src={rLs}
        alt="Learner Satisfaction: 4.9/5"
        _dark={{
          filter: 'brightness(2) saturate(0.4)',
        }}
      />
      <Image
        objectFit="contain"
        width="100%"
        height="60px"
        // width="60px"
        htmlHeight="60"
        // htmlWidth="60"
        src={rNawl}
        alt="Apple App Store: New Apps We Love"
        _dark={{
          filter: 'brightness(2) saturate(0.4)',
        }}
      />
      <Image
        objectFit="contain"
        width="100%"
        height="60px"
        maxWidth="240px"
        mx="auto"
        // width="60px"
        htmlHeight="60"
        // htmlWidth="60"
        src={rEducate}
        alt="Participant in Educate by UCL and ERDF"
        _dark={{
          filter: 'brightness(2) saturate(0.4)',
        }}
      />
      <Image
        objectFit="contain"
        width="100%"
        height="45px"
        // width="60px"
        htmlHeight="45"
        // htmlWidth="60"
        src={rCs}
        alt="Customer Support: 4.6/5"
        display={{ base: 'none', lg: 'block' }}
        _dark={{
          filter: 'brightness(2) saturate(0.4)',
        }}
      />
    </SimpleGrid>
    {/* </Stack> */}
  </Container>
);

const About = () => {
  return (
    <Container maxW="7xl" py={{ base: 10, md: 20 }}>
      <Box
        borderRadius="lg"
        overflow="hidden"
        mb={16}
        display={{ base: 'none', md: 'block' }}
        maxWidth="750px"
        mx="auto"
      >
        <Image
          src={pChannel}
          htmlHeight="454"
          htmlWidth="750"
          bg="background.tint2"
          alt="Example Content"
          width="100%"
        />
      </Box>
      <Box
        borderRadius="lg"
        overflow="hidden"
        mb={{ base: 12, md: 16, lg: 20 }}
        display={{ base: 'block', md: 'none' }}
        maxWidth="400px"
        mx="auto"
      >
        <Image
          src={pChannelMob}
          htmlHeight="368"
          htmlWidth="400"
          bg="background.tint2"
          alt="Example Content"
          width="100%"
        />
      </Box>

      <Heading
        as="h1"
        lineHeight={1.2}
        fontWeight="extrabold"
        fontSize={{ base: 'xl', sm: '2xl', md: '3xl', lg: '4xl' }}
        mb={12}
        maxW={{ base: '400px', md: '500px', lg: '600px' }}
        mx="auto"
        textAlign="center"
      >
        Easily create & run{' '}
        <chakra.span color="common.primary">better online courses</chakra.span>{' '}
        with Steppit
      </Heading>

      <Box
        maxW={{ base: '400px', md: '800px' }}
        mx="auto"
        textAlign="center"
        fontSize={{ base: 'lg', sm: 'md', md: 'lg' }}
        mb={{ base: 12, md: 16, lg: 20 }}
      >
        <Text mb={4}>
          In a perfect world, you would provide <chakra.b>one-to-one</chakra.b>{' '}
          teaching to every learner.
        </Text>
        <Text mb={4}>
          But online, giving everyone a great learning experience often feels
          impossible—leaving courses either <chakra.b>ineffective</chakra.b> or{' '}
          <chakra.b>overwhelming</chakra.b> to make.
        </Text>
        <Text mb={12} fontWeight="bold">
          At Steppit, we believe you shouldn’t have to choose between quality
          and scale.
        </Text>
        <Flex
          textAlign="center"
          bg="background.success"
          color="text.success"
          borderRadius="lg"
          alignItems="center"
          p={{ base: 4, md: 6 }}
          mx={{ base: 4, md: 0 }}
          alignSelf="center"
          mb={12}
        >
          <Text fontSize={{ base: 'md', md: 'lg' }}>
            By combining the right tech with the benefits of one-to-one
            teaching, Steppit unlocks a new online learning format that is{' '}
            <chakra.b>more engaging</chakra.b>,{' '}
            <chakra.b>more effective</chakra.b> and{' '}
            <chakra.b>easier to produce</chakra.b> than any other.
          </Text>
        </Flex>
      </Box>

      <SimpleGrid
        columns={{ base: 1, md: 3 }}
        spacing={{ base: 6, md: 10 }}
        maxWidth={{ base: '300px', md: 'none' }}
        mx="auto"
      >
        <Feature
          icon={<Icon as={FcCollaboration} w={10} h={10} />}
          title="Create with Support"
          text={
            "With our expert team's support and easy-to-use tools, Steppit makes creating online courses simple and stress-free."
          }
        />
        <Feature
          icon={<Icon as={FcIdea} w={10} h={10} />}
          title="Designed for Learners"
          text={
            'Give your learners the best online experience with self-paced, micro-learning sessions and cohort-based classes.'
          }
        />
        <Feature
          icon={<Icon as={FcAreaChart} w={10} h={10} />}
          title="Built to Scale"
          text={
            'Grow your teaching provision, streamline your processes, and reach more learners without compromising on quality.'
          }
        />
      </SimpleGrid>
    </Container>
  );
};

const LandingScreen: React.FC<LandingScreenProps> = ({}) => {
  const bg = useColorModeValue('background.default', 'background.tint3');
  const discountIsValid = true;
  const dispatch = useDispatch();

  const history = useHistory();
  if (PLATFORM !== 'steppit') {
    history.push(navRoutes.public.login.path());
    return null;
  }

  return (
    <Box bg={bg}>
      <Flex
        as="nav"
        role="navigation"
        position="fixed"
        top={0}
        left={0}
        padding={{ base: 0, md: 0.5 }}
        zIndex={1100}
        width="100%"
        backgroundColor={bg}
        aria-label="Main Navigation"
      >
        <LinkBox padding={3} zIndex={1101}>
          <LinkOverlay href="/" aria-label="Steppit Homepage">
            <Brand navbarTransparent={false} />
          </LinkOverlay>
        </LinkBox>
        <AppHeader topPosition={0} onBg />
      </Flex>
      <Box id="steppit-newsletter" />
      <ScreenWrapper>
        <Hero />
        <Box>
          <Box px={4} py={1} display={{ base: 'block', sm: 'none' }} w="100%">
            <LinkButton
              px={6}
              py={5}
              icon="Event"
              href={meetingUrl}
              variant="outline"
              onClick={() => analytics.logConversion(dispatch, 'openedContact')}
              w="100%"
            >
              Book an Intro
            </LinkButton>
          </Box>
          <Box
            position="sticky"
            top={NAV_HEIGHT}
            w="100%"
            py={2}
            px={4}
            bg={bg}
            zIndex={100}
            display={{ base: 'block', md: 'none' }}
            maxW="450px"
            mx="auto"
          >
            <SimpleGrid columns={{ base: 1, sm: 2 }} spacing={2} w="100%">
              <LinkButton
                px={6}
                py={5}
                icon="Event"
                href={meetingUrl}
                variant="outline"
                display={{ base: 'none', sm: 'flex' }}
                onClick={() =>
                  analytics.logConversion(dispatch, 'openedContact')
                }
              >
                Book an Intro
              </LinkButton>
              <LinkButton
                px={6}
                py={5}
                icon="ArrowForward"
                iconPosition="right"
                href={trialUrl}
                onClick={() =>
                  analytics.logConversion(dispatch, 'openedContact')
                }
              >
                Get a Free Trial
              </LinkButton>
            </SimpleGrid>
          </Box>
          <Box pb={6} display={{ base: 'block', md: 'none' }} />
          {/* <Container maxW="7xl" pt={{ base: 8, md: 0 }}>
            {discountIsValid && discountLabel ? (
              <Text
                fontWeight="bold"
                fontSize="sm"
                textTransform="uppercase"
                color="text.success"
                textAlign="center"
                mb={2}
                mt={{ base: 0, md: -6 }}
                // display={{ base: 'none', md: 'block' }}
              >
                {discountLabel}
              </Text>
            ) : null}
            {discountIsValid && discount ? (
              <Flex
                bg="background.success"
                py={3}
                px={4}
                borderRadius={{ base: 'lg', lg: 'full' }}
                textAlign={{ base: 'center' }}
                alignItems="center"
                justifyContent="center"
                mb={12}
                color="text.success"
                flexDirection={{ base: 'column', lg: 'row' }}
              >
                <Text fontWeight="bold">{discount}</Text>
              </Flex>
            ) : null}
          </Container> */}
          <Divider />
          <Creds />
          <Divider />
          <About />
          <Divider />
          <Detail1 />
          <Divider />
          {/* <Special /> */}
          <Detail2 />
          <Divider />
          <Detail3 />
          {/* <Divider />
          <ProSection1 /> */}
          <Divider />
          <Testimonials />
          <Divider />
          <JamCodingTestimonialFeature />
          <Divider />
          <Examples />
          {/* <Divider />
          <Flow /> */}
          {/* <Divider />
          <SortedTestimonialFeature /> */}
          <Divider />
          <Solutions />
          {/* <Divider />
          <ProSection2 /> */}
          <Divider />
          <Features />
          <Divider />
          {/* {moment().isBefore(moment('2024-04-01')) ? (
            <>
              <FounderMessage />
              <Divider />
            </>
          ) : null} */}
          <Pricing />
        </Box>
        <Divider />
        <CallToAction />
        <Creds />
      </ScreenWrapper>
      <Footer />
    </Box>
  );
};

export default LandingScreen;
