import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import navRoutes from 'navigation/Routes';
import moment from 'moment';
import { useHistory } from 'react-router';

import { GlobalState } from 'types';
import { ISessionListAllItem, SessionListAllState } from 'types/cms';

import { sessionActions, unitActions, courseActions } from 'redux/actions/cms';

import { commonUtils } from 'utils';

import { ICONS } from 'constants/ui';
import { NAV_HEIGHT } from 'containers/AppHeader';

import {
  Text,
  Card,
  Flex,
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  MdIcon,
  Button,
  Spinner,
  Collapse,
  Tooltip,
} from '@workshop/ui';

import { CatalogueSkeleton } from 'screens/cms/CourseCatalogue/src/CatalogueSkeleton';
import { ItemLarge } from 'components/ListItem';
import { EditModal } from 'components/Common/EditModal';

interface SessionListModalProps {
  isOpen: boolean;
  onClose: () => void;
  courseId: number;
  unitId: number;
  maxNumSelections: number;
  sessionToReplace?: number;
}

interface SessionListProps {
  allSessions: SessionListAllState;
  isLoading: boolean;
  inModal?: boolean;
  onSelection?: (sessionIds: number[]) => Promise<void>;
  disableCourseId?: number;
  maxNumSelections?: number;
  sessionToReplace?: number;
}

const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const SessionList: React.FC<SessionListProps> = ({
  allSessions,
  isLoading,
  inModal,
  onSelection,
  disableCourseId,
  maxNumSelections,
  sessionToReplace,
}) => {
  const [sessionSearchQuery, setSessionSearchQuery] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [selectedIds, setSelectedIds] = useState([]);

  const mySessions = Object.values(allSessions)
    .sort((aSession, bSession) => {
      const a = aSession as ISessionListAllItem;
      const b = bSession as ISessionListAllItem;
      if (sortBy === 'name-ascending') {
        return a.title.localeCompare(b.title);
      } else if (sortBy === 'name-descending') {
        return b.title.localeCompare(a.title);
      } else if (sortBy === 'created-ascending') {
        return moment(b.created).diff(moment(a.created));
      } else if (sortBy === 'created-descending') {
        return moment(a.created).diff(moment(b.created));
      } else if (sortBy === 'modified-ascending') {
        return moment(b.modified).diff(moment(a.modified));
      } else if (sortBy === 'modified-descending') {
        return moment(a.modified).diff(moment(b.modified));
      }
      return moment(b.created).diff(moment(a.created));
    })
    .filter((fSession) => {
      const s = fSession as ISessionListAllItem;
      const queryText = sessionSearchQuery.toLowerCase().trim();
      const searchableText = `${s.title} ${
        s.syncedCourses?.map((c) => `${c.title} `) || ''
      }`.toLowerCase();
      // Extract quoted phrases and individual words
      const matches = queryText.match(/"([^"]+)"|(\S+)/g) || [];
      const terms = matches.map((match) =>
        match.startsWith('"') && match.endsWith('"')
          ? match.slice(1, -1) // Remove quotes for exact phrases
          : match
      );
      return terms.every((term) =>
        searchableText.includes(term.replace('"', ''))
      );
    });

  const maxSelectionsReached =
    maxNumSelections && selectedIds.length >= maxNumSelections;

  return (
    <>
      <Flex mt={-4} />
      <Flex
        position="sticky"
        top={inModal ? 0 : NAV_HEIGHT + 36}
        zIndex={1}
        mx={{ base: 0, md: -4 }}
        px={{ base: 0, md: 4 }}
        py={2}
        mb={4}
        flexDirection="column"
        bg="background.tint3"
        _dark={{
          bg: inModal ? 'gray.700' : 'background.tint3',
        }}
      >
        <Flex
          mx={{ base: 'defaultMargin', md: 0 }}
          flexDirection={{ base: 'column-reverse', sm: 'row' }}
        >
          <InputGroup flex={1}>
            <InputLeftElement>
              <MdIcon name="Search" />
            </InputLeftElement>
            <Input
              name="search"
              placeholder="Search for a session..."
              backgroundColor="background.default"
              borderRadius="full"
              _placeholder={{
                color: 'text.muted',
              }}
              onChange={(e) => setSessionSearchQuery(e.target.value)}
            />
          </InputGroup>
          <Select
            width={{ base: '220px', sm: '120px', md: '220px' }}
            placeholder="Sort By"
            variant="filled"
            icon={<MdIcon name="SwapVert" />}
            borderRadius="full"
            colorScheme="common.secondaryPalette"
            ml={{ base: 0, sm: 2 }}
            mb={{ base: 3, sm: 0 }}
            alignSelf="flex-end"
            onChange={(e: React.FormEvent<HTMLSelectElement>) =>
              setSortBy(e.currentTarget.value)
            }
            cursor="pointer"
          >
            <option value="name-ascending">Name: A-Z</option>
            <option value="name-descending">Name: Z-A</option>
            <option value="created-ascending">Created: Latest</option>
            <option value="created-descending">Created: Oldest</option>
            {/* <option value="modified-ascending">Modified: Latest</option>
          <option value="modified-descending">Modified: Oldest</option> */}
            {/* <option value="progress-complete">Progress: Complete</option>
          <option value="progress-incomplete">Progress: Incomplete</option> */}
          </Select>
        </Flex>
      </Flex>
      {isLoading ? (
        <CatalogueSkeleton condensed />
      ) : mySessions.length > 0 ? (
        <Flex direction="column" mb={8}>
          <Box>
            {mySessions.map((s) => {
              const session = s as ISessionListAllItem;
              const isSelected = onSelection
                ? // @ts-ignore
                  selectedIds.includes(session.id)
                : undefined;
              const onSelect = onSelection
                ? // @ts-ignore
                  () => setSelectedIds([...selectedIds, session.id])
                : undefined;
              const onDeselect = onSelection
                ? () =>
                    setSelectedIds(selectedIds.filter((i) => i !== session.id))
                : undefined;
              const selectionOverLimit =
                maxSelectionsReached &&
                !selectedIds.find((i) => i === session.id);
              const alreadyOnThisCourse = onSelection
                ? !!session.syncedCourses?.find((c) => c.id === disableCourseId)
                : undefined;
              const item = (
                <Tooltip
                  label={
                    !alreadyOnThisCourse && selectionOverLimit
                      ? '6 sessions max per unit'
                      : ''
                  }
                  textAlign="center"
                  placement="top"
                >
                  <Card
                    key={`session-list-item-${session.id}`}
                    direction="column"
                    mb="defaultMargin"
                    padding={0}
                    cursor={
                      alreadyOnThisCourse || selectionOverLimit
                        ? 'not-allowed'
                        : 'pointer'
                    }
                    opacity={
                      alreadyOnThisCourse
                        ? 0.2
                        : !onSelection || isSelected || selectedIds.length === 0
                        ? 1
                        : 0.8
                    }
                    transition="opacity 0.3s"
                    {...(onSelection
                      ? {
                          ...(alreadyOnThisCourse || selectionOverLimit
                            ? {}
                            : {
                                onClick: isSelected ? onDeselect : onSelect,
                                _hover: {
                                  bg: 'background.tint1',
                                },
                              }),
                        }
                      : {
                          _hover: {
                            bg: 'background.tint1',
                            transform: { base: 'auto', md: 'scale(1.01)' },
                          },
                          transition: { base: 'none', md: 'transform 0.3s' },
                        })}
                  >
                    <ItemLarge
                      title={session.title}
                      image={session.imageThumbnail}
                      buttonLabel="Edit Session"
                      buttonLink={
                        onSelection
                          ? undefined
                          : navRoutes.cms.standaloneSession.path(
                              session.id.toString()
                            )
                      }
                      isReady={session.isReady}
                      isPublic={session.isPublic}
                      numSteps={session.stepCount - 2}
                      numCourses={
                        alreadyOnThisCourse
                          ? undefined
                          : session.syncedCourses?.length
                      }
                      imagePlaceholderIcon={ICONS.session}
                      condensed
                      isSelected={isSelected}
                      cantBeSelected={Boolean(
                        alreadyOnThisCourse || selectionOverLimit
                      )}
                    />
                  </Card>
                </Tooltip>
              );
              if (onSelection) return item;
              return (
                <Link
                  key={`session-item-${session.id}`}
                  to={navRoutes.cms.standaloneSession.path(
                    session.id.toString()
                  )}
                  style={{ display: 'block' }}
                >
                  {item}
                </Link>
              );
            })}
          </Box>
        </Flex>
      ) : sessionSearchQuery ? (
        <Flex px={6} pb={10} justifyContent="center" alignItems="center">
          <Text textAlign="center" color="text.muted">
            No sessions found
          </Text>
        </Flex>
      ) : null}
      {onSelection && !isLoading ? (
        <Flex
          position="fixed"
          bottom={6}
          left={0}
          right={0}
          opacity={selectedIds.length === 0 ? 0 : 1}
          transform={`translateY(${selectedIds.length === 0 ? '80' : '0'}px)`}
          transition="transform 0.3s, opacity 0.3s"
          pointerEvents="none"
          flexDirection="column"
          alignItems="center"
        >
          {maxSelectionsReached && !sessionToReplace && (
            <Text
              color="text.muted"
              fontSize="sm"
              textAlign="center"
              mb={2}
              py={2}
              px={3}
              borderRadius="full"
              bg="rgba(0,0,0,0.4)"
              backdropFilter="blur(10px)"
            >
              Unit full (6 sessions max)
            </Text>
          )}
          <Button
            icon="ArrowForward"
            iconPosition="right"
            onClick={() => onSelection(selectedIds)}
            size="lg"
            pointerEvents="auto"
          >
            {sessionToReplace
              ? 'Replace with Session'
              : `Import ${
                  selectedIds.length > 1 ? `${selectedIds.length} ` : ''
                }Session${selectedIds.length <= 1 ? '' : 's'}`}
          </Button>
        </Flex>
      ) : null}
    </>
  );
};

export const SessionListModal: React.FC<SessionListModalProps> = ({
  isOpen,
  onClose,
  courseId,
  unitId,
  maxNumSelections,
  sessionToReplace,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loadData = async () => {
    await dispatch(sessionActions.listAll({ fetchNextPage: true }));
  };
  const [isImporting, setIsImporting] = useState(false);
  const [wasImported, setWasImported] = useState(false);

  useEffect(() => {
    if (isOpen) {
      loadData();
    }
  }, [isOpen]);

  const allSessions = useSelector(
    (state: GlobalState) => state.cms.session.allSessions
  );
  const isLoading = useSelector(
    (state: GlobalState) => state.ui.session.sessionList.loading
  );

  return (
    <EditModal
      title={
        isImporting || wasImported
          ? ''
          : sessionToReplace
          ? 'Select a Session'
          : 'Import Sessions'
      }
      isOpen={isOpen}
      onClose={onClose}
      onCancel={isImporting ? undefined : onClose}
      modalSize="xl"
      saveLabel=""
      onSave={() => null}
      cancelDisabled={isImporting}
      closeOnOverlayClick={!isImporting}
      showCloseButton={!isImporting}
    >
      {/* @ts-ignore */}
      <Collapse in={isImporting && !wasImported}>
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          width="100%"
          color="common.primary"
          mt={3}
          minHeight="100px"
        >
          <Spinner mb={4} />
          <Text>
            {sessionToReplace
              ? 'Replacing with selected session...'
              : 'Importing sessions...'}
          </Text>
        </Flex>
      </Collapse>
      {/* @ts-ignore */}
      <Collapse in={wasImported}>
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          width="100%"
          mt={2}
          mb={8}
          minHeight="100px"
        >
          <Flex
            boxSize="image.lg"
            alignItems="center"
            justifyContent="center"
            zIndex={1}
            borderRadius="full"
          >
            <Text fontSize="6xl">
              <MdIcon name="Done" color="common.progress" />
            </Text>
          </Flex>
          <Text
            fontWeight="extrabold"
            fontSize={{ base: '3xl', md: '4xl' }}
            lineHeight={1.2}
          >
            {sessionToReplace ? 'Session replaced' : 'Import complete'}
          </Text>
        </Flex>
      </Collapse>

      {!isImporting && !wasImported && (
        <Box
          mx={{ base: -4, md: -5 }}
          px={{ base: 0, md: 5 }}
          bg="background.tint3"
          _dark={{
            bg: 'gray.700',
          }}
        >
          <SessionList
            allSessions={allSessions}
            isLoading={isLoading}
            inModal
            onSelection={async (sessionIds) => {
              setIsImporting(true);
              let lastDuplicateRes = null;
              const duplicateSessionPromises = sessionIds.map(
                (sId) => async () => {
                  lastDuplicateRes = await dispatch(
                    sessionActions.duplicate(sId, unitId)
                  );
                }
              );
              await commonUtils.resolveQueue(duplicateSessionPromises);
              await timeout(3000 / sessionIds.length);
              setWasImported(true);
              // @ts-ignore
              if (sessionToReplace && lastDuplicateRes?.payload?.module) {
                await timeout(3000);
                history.push(
                  navRoutes.cms.session.path(
                    courseId.toString(),
                    // @ts-ignore
                    lastDuplicateRes.payload.module.toString()
                  )
                );
                await dispatch(sessionActions.remove(sessionToReplace));
              } else {
                await timeout(2000);
              }
              await Promise.all([
                dispatch(courseActions.retrieve(courseId)),
                dispatch(unitActions.list(courseId)),
              ]);
              onClose();
              setIsImporting(false);
              setWasImported(false);
            }}
            maxNumSelections={maxNumSelections}
            sessionToReplace={sessionToReplace}
            disableCourseId={courseId}
          />
        </Box>
      )}
    </EditModal>
  );
};

export default SessionList;
