import React, { useState, useEffect } from 'react';
import { Text, Flex, Box, Card, Switch } from '@workshop/ui';

interface AccessibilityProps {}

const Accessibility: React.FC<AccessibilityProps> = () => {
  const [captionsOn, setCaptionsOn] = useState(false);
  const [dyslexicOn, setDyslexicOn] = useState(false);

  useEffect(() => {
    const closedCaptions = localStorage.getItem('closedCaptions');
    if (closedCaptions === 'on') {
      setCaptionsOn(true);
    }
    const dyslexicFont = localStorage.getItem('dyslexicFont');
    if (dyslexicFont === 'on') {
      setDyslexicOn(true);
    }
  }, []);

  return (
    <Card padding="defaultPadding">
      <Box w="100%">
        <Flex flex={1} mb={3}>
          <Box flex={1}>
            <Text>Captions</Text>
            <Text fontSize="sm" color="text.muted">
              Display captions where possible on videos and audio clips.
            </Text>
          </Box>
          <Flex>
            <Switch
              isChecked={captionsOn}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setCaptionsOn(!captionsOn);
                localStorage.setItem(
                  'closedCaptions',
                  captionsOn ? 'off' : 'on'
                );
              }}
            />
          </Flex>
        </Flex>
        <Flex flex={1}>
          <Box flex={1}>
            <Text>Dyslexic-friendly text</Text>
            <Text
              fontFamily="OpenDyslexic"
              fontWeight="bold"
              fontSize="sm"
              marginBottom={2}
            >
              Here is an example of Dyslexic-friendly text.
            </Text>
            <Text fontSize="sm" color="text.muted">
              Some people find this text easier to read. This will activate
              dyslexic-friendly text on all captions and step summaries.
            </Text>
          </Box>
          <Flex>
            <Switch
              isChecked={dyslexicOn}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setDyslexicOn(!dyslexicOn);
                localStorage.setItem('dyslexicFont', dyslexicOn ? 'off' : 'on');
              }}
            />
          </Flex>
        </Flex>
      </Box>
    </Card>
  );
};

export default Accessibility;
