import React from 'react';

import { ICONS } from 'constants/ui';

import { Flex, Box, Image, MdIcon, useColorModeValue } from '@workshop/ui';

const MiniGameCard = ({
  onClick,
  card,
  index,
  isInactive,
  isFlipped,
  isDisabled,
}: {
  onClick: (index: number) => void;
  card: {
    type: string;
    image: string;
  };
  index: number;
  isInactive: boolean;
  isFlipped: boolean;
  isDisabled: boolean;
}) => {
  const handleClick = () => {
    !isFlipped && !isDisabled && !isInactive && onClick(index);
  };
  const bg = useColorModeValue('background.default', 'text.primary');

  return (
    <Box
      onClick={handleClick}
      transform={isFlipped ? 'rotateY(180deg)' : 'rotateY(0deg)'}
      width="100%"
      height="100%"
      transition="0.3s"
      position="relative"
      cursor={isInactive ? 'auto' : 'pointer'}
      sx={{
        transformStyle: 'preserve-3d',
        backfaceVisibility: 'hidden',
      }}
      borderRadius="md"
      boxShadow="md"
      bg="background.default"
      {...(!isInactive
        ? {
            _hover: {
              transform: isFlipped
                ? 'rotateY(180deg)'
                : 'rotateY(0deg) scale(1.1)',
            },
          }
        : {})}
    >
      <Flex
        sx={{
          backfaceVisibility: 'hidden',
        }}
        position="absolute"
        width="100%"
        height="100%"
        borderRadius="md"
        alignItems="center"
        justifyContent="center"
        overflow="hidden"
        bg={isInactive ? 'background.success' : bg}
        color={isInactive ? 'common.progress' : 'background.primary'}
      >
        <MdIcon
          name={ICONS.course}
          fontSize={{ base: '4xl', sm: '5xl', md: '6xl' }}
        />
      </Flex>
      <Box
        sx={{
          backfaceVisibility: 'hidden',
        }}
        position="absolute"
        width="100%"
        height="100%"
        transform="rotateY(180deg)"
        bg="background.primary"
        borderRadius="md"
        boxShadow="md"
        overflow="hidden"
      >
        <Image
          width="100%"
          height="100%"
          src={card.image}
          alt="card"
          objectFit="cover"
        />
      </Box>
    </Box>
  );
};

export default MiniGameCard;
