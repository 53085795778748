import React, { ReactElement } from 'react';
import {
  useSlate,
  ReactEditor,
  RenderLeafProps,
  RenderElementProps,
} from 'slate-react';
import { Editor, Transforms, Range, Element as SlateElement } from 'slate';
import { HistoryEditor } from 'slate-history';
import isURL from 'validator/lib/isURL';

import {
  IconButton,
  HStack,
  chakra,
  ListItem,
  OrderedList,
  UnorderedList,
  Heading,
  MdIcon,
} from '@workshop/ui';

export type EditorProps = Editor | ReactEditor | HistoryEditor;
const LIST_TYPES = ['ol', 'ul'];

const isBlockActive = (editor: EditorProps, format: string) => {
  const nodeGen = Editor.nodes(editor, {
    match: (n) =>
      // @ts-ignore
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
  });

  let node = nodeGen.next();
  while (!node.done) {
    return true;
  }
  return false;
};

const isMarkActive = (editor: EditorProps, format: string) => {
  const marks = Editor.marks(editor);
  // @ts-ignore
  return marks ? marks[format] === true : false;
};

export const toggleBlock = (editor: EditorProps, format: string) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      LIST_TYPES.includes(
        // @ts-ignore
        (!Editor.isEditor(n) && SlateElement.isElement(n) && n.type) as string
      ),
    split: true,
  });
  const newProperties: Partial<SlateElement> = {
    // @ts-ignore
    type: isActive ? 'p' : isList ? 'li' : format,
  };
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const isLinkActive = (editor: EditorProps) => {
  const [link] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  });
  return !!link;
};

const unwrapLink = (editor: EditorProps) => {
  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  });
};

export const wrapLink = (editor: EditorProps, url: string) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: url }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};

const insertLink = (editor: EditorProps, url: string) => {
  if (editor.selection) {
    wrapLink(editor, url);
  }
};

export const toggleMark = (editor: EditorProps, format: string) => {
  const isActive = isMarkActive(editor, format);
  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

export const MarkButton = ({
  format,
  icon,
}: {
  format: string;
  icon: ReactElement;
}) => {
  const editor = useSlate();
  return (
    <IconButton
      variant="outline"
      colorScheme="blue"
      isActive={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
      aria-label={format}
      icon={icon}
      borderWidth={0}
      fontSize="20px"
    />
  );
};

export const BlockButton = ({
  format,
  icon,
}: {
  format: string;
  icon: ReactElement;
}) => {
  const editor = useSlate();
  return (
    <IconButton
      variant="outline"
      colorScheme="blue"
      isActive={isBlockActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
      aria-label={format}
      icon={icon}
      borderWidth={0}
      fontSize="20px"
    />
  );
};

const AddLinkButton = () => {
  const editor = useSlate();
  return (
    <IconButton
      variant="outline"
      colorScheme="blue"
      isActive={isLinkActive(editor)}
      onMouseDown={(event) => {
        if (isLinkActive(editor)) {
          unwrapLink(editor);
        } else {
          event.preventDefault();
          const selectedText = Editor.string(editor, editor.selection);
          if (selectedText && isURL(selectedText)) {
            insertLink(editor, selectedText);
          } else {
            const url = window.prompt('Enter the URL of the link:');
            if (!url) return;
            insertLink(editor, url);
          }
        }
      }}
      aria-label="link"
      icon={<MdIcon name="Link" />}
      borderWidth={0}
      fontSize="20px"
    />
  );
};

export const Toolbar = ({ allowLinks }: { allowLinks?: boolean }) => {
  return (
    <HStack
      borderWidth="0 0 1px 0"
      padding="10px 5px"
      spacing="5px"
      wrap="wrap"
    >
      <MarkButton format="bold" icon={<MdIcon name="FormatBold" />} />
      <MarkButton format="italic" icon={<MdIcon name="FormatItalic" />} />
      <MarkButton
        format="underline"
        icon={<MdIcon name="FormatUnderlined" />}
      />
      {/* <MarkButton format="code" icon={<MdIcon name="Code" />} /> */}
      <BlockButton format="h2" icon={<MdIcon name="LooksOne" />} />
      <BlockButton format="h3" icon={<MdIcon name="LooksTwo" />} />
      {/* <BlockButton format="blockquote" icon={<MdIcon name="FormatQuote" />} /> */}
      <BlockButton format="ol" icon={<MdIcon name="FormatListNumbered" />} />
      <BlockButton format="ul" icon={<MdIcon name="FormatListBulleted" />} />
      {allowLinks && <AddLinkButton />}
    </HStack>
  );
};

const BlockquoteStyle: React.CSSProperties | undefined = {
  margin: '1.5em 10px',
  padding: '0.5em 10px',
};

// Put this at the start and end of an inline component to work around this Chromium bug:
// https://bugs.chromium.org/p/chromium/issues/detail?id=1249405
const InlineChromiumBugfix = () => (
  <chakra.span contentEditable={false} fontSize={0}>
    {String.fromCodePoint(160) /* Non-breaking space */}
  </chakra.span>
);

export const Element = ({
  attributes,
  children,
  element,
}: RenderElementProps) => {
  // @ts-ignore
  switch (element.type) {
    case 'blockquote':
      return (
        <chakra.blockquote
          style={BlockquoteStyle}
          borderLeftWidth="10px"
          borderLeftColor="border.default"
          {...attributes}
        >
          {children}
        </chakra.blockquote>
      );
    case 'li':
      return <chakra.li {...attributes}>{children}</chakra.li>;
    case 'ol':
      return <OrderedList {...attributes}>{children}</OrderedList>;
    case 'ul':
      return <UnorderedList {...attributes}>{children}</UnorderedList>;
    case 'h1':
      return (
        <Heading as="h1" fontSize="2xl" mb={4} {...attributes}>
          {children}
        </Heading>
      );
    case 'h2':
      return (
        <Heading as="h2" fontSize="xl" mb={4} {...attributes}>
          {children}
        </Heading>
      );
    case 'h3':
      return (
        <Heading as="h3" fontSize="lg" mb={4} {...attributes}>
          {children}
        </Heading>
      );
    case 'link':
      return (
        <chakra.a
          fontWeight="semibold"
          textDecoration="underline"
          color="common.primary"
          {...attributes}
        >
          <InlineChromiumBugfix />
          {children}
          <InlineChromiumBugfix />
        </chakra.a>
      );
    default:
      return <p {...attributes}>{children}</p>;
  }
};

export const Leaf = ({ attributes, children, leaf }: RenderLeafProps) => {
  // @ts-ignore
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  // @ts-ignore
  if (leaf.code) {
    children = (
      <chakra.code
        padding="3px"
        backgroundColor="background.tint2"
        fontSize="90%"
      >
        {children}
      </chakra.code>
    );
  }

  // @ts-ignore
  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  // @ts-ignore
  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};
