import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaTiktok,
  FaDiscord,
  FaPatreon,
} from 'react-icons/fa';
import { Element } from 'react-scroll';
import { TwitterPicker } from 'react-color';

import navRoutes from 'navigation/Routes';

import { PLATFORM } from 'constants/env';
import { PLATFORM_WEBSITE } from 'constants/common';
import { WORKSHOP_ORGS } from 'constants/organisation';
import { ICONS } from 'constants/ui';

import { courseActions, cohortActions } from 'redux/actions/learner';
import { organisationActions } from 'redux/actions/common';
import { courseActions as cmsCourseActions } from 'redux/actions/cms';

import { hooks, colorUtils, analytics } from 'utils';
import { useWindowDimensions } from 'utils/hooks/useDimensions';

import { GlobalState } from 'types';
import { MaterialColors, Cohort, Organisation } from 'types/common';
import { CourseSummary } from 'types/learner';
import { ICourseListItem } from 'types/cms';

import { ScreenWrapper } from 'screens/common/ScreenWrapper';
import { OrganisationDetailsCard } from 'screens/cms/MyOrganisation/src';

import {
  Flex,
  Box,
  Image,
  Card,
  Text,
  Link as ExternalLink,
  Button,
  LinkButton,
  Divider,
  Grid,
  GridItem,
  Stack,
  MdIcon,
  useColorModeValue,
  useTheme,
  chakra,
  TextProps,
  Spinner,
} from '@workshop/ui';

import { NAV_HEIGHT } from 'containers/AppHeader';

import { UserAvatar } from 'components/UserAvatar';
import { LoadingScreen } from 'components/Loading';
import {
  ScrollNav,
  ScheduleWidget,
  StandaloneImageUpload,
  RenderHtml,
  InformationCard,
  OnboardingChecklist,
  OnboardingStep,
  StepsModal,
} from 'components/Common';
import { HeaderTag } from 'components/AppHeader';
import { Footer } from 'components/Footer';
import { FormCard } from 'components/FormCard';

// Routing Props
interface MatchParams {
  handle: string;
}

// Props passed to our component from parents
interface OwnProps extends RouteComponentProps<MatchParams> {}

// Props passed to our component via redux
type PropsFromRedux = ConnectedProps<typeof connector>;

// Combined props we're passing to our component
interface Props extends OwnProps, PropsFromRedux {}

type ColorCombination = {
  bgColor: string | undefined;
  bgAccentColor: string | undefined;
  textColor: string | undefined;
};

const Head = ({
  name,
  team,
  handle,
  isPro,
  banner,
  bannerColor,
  onBannerColor,
  bg,
  subtitleColor,
  profilePic,
  isEditing,
  isApp,
}: {
  name: string;
  team: number;
  handle: string;
  isPro: boolean;
  bannerColor: string;
  onBannerColor: string;
  bg: string;
  banner: string;
  subtitleColor: string;
  profilePic: React.ReactElement;
  isEditing: boolean;
  isApp?: boolean;
}) => {
  // Main image, profile picture, name, 'follow' buttons

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  return (
    <Flex flex={1} flexDirection="column">
      <Box position="relative">
        <Box
          position="absolute"
          left={0}
          top={0}
          right={0}
          bottom={0}
          bgGradient={`linear(to-b, ${bg}, transparent)`}
          zIndex={-1}
          opacity={0.2}
        />
        <Box
          maxWidth={{ base: 'none', xl: '1200px', '2xl': '1250px' }}
          width={{ base: '100%', xl: '72%', '2xl': '72%' }}
          borderRadius={{ base: 'none', xl: 'lg' }}
          overflow="hidden"
          mt={{ base: 0, xl: isEditing ? 16 : 7 }}
          mx="auto"
        >
          {isEditing ? (
            <Box
              role="group"
              width="100%"
              paddingBottom={banner ? '33.33%' : '25%'}
              position="relative"
            >
              <Flex position="absolute" left={0} top={0} right={0} bottom={0}>
                <StandaloneImageUpload
                  id="banner"
                  name="banner"
                  backgroundColor={bannerColor}
                  height="100%"
                  image={banner}
                  onDrop={async (name: string, acceptedFiles: File[]) => {
                    setIsLoading(true);
                    await dispatch(
                      organisationActions.updateOrganisationProfile(team, {
                        // @ts-ignore
                        imageBanner: acceptedFiles[0],
                      })
                    );
                    setIsLoading(false);
                    analytics.track('Channel Details Edited');
                  }}
                  width="100%"
                  isDisabled={isLoading}
                  hideText
                  borderRadius={0}
                  borderColor="transparent"
                  hideAddPhotoIcon
                  hoverBg={bannerColor}
                />
              </Flex>
              <Flex
                position="absolute"
                bottom={4}
                right={4}
                pointerEvents="none"
                alignItems={{ base: 'flex-end', md: 'center' }}
                flexDirection={{ base: 'column', md: 'row' }}
              >
                <Text
                  fontSize="xs"
                  fontWeight="semibold"
                  color={banner ? 'text.opposite' : onBannerColor}
                  bg={banner ? 'text.muted' : bannerColor}
                  px={{ base: banner ? 2 : 0, md: 3 }}
                  mb={{ base: 1.5, md: 0 }}
                  py={1}
                  mr={{ base: 0, md: 2 }}
                  borderRadius="full"
                  opacity={banner ? 0 : 1}
                  transition="opacity 0.3s"
                  _groupHover={{ opacity: 1 }}
                >
                  Recommended 1500x500px
                </Text>
                <Box _groupHover={{ opacity: 0.8 }} transition="opacity 0.3s">
                  <Button secondary size="sm" icon="Edit">
                    Edit Banner
                  </Button>
                </Box>
              </Flex>
            </Box>
          ) : (
            <>
              {banner ? (
                <Box
                  backgroundImage={`url(${banner})`}
                  backgroundSize="cover"
                  backgroundPosition="center"
                  w="100%"
                  h={0}
                  paddingBottom="33.33%"
                />
              ) : (
                <Box
                  w="100%"
                  height={{ base: '100px', md: '150px', xl: '200px' }}
                  bg={bannerColor}
                />
              )}
            </>
          )}
        </Box>
      </Box>

      <Flex
        alignItems="center"
        px={{ base: 'defaultMargin', md: 0 }}
        width={{ base: '100%', md: '85%', xl: '70%', '2xl': '70%' }}
        maxWidth="1200px"
        alignSelf="center"
        pb={2}
      >
        <Flex mt={{ base: -8, md: -16 }}>{profilePic}</Flex>
        <Flex
          flexDirection="column"
          justifyContent="center"
          ml={{ base: 4, md: 5 }}
        >
          <Text
            as="h1"
            fontSize={{ base: 'xl', sm: '2xl', md: '3xl' }}
            fontWeight="extrabold"
            mt={2}
          >
            {name}
          </Text>
          {handle ? (
            <Flex alignItems="center">
              <Text
                fontWeight="semibold"
                fontSize={{ base: 'xs', sm: 'sm' }}
                overflowWrap="anywhere"
                color={subtitleColor}
                mr={2}
              >
                {isEditing ? `${PLATFORM_WEBSITE}/@${handle}` : `@${handle}`}
              </Text>
              {isEditing && !isApp && (
                <LinkButton
                  to={`/@${handle}`}
                  target="_blank"
                  rel="noreferrer noopener"
                  icon="Visibility"
                  size="xs"
                  w={6}
                  variant="outline"
                />
              )}
            </Flex>
          ) : null}
        </Flex>
      </Flex>
    </Flex>
  );
};

const Nav = ({
  colors,
  bg,
  surface,
  hasCourses,
  hasSessions,
  hasCohorts,
  isEditing,
  isApp,
}: {
  colors: MaterialColors | null;
  bg: string;
  surface: string;
  hasCourses: boolean;
  hasSessions: boolean;
  hasCohorts: boolean;
  isEditing: boolean;
  isApp?: boolean;
}) => {
  // In-page sticky nav

  const activeBg = useColorModeValue(
    colors?.light.primary,
    colors?.dark.primary
  );
  const activeText = useColorModeValue(
    colors?.light.onPrimary,
    colors?.dark.onPrimary
  );
  const activeHoverBg = useColorModeValue(
    colors?.light.onPrimaryContainer,
    colors?.dark.onPrimaryContainer
  );
  const inactiveText = useColorModeValue(
    colors?.light.outline,
    colors?.dark.outline
  );
  const inactiveHoverText = useColorModeValue(
    colors?.light.onSurfaceVariant,
    colors?.dark.onSurfaceVariant
  );
  const inactiveHoverBg = useColorModeValue(
    colors?.light.surfaceVariant,
    colors?.dark.surfaceVariant
  );
  const border = bg;

  const tabs = isEditing
    ? [
        {
          slug: 'about',
          name: 'About',
        },
        {
          slug: 'next-class',
          name: 'Next Class',
        },
        {
          slug: 'courses',
          name: 'Courses',
        },
        {
          slug: 'schedule',
          name: 'Schedule',
        },
        {
          slug: 'free-sessions',
          name: 'Free Sessions',
        },
      ]
    : [
        {
          slug: 'about',
          name: 'About',
        },
      ];
  if (!isEditing) {
    if (hasCohorts) {
      tabs.push({
        slug: 'next-class',
        name: 'Next Class',
      });
    }
    if (hasCourses) {
      tabs.push({
        slug: 'courses',
        name: 'Courses',
      });
    }
    if (hasCohorts) {
      tabs.push({
        slug: 'schedule',
        name: 'Schedule',
      });
    }
    if (hasSessions) {
      tabs.push({
        slug: 'free-sessions',
        name: 'Free Sessions',
      });
    }
  }

  return (
    <ScrollNav
      tabs={tabs}
      // @ts-ignore
      colors={
        activeBg
          ? {
              bg,
              border,
              surface,
              activeBg,
              activeHoverBg,
              activeText,
              inactiveText,
              inactiveHoverText,
              inactiveHoverBg,
            }
          : undefined
      }
      showTopPadding={isEditing}
      bg={isEditing ? 'background.tint3' : 'background.default'}
      isApp={isApp}
    />
  );
};

const Intro = ({
  socialLinks,
  linkColor,
  isEditing,
  channelSteps,
  channel,
  onOnboardingComplete,
}: {
  socialLinks: {
    link: string;
    icon: React.ReactElement;
  }[];
  linkColor: string;
  isEditing: boolean;
  channelSteps: OnboardingStep[];
  channel: Organisation;
  onOnboardingComplete: () => void;
}) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [isUpdatingLinks, setIsUpdatingLinks] = useState(false);
  const [editingLinks, setEditingLinks] = useState(false);

  const { team, websiteUrl, introduction } = channel;

  const dispatch = useDispatch();

  const fixUrl = (url: string) => {
    if (url && !/^https?:\/\//i.test(url)) {
      return 'https://' + url;
    } else return url;
  };

  const updateOrganisationData = async (data: Partial<Organisation>) => {
    if (!channel?.team) return;
    analytics.track('Channel Details Edited');

    const fixedData = {
      ...data,
      ...(data.websiteUrl ? { websiteUrl: fixUrl(data.websiteUrl) } : {}),
      ...(data.facebookUrl ? { facebookUrl: fixUrl(data.facebookUrl) } : {}),
      ...(data.instagramUrl ? { instagramUrl: fixUrl(data.instagramUrl) } : {}),
      ...(data.twitterUrl ? { twitterUrl: fixUrl(data.twitterUrl) } : {}),
      ...(data.youtubeUrl ? { youtubeUrl: fixUrl(data.youtubeUrl) } : {}),
      ...(data.tiktokUrl ? { tiktokUrl: fixUrl(data.tiktokUrl) } : {}),
      ...(data.discordUrl ? { discordUrl: fixUrl(data.discordUrl) } : {}),
      ...(data.patreonUrl ? { patreonUrl: fixUrl(data.patreonUrl) } : {}),
    };

    return await dispatch(
      organisationActions.updateOrganisationProfile(channel.team, {
        ...fixedData,
      })
    );
  };

  // Intro section with website & social links
  return (
    <Flex flexDirection="column">
      {isEditing ? (
        <Flex flexDirection={{ base: 'column', md: 'row' }} mb={6}>
          <OnboardingChecklist
            id="channel-onboarding"
            onboardingSteps={channelSteps}
            title="Customize Your Channel"
            emoji="🎨"
            onComplete={onOnboardingComplete}
            pr={{ base: 0, md: 4 }}
            mb={{ base: 4, md: 0 }}
          />
          <Box
            flex={2}
            borderRadius={{ base: 'lg', md: 'none' }}
            overflow={{ base: 'hidden', md: 'visible' }}
          >
            <FormCard
              onSave={async (data) => {
                setIsUpdating(true);
                await dispatch(
                  organisationActions.updateOrganisationProfile(team, data)
                );
                setIsUpdating(false);
                analytics.track('Channel Details Edited');
              }}
              onCancel={() => {}}
              isUpdating={isUpdating}
              items={[
                {
                  id: 'introduction',
                  name: 'introduction',
                  richEditor: true,
                  defaultValue: introduction || undefined,
                  placeholder:
                    'Give your audience an introduction to your channel...',
                  label: '',
                  aiButton:
                    PLATFORM === 'steppit'
                      ? {
                          toolSlug: 'channelPage',
                          label: 'Generate Introduction',
                          tooltip: `Let your assistant draft an introduction for your channel`,
                          isLoading: false,
                          loadingText: '',
                          isOutline: !!introduction,
                          isDisabled: isUpdating,
                          alwaysShow: true,
                          onSave: async (res) => {
                            setIsUpdating(true);
                            await dispatch(
                              organisationActions.updateOrganisationProfile(
                                team,
                                {
                                  introduction: res.channelIntro,
                                }
                              )
                            );
                            setIsUpdating(false);
                            analytics.track('Channel Details Edited');
                          },
                        }
                      : undefined,
                },
              ]}
            />
          </Box>
        </Flex>
      ) : (
        <Box mb={6}>
          <RenderHtml html={introduction || ''} />
        </Box>
      )}

      {!editingLinks && (
        <Stack direction="row" alignItems="center" alignSelf="flex-end">
          {websiteUrl ? (
            <ExternalLink
              href={websiteUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Text
                fontSize="sm"
                fontWeight="semibold"
                color={linkColor}
                mr={socialLinks.length > 0 ? 2 : 0}
              >
                {websiteUrl.replace('http://', '').replace('https://', '')}
              </Text>
            </ExternalLink>
          ) : null}
          {socialLinks.map((l) => (
            <ExternalLink
              href={l.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {l.icon}
            </ExternalLink>
          ))}
        </Stack>
      )}
      {isEditing && (
        <>
          {editingLinks ? (
            <>
              <OrganisationDetailsCard
                isLoading={isUpdatingLinks}
                onSubmit={async (data) => {
                  setIsUpdatingLinks(true);
                  const res = await updateOrganisationData(data);
                  setIsUpdatingLinks(false);
                  setEditingLinks(false);
                  return res;
                }}
                onCancel={() => setEditingLinks(false)}
                linksOnly
                introduction={channel.introduction || ''}
                contactEmail={channel.contactEmail || ''}
                contactNumber={channel.contactNumber || ''}
                logo={channel.logo || ''}
                logoDark={channel.logoDark || ''}
                name={channel.name || ''}
                websiteUrl={channel.websiteUrl || ''}
                facebookUrl={channel.facebookUrl || ''}
                instagramUrl={channel.instagramUrl || ''}
                twitterUrl={channel.twitterUrl || ''}
                youtubeUrl={channel.youtubeUrl || ''}
                tiktokUrl={channel.tiktokUrl || ''}
                discordUrl={channel.discordUrl || ''}
                patreonUrl={channel.patreonUrl || ''}
                handle={channel.handle || ''}
                persona={channel.persona || 'general'}
              />
              <Box mb={3} />
            </>
          ) : (
            <>
              <Flex justifyContent="flex-end" mt={4}>
                <Button
                  icon="Edit"
                  variant="outline"
                  size="sm"
                  onClick={() => setEditingLinks(true)}
                >
                  Edit Links
                </Button>
              </Flex>
            </>
          )}
        </>
      )}
      <Box mb={4} />
    </Flex>
  );
};

const NextClass = ({
  cohort,
  course,
  surfaceColor,
  colorCombinations,
  isEditing,
}: {
  cohort?: Cohort;
  course?: CourseSummary;
  surfaceColor: string;
  colorCombinations: ColorCombination[];
  isEditing: boolean;
}) => {
  const colorCombo = cohort
    ? colorCombinations[cohort.courseDetails.id % colorCombinations.length]
    : colorCombinations[0];
  // If applicable, link to next class starting

  if (!cohort && isEditing) {
    const dummyImages = [
      {
        bg: colorCombinations[1].bgAccentColor,
        accent: colorCombinations[1].bgColor,
      },
      {
        bg: colorCombinations[2].bgAccentColor,
        accent: colorCombinations[2].bgColor,
      },
      {
        bg: colorCombinations[2].bgColor,
        accent: colorCombinations[2].bgAccentColor,
      },
    ];
    return (
      <Flex flexDirection="column" mb={6}>
        <InformationCard
          id="channel-next-class"
          information={{
            description:
              'When you start running classes on your courses, your next upcoming class will be featured here.',
            overlay: true,
          }}
          isDismissable={false}
          mb={6}
          color="text.default"
        />
        <Box flex={1}>
          <Flex alignItems="center">
            <Box
              position="relative"
              height="image.xl"
              width="image.xl"
              transform={`translateX(${(dummyImages.length - 1) * 50}px)`}
              zIndex={0}
            >
              {dummyImages.map((img, index) => (
                <Box
                  key={`nextClass-${index}`}
                  overflow="hidden"
                  borderRadius="md"
                  height="image.xl"
                  width="image.xl"
                  position="absolute"
                  left={`-${index * 50}px`}
                  transform={`scale(${
                    index === dummyImages.length - 3
                      ? 0.8
                      : index === dummyImages.length - 2
                      ? 0.9
                      : 1
                  })`}
                  backgroundColor={surfaceColor}
                >
                  <Flex
                    opacity={
                      index === dummyImages.length - 3
                        ? 0.3
                        : index === dummyImages.length - 2
                        ? 0.6
                        : 1
                    }
                    height="image.xl"
                    width="image.xl"
                    // @ts-ignore
                    bg={img.bg}
                    // @ts-ignore
                    color={img.accent}
                    alignItems="center"
                    justifyContent="center"
                    fontSize="6xl"
                  >
                    <MdIcon name="GroupWork" />
                  </Flex>
                </Box>
              ))}
            </Box>
            <Flex flex={1} zIndex={1} flexDirection="column">
              <Flex
                backgroundColor={colorCombo.bgColor}
                borderRightRadius="full"
              >
                <Flex flex={1} py={2} px={3} alignItems="center">
                  <Box
                    boxSize="36px"
                    borderRadius="full"
                    display={{ base: 'none', md: 'block' }}
                    bg={colorCombo.bgAccentColor}
                  />

                  <Box
                    width={0.5}
                    height="100%"
                    mx={3}
                    backgroundColor={colorCombo.bgAccentColor}
                    display={{ base: 'none', md: 'block' }}
                  />
                  <Box flex={1} color={colorCombo.textColor} opacity={0.5}>
                    <Text fontWeight="semibold" noOfLines={2}>
                      My First Class
                    </Text>
                    <Text fontSize="sm">Coming Soon</Text>
                  </Box>

                  <Flex flex={1} />
                  <MdIcon
                    name="ArrowForwardIos"
                    mx={2}
                    color={colorCombo.bgAccentColor}
                  />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </Flex>
    );
  }

  const images = course?.sessionImages?.map((i) => i.imageThumbnail) || [
    cohort?.courseDetails.imageLandscape,
  ];

  return (
    <Flex mb={6} _hover={{ opacity: 0.8 }}>
      <Link
        to={navRoutes.global.courseLanding.path(course?.slug)}
        style={{ flex: 1 }}
        {...(isEditing
          ? {
              target: '_blank',
              rel: 'noopener noreferrer',
            }
          : {})}
      >
        <Flex alignItems="center">
          <Box
            position="relative"
            height="image.xl"
            width="image.xl"
            transform={`translateX(${(images.length - 1) * 50}px)`}
            zIndex={0}
          >
            {images.map((img, index) => (
              <Box
                key={`nextClass-${index}`}
                overflow="hidden"
                borderRadius="md"
                height="image.xl"
                width="image.xl"
                position="absolute"
                left={`-${index * 50}px`}
                transform={`scale(${
                  index === images.length - 3
                    ? 0.8
                    : index === images.length - 2
                    ? 0.9
                    : 1
                })`}
                backgroundColor={surfaceColor}
              >
                <Image
                  // @ts-ignore
                  src={img}
                  height="image.xl"
                  width="image.xl"
                  objectFit="cover"
                  opacity={
                    index === images.length - 3
                      ? 0.3
                      : index === images.length - 2
                      ? 0.6
                      : 1
                  }
                  bg="background.tint3"
                />
              </Box>
            ))}
          </Box>
          <Flex flex={1} zIndex={1} flexDirection="column">
            <Flex backgroundColor={colorCombo.bgColor} borderRightRadius="full">
              <Flex flex={1} py={2} px={3} alignItems="center">
                <Image
                  boxSize="36px"
                  src={
                    cohort?.courseDetails.imageLandscape ||
                    course?.imageLandscapeThumbnail
                  }
                  borderRadius="full"
                  objectFit="cover"
                  display={{ base: 'none', md: 'block' }}
                  bg="background.tint3"
                />
                {course ? (
                  <>
                    <Box
                      width={0.5}
                      height="100%"
                      mx={3}
                      backgroundColor={colorCombo.bgAccentColor}
                      display={{ base: 'none', md: 'block' }}
                    />
                    <Box flex={1} color={colorCombo.textColor}>
                      <Text fontWeight="semibold" noOfLines={2}>
                        {course.title}
                      </Text>
                      <Text fontSize="sm">
                        {`${course.duration} unit${
                          course.duration === 1 ? '' : 's'
                        }${
                          course.price ? `  |  £${parseInt(course.price)}` : ''
                        }`}
                      </Text>
                    </Box>
                  </>
                ) : null}
                <Flex flex={1} />
                <MdIcon
                  name="ArrowForwardIos"
                  mx={2}
                  color={colorCombo.bgAccentColor}
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Link>
    </Flex>
  );
};

type DummyCourse = {
  bg?: string;
  accent?: string;
  title: string;
  subtitle: string;
  summary: string;
  image?: string | null;
};

const Courses = ({
  courses,
  dummyCourse,
  mutedTextColor,
  isEditing,
  colorCombinations,
}: {
  courses: CourseSummary[];
  dummyCourse?: ICourseListItem;
  mutedTextColor: string;
  isEditing: boolean;
  colorCombinations: ColorCombination[];
}) => {
  // List of courses available from this channel

  const dummyCourses: DummyCourse[] = dummyCourse
    ? [
        {
          bg: colorCombinations[1].bgColor,
          accent: colorCombinations[1].bgAccentColor,
          title: dummyCourse.title,
          subtitle: dummyCourse.subtitle,
          summary: dummyCourse.summaryText,
          image: dummyCourse.imageLandscapeThumbnail,
        },
      ]
    : [
        {
          bg: colorCombinations[1].bgColor,
          accent: colorCombinations[1].bgAccentColor,
          title: 'My First Course',
          subtitle: 'Coming Soon',
          summary: "Here's a summary of my course.",
          image: null,
        },
      ];
  return (
    <>
      {isEditing && courses.length === 0 && (
        <InformationCard
          id="channel-courses"
          information={{
            description: `This is where you'll showcase all of your available courses. Published courses are automatically hidden from your channel until you make them visible via your course's "Share" settings.`,
            overlay: true,
          }}
          isDismissable={false}
          mb={6}
          color="text.default"
        />
      )}
      <Stack spacing={6} mb={6}>
        {isEditing && courses.length === 0
          ? dummyCourses.map((c: DummyCourse) => (
              <Box>
                <Stack direction={{ base: 'column', md: 'row' }} spacing={6}>
                  {c.image ? (
                    <Image
                      src={c.image}
                      height={{ base: 'auto', md: 'image.xl' }}
                      width={{ base: '100%', md: 'image.3xl' }}
                      maxHeight={{ base: 'image.4xl', md: 'none' }}
                      minHeight={{ base: 'image.2xl', md: 'none' }}
                      objectFit="cover"
                      borderRadius="md"
                      bg="background.tint3"
                      opacity={0.5}
                    />
                  ) : (
                    <Flex
                      height={{ base: 'auto', md: 'image.xl' }}
                      width={{ base: '100%', md: 'image.3xl' }}
                      maxHeight={{ base: 'image.3xl', md: 'none' }}
                      minHeight={{ base: 'image.2xl', md: 'none' }}
                      borderRadius="md"
                      bg={c.bg}
                      color={c.accent}
                      alignItems="center"
                      justifyContent="center"
                      fontSize="6xl"
                    >
                      <MdIcon name={ICONS.course} />
                    </Flex>
                  )}

                  <Flex
                    flexDirection="column"
                    justifyContent="center"
                    flex={1}
                    opacity={0.5}
                  >
                    <Flex mb={1} alignItems="center">
                      <Text as="h3" fontWeight="semibold" mr={3}>
                        {c.title}
                      </Text>
                      <MdIcon name="VisibilityOff" color="text.muted" />
                    </Flex>
                    <Box
                      mb={3}
                      noOfLines={2}
                      fontSize="sm"
                      color={mutedTextColor}
                    >
                      <RenderHtml html={c.summary} />
                    </Box>
                  </Flex>
                  <MdIcon
                    name="ArrowForwardIos"
                    ml={2}
                    color={mutedTextColor}
                    alignSelf={{ base: 'flex-end', md: 'center' }}
                  />
                </Stack>
              </Box>
            ))
          : courses.map((c) => {
              const hasCohorts = c.courseType !== 'session';
              return (
                <Link
                  to={navRoutes.global.courseLanding.path(c.slug)}
                  {...(isEditing
                    ? {
                        target: '_blank',
                        rel: 'noopener noreferrer',
                      }
                    : {})}
                >
                  <Stack
                    direction={{ base: 'column', md: 'row' }}
                    spacing={6}
                    _hover={{ opacity: 0.8 }}
                  >
                    <Box
                      height={{ base: 0, md: 'image.xl' }}
                      width={{ base: '100%', md: 'image.3xl' }}
                      pb={{ base: '56.25%', md: 0 }}
                      borderRadius="md"
                      bg="background.tint3"
                      overflow="hidden"
                      position="relative"
                    >
                      <Box
                        position="absolute"
                        width="100%"
                        height="100%"
                        filter="blur(10px)"
                        zIndex={0}
                      >
                        <Image
                          src={c.imageLandscapeTiny}
                          width="100%"
                          height="100%"
                          objectFit="cover"
                        />
                      </Box>
                      <Image
                        src={c.imageLandscapeMobile}
                        width="100%"
                        height="100%"
                        objectFit="contain"
                        position="absolute"
                        zIndex={1}
                      />
                    </Box>
                    <Flex
                      flexDirection="column"
                      justifyContent="center"
                      flex={1}
                    >
                      <Text as="h3" fontWeight="semibold">
                        {c.title}
                      </Text>
                      <Text
                        color={mutedTextColor}
                        fontWeight="semibold"
                        fontSize="sm"
                        mb={3}
                      >
                        {`${c.duration} unit${c.duration === 1 ? '' : 's'}${
                          c.price ? ` | £${parseInt(c.price)}` : ''
                        }`}
                      </Text>
                      {c.summaryText ? (
                        <Box
                          mb={3}
                          noOfLines={2}
                          fontSize="sm"
                          color={mutedTextColor}
                        >
                          <RenderHtml html={c.summaryText} />
                        </Box>
                      ) : null}
                      <Stack
                        direction="row"
                        mb={3}
                        mt={2}
                        alignSelf="flex-start"
                      >
                        {c.isPublic && (
                          <HeaderTag
                            title="Public Course"
                            bg="background.success"
                            color="text.success"
                          />
                        )}
                        {hasCohorts && (
                          <HeaderTag
                            title="Classes Available"
                            bg="background.primary"
                            color="text.primary"
                          />
                        )}
                      </Stack>
                    </Flex>
                    <MdIcon
                      name="ArrowForwardIos"
                      ml={2}
                      color={mutedTextColor}
                      alignSelf={{ base: 'flex-end', md: 'center' }}
                    />
                  </Stack>
                </Link>
              );
            })}
      </Stack>
    </>
  );
};

const Schedule = ({
  cohorts,
  channel,
  isEditing,
}: {
  cohorts: Cohort[];
  channel: Organisation;
  isEditing: boolean;
}) => {
  // Schedule widget of upcoming classes
  return (
    <Box mb={6}>
      {channel && isEditing && cohorts.length === 0 ? (
        <InformationCard
          id="channel-schedule"
          information={{
            description:
              "All of your available upcoming classes will appear on your channel's schedule.",
            overlay: true,
          }}
          isDismissable={false}
          mb={6}
          color="text.default"
        />
      ) : null}
      <ScheduleWidget
        cohorts={cohorts}
        org={channel}
        link="course"
        isLoading={!channel}
        noShadow
        openInNew={isEditing}
      />
    </Box>
  );
};

const FreeSessions = ({
  sessions,
  mutedTextColor,
  isEditing,
  colorCombinations,
}: {
  sessions: CourseSummary[];
  mutedTextColor: string;
  isEditing: boolean;
  colorCombinations: ColorCombination[];
}) => {
  // Links to public sessions & courses
  const dummySessions = [
    {
      bg: colorCombinations[0].bgColor,
      accent: colorCombinations[0].bgAccentColor,
      title: 'My First Session',
      summary: 'A brief summary of my 1st session.',
    },
    {
      bg: colorCombinations[2].bgColor,
      accent: colorCombinations[2].bgAccentColor,
      title: 'My Second Session',
      summary: 'A brief summary of my 2nd session.',
    },
    {
      bg: colorCombinations[3].bgColor,
      accent: colorCombinations[3].bgAccentColor,
      title: 'My Third Session',
      summary: 'A brief summary of my 3rd session.',
    },
  ];
  return (
    <>
      {isEditing && sessions.length === 0 && (
        <InformationCard
          id="channel-sessions"
          information={{
            description:
              'You can create and share free sessions on your channel here – these could be anything from previews of your courses to standalone how-tos.',
            overlay: true,
          }}
          isDismissable={false}
          mb={6}
          color="text.default"
        />
      )}
      <Grid gap={4} templateColumns="repeat(12, 1fr)" mb={6}>
        {isEditing && sessions.length === 0
          ? dummySessions.map((s) => (
              <GridItem
                colSpan={{
                  base: 12,
                  sm: 6,
                  md: 4,
                  '2xl': 3,
                }}
              >
                <Box>
                  <Flex
                    height={{ base: 'auto', md: 'image.2xl' }}
                    width="100%"
                    maxHeight={{ base: 'image.3xl', md: 'none' }}
                    minHeight={{ base: 'image.2xl', md: 'none' }}
                    objectFit="cover"
                    borderRadius="md"
                    mb={4}
                    bg={s.bg}
                    color={s.accent}
                    alignItems="center"
                    justifyContent="center"
                    fontSize="6xl"
                  >
                    <MdIcon name={ICONS.session} />
                  </Flex>
                  <Flex alignItems="center" opacity={0.5}>
                    <Text as="h3" fontWeight="semibold" flex={1} mb={1}>
                      {s.title}
                    </Text>
                    <MdIcon
                      name="ArrowForwardIos"
                      ml={2}
                      color={mutedTextColor}
                    />
                  </Flex>
                  <Box
                    opacity={0.5}
                    color={mutedTextColor}
                    fontSize="sm"
                    mb={3}
                    noOfLines={2}
                  >
                    <RenderHtml html={s.summary} />
                  </Box>
                </Box>
              </GridItem>
            ))
          : sessions.map((c) => {
              return (
                <GridItem
                  colSpan={{
                    base: 12,
                    sm: 6,
                    md: 4,
                    '2xl': 3,
                  }}
                  _hover={{ opacity: 0.8 }}
                >
                  <Link
                    to={navRoutes.global.publicStandaloneSession.path(c.slug)}
                    {...(isEditing
                      ? {
                          target: '_blank',
                          rel: 'noopener noreferrer',
                        }
                      : {})}
                  >
                    <Image
                      src={c.imageLandscapeThumbnail}
                      height={{ base: 'auto', md: 'image.2xl' }}
                      width="100%"
                      maxHeight={{ base: 'image.3xl', md: 'none' }}
                      minHeight={{ base: 'image.2xl', md: 'none' }}
                      objectFit="cover"
                      borderRadius="md"
                      mb={4}
                      bg="background.tint3"
                    />
                    <Flex alignItems="center">
                      <Text as="h3" fontWeight="semibold" flex={1}>
                        {c.title}
                      </Text>
                      <MdIcon
                        name="ArrowForwardIos"
                        ml={2}
                        color={mutedTextColor}
                      />
                    </Flex>

                    {c.summaryText ? (
                      <Box
                        color={mutedTextColor}
                        fontSize="sm"
                        mb={3}
                        noOfLines={2}
                      >
                        <RenderHtml html={c.summaryText} />
                      </Box>
                    ) : null}
                  </Link>
                </GridItem>
              );
            })}
      </Grid>
    </>
  );
};

const ChannelScreen: React.FC<Props> = ({
  handle,
  channel,
  courses,
  editCourses,
  cohorts,
  isEditing,
}) => {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const baseColor = channel?.id
    ? colorUtils.getRandomColor(channel.id, undefined, undefined, 'hex')
    : '';
  const [brandColor, setBrandColor] = useState(
    channel?.brandColor || baseColor
  );
  const [isUpdatingLogo, setIsUpdatingLogo] = useState(false);
  const [showOnboardingCompleteModal, setShowOnboardingCompleteModal] =
    useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    if (handle) {
      dispatch(organisationActions.fetchOrganisationSummary(handle));
      dispatch(courseActions.listForOrg(handle));
    }
  }, [handle]);
  useEffect(() => {
    if (isEditing) {
      dispatch(cmsCourseActions.list({ fetchNextPage: false }));
    }
  }, [isEditing]);
  useEffect(() => {
    if (courses.length > 0) {
      const fetchCohorts = async () => {
        const fetchCohortActions = courses.map((c) =>
          dispatch(cohortActions.courseList({ courseSlug: c.slug }))
        );
        await Promise.all(fetchCohortActions);
      };
      fetchCohorts();
    }
  }, [courses.length]);
  useEffect(() => {
    if (channel?.brandColor) {
      setBrandColor(channel.brandColor);
    } else if (baseColor) {
      setBrandColor(baseColor);
    }
  }, [channel?.brandColor, baseColor]);
  useEffect(() => {
    if (handle) {
      if (isEditing) {
        analytics.track('Viewed Edit Channel');
      } else {
        analytics.track('Viewed Channel Page');
      }
    }
  }, [isEditing, handle]);

  const theme = useTheme();
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < parseInt(theme.breakpoints.md, 10);

  const colors = hooks.useColors(brandColor);
  const palette = useColorModeValue(colors?.light, colors?.dark);
  const oppositePalette = useColorModeValue(colors?.dark, colors?.light);

  const currentRoute = hooks.useCurrentRoute();
  const isApp = currentRoute?.isApp;

  const colorCombinations = [
    {
      bgColor: palette?.primaryContainer,
      bgAccentColor: oppositePalette?.primary,
      textColor: oppositePalette?.onPrimary,
    },
    {
      bgColor: palette?.secondaryContainer,
      bgAccentColor: oppositePalette?.secondary,
      textColor: oppositePalette?.onSecondary,
    },
    {
      bgColor: palette?.tertiaryContainer,
      bgAccentColor: oppositePalette?.tertiary,
      textColor: oppositePalette?.onTertiary,
    },
    {
      bgColor: palette?.errorContainer,
      bgAccentColor: oppositePalette?.error,
      textColor: oppositePalette?.onError,
    },
    {
      bgColor: palette?.surfaceVariant,
      bgAccentColor: oppositePalette?.outline,
      textColor: palette?.onSurfaceVariant,
    },
  ];

  const bg = palette?.primaryContainer || 'background.tint1';
  const textColor = palette?.onPrimaryContainer || 'text.default';
  const titleColor = palette?.secondary || 'text.default';
  const borderColor = 'border.default';
  const surface = palette?.surface || 'background.default';
  const mutedTextColor = palette?.outline || 'text.muted';
  const bannerColor = palette?.primaryContainer || 'common.primary';
  const onBannerColor = palette?.onPrimaryContainer || 'text.opposite';

  if (PLATFORM !== 'steppit' || WORKSHOP_ORGS.includes(channel?.id)) {
    history.push(navRoutes.common.home.path());
    return null;
  }
  const socialLinks = [];
  if (channel?.facebookUrl) {
    socialLinks.push({
      link: channel.facebookUrl,
      icon: <FaFacebook color="#4267B2" />,
    });
  }
  if (channel?.instagramUrl) {
    socialLinks.push({
      link: channel.instagramUrl,
      icon: <FaInstagram color="#962fbf" />,
    });
  }
  if (channel?.twitterUrl) {
    socialLinks.push({
      link: channel.twitterUrl,
      icon: <FaTwitter color="#00acee" />,
    });
  }
  if (channel?.youtubeUrl) {
    socialLinks.push({
      link: channel.youtubeUrl,
      icon: <FaYoutube color="#FF0000" />,
    });
  }
  if (channel?.tiktokUrl) {
    socialLinks.push({
      link: channel.tiktokUrl,
      icon: <FaTiktok color="#69C9D0" />,
    });
  }
  if (channel?.discordUrl) {
    socialLinks.push({
      link: channel.discordUrl,
      icon: <FaDiscord color="#5865F2" />,
    });
  }
  if (channel?.patreonUrl) {
    socialLinks.push({
      link: channel.patreonUrl,
      icon: <FaPatreon color="#FF424D" />,
    });
  }

  const headingProps: TextProps = {
    as: 'h2',
    color: titleColor,
    fontWeight: 'semibold',
    mt: 3,
    mb: 6,
  };

  // TODO: Add TikTok, Discord, Patreon

  const fullCourses = courses.filter(
    (c) => c.courseType !== 'session' && c.isVisible
  );
  const dummyCourse = editCourses.length > 0 ? editCourses[0] : undefined;
  const sessions = courses.filter(
    (c) =>
      c.courseType === 'session' &&
      c.standaloneModule &&
      c.isPublic &&
      c.isVisible
  );
  const hasCourses = fullCourses.length > 0;
  const hasSessions = sessions.length > 0;
  const hasCohorts = cohorts.length > 0;

  const nextCohort =
    hasCohorts &&
    cohorts.sort((a, b) => moment(a.startDate).diff(moment(b.startDate)))[0];

  const channelSteps = [
    {
      slug: 'color-scheme',
      label: 'Pick a color scheme',
      isComplete: Boolean(channel?.brandColor),
      onClick: () => setShowColorPicker(!showColorPicker),
      showClickable: true,
    },
    {
      slug: 'profile-picture',
      label: 'Add a profile picture',
      isComplete: Boolean(channel?.logoDark),
    },
    {
      slug: 'banner-picture',
      label: 'Upload a banner picture',
      isComplete: Boolean(channel?.imageBanner),
    },
    {
      slug: 'links',
      label: 'Link to your website or socials',
      isComplete: Boolean(
        channel?.websiteUrl ||
          channel?.facebookUrl ||
          channel?.instagramUrl ||
          channel?.twitterUrl ||
          channel?.youtubeUrl ||
          channel?.tiktokUrl ||
          channel?.discordUrl ||
          channel?.patreonUrl
      ),
    },
    {
      slug: 'intro',
      label: 'Write an introduction',
      isComplete: Boolean(
        channel?.introduction && channel?.introduction !== '<p></p>'
      ),
    },
  ];

  return (
    <>
      {!channel ? (
        <LoadingScreen />
      ) : (
        <>
          <Flex
            position="absolute"
            top={0}
            right={0}
            bottom={0}
            left={0}
            // bg={surface}
            bg={isEditing ? 'background.tint3' : 'background.default'}
            zIndex={0}
          >
            {/* <Flex flex={1} bg={bg} opacity={0.14} /> */}
          </Flex>
          <Flex
            zIndex={4}
            color={titleColor}
            mt={{
              base: isApp ? 0 : NAV_HEIGHT,
              md: isEditing || isApp ? 0 : NAV_HEIGHT,
            }}
          >
            <Head
              name={channel.name}
              team={channel.team}
              isPro={channel.isPro}
              banner={channel.imageBannerMobile}
              handle={channel.handle}
              bannerColor={bannerColor}
              onBannerColor={onBannerColor}
              bg={bg}
              subtitleColor={mutedTextColor}
              isEditing={isEditing}
              isApp={isApp}
              profilePic={
                <Flex position="relative">
                  <Flex
                    bg="background.default"
                    p={1}
                    position="relative"
                    alignSelf="flex-start"
                    borderRadius="full"
                    overflow="hidden"
                  >
                    <Box>
                      {isEditing ? (
                        <>
                          <Flex
                            backgroundColor="background.tint1"
                            borderRadius="full"
                            role="group"
                          >
                            <Flex
                              _hover={{ opacity: channel.logoDark ? 0.6 : 0.8 }}
                              opacity={isUpdatingLogo ? 0.5 : 1}
                            >
                              <StandaloneImageUpload
                                id="logoDark"
                                backgroundColor="transparent"
                                backgroundSize="cover"
                                borderRadius="full"
                                width={{ base: 'image.md', md: 'image.xl' }}
                                height={{ base: 'image.md', md: 'image.xl' }}
                                image={channel.logoDark || ''}
                                name="logoDark"
                                onDrop={async (
                                  name: string,
                                  acceptedFiles: File[]
                                ) => {
                                  setIsUpdatingLogo(true);
                                  await dispatch(
                                    organisationActions.updateOrganisationProfile(
                                      channel.team,
                                      {
                                        // @ts-ignore
                                        logoDark: acceptedFiles[0],
                                      }
                                    )
                                  );
                                  setIsUpdatingLogo(false);
                                  analytics.track('Channel Details Edited');
                                }}
                                isDisabled={isUpdatingLogo}
                                hideText
                                hideAddPhotoIcon={isUpdatingLogo}
                              />
                            </Flex>
                            <Flex
                              position="absolute"
                              top={0}
                              left={0}
                              right={0}
                              bottom={0}
                              alignItems="center"
                              justifyContent="center"
                              fontSize="2xl"
                              opacity={isUpdatingLogo ? 1 : 0}
                              pointerEvents="none"
                              _groupHover={{
                                opacity: channel.logoDark ? 1 : 0,
                              }}
                            >
                              {isUpdatingLogo ? (
                                <Spinner color="common.primary" />
                              ) : (
                                <MdIcon
                                  name="Edit"
                                  color="#555"
                                  mixBlendMode="difference"
                                />
                              )}
                            </Flex>
                          </Flex>
                        </>
                      ) : (
                        <UserAvatar
                          name={channel.name}
                          userId={channel.id || 1}
                          avatarPicture={channel.logoDark || ''}
                          size={isMobile ? 'md' : 'xl'}
                        />
                      )}
                    </Box>
                  </Flex>
                  {isEditing && (
                    <Flex
                      position="absolute"
                      bottom={0}
                      left={0}
                      alignItems="flex-start"
                      flexDirection="column"
                    >
                      <Card
                        padding={1}
                        borderRadius="md"
                        cursor="pointer"
                        role="group"
                        onClick={() => setShowColorPicker(!showColorPicker)}
                        zIndex={1}
                      >
                        <Flex
                          boxSize={{ base: 6, md: 8 }}
                          borderRadius="sm"
                          backgroundColor={brandColor}
                          _groupHover={{ opacity: 0.8 }}
                          alignItems="center"
                          justifyContent="center"
                        >
                          <MdIcon
                            name="Edit"
                            color="#555"
                            mixBlendMode="difference"
                          />
                        </Flex>
                      </Card>
                      {showColorPicker && (
                        <Box position="absolute" pt={12}>
                          <TwitterPicker
                            color={brandColor}
                            onChangeComplete={(color: { hex: string }) => {
                              setBrandColor(color.hex);
                              dispatch(
                                organisationActions.updateOrganisationProfile(
                                  channel.team,
                                  { brandColor: color.hex }
                                )
                              );
                              analytics.track('Channel Details Edited');
                            }}
                            triangle="top-left"
                            colors={[
                              '#FF8A65',
                              '#FCB900',
                              '#99EFCB',
                              '#00D084',
                              '#2CCCE4',
                              '#0693E3',
                              '#BBBBBB',
                              '#E71841',
                              '#9900EF',
                              '#F78DA7',
                            ]}
                          />
                        </Box>
                      )}
                    </Flex>
                  )}
                </Flex>
              }
            />
          </Flex>
          <ScreenWrapper>
            <Nav
              colors={colors}
              bg={bg}
              surface={surface}
              hasCourses={hasCourses}
              hasSessions={hasSessions}
              hasCohorts={hasCohorts}
              isEditing={isEditing}
              isApp={isApp}
            />
            <Flex
              flexDirection="column"
              mx={{ base: 'defaultMargin', md: 0 }}
              mb={120}
              zIndex={1}
              color={textColor}
            >
              <Element name="about">
                <Intro
                  socialLinks={socialLinks}
                  linkColor={mutedTextColor}
                  isEditing={isEditing}
                  channelSteps={channelSteps}
                  channel={channel}
                  onOnboardingComplete={() => {
                    analytics.track('Channel Details Completed');
                    if (!hasCourses && !hasSessions) {
                      setShowOnboardingCompleteModal(true);
                    }
                  }}
                />
              </Element>
              {Boolean(isEditing || (hasCohorts && nextCohort)) && (
                <>
                  <Divider borderColor={borderColor} />
                  <Element name="next-class">
                    <Text {...headingProps}>
                      Next Class
                      <chakra.span fontWeight="bold">
                        {hasCohorts && nextCohort
                          ? moment(nextCohort.startDate).isBefore(moment())
                            ? ''
                            : ` Starting ${moment(nextCohort.startDate).format(
                                'Do MMMM'
                              )}`
                          : ' Starting Soon'}
                      </chakra.span>
                    </Text>
                    <NextClass
                      cohort={nextCohort || undefined}
                      course={
                        nextCohort
                          ? courses.find(
                              (c) => c.id === nextCohort.courseDetails.id
                            )
                          : undefined
                      }
                      surfaceColor={surface}
                      colorCombinations={colorCombinations}
                      isEditing={isEditing}
                    />
                  </Element>
                </>
              )}
              {Boolean(isEditing || hasCourses) && (
                <>
                  <Divider borderColor={borderColor} />
                  <Element name="courses">
                    <Flex alignItems="center">
                      <Text flex={1} {...headingProps}>
                        Courses
                      </Text>
                      {isEditing && !isApp && (
                        <LinkButton
                          mb={3}
                          size="sm"
                          variant="outline"
                          icon={ICONS.course}
                          to={navRoutes.cms.catalogue.path()}
                        >
                          Content
                        </LinkButton>
                      )}
                    </Flex>
                    <Courses
                      courses={fullCourses}
                      dummyCourse={dummyCourse}
                      mutedTextColor={mutedTextColor}
                      isEditing={isEditing}
                      colorCombinations={colorCombinations}
                    />
                  </Element>
                </>
              )}
              {Boolean(isEditing || hasCohorts) && (
                <>
                  <Divider borderColor={borderColor} />
                  <Element name="schedule">
                    <Text {...headingProps}>Schedule</Text>
                    <Schedule
                      channel={channel}
                      cohorts={cohorts}
                      isEditing={isEditing}
                    />
                  </Element>
                </>
              )}
              {Boolean(isEditing || hasSessions) && (
                <>
                  <Divider borderColor={borderColor} />
                  <Element name="free-sessions">
                    <Flex alignItems="center">
                      <Text flex={1} {...headingProps}>
                        Free Sessions
                      </Text>
                      {isEditing && !isApp && (
                        <LinkButton
                          mb={3}
                          size="sm"
                          variant="outline"
                          icon={ICONS.course}
                          to={navRoutes.cms.catalogue.path()}
                        >
                          Content
                        </LinkButton>
                      )}
                    </Flex>
                    <FreeSessions
                      sessions={sessions}
                      mutedTextColor={mutedTextColor}
                      isEditing={isEditing}
                      colorCombinations={colorCombinations}
                    />
                  </Element>
                </>
              )}
            </Flex>
          </ScreenWrapper>
          {!isEditing && <Footer />}
          {showOnboardingCompleteModal && (
            <StepsModal
              heading=""
              isOpen={showOnboardingCompleteModal}
              onClose={() => setShowOnboardingCompleteModal(false)}
              modalSize="xl"
              onCompleteStep={async () => {
                setShowOnboardingCompleteModal(false);
                history.push(navRoutes.cms.catalogue.path());
              }}
              bigNext
              disablePrev
              steps={[
                {
                  label: '',
                  icon: null,
                  nextButtonText: 'Go to Content',
                  content: (
                    <Flex
                      py={4}
                      flexDirection="column"
                      alignItems="center"
                      textAlign="center"
                      maxW="500px"
                      mx="auto"
                      mb={3}
                    >
                      <Flex
                        boxSize="image.lg"
                        alignItems="center"
                        justifyContent="center"
                        zIndex={1}
                        borderRadius="full"
                      >
                        <Text fontSize="6xl">😎</Text>
                      </Flex>

                      <Text
                        fontWeight="extrabold"
                        fontSize={{ base: '3xl', md: '4xl' }}
                        lineHeight="1.2"
                        mb={2}
                      >
                        Looking good!
                      </Text>

                      <Text
                        color="text.muted"
                        fontSize="lg"
                        whiteSpace="break-spaces"
                      >
                        {`Now your channel's coming together, let's ${
                          editCourses.length === 0
                            ? 'start making some content for it'
                            : editCourses.filter(
                                (c) => c.courseType !== 'session'
                              ).length === 1
                            ? 'take a look at that course'
                            : 'get to work on your content'
                        }...`}
                      </Text>
                    </Flex>
                  ),
                },
              ]}
            />
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: GlobalState, props: OwnProps) => {
  const { handle: paramHandle } = props.match.params;

  let handle = paramHandle;
  let isEditing = false;
  if (!handle) {
    const currentTeamProfile =
      state.organisation.currentTeam &&
      state.organisation.teamProfiles[state.organisation.currentTeam];
    handle =
      currentTeamProfile && 'handle' in currentTeamProfile
        ? currentTeamProfile.handle
        : '';
    if (handle) {
      isEditing = true;
    }
  }

  const org = Object.values(state.organisation.teamProfiles).find(
    (o) => o.handle === handle
  );
  const courses = Object.values(state.learner.courses.courses.summary).filter(
    (c) => c.organisation.handle === handle
  );
  const cohorts = Object.values(state.learner.courses.upcomingCohorts).filter(
    (ch) => {
      const course = courses.find((c) => c.slug === ch.course);
      return (
        course &&
        org &&
        course.isVisible &&
        course.organisation.id === org.id &&
        ch.organisation === org.id
      );
    }
  );

  let editCourses: ICourseListItem[] = [];
  if (isEditing) {
    editCourses = Object.values(state.cms.course.courseList).filter(
      (c) => c.courseType !== 'session' && c.organisation === org.id
    );
  }

  return {
    handle,
    channel: org,
    courses,
    editCourses,
    cohorts,
    isEditing,
  };
};

const connector = connect(mapStateToProps);

export default connector(withRouter(ChannelScreen));
