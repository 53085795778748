import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { RouteComponentProps, Link } from 'react-router-dom';

import isEmpty from 'lodash/isEmpty';

import { GlobalState } from 'types';
import { ICourseListItem } from 'types/cms';
import { CourseDetails } from 'types/learner';

import { PLATFORM } from 'constants/env';
import { PLATFORM_DISPLAY_NAME } from 'constants/common';
import { PRO_ORGS } from 'constants/organisation';
import { ICONS } from 'constants/ui';

import navRoutes from 'navigation/Routes';

import { getParamFromUrl, analytics } from 'utils';
import { useWindowDimensions } from 'utils/hooks/useDimensions';

import { courseActions as learnerCourseActions } from 'redux/actions/learner';
import {
  courseActions,
  sessionActions,
  enrolmentActions,
} from 'redux/actions/cms';
import { getLicensedCourses } from 'redux/selectors/course';
import { useCurrentTeamProfile } from 'redux/selectors';

import { ICoursePublishStatus } from 'constants/courses';
import { CREATE_COURSE_WHITELIST } from 'constants/organisation';

import {
  Text,
  Stack,
  Card,
  Flex,
  Box,
  Button,
  chakra,
  useTheme,
} from '@workshop/ui';

import { CatalogueSkeleton } from 'screens/cms/CourseCatalogue/src/CatalogueSkeleton';
import { ScreenWrapper } from 'screens/common/ScreenWrapper';

import { SectionTitle, InformationCard, ButtonGrid } from 'components/Common';
import { ItemLarge } from 'components/ListItem';
import { InPageNav, SessionList } from 'components/Common';

import CreateCourseModal from './CreateCourseModal';
import NewSessionModal from './NewSessionModal';

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux, RouteComponentProps {}

const getBackgroundColor = (status: ICoursePublishStatus) => {
  switch (status) {
    case 'published':
      return 'background.success';

    case 'in_review':
      return 'background.warning';
  }

  return 'background.tint2';
};

const getColor = (status: ICoursePublishStatus) => {
  switch (status) {
    case 'published':
      return 'text.success';

    case 'in_review':
      return 'text.warning';
  }

  return 'text.muted';
};

const CourseCatalogue: React.FC<Props> = ({
  courses,
  allSessions,
  licensedCourses,
  licenses,
  currentTeam,
  location,
  ui,
  sessionUi,
  history,
}) => {
  const dispatch = useDispatch();
  const [isUpdating, setIsUpdating] = useState(false);
  const [currentView, setCurrentView] = useState('courses');

  const popupParam = getParamFromUrl(location, 'p');

  const [isNewCourseOpen, setIsNewCourseOpen] = useState(
    popupParam === 'course'
  );
  const [isNewSessionOpen, setIsNewSessionOpen] = useState(
    popupParam === 'session'
  );

  const currentTeamProfile = useCurrentTeamProfile();
  const currentTeamOrg = currentTeamProfile?.id;
  const isPro = Boolean(
    currentTeamProfile?.isPro || (currentTeam && PRO_ORGS.includes(currentTeam))
  );

  const theme = useTheme();
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < parseInt(theme.breakpoints.md, 10);

  useEffect(() => {
    const loadData = async () => {
      await Promise.all([
        dispatch(courseActions.list({ fetchNextPage: true })),
        dispatch(sessionActions.listAll({ fetchNextPage: true })),
      ]);
    };
    loadData();
    dispatch(enrolmentActions.listLicenses());
  }, [dispatch]);

  useEffect(() => {
    if (licenses.length > 0) {
      let coursesToFetch: string[] = [];
      licenses.forEach((l) => {
        const slugs = l.courses.map((c) => c.slug);
        coursesToFetch = [...new Set([...coursesToFetch, ...slugs])];
      });
      coursesToFetch.forEach((courseSlug) => {
        dispatch(learnerCourseActions.retrieve(courseSlug));
      });
    }
  }, [licenses.length]);

  const handleSave = async (data: Partial<ICourseListItem>) => {
    setIsUpdating(true);
    const newCourseRes = await dispatch(courseActions.create(data));
    setIsUpdating(false);
    setIsNewCourseOpen(false);
    // @ts-ignore
    if (newCourseRes?.payload?.entities?.courses) {
      const newCourse =
        // @ts-ignore
        newCourseRes.payload.entities.courses[newCourseRes.payload.result];
      if (newCourse.courseType === 'session') {
        analytics.track('Standalone Session Created');
        history.push(
          navRoutes.cms.standaloneSession.path(
            newCourse.standaloneModule.toString()
          )
        );
      } else {
        analytics.track('Course Created From Scratch');
        history.push({
          pathname: navRoutes.cms.editCourse.path(newCourse.id.toString()),
          search: 't=new',
        });
      }
    }
  };

  const isLoading = ui.courseList.loading && isEmpty(courses);
  const sessionsLoading = sessionUi.sessionList.loading && isEmpty(allSessions);

  const licensedCoursesByOrg = licensedCourses
    ? Object.values(licensedCourses).reduce(
        (
          obj: {
            [orgId: string]: {
              id: number;
              name: string;
              courses: CourseDetails[];
              contactEmail: string | null;
            };
          },
          value
        ) => {
          const key = value.organisation.id.toString();
          if (obj[key] == null) {
            obj[key] = {
              id: value.organisation.id,
              name: value.organisation.name,
              courses: [],
              contactEmail: value.organisation.contactEmail,
            };
          }

          obj[key].courses.push(value);
          return obj;
        },
        {}
      )
    : null;

  const myCourses = Object.values(courses).filter(
    (c) => c.organisation === currentTeamOrg && c.courseType !== 'session'
  );

  const showCreateCourse =
    PLATFORM === 'steppit' ||
    (currentTeam && CREATE_COURSE_WHITELIST.includes(currentTeam));

  const showSessions = true;

  const tabs = [
    {
      slug: 'courses',
      label: 'Courses',
      icon: ICONS.course,
    },
    {
      slug: 'sessions',
      label: 'Sessions',
      icon: ICONS.session,
    },
  ];

  return (
    <ScreenWrapper>
      <InPageNav
        tabs={tabs}
        initialTab="courses"
        onSwitchTab={(activeTab) => setCurrentView(activeTab)}
        navByParams
        rightElement={
          <Stack direction={{ base: 'column', sm: 'row' }} spacing={1}>
            {showCreateCourse && (
              <Button
                onClick={() => setIsNewCourseOpen(true)}
                icon="Queue"
                size={isMobile ? 'xs' : 'sm'}
                variant="outline"
              >
                <Text>New Course</Text>
              </Button>
            )}
            <Button
              onClick={() => setIsNewSessionOpen(true)}
              icon="Add"
              size={isMobile ? 'xs' : 'sm'}
              variant="outline"
            >
              <Text>
                <chakra.span display={{ base: 'none', md: 'inline' }}>
                  {'Create a '}
                </chakra.span>
                Session
              </Text>
            </Button>
          </Stack>
        }
      />
      {currentView === 'courses' && (
        <>
          {isPro && (
            <Box mx={{ base: 'defaultMargin', md: 0 }}>
              <InformationCard id="pro_content" mb={6} />
            </Box>
          )}
          {licensedCoursesByOrg && (
            <>
              <Box mx={{ base: 'defaultMargin', md: 0 }}>
                <InformationCard id="course_preview_section" mb={6} />
              </Box>
              {Object.values(licensedCoursesByOrg).map((org) => {
                return (
                  <Box key={`library-org-${org.id}`} mb={4}>
                    <SectionTitle title={`Courses from ${org.name}`} />
                    {org.contactEmail && (
                      <Text
                        marginX={{ base: 'defaultMargin', md: 0 }}
                        color="text.muted"
                        fontSize="sm"
                        mb={4}
                      >
                        {`If you have any questions for ${org.name}, please contact `}
                        <chakra.a
                          href={`mailto:${org.contactEmail}`}
                          color="text.primary"
                          fontWeight="semibold"
                        >
                          {org.contactEmail}
                        </chakra.a>
                        {'.'}
                      </Text>
                    )}
                    <Flex
                      flexDirection="column"
                      position="relative"
                      mb="defaultMargin"
                    >
                      {Object.values(org.courses).map((course) => {
                        return (
                          <Link
                            key={`licensed-${course.slug}`}
                            to={navRoutes.cms.previewCourse.path(course.id)}
                            style={{ display: 'block' }}
                          >
                            <Card
                              direction="column"
                              mb="defaultMargin"
                              padding={0}
                              cursor="pointer"
                              _hover={{
                                bg: 'background.tint1',
                                transform: { base: 'auto', md: 'scale(1.01)' },
                              }}
                              transition={{
                                base: 'none',
                                md: 'transform 0.3s',
                              }}
                            >
                              <ItemLarge
                                title={course.title}
                                image={course.imageLandscapeThumbnail}
                                buttonLabel="Preview Course"
                                buttonLink={navRoutes.cms.previewCourse.path(
                                  course.id
                                )}
                                tag="Agreement Active"
                                tagBg="background.info"
                                tagColor="text.info"
                              />
                            </Card>
                          </Link>
                        );
                      })}
                    </Flex>
                  </Box>
                );
              })}
            </>
          )}

          {isLoading ? (
            <CatalogueSkeleton />
          ) : myCourses.length > 0 ? (
            <Flex direction="column" mb={8}>
              <Box>
                {/* Only show heading if there are licensed courses above */}
                {licensedCoursesByOrg && (
                  <Flex alignItems="center" mb={3}>
                    <SectionTitle title="My Courses" flex={1} mb={0} />
                  </Flex>
                )}
                {myCourses.map((course) => {
                  return (
                    <Link
                      to={navRoutes.cms.editCourse.path(course.id.toString())}
                      style={{ display: 'block' }}
                    >
                      <Card
                        key={course.id}
                        direction="column"
                        mb="defaultMargin"
                        padding={0}
                        cursor="pointer"
                        _hover={{
                          bg: 'background.tint1',
                          transform: { base: 'auto', md: 'scale(1.01)' },
                        }}
                        transition={{ base: 'none', md: 'transform 0.3s' }}
                      >
                        <ItemLarge
                          key={course.id}
                          title={course.title}
                          subtitle={`${course.numUnits} units  ·  ${course.numModules} sessions`}
                          image={course.imageLandscapeThumbnail}
                          buttonLabel={
                            course.status === 'published' ||
                            PLATFORM === 'workshop'
                              ? 'Edit Course'
                              : 'Keep Building'
                          }
                          buttonLink={navRoutes.cms.editCourse.path(
                            course.id.toString()
                          )}
                          buttonSecondary={
                            course.status === 'published' ||
                            PLATFORM === 'workshop'
                          }
                          tag={
                            course.status === 'published' ||
                            PLATFORM === 'workshop'
                              ? `${course.status}${
                                  course.isLockedForEditing ? ' - Locked' : ''
                                }`
                              : undefined
                          }
                          tagBg={getBackgroundColor(course.status)}
                          tagColor={getColor(course.status)}
                          progress={
                            course.status === 'published' ||
                            PLATFORM === 'workshop'
                              ? undefined
                              : course.buildProgress
                          }
                          isPublic={
                            course.status === 'published' &&
                            PLATFORM === 'steppit'
                              ? course.isPublic
                              : null
                          }
                          isVisible={
                            course.status === 'published' &&
                            PLATFORM === 'steppit'
                              ? course.isVisible
                              : null
                          }
                          imagePlaceholderIcon={ICONS.course}
                        />
                      </Card>
                    </Link>
                  );
                })}{' '}
              </Box>
            </Flex>
          ) : null}
        </>
      )}
      {showSessions && currentView === 'sessions' && (
        <SessionList allSessions={allSessions} isLoading={sessionsLoading} />
      )}
      {showCreateCourse && showSessions && (
        // ((currentView === 'sessions' &&
        //   Object.values(allSessions).length === 0) ||
        //   (currentView === 'courses' && myCourses.length === 0)) &&
        <ButtonGrid
          mb={8}
          mx={{ base: 'defaultMargin', md: 0 }}
          items={[
            {
              slug: 'newSession',
              name: 'Make a Quick Session',
              description: `Create a standalone ${PLATFORM_DISPLAY_NAME} session to share online.`,
              icon: ICONS.session,
              onClick: () => setIsNewSessionOpen(true),
            },
            {
              slug: 'newCourse',
              name: 'Build a Full Course',
              description: `Combine multiple sessions into a course to share online or run in private classes.`,
              icon: ICONS.course,
              onClick: () => setIsNewCourseOpen(true),
            },
          ]}
        />
      )}
      <CreateCourseModal
        onSave={handleSave}
        isOpen={isNewCourseOpen}
        onClose={() => setIsNewCourseOpen(false)}
        isUpdating={isUpdating}
        title="New Course"
      />
      <NewSessionModal
        onSave={async (data) => {
          await handleSave({ ...data, courseType: 'session' });
          setIsNewSessionOpen(false);
        }}
        isOpen={isNewSessionOpen}
        onClose={() => setIsNewSessionOpen(false)}
        isUpdating={isUpdating}
        title="Create a Session"
      />
    </ScreenWrapper>
  );
};

const mapStateToProps = (state: GlobalState) => {
  const {
    organisation: { currentTeam, teamProfiles },
    learner: {
      courses: {
        courses: { detail: courseState },
      },
    },
    cms: {
      enrolment: { license: licenseState },
    },
  } = state;

  const licensedCourses = currentTeam
    ? getLicensedCourses(
        courseState,
        licenseState,
        teamProfiles[currentTeam]?.id
      )
    : {};
  const licenses = Object.values(licenseState);
  return {
    courses: state.cms.course.courseList,
    allSessions: state.cms.session.allSessions,
    licensedCourses,
    licenses,
    currentTeam,
    ui: state.ui.course,
    sessionUi: state.ui.session,
  };
};

const connector = connect(mapStateToProps);

export default connector(CourseCatalogue);
