import React from 'react';
import {
  Box,
  Flex,
  LinkButton,
  Heading,
  MdIcon,
  Progress,
  Text,
} from '@workshop/ui';
import { ICONS } from 'constants/ui';
import { HeaderTag } from 'components/AppHeader';

interface Props {
  buttonLabel?: string;
  buttonLink?: string;
  buttonSecondary?: boolean;
  image: string;
  onClick?: () => void;
  tag?: string;
  tagBg?: string;
  tagColor?: string;
  title: string;
  subtitle?: string;
  isPublic?: boolean | null;
  isVisible?: boolean | null;
  isReady?: boolean | null;
  progress?: number;
  numSteps?: number | null;
  numCourses?: number | null;
  imagePlaceholderIcon?: string;
  condensed?: boolean;
  isSelected?: boolean;
  cantBeSelected?: boolean;
}

const ItemLarge: React.FC<Props> = ({
  title,
  subtitle,
  tag,
  tagBg = 'background.tint1',
  tagColor = 'text.muted',
  onClick,
  buttonLabel,
  buttonLink,
  buttonSecondary = true,
  image,
  isPublic = null,
  isVisible = null,
  isReady = null,
  numSteps = null,
  numCourses = null,
  progress,
  imagePlaceholderIcon = 'CropOriginal',
  condensed = false,
  isSelected,
  cantBeSelected,
}) => {
  const imageSize = condensed
    ? { base: 'image.xs', md: 'image.xs' }
    : { base: 'image.sm', md: 'image.md' };
  return (
    <Flex padding={condensed ? 2 : 'defaultPadding'} alignItems="center">
      <Flex mr={4}>
        <Box
          overflow="hidden"
          borderRadius="md"
          backgroundColor="background.tint3"
        >
          {image ? (
            <Flex
              backgroundImage={`url(${image})`}
              backgroundSize="cover"
              backgroundPosition="center"
              height={imageSize}
              width={imageSize}
            />
          ) : (
            <Flex
              height={imageSize}
              width={imageSize}
              flex="1 0"
              alignItems="center"
              justifyContent="center"
            >
              <MdIcon
                name={imagePlaceholderIcon}
                color="text.muted"
                boxSize={condensed ? 6 : { base: 7, md: 8 }}
              />
            </Flex>
          )}
        </Box>
      </Flex>
      <Flex flex={1} flexDirection={{ base: 'column', lg: 'row' }}>
        <Flex flex={1} flexDirection="column" mr="defaultMargin">
          <Heading
            as="h3"
            fontWeight="semibold"
            fontSize={{ base: 'md', md: condensed ? 'md' : 'lg' }}
            noOfLines={2}
          >
            {title}
          </Heading>
          <Flex flexDirection="row" alignItems="center" flexWrap="wrap">
            {tag ? (
              <Flex mr={3} mt={1.5}>
                <HeaderTag title={tag} bg={tagBg} color={tagColor} />
              </Flex>
            ) : null}
            {isPublic === true && isReady === null ? (
              <MdIcon mr={3} mt={1.5} name="Public" color="icon.success" />
            ) : null}
            {isVisible === false ? (
              <MdIcon
                name="VisibilityOff"
                color="icon.disabled"
                mr={3}
                mt={1.5}
              />
            ) : null}
            {subtitle ? (
              <Text
                color="text.muted"
                fontSize={{ base: 'xs', sm: 'sm' }}
                mt={1.5}
              >
                {subtitle}
              </Text>
            ) : null}
          </Flex>
          {progress !== undefined ? (
            <Box w="100%">
              <Progress
                value={progress}
                // hasStripe
                isAnimated
                borderRadius="full"
                colorScheme="green"
                height={2.5}
                w="100%"
                maxWidth="300px"
                mt={2.5}
                sx={{
                  '& > div:first-child': {
                    transitionProperty: 'width',
                  },
                }}
              />
            </Box>
          ) : null}
        </Flex>
        <Flex alignItems="center">
          {isReady === true ? (
            <MdIcon name="Done" color="icon.success" mr={1.5} />
          ) : isReady === false ? (
            <MdIcon
              name="IncompleteCircle"
              color="icon.muted"
              mr={1.5}
              opacity={0.5}
            />
          ) : null}
          {numSteps !== null && numCourses !== null && (
            <>
              <Text
                color="text.muted"
                fontSize={{ base: 'xs', sm: 'sm' }}
                mr={1.5}
              >
                {`${numSteps} steps`}
              </Text>
              <Text
                color="text.muted"
                fontSize={{ base: 'xs', sm: 'sm' }}
                mr={1.5}
              >
                •
              </Text>
              <MdIcon
                name={ICONS.course}
                color="text.muted"
                mr={1.5}
                mt={1.5}
              />
              <Text
                color="text.muted"
                fontSize={{ base: 'xs', sm: 'sm' }}
                mr={3}
              >
                {`in ${numCourses} course${numCourses === 1 ? '' : 's'}`}
              </Text>
            </>
          )}
          {cantBeSelected && numCourses === null && (
            <Text color="text.muted" fontSize={{ base: 'xs', sm: 'sm' }} mr={3}>
              Already in this course
            </Text>
          )}
          {isPublic === true && isReady !== null ? (
            <MdIcon name="Public" color="icon.success" mr={3} />
          ) : null}
        </Flex>
      </Flex>
      <Flex alignItems="center" justifyContent="flex-end">
        {isSelected !== undefined ? (
          <MdIcon
            mx={3}
            color={
              cantBeSelected
                ? 'icon.disabled'
                : isSelected
                ? 'common.primary'
                : 'text.muted'
            }
            name={
              cantBeSelected
                ? 'IndeterminateCheckBox'
                : isSelected
                ? 'CheckBox'
                : 'CheckBoxOutlineBlank'
            }
          />
        ) : null}
        <Box display={{ base: 'none', md: condensed ? 'none' : 'block' }}>
          {buttonLabel && buttonLink && (
            <LinkButton
              size="sm"
              secondary={buttonSecondary}
              to={buttonLink}
              icon={!buttonSecondary ? 'ArrowForward' : undefined}
              iconPosition="right"
            >
              {buttonLabel}
            </LinkButton>
          )}
        </Box>
        <Box display={{ base: 'block', md: condensed ? 'block' : 'none' }}>
          {buttonLabel && buttonLink && (
            <LinkButton
              size="sm"
              secondary={buttonSecondary}
              to={buttonLink}
              icon="ArrowForward"
              w={8}
            />
          )}
        </Box>
      </Flex>
    </Flex>
  );
};

export default ItemLarge;
