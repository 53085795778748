import React from 'react';

import { Card, Text, Box, Button, Flex, Skeleton } from '@workshop/ui';

export const CatalogueSkeleton: React.FC<{
  isLoading?: boolean;
  condensed?: boolean;
}> = ({ isLoading = true, condensed = false }) => {
  const skeletors = [0, 1, 2]; // Numbers of 👻 courses
  const imageSize = condensed
    ? { base: 'image.xs', md: 'image.xs' }
    : { base: 'image.sm', md: 'image.md' };
  return (
    <Flex direction="column" mb={8}>
      {skeletors.map((skeletor, idx) => {
        return (
          <Card
            key={`skeleton-${skeletor}`}
            direction="column"
            mb="defaultMargin"
            padding={0}
          >
            <Flex padding={condensed ? 2 : 'defaultPadding'} flex={1}>
              <Flex flex={1}>
                <Box>
                  <Skeleton isLoaded={!isLoading}>
                    <Text boxSize={imageSize}>Image</Text>
                  </Skeleton>
                </Box>
                <Flex
                  flex={1}
                  flexDirection="column"
                  marginX="defaultMargin"
                  justifyContent="center"
                >
                  <Box>
                    <Skeleton isLoaded={!isLoading}>
                      <Text>Title</Text>
                    </Skeleton>
                  </Box>
                  {!condensed && (
                    <Box marginY="defaultMargin" maxW={'100px'}>
                      <Skeleton isLoaded={!isLoading}>
                        <Text>Status</Text>
                      </Skeleton>
                    </Box>
                  )}
                </Flex>
                <Flex flex={1} alignItems="center" justifyContent="flex-end">
                  <Skeleton isLoaded={!isLoading}>
                    <Button>Button</Button>
                  </Skeleton>
                </Flex>
              </Flex>
            </Flex>
            {/* {skeletors.map((skeletor, idx) => (
              <Box key={`skeleton-unit-${idx}`}>
                <Flex
                  flex={1}
                  padding="defaultPadding"
                  cursor="pointer"
                  border="1px"
                  borderColor="border.muted"
                >
                  <Skeleton isLoaded={!isLoading}>
                    <Text minW="200px">unit</Text>
                  </Skeleton>
                </Flex>
              </Box>
            ))} */}
          </Card>
        );
      })}
    </Flex>
  );
};
