import React, { useState, useEffect, useRef } from 'react';
import { useForm, FormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { GlobalState } from 'types';

import navRoutes from 'navigation/Routes';

import { errorToastMessage } from 'redux/actions/common/ui';
import {
  useIsUserLoading,
  useUser,
  useUserProfile,
  useDiscourseUser,
  useTeams,
  useTeamProfiles,
  useRoles,
  useUserLibraryCourses,
} from 'redux/selectors';
import { useWindowDimensions } from 'utils/hooks/useDimensions';

import { getFlagEmoji, hooks, analytics, getParamFromUrl } from 'utils';

import { profileActions, discourseActions } from 'redux/actions/common';
import { userLibraryActions } from 'redux/actions/learner';
import { courseActions } from 'redux/actions/cms';

import { COUNTRY_OPTIONS } from 'constants/settings';
import { PLATFORM, discourseUrl } from 'constants/env';
import { PLATFORM_DISPLAY_NAME } from 'constants/common';
import { ICONS } from 'constants/ui';

import {
  Box,
  Flex,
  Text,
  MdIcon,
  Input,
  Link,
  Stack,
  Progress,
  Button,
  chakra,
} from '@workshop/ui';
import {
  LabelInput,
  LabelSelect,
  LabelCheckbox,
  ButtonGrid,
  StepsModal,
} from 'components/Common';
import { UserAvatar } from 'components/UserAvatar';

interface WelcomePopupProps {
  isOpen: boolean;
  onClose: (openCreateOrg?: boolean, openChannel?: boolean) => void;
}

export const WelcomePopup: React.FC<WelcomePopupProps> = ({
  isOpen,
  onClose,
}) => {
  const { library: libraryLoading } = hooks.useLoadingDataState({
    library: {
      actions: [userLibraryActions.retrieve],
    },
  });

  const location = useLocation();
  const builtCourseParam = getParamFromUrl(location, 'c');
  const [builtCourse, setBuiltCourse] = useState(
    PLATFORM === 'steppit' && !!builtCourseParam
  );

  const { course: courseLoading } = hooks.useLoadingDataState(
    {
      course: {
        actions: builtCourseParam
          ? [() => courseActions.retrieve(parseInt(builtCourseParam))]
          : [],
      },
    },
    [builtCourseParam]
  );
  const course = useSelector((state: GlobalState) =>
    builtCourseParam ? state.cms.course.course[builtCourseParam] : null
  );

  useEffect(() => {
    if (!course && !courseLoading && builtCourse) {
      setBuiltCourse(false);
    }
  }, [courseLoading, course]);

  const userLoading = useIsUserLoading();

  const isLoading = userLoading || libraryLoading;

  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < 689;

  const fileInputRef = useRef<HTMLInputElement>(null);

  const user = useUser();
  const userProfile = useUserProfile();
  const { country, dateOfBirth } = userProfile;

  const userTeams = useTeams();
  const teamProfiles = useTeamProfiles();
  const roles = useRoles();

  const courses = useUserLibraryCourses();

  // const [userName, setUserName] = useState(user?.name);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [avatarLoading, setAvatarLoading] = useState(false);

  // 4 potential welcome flows: builtCourse, hasChannels, hasCourses & vanillaSignup
  const hasChannels = userTeams.length > 0 && !builtCourse;
  const hasCourses = courses.length > 0 && !hasChannels && !builtCourse;
  const vanillaSignup =
    !hasCourses && !hasChannels && !builtCourse && PLATFORM === 'steppit';

  const [showConfetti, setShowConfetti] = useState(false);
  const [userGoal, setUserGoal] = useState('');

  const [years, setYears] = useState({});

  const discourseUser = useDiscourseUser();

  const dispatch = useDispatch();

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    let startYear = 1900;
    const yearsArray = [];
    while (startYear <= currentYear) {
      yearsArray.push(startYear++);
    }
    const yearsObj = yearsArray.reduce(
      (acc, key) => ({
        ...acc,
        [key]: `${key}`,
      }),
      {}
    );
    setYears(yearsObj);
  }, []);

  useEffect(() => {
    if (isOpen) {
      analytics.track('Home Tour Started');
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && builtCourse && course && !courseLoading) {
      setShowConfetti(true);
    }
  }, [isOpen, builtCourse, course, courseLoading]);

  const userFormMethods = useForm<{
    // name: string;
    country: string;
    dateOfBirth: string;
    parentalConsent: boolean;
  }>({
    defaultValues: {
      // name: userName,
      country,
      dateOfBirth,
    },
  });

  const {
    register: userRegister,
    handleSubmit: handleUpdateUserSubmit,
    errors: userErrors,
    clearError: clearUserError,
    setValue: setUserValue,
    watch,
  } = userFormMethods;

  const countryValue = watch('country');

  useEffect(() => {
    if (countryValue === '-') {
      setUserValue('country', '');
    }
    if (countryValue.charAt(countryValue.length - 1) === '1') {
      setUserValue('country', countryValue.slice(0, 2));
    }
  }, [countryValue]);

  const dobValue = watch('dateOfBirth');
  const dobYear = dobValue ? dobValue.split('-')[0] : '2000';
  const dobMonth = dobValue ? `${parseInt(dobValue.split('-')[1])}` : '1';
  const dobDay = dobValue ? `${parseInt(dobValue.split('-')[2])}` : '1';

  const setDob = ({
    day,
    month,
    year,
  }: {
    day?: string;
    month?: string;
    year?: string;
  }) => {
    const d = day || dobDay;
    const m = month || dobMonth;
    const y = year || dobYear;
    const newDob = `${y}-${m.length === 1 ? `0${m}` : m}-${
      d.length === 1 ? `0${d}` : d
    }`;
    setUserValue('dateOfBirth', newDob);
    return newDob;
  };

  // useEffect(() => {
  //   if (user?.name) {
  //     setUserName(user.name);
  //     setUserValue('name', user.name);
  //   }
  // }, [user?.name]);

  const userAge = dobValue
    ? moment(moment()).diff(dobValue, 'years')
    : undefined;

  const parentalConsentValue = watch('parentalConsent');

  const onAvatarChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const files = e?.target?.files;

    if (!files?.length) return;

    // TODO : fix ts definition for createUpload body in discourse-js
    const upload = await dispatch(
      discourseActions.createUpload({
        // @ts-ignore
        'files[]': files[0],
        type: 'avatar',
        ...(discourseUser?.id ? { user_id: discourseUser.id } : {}),
      })
    );

    if (!upload) {
      dispatch(
        errorToastMessage(
          'Failed to upload image. Please refresh the page and try again.'
        )
      );
      return;
    }

    const success = await dispatch(
      discourseActions.pickAvatar(discourseUser.username, upload.id)
    );

    if (!success) {
      dispatch(
        errorToastMessage(
          'Failed to set avatar. Please refresh the page and try again.'
        )
      );
      return;
    }

    analytics.track('Profile Picture Uploaded');

    dispatch(discourseActions.getUser(user.username));
  };

  const onUpdateUserGoal = async () => {
    if (userGoal) {
      setIsSubmitting(true);
      const response = await dispatch(
        profileActions.updateUserProfile(
          {
            // @ts-ignore
            profile: {
              goal: userGoal,
            },
          },
          { toast: { success: false, error: true } }
        )
      );
      if (response.error) {
        setIsSubmitting(false);
        return;
      }
      setIsSubmitting(false);
    }
  };

  const onUpdateUserCountry = handleUpdateUserSubmit(async (data) => {
    clearUserError();
    if (data.country) {
      setIsSubmitting(true);
      const response = await dispatch(
        profileActions.updateUserProfile(
          {
            // @ts-ignore
            profile: {
              ...(data.country ? { country: data.country } : {}),
            },
          },
          { toast: { success: false, error: true } }
        )
      );
      if (response.error) {
        setIsSubmitting(false);
        return;
      }
      setIsSubmitting(false);
    }
  });

  const onUpdateUserDob = handleUpdateUserSubmit(async (data) => {
    clearUserError();
    let dateOfBirth = data.dateOfBirth;
    if (!dateOfBirth) {
      dateOfBirth = setDob({});
    }

    if (Boolean(userAge && userAge < 13)) {
      dispatch(errorToastMessage('You must be over 13 years old to continue.'));
      return;
    }
    setIsSubmitting(true);
    const response = await dispatch(
      profileActions.updateUserProfile(
        {
          // @ts-ignore
          profile: {
            dateOfBirth,
            // While we're calling this endpoint, pass in a goal for learners as they won't see the 'userGoal' step
            ...(hasCourses && !userGoal ? { goal: 'learn' } : {}),
          },
        },
        { toast: { success: false, error: true } }
      )
    );
    if (response.error) {
      setIsSubmitting(false);
      return;
    }

    analytics.track('Date of Birth Entered', {
      date_of_birth: dateOfBirth,
    });

    setIsSubmitting(false);
  });

  const goalStep = hasCourses ? null : 1;
  const countryStep = hasCourses ? 1 : 2;
  const dobStep = hasCourses ? 2 : 3;
  const finalStepIndex =
    !vanillaSignup && !hasCourses && !hasChannels && !builtCourse
      ? 4
      : hasCourses
      ? 4
      : 5;

  const titleProps = {
    fontWeight: 'extrabold',
    fontSize: { base: '3xl', md: '4xl' },
    lineHeight: 1.2,
  };

  return (
    <StepsModal
      heading=""
      isOpen={isOpen}
      onClose={onClose}
      disableClose
      hideAllStepLabels
      modalSize="2xl"
      forceHorizontalSteps
      bigNext
      disablePrev
      onCompleteStep={async (stepIndex: number) => {
        if (stepIndex === finalStepIndex) {
          const createNewChannel = !!vanillaSignup;
          const openChannel = !!builtCourse;
          onClose(createNewChannel, openChannel);
          analytics.track('Home Tour Completed');
        } else {
          analytics.track('Home Tour Step Completed');
        }
        if (stepIndex >= 0 && showConfetti) {
          setShowConfetti(false);
        }
        if (stepIndex === goalStep) {
          try {
            await onUpdateUserGoal();
          } catch {
            return 'error';
          }
        }
        if (stepIndex === countryStep) {
          try {
            await onUpdateUserCountry();
          } catch {
            return 'error';
          }
        }
        if (stepIndex === dobStep) {
          try {
            await onUpdateUserDob();
          } catch {
            return 'error';
          }
        }
      }}
      showConfetti={showConfetti}
      loading={builtCourse && courseLoading}
      steps={[
        {
          label: 'Welcome',
          icon: <MdIcon name="Celebration" />,
          nextButtonText: 'Next',
          content: (
            <Flex
              py={4}
              flexDirection="column"
              alignItems="center"
              textAlign="center"
              mb={3}
            >
              <Flex
                boxSize="image.lg"
                alignItems="center"
                justifyContent="center"
                zIndex={1}
                borderRadius="full"
              >
                <Text fontSize="6xl">{builtCourse ? '👏' : '👋'}</Text>
              </Flex>

              <Text {...titleProps} mb={2}>
                {builtCourse && PLATFORM === 'steppit'
                  ? "You've built your first course!"
                  : `Welcome to ${PLATFORM_DISPLAY_NAME}`}
              </Text>

              <Text color="text.muted" fontSize="lg" whiteSpace="break-spaces">
                {builtCourse && PLATFORM === 'steppit'
                  ? "Congratulations on taking the first step towards sharing your knowledge and running your own classes! Now, let's get you set up..."
                  : "Now, let's get you set up..."}
              </Text>
            </Flex>
          ),
        },
        ...(!hasCourses
          ? [
              {
                label: 'Goal',
                icon: <MdIcon name="Mood" />,
                nextButtonVariant: userGoal
                  ? ('solid' as 'solid')
                  : ('outline' as 'outline'),
                nextButtonText: userGoal ? 'Next' : 'Skip',
                content: (
                  <Flex
                    pt={4}
                    pb={0}
                    flexDirection="column"
                    alignItems="center"
                    textAlign="center"
                  >
                    <Flex
                      boxSize="image.lg"
                      alignItems="center"
                      justifyContent="center"
                      zIndex={1}
                      borderRadius="full"
                    >
                      <Text fontSize="6xl">🥅</Text>
                    </Flex>

                    <Text {...titleProps} mb={2}>
                      What's your primary goal?
                    </Text>

                    <Text
                      color="text.muted"
                      fontSize="lg"
                      whiteSpace="break-spaces"
                    >
                      You might want a bit of everything, but just pick the
                      closest fit.
                    </Text>
                    <ButtonGrid
                      m={4}
                      mt={6}
                      templateColumns="repeat(6, 1fr)"
                      uniform
                      condensed
                      items={[
                        {
                          slug: 'money',
                          name: '“I want to generate an income”',
                          emoji: '🤑',
                          onClick: () => setUserGoal('money'),
                          isActive: userGoal === 'money',
                        },
                        {
                          slug: 'community',
                          name: '“I want to engage with my community”',
                          emoji: '🥰',
                          onClick: () => setUserGoal('community'),
                          isActive: userGoal === 'community',
                        },
                        {
                          slug: 'easy',
                          name: '“I want a quick and easy way to create courses”',
                          emoji: '😌',
                          onClick: () => setUserGoal('easy'),
                          isActive: userGoal === 'easy',
                        },
                        {
                          slug: 'quality',
                          name: '“I want my courses to stand out from the crowd”',
                          emoji: '🤩',
                          onClick: () => setUserGoal('quality'),
                          isActive: userGoal === 'quality',
                        },
                        {
                          slug: 'business',
                          name: '“I want to train my colleagues”',
                          emoji: '👨‍💼',
                          onClick: () => setUserGoal('business'),
                          isActive: userGoal === 'business',
                        },
                        {
                          slug: 'education',
                          name: '“I want to teach my existing students”',
                          emoji: '🧑‍🏫',
                          onClick: () => setUserGoal('education'),
                          isActive: userGoal === 'education',
                        },
                      ]}
                    />
                    <Stack alignItems="center">
                      <Button
                        variant="ghost"
                        icon={userGoal === 'learn' ? 'TaskAlt' : ''}
                        iconPosition="right"
                        size="sm"
                        onClick={() => setUserGoal('learn')}
                      >
                        “I'm here to learn”
                      </Button>
                      <Button
                        variant="ghost"
                        icon={userGoal === 'other' ? 'TaskAlt' : ''}
                        iconPosition="right"
                        size="sm"
                        onClick={() => setUserGoal('other')}
                      >
                        None of the above
                      </Button>
                    </Stack>
                  </Flex>
                ),
              },
            ]
          : []),
        {
          label: 'Country',
          icon: <MdIcon name="Public" />,
          nextButtonText: 'Next',
          content: (
            <Flex
              py={4}
              flexDirection="column"
              alignItems="center"
              textAlign="center"
              mb={3}
            >
              <Flex
                boxSize="image.lg"
                alignItems="center"
                justifyContent="center"
                zIndex={1}
                borderRadius="full"
              >
                <Text fontSize="6xl">🌎</Text>
              </Flex>

              <Text {...titleProps} mb={2}>
                Where are you from?
              </Text>

              <Text
                color="text.muted"
                fontSize="lg"
                mb={6}
                whiteSpace="break-spaces"
              >
                This will help us tailor your experience to your region.
              </Text>
              <FormContext {...userFormMethods}>
                <LabelSelect
                  id="country"
                  name="country"
                  label=""
                  labelPosition="top"
                  color={countryValue ? 'text.default' : 'text.muted'}
                  error={Boolean(userErrors.country)}
                  errorMessage="This information is required."
                  isLoading={isLoading}
                  registerInputRef={userRegister}
                  options={Object.keys(COUNTRY_OPTIONS).reduce(
                    (acc, key) => ({
                      ...acc,
                      [key]:
                        key === '-'
                          ? '-'
                          : `${getFlagEmoji(key.slice(0, 2))} ${
                              COUNTRY_OPTIONS[key]
                            }`,
                    }),
                    {}
                  )}
                  defaultValue={country ? country : undefined}
                  isDisabled={isSubmitting || isLoading}
                  unsorted
                />
              </FormContext>
            </Flex>
          ),
        },
        {
          label: 'Birthday',
          icon: <MdIcon name="Cake" />,
          nextButtonText: 'Next',
          nextButtonDisabled:
            !userAge ||
            Boolean(userAge && userAge < 13) ||
            Boolean(userAge && userAge < 18 && !parentalConsentValue),
          content: (
            <Flex
              py={4}
              flexDirection="column"
              alignItems="center"
              textAlign="center"
              maxW="500px"
              mx="auto"
              mb={3}
            >
              <Flex
                boxSize="image.lg"
                alignItems="center"
                justifyContent="center"
                zIndex={1}
                borderRadius="full"
              >
                <Text fontSize="6xl">🎂</Text>
              </Flex>

              <Text {...titleProps} mb={2}>
                When's your birthday?
              </Text>

              <Text
                color="text.muted"
                fontSize="lg"
                mb={6}
                whiteSpace="break-spaces"
              >
                To keep our users safe, we need to verify your age. Please enter
                your date of birth:
              </Text>
              <FormContext {...userFormMethods}>
                <Box w="100%">
                  <Box opacity={0} height={0} overflow="hidden">
                    <LabelInput
                      id="dateOfBirth"
                      name="dateOfBirth"
                      label=""
                      placeholder="Please enter your birth date"
                      labelPosition="top"
                      labelStyleProps={{ textAlign: 'center' }}
                      error={Boolean(userErrors.dateOfBirth)}
                      errorMessage="Please enter your birth date"
                      registerInputRef={userRegister}
                      isDisabled={isSubmitting || isLoading}
                      inputType="date"
                    />
                  </Box>
                  <Stack direction="row" gap={{ base: 0, sm: 1 }}>
                    <LabelSelect
                      id="dobDay"
                      name="dobDay"
                      label="Day"
                      labelPosition="top"
                      labelStyleProps={{ textAlign: 'center' }}
                      isLoading={isLoading}
                      options={{
                        '1': '1',
                        '2': '2',
                        '3': '3',
                        '4': '4',
                        '5': '5',
                        '6': '6',
                        '7': '7',
                        '8': '8',
                        '9': '9',
                        '10': '10',
                        '11': '11',
                        '12': '12',
                        '13': '13',
                        '14': '14',
                        '15': '15',
                        '16': '16',
                        '17': '17',
                        '18': '18',
                        '19': '19',
                        '20': '20',
                        '21': '21',
                        '22': '22',
                        '23': '23',
                        '24': '24',
                        '25': '25',
                        '26': '26',
                        '27': '27',
                        '28': '28',
                        '29': '29',
                        '30': '30',
                        '31': '31',
                      }}
                      value={dobDay}
                      onChange={(e) => setDob({ day: e.target.value })}
                      isDisabled={isSubmitting || isLoading}
                      unsorted
                    />
                    <LabelSelect
                      id="dobMonth"
                      name="dobMonth"
                      label="Month"
                      labelPosition="top"
                      labelStyleProps={{ textAlign: 'center' }}
                      isLoading={isLoading}
                      options={{
                        '1': 'January',
                        '2': 'February',
                        '3': 'March',
                        '4': 'April',
                        '5': 'May',
                        '6': 'June',
                        '7': 'July',
                        '8': 'August',
                        '9': 'September',
                        '10': 'October',
                        '11': 'November',
                        '12': 'December',
                      }}
                      value={dobMonth}
                      onChange={(e) => setDob({ month: e.target.value })}
                      isDisabled={isSubmitting || isLoading}
                      unsorted
                    />
                    <LabelSelect
                      id="dobYear"
                      name="dobYear"
                      label="Year"
                      labelPosition="top"
                      labelStyleProps={{ textAlign: 'center' }}
                      isLoading={isLoading}
                      options={years}
                      value={dobYear}
                      onChange={(e) => setDob({ year: e.target.value })}
                      isDisabled={isSubmitting || isLoading}
                      reverseSort
                    />
                  </Stack>
                  {Boolean(userAge && userAge >= 13 && userAge < 18) && (
                    <LabelCheckbox
                      id="parentalConsent"
                      name="parentalConsent"
                      labelPosition="top"
                      labelStyleProps={{ textAlign: 'center' }}
                      label="Please confirm that you have consent from your parent or guardian to use this platform by ticking this box:"
                      registerInputRef={userRegister}
                      isDisabled={false}
                      tooltip="age_consent"
                      justifyContent="center"
                    />
                  )}
                  {Boolean(userAge !== undefined && userAge < 13) && (
                    <Text color="text.error" fontSize="sm">
                      You must be over 13 years old to continue. Please see our{' '}
                      <Link
                        color="text.info"
                        href={navRoutes.global.terms.path()}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms of Service
                      </Link>{' '}
                      for more information.
                    </Text>
                  )}
                </Box>
              </FormContext>
            </Flex>
          ),
        },
        ...(builtCourse
          ? [
              {
                label: 'Course',
                icon: <MdIcon name={ICONS.course} />,
                content: (
                  <Flex
                    py={4}
                    flexDirection="column"
                    alignItems="center"
                    textAlign="center"
                    mb={3}
                  >
                    <Flex
                      boxSize="image.lg"
                      alignItems="center"
                      justifyContent="center"
                      zIndex={1}
                      borderRadius="full"
                    >
                      <Text fontSize="6xl">🚀</Text>
                    </Flex>

                    <Text {...titleProps} mb={4}>
                      Your course is underway
                    </Text>
                    {course ? (
                      <Flex
                        flexDirection="column"
                        borderWidth={2}
                        p={4}
                        borderRadius="lg"
                        mb={6}
                        w="100%"
                        maxW="500px"
                        mx="auto"
                      >
                        <Text
                          fontSize="lg"
                          fontWeight="bold"
                          mb={course.subtitle ? 0 : 2}
                        >
                          {course.title}
                        </Text>
                        {course.subtitle ? (
                          <Text color="text.muted" mb={2}>
                            {/* {`${course.numUnits} units  ·  ${course.numModules} sessions`} */}
                            {course.subtitle}
                          </Text>
                        ) : null}

                        <Progress
                          value={course.buildProgress}
                          hasStripe
                          isAnimated
                          borderRadius="full"
                          colorScheme="green"
                          mt={2}
                          mb={1}
                          height={2.5}
                          w="100%"
                          maxWidth="300px"
                          mx="auto"
                          sx={{
                            '& > div:first-child': {
                              transitionProperty: 'width',
                            },
                          }}
                        />
                      </Flex>
                    ) : null}

                    <Text
                      color="text.muted"
                      fontSize="lg"
                      whiteSpace="break-spaces"
                    >
                      When you're ready to keep building your course, head to
                      the{' '}
                      <chakra.span fontWeight="bold" display="inline-block">
                        <MdIcon
                          name={ICONS.course}
                          display="inline"
                          mr={1}
                          mb={-0.5}
                        />
                        Content
                      </chakra.span>{' '}
                      tab in your sidebar.
                    </Text>
                  </Flex>
                ),
              },
              {
                label: 'Channel',
                icon: <MdIcon name="Store" />,
                nextButtonText: "Let's Go",
                content: (
                  <Flex
                    py={4}
                    flexDirection="column"
                    alignItems="center"
                    textAlign="center"
                    mb={3}
                  >
                    <Flex
                      boxSize="image.lg"
                      alignItems="center"
                      justifyContent="center"
                      zIndex={1}
                      borderRadius="full"
                    >
                      <Text fontSize="6xl">🎨</Text>
                    </Flex>

                    <Text {...titleProps} mb={2}>
                      Let's customize your channel
                    </Text>

                    <Text
                      color="text.muted"
                      fontSize="lg"
                      whiteSpace="break-spaces"
                    >
                      First, we'll make your channel into the perfect hub for
                      your courses...
                    </Text>
                  </Flex>
                ),
              },
            ]
          : [
              {
                label: 'Profile',
                icon: <MdIcon name="AccountCircle" />,
                content: (
                  <Flex
                    py={4}
                    flexDirection="column"
                    alignItems="center"
                    textAlign="center"
                    mb={3}
                  >
                    <Flex
                      boxSize="image.lg"
                      alignItems="center"
                      justifyContent="center"
                      zIndex={1}
                    >
                      <FormContext {...userFormMethods}>
                        <Box>
                          <Input
                            ref={fileInputRef}
                            id="userAvatar"
                            type="file"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={async (e) => {
                              setAvatarLoading(true);
                              await onAvatarChange(e);
                              setAvatarLoading(false);
                            }}
                            name="userAvatar"
                          />
                          <UserAvatar
                            canEdit
                            name={user.name}
                            userId={user.id}
                            avatarPicture={
                              Boolean(discourseUser?.avatarTemplate)
                                ? `${discourseUrl}${discourseUser.avatarTemplate.replace(
                                    '{size}',
                                    '240'
                                  )}`
                                : ''
                            }
                            onClick={() => fileInputRef.current?.click()}
                            isLoading={avatarLoading}
                            mr="auto"
                            ml="auto"
                            mb={4}
                            size="md"
                          />
                        </Box>
                      </FormContext>
                    </Flex>

                    <Text {...titleProps} mb={2}>
                      Add a profile picture
                    </Text>

                    <Text
                      color="text.muted"
                      fontSize="lg"
                      whiteSpace="break-spaces"
                    >
                      {`This will be visible to anyone you interact with on ${PLATFORM_DISPLAY_NAME}.`}
                    </Text>
                  </Flex>
                ),
              },
              ...(hasChannels
                ? [
                    {
                      label: 'My Channels',
                      icon: <MdIcon name="Groups" />,
                      nextButtonText: "Let's Go",
                      content: (
                        <Flex
                          py={4}
                          flexDirection="column"
                          alignItems="center"
                          textAlign="center"
                          mb={3}
                        >
                          <Flex
                            boxSize="image.lg"
                            alignItems="center"
                            justifyContent="center"
                            zIndex={1}
                            borderRadius="full"
                          >
                            <Text fontSize="6xl">🏡</Text>
                          </Flex>

                          <Text {...titleProps} mb={2}>
                            {`Your channel${userTeams.length === 1 ? '' : 's'}`}
                          </Text>

                          <Text
                            color="text.muted"
                            fontSize="lg"
                            mb={6}
                            whiteSpace="break-spaces"
                          >
                            {`Your channel${
                              userTeams.length === 1 ? ' is' : 's are'
                            } home to the content you make and the classes you run.`}
                          </Text>
                          <Flex
                            flexDirection="column"
                            w="100%"
                            maxW="500px"
                            mx="auto"
                          >
                            {userTeams.map((userTeam) => {
                              const teamProfile = teamProfiles[userTeam.team];
                              // Extract the user's roles for the current team we're iterating over
                              const roleIds =
                                userTeams.find(
                                  ({ team }) => team === userTeam.team
                                )?.roles || [];
                              const userTeamRoles = roleIds.map(
                                (roleId) => roles[roleId]?.name
                              );
                              return (
                                <Flex
                                  key={`team-${userTeam.id}`}
                                  flex={1}
                                  flexDir="column"
                                  backgroundColor="background.tint3"
                                  borderRadius="md"
                                  mt={2}
                                  overflow="hidden"
                                  alignItems="flex-start"
                                  position="relative"
                                >
                                  <Flex p={4} alignItems="center">
                                    <UserAvatar
                                      name={teamProfile?.name}
                                      userId={teamProfile?.id || 1}
                                      avatarPicture={
                                        teamProfile?.logoDark || ''
                                      }
                                      size="sm"
                                    />
                                    <Box pl={4} flex={1}>
                                      <Text fontWeight="semibold">
                                        {teamProfile?.name}
                                      </Text>
                                      <Flex alignItems="center">
                                        <Text color="text.muted" fontSize="sm">
                                          {userTeamRoles.map((r, idx) =>
                                            idx + 1 < userTeamRoles.length
                                              ? `${r}, `
                                              : r
                                          )}
                                        </Text>
                                      </Flex>
                                    </Box>
                                  </Flex>
                                </Flex>
                              );
                            })}
                          </Flex>
                        </Flex>
                      ),
                    },
                  ]
                : []),
              ...(hasCourses
                ? [
                    {
                      label: 'My Classes',
                      icon: <MdIcon name="Workspaces" />,
                      nextButtonText: "Let's Go",
                      content: (
                        <Flex
                          py={4}
                          flexDirection="column"
                          alignItems="center"
                          textAlign="center"
                          mb={3}
                        >
                          <Flex
                            boxSize="image.lg"
                            alignItems="center"
                            justifyContent="center"
                            zIndex={1}
                            borderRadius="full"
                          >
                            <Text fontSize="6xl">👥</Text>
                          </Flex>

                          <Text {...titleProps} mb={2}>
                            {`Your class${courses.length === 1 ? '' : 'es'}`}
                          </Text>

                          <Text
                            color="text.muted"
                            fontSize="lg"
                            mb={6}
                            whiteSpace="break-spaces"
                          >
                            {`Here${
                              courses.length === 1
                                ? "'s your class"
                                : ' are your classes'
                            }. When you enroll on a course, you join a class of likeminded people to share your learning journey with.`}
                          </Text>
                          <Flex
                            flexDirection="column"
                            w="100%"
                            maxW="500px"
                            mx="auto"
                          >
                            {courses.map((course) => {
                              return (
                                <Flex
                                  key={`course-${course.id}`}
                                  flex={1}
                                  flexDir="column"
                                  backgroundColor="background.tint3"
                                  borderRadius="md"
                                  mt={2}
                                  overflow="hidden"
                                  position="relative"
                                  alignItems="flex-start"
                                  textAlign="left"
                                >
                                  <Flex p={4} alignItems="center">
                                    <Flex
                                      backgroundImage={`url(${course.imageLandscapeThumbnail})`}
                                      backgroundSize="cover"
                                      backgroundPosition="center"
                                      backgroundColor="background.tint2"
                                      height={{
                                        base: 'image.md',
                                        md: 'image.md',
                                      }}
                                      width={{
                                        base: 'image.md',
                                        md: 'image.lg',
                                      }}
                                      borderRadius="sm"
                                    />
                                    <Box pl={4} flex={1}>
                                      <Text fontWeight="semibold">
                                        {course.title}
                                      </Text>
                                      <Flex alignItems="center">
                                        <Text color="text.muted" fontSize="sm">
                                          {course.organisation.name}
                                        </Text>
                                      </Flex>
                                    </Box>
                                  </Flex>
                                </Flex>
                              );
                            })}
                          </Flex>
                        </Flex>
                      ),
                    },
                  ]
                : []),
              ...(vanillaSignup
                ? [
                    {
                      label: 'Create a Channel',
                      icon: <MdIcon name="AddBusiness" />,
                      nextButtonText: 'Create Channel',
                      content: (
                        <Flex
                          py={4}
                          flexDirection="column"
                          alignItems="center"
                          textAlign="center"
                          mb={3}
                        >
                          <Flex
                            boxSize="image.lg"
                            alignItems="center"
                            justifyContent="center"
                            zIndex={1}
                            borderRadius="full"
                          >
                            <Text fontSize="6xl">🏡</Text>
                          </Flex>

                          <Text {...titleProps} mb={2}>
                            Create your channel
                          </Text>

                          <Text
                            color="text.muted"
                            fontSize="lg"
                            whiteSpace="break-spaces"
                          >
                            Your channel will become the home of your sessions,
                            courses and classes. Let's set yours up now...
                          </Text>
                        </Flex>
                      ),
                    },
                  ]
                : []),
            ]),
      ]}
    />
  );
};
