import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import navRoutes from 'navigation/Routes';
import { useDispatch } from 'react-redux';

import { sessionActions } from 'redux/actions/cms';

import {
  Flex,
  MdIcon,
  Text,
  Box,
  Select,
  Collapse,
  Spinner,
} from '@workshop/ui';

import { ICONS } from 'constants/ui';

import { Loading } from 'components/Loading';
import { InformationCard } from 'components/Common';
import { EditModal } from 'components/Common/EditModal';

interface SyncedSessionModalProps {
  isOpen: boolean;
  onClose: () => void;
  isLoading: boolean;
  state: 'info' | 'copy';
  updateState: (state: 'info' | 'copy') => void;
  courseOptions: {
    id: number;
    title: string;
  }[];
  initialCourseId?: number;
  sessionName?: string;
  sessionId: number;
}

const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const SyncedSessionModal: React.FC<SyncedSessionModalProps> = ({
  isOpen,
  onClose,
  isLoading,
  state,
  updateState,
  courseOptions,
  initialCourseId,
  sessionName,
  sessionId,
}) => {
  const [selectedCourse, setSelectedCourse] = useState(initialCourseId || null);
  const [isUnsyncing, setIsUnsyncing] = useState(false);
  const [isUnsynced, setIsUnsynced] = useState(false);
  const numCourses = courseOptions?.length;
  const dispatch = useDispatch();
  const modalStates = {
    info: {
      title: "You're editing a shared session",
      description:
        'Any edits you make here will be visible on all these courses, unless you make a copy of the session specifically for one course.',
      primaryCtaLabel: 'Got it 👌',
      primaryCtaColor: 'blue',
      primaryCtaOnClick: onClose,
      secondaryCtaLabel: 'Make a Copy',
      secondaryCtaIcon: 'ContentCopy',
      secondaryCtaOnClick: () => updateState('copy'),
    },
    copy: {
      title: 'Are you sure?',
      description:
        'If you make a copy of this session, you can make edits that will only appear on this course. Any further changes will not be shared across other courses.',
      primaryCtaLabel: 'Yes, make a copy',
      primaryCtaColor: 'yellow',
      primaryCtaOnClick: async () => {
        setIsUnsyncing(true);
        await dispatch(sessionActions.unsync(sessionId));
        setIsUnsynced(true);
        await timeout(2000);
        await dispatch(sessionActions.retrieve(sessionId));
        dispatch(sessionActions.listAll({ fetchNextPage: true }));
        onClose();
        setIsUnsyncing(false);
        setIsUnsynced(false);
      },
      secondaryCtaLabel: '',
      secondaryCtaIcon: '',
      secondaryCtaOnClick: () => null,
    },
  };
  const modalState = modalStates[state];
  const {
    title,
    description,
    primaryCtaLabel,
    primaryCtaColor,
    primaryCtaOnClick,
    secondaryCtaLabel,
    secondaryCtaIcon,
    secondaryCtaOnClick,
  } = modalState;
  return (
    <EditModal
      title={isUnsyncing ? '' : title}
      isOpen={isOpen}
      onClose={onClose}
      onCancel={isUnsyncing ? undefined : onClose}
      modalSize="xl"
      saveLabel={isUnsyncing ? '' : primaryCtaLabel}
      onSave={primaryCtaOnClick}
      saveColorScheme={primaryCtaColor}
      secondaryButtonLabel={isUnsyncing ? '' : secondaryCtaLabel}
      secondaryButtonIcon={secondaryCtaIcon}
      onSecondaryButtonClick={secondaryCtaOnClick}
      // cancelLabel="Done"
      saveDisabled={state === 'copy' && !selectedCourse}
      cancelDisabled={isUnsyncing}
      closeOnOverlayClick={!isUnsyncing}
      showCloseButton={!isUnsyncing}
    >
      {isUnsyncing ? (
        <>
          <Collapse in={isUnsyncing && !isUnsynced}>
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              width="100%"
              color="common.primary"
              mt={3}
              minHeight="100px"
            >
              <Spinner mb={4} />
              <Text>Making a copy of your session...</Text>
            </Flex>
          </Collapse>
          <Collapse in={isUnsynced}>
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              width="100%"
              mt={2}
              mb={8}
              minHeight="100px"
            >
              <Flex
                boxSize="image.lg"
                alignItems="center"
                justifyContent="center"
                zIndex={1}
                borderRadius="full"
              >
                <Text fontSize="6xl">
                  <MdIcon name="Done" color="common.progress" />
                </Text>
              </Flex>
              <Text
                fontWeight="extrabold"
                fontSize={{ base: '3xl', md: '4xl' }}
                lineHeight="1.2"
              >
                Copy complete
              </Text>
            </Flex>
          </Collapse>
        </>
      ) : isLoading ? (
        <Loading />
      ) : state === 'copy' ? (
        <>
          <Text mb={4}>{description}</Text>
          <Select
            // width={{ base: '220px', sm: '120px', md: '220px' }}
            {...(initialCourseId
              ? { defaultValue: initialCourseId }
              : { placeholder: 'Select a course to copy to' })}
            variant="filled"
            icon={<MdIcon name={ICONS.course} />}
            borderRadius="full"
            colorScheme="common.secondaryPalette"
            // ml={{ base: 0, sm: 2 }}
            // mb={{ base: 3, sm: 0 }}
            // alignSelf="flex-end"
            onChange={(e: React.FormEvent<HTMLSelectElement>) =>
              // ts-ignore
              setSelectedCourse(e.currentTarget.value)
            }
            cursor="pointer"
            mb={5}
          >
            {courseOptions?.map((c) => (
              <option key={`selectCourseOption-${c.id}`} value={c.id}>
                {c.title}
              </option>
            ))}
          </Select>
          {selectedCourse && courseOptions ? (
            <>
              <Box p={4} mb={5} borderRadius="md" bg="background.tint1">
                <Text>You're making a new copy of the session:</Text>
                <Text fontWeight="bold" mb={2}>
                  {sessionName}
                </Text>
                <Text>For the course:</Text>
                <Text fontWeight="bold">
                  {
                    courseOptions.find((c) => c.id === Number(selectedCourse))
                      ?.title
                  }
                </Text>
              </Box>

              <InformationCard
                id="synced-session-copy"
                information={{
                  status: 'warning',
                  description: "This action can't be undone",
                }}
                isDismissable={false}
                colorScheme="yellow"
                mb={6}
              />
            </>
          ) : (
            <InformationCard
              id="synced-session-select"
              information={{
                status: 'info',
                description: 'Please select a course to continue',
              }}
              isDismissable={false}
              mb={6}
            />
          )}
        </>
      ) : (
        <>
          <Text mb={6}>{`This session appears in ${numCourses} courses:`}</Text>
          <Box
            p={4}
            mb={5}
            borderRadius="md"
            bg="background.tint2"
            _dark={{ bg: 'background.tint1' }}
          >
            {courseOptions?.map((c) => (
              <Link to={navRoutes.cms.editCourse.path(c.id)}>
                <Text
                  key={`courseOption-${c.id}`}
                  fontWeight="semibold"
                  _hover={{ opacity: 0.6 }}
                  color="common.primary"
                >
                  {c.title}
                </Text>
              </Link>
            ))}
          </Box>
          <Text mb={6}>{description}</Text>
        </>
      )}
    </EditModal>
  );
};

export default SyncedSessionModal;
