import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { profileActions } from 'redux/actions/common';
import {
  useIsUserLoading,
  useDismissedInformationCards,
  useTeams,
  useCurrentTeamProfile,
  useCurrentTeam,
} from 'redux/selectors';

import { PLATFORM } from 'constants/env';
import { PRO_ORGS } from 'constants/organisation';

import { getParamFromUrl } from 'utils';

import { HomeCard, HomeCardAlt } from 'screens/common/Home';
import { ScreenWrapper } from 'screens/common/ScreenWrapper';

import { Box, Button } from '@workshop/ui';

import { InformationCard } from 'components/Common';
import { CreateOrgPopup } from 'components/CreateOrgPopup';

import navRoutes from 'navigation/Routes';

import { WelcomePopup } from './WelcomePopup';
import { ProWelcomePopup } from './ProWelcomePopup';

interface HomeScreenProps extends RouteComponentProps {}

const HomeScreen: React.FC<HomeScreenProps> = ({ location }) => {
  const isLoading = useIsUserLoading();
  const dismissedInformationCards = useDismissedInformationCards();
  const dispatch = useDispatch();
  const history = useHistory();
  const myTeams = useTeams();
  const hasTeams = myTeams.length > 0;

  const popupParam = getParamFromUrl(location, 'p');
  const [orgPopupIsOpen, setOrgPopupIsOpen] = useState(popupParam === 'new');
  const [welcomePopupIsOpen, setWelcomePopupIsOpen] = useState(false);
  const [proWelcomePopupIsOpen, setProWelcomePopupIsOpen] = useState(false);

  const currentTeamProfile = useCurrentTeamProfile();
  const currentTeam = useCurrentTeam();
  const isPro =
    PLATFORM === 'steppit' &&
    Boolean(
      currentTeamProfile?.isPro ||
        (currentTeam && PRO_ORGS.includes(currentTeam))
    );

  useEffect(() => {
    if (
      !isLoading &&
      (popupParam === 'welcome' ||
        !dismissedInformationCards.includes('welcome_popup'))
    ) {
      setWelcomePopupIsOpen(true);
    }
    if (
      !isLoading &&
      (popupParam === 'pro' ||
        (isPro && !dismissedInformationCards.includes('pro_welcome_popup')))
    ) {
      setProWelcomePopupIsOpen(true);
    }
  }, [dismissedInformationCards.length, isLoading, popupParam, isPro]);

  return (
    <Box mt={{ base: PLATFORM === 'steppit' ? '-65px' : 0, md: 0 }} zIndex={4}>
      <ScreenWrapper>
        <Box mx="auto" w="100%" px={{ base: 'defaultMargin', md: 0 }}>
          {PLATFORM === 'steppit' && isPro ? (
            <>
              {/* {!dismissedInformationCards.includes('accelerator_program') ? (
                <Box
                  maxWidth={{ base: '550px', md: '850px' }}
                  mx="auto"
                  cursor="pointer"
                  _hover={{ opacity: 0.8, transform: 'scale(1.01)' }}
                  transition="transform 0.3s"
                >
                  <Link to={navRoutes.global.acceleratorProgram.path()}>
                    <InformationCard
                      id="accelerator_program"
                      information={{
                        title:
                          'Join our accelerator program, FREE for Pro channels 🙌',
                        description:
                          'Fast-track your course creation with help from the Steppit team.',
                        thumbnail:
                          'https://d1bqmh4sljm7sh.cloudfront.net/marketing-assets/steppit-course-banner.png',
                        overlay: true,
                      }}
                      isDismissable={false}
                      cta={
                        <Button
                          icon="ArrowForward"
                          iconPosition="right"
                          size="sm"
                          // variant="outline"
                          mt={{ base: 3, md: 0 }}
                          ml={{ base: 0, md: 3 }}
                        >
                          Learn More
                        </Button>
                      }
                    />
                  </Link>
                </Box>
              ) : (
                <Box maxWidth="850px" mx="auto">
                  <InformationCard id="on_accelerator_program" />
                </Box>
              )} */}
            </>
          ) : (
            PLATFORM !== 'steppit' && (
              <InformationCard
                id={hasTeams ? 'home_screen' : 'learner_home_screen'}
                // maxWidth={PLATFORM === 'steppit' ? '850px' : '500px'}
                maxWidth="500px"
                mx="auto"
              />
            )
          )}
        </Box>

        {PLATFORM === 'steppit' ? (
          <HomeCardAlt onNewOrgClick={() => setOrgPopupIsOpen(true)} />
        ) : (
          <HomeCard />
        )}

        {orgPopupIsOpen && (
          <CreateOrgPopup
            isOpen={orgPopupIsOpen}
            onClose={() => setOrgPopupIsOpen(false)}
          />
        )}
        <WelcomePopup
          isOpen={welcomePopupIsOpen}
          onClose={(openCreateOrg?: boolean, openChannel?: boolean) => {
            setWelcomePopupIsOpen(false);
            dispatch(
              profileActions.updateUserProfile(
                {
                  dismissedInformationCards: [
                    ...dismissedInformationCards,
                    'welcome_popup',
                  ],
                },
                { toast: { success: false, error: false } }
              )
            );
            if (openChannel) {
              history.push(navRoutes.cms.editChannel.path());
            } else {
              let searchParams = new URLSearchParams();
              searchParams.delete('p');
              history.push({
                pathname: location.pathname,
                search: searchParams.toString(),
              });
            }
            if (openCreateOrg) {
              setOrgPopupIsOpen(true);
            }
          }}
        />
        {PLATFORM === 'steppit' && (
          <ProWelcomePopup
            isOpen={proWelcomePopupIsOpen && !welcomePopupIsOpen}
            onClose={() => {
              setProWelcomePopupIsOpen(false);
              dispatch(
                profileActions.updateUserProfile(
                  {
                    dismissedInformationCards: [
                      ...dismissedInformationCards,
                      'pro_welcome_popup',
                    ],
                  },
                  { toast: { success: false, error: false } }
                )
              );
            }}
          />
        )}
      </ScreenWrapper>
    </Box>
  );
};

export default HomeScreen;
